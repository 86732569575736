import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useGetReferrals } from '@services/referrals/getReferrals';
import React, { useEffect, useState } from 'react';

interface Referral {
  name: string;
  status: 'APPROVED' | 'AWAITING' | 'REJECTED';
  createdTime: string;
}

interface ReferralsTableProps {
  lawyerId: string | null;
}

const ReferralsTable: React.FC<ReferralsTableProps> = ({ lawyerId }) => {
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [getReferrals, { loading, error }] = useGetReferrals();

  useEffect(() => {
    if (lawyerId) {
      fetchReferrals();
    }
  }, [lawyerId]);

  const fetchReferrals = async () => {
    if (!lawyerId) return;
    try {
      const response = await getReferrals({ lawyerId });
      if (Array.isArray(response)) {
        const parsedReferrals: Referral[] = response.map(
          ({ name, status, createdTime }): Referral => ({ name, status, createdTime })
        );
        setReferrals(parsedReferrals);
      } else {
        console.error('Unexpected response type:', response);
      }
    } catch (err) {
      console.error('Error fetching referrals:', err);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'approved':
        return 'success';
      case 'credited':
        return 'success';
      case 'awaiting':
        return 'warning';
      case 'rejected':
        return 'error';
      default:
        return 'default';
    }
  };

  const getStatusText = (status: string) => {
    switch (status.toLowerCase()) {
      case 'approved':
        return 'Approved, awaiting credit';
      case 'credited':
        return 'Credited!';
      case 'awaiting':
        return 'Awaiting response';
      case 'rejected':
        return 'Rejected';
    }
  };

  if (loading) return <Typography>Loading referrals...</Typography>;
  if (error) return <Typography color="error">Error loading referrals</Typography>;


  return (
    referrals.length > 0 ? (
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "text.secondary" }}>Name</TableCell>
              <TableCell sx={{ color: "text.secondary" }}>Status</TableCell>
              <TableCell sx={{ color: "text.secondary" }}>Referred At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {referrals.map((referral, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: "text.secondary" }}>{referral.name}</TableCell>
                <TableCell sx={{ color: "text.secondary" }}>
                  <Chip
                    label={getStatusText(referral.status)}
                    color={getStatusColor(referral.status)}
                    size="small"
                  />
                </TableCell>
                <TableCell sx={{ color: "text.secondary" }}>{new Date(referral.createdTime).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <></>
    )
  )
};

export default ReferralsTable;