import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const Tip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    children={
      <div>{children}</div>
    } /* Wrap this to avoid failures on disabled buttons */
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

export default Tip;
