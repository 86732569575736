import { ReactNode, createContext, useContext, useState } from "react";

export type UIMessage = {
  id?: string;
  type: "success" | "error" | "warning" | "info";
  message: string;
};

type UIMessageContextProps = {
  uiMessages: UIMessage[];
  removeUIMessage: (id: string) => void;
  showSuccessMessage: (message: string) => void;
  showErrorMessage: (message: string) => void;
};

const UIMessageContext = createContext<UIMessageContextProps>({
  uiMessages: [],
  removeUIMessage: (id: string) => { },
  showSuccessMessage: (message: string) => { },
  showErrorMessage: (message: string) => { },
});

export const UIMessageProvider = ({ children }: { children: ReactNode }) => {
  const [uiMessages, setUIMessages] = useState<UIMessage[]>([]);

  // Add UI Message
  const addUIMessage = (uiMessage: UIMessage) => {
    const id = uiMessage.id || new Date().getTime().toString();

    // trim the message
    let trimmedMessage = uiMessage?.message?.trim() ?? "";
    // ensure the sentence ends with a period
    if (![".", "!", "?"].includes(trimmedMessage.slice(-1))) {
      trimmedMessage += ".";
    }

    setUIMessages((prevState) => [
      ...prevState,
      {
        type: uiMessage?.type ?? "info",
        message: trimmedMessage,
        id,
      },
    ]);
  };

  const showSuccessMessage = (message: string) => {
    addUIMessage({
      type: "success",
      message,
    });
  };

  const showErrorMessage = (message: string) => {
    addUIMessage({
      type: "error",
      message,
    });
  };

  // Remove UI Message
  const removeUIMessage = (id: string) => {
    setUIMessages((prevState) =>
      prevState.filter((uiMessage) => uiMessage.id !== id)
    );
  };

  return (
    <UIMessageContext.Provider
      value={{
        uiMessages,
        removeUIMessage,
        showSuccessMessage,
        showErrorMessage,
      }}
    >
      {children}
    </UIMessageContext.Provider>
  );
};

export const useUIMessageContext = () => useContext(UIMessageContext);
