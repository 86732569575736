import { useGetGroupedDailyStatisticsByDateLazyQuery } from "@/graphql/generated";
import { useAppContext } from "@context/AppContext";
import { useEffect, useMemo, useState } from "react";

import MonthNavigation from "@components/MonthNavigation";
import { useCampaignsContext } from "@context/CampaignsContext";
import palette from "@design/palette";
import { UUID } from "@utils/text";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface OutreachChartProps {
  campaignId?: UUID;
}

const OutreachChart = ({ campaignId }: OutreachChartProps) => {
  const { lawyerId } = useAppContext();
  const { campaigns } = useCampaignsContext();

  // Order by created time
  const [
    getGroupedDailyStatisticsByDate,
    { data: groupedStatistics, loading, error },
  ] = useGetGroupedDailyStatisticsByDateLazyQuery();

  // Fetch data
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const campaignIds = useMemo(() => {
    return campaignId ? [campaignId] : campaigns?.map((c) => c.id);
  }, [campaignId, campaigns]);

  const fetchStatistics = () => {
    if (!lawyerId) return;
    if (!campaignIds?.length) return;
    if (!startDate || !endDate) return;

    //yyyy-mm-dd
    const start = startDate.toISOString().split("T")[0];
    const end = endDate.toISOString().split("T")[0];

    getGroupedDailyStatisticsByDate({
      variables: {
        lawyerId,
        campaignIds,
        startDate: start,
        endDate: end,
        statName: "EMAILS_SENT",
      },
    });
  };

  useEffect(() => {
    fetchStatistics();
  }, [lawyerId, campaignIds, startDate, endDate]);

  // Prepare chart data
  const data = useMemo(() => {
    if (!groupedStatistics?.statistics?.groupedByDate) return [];

    const datesWithStats: any =
      groupedStatistics.statistics.groupedByDate.reduce((acc, node) => {
        const dateKey = node.keys?.[2].split(" ")[0] as string; // date
        const value = Math.round((node.sum?.value! || 0) / 3) as number;

        return {
          ...acc,
          [dateKey]: value,
        };
      }, {});

    if (!datesWithStats) return [];

    // From start to end date use value or put 0
    const dates: Array<{
      name: string;
      value: number;
    }> = [];
    let currentDate = new Date(startDate!);

    while (currentDate <= endDate!) {
      const dateKey = currentDate.toISOString().split("T")[0];
      dates.push({
        name: dateKey,
        value: datesWithStats[dateKey] || 0,
      });

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }, [groupedStatistics]);

  const handleMonthChange = (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <>
      <MonthNavigation onChange={handleMonthChange} disabled={loading} />
      <ResponsiveContainer height={400}>
        <BarChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" fontSize={12} />
          <YAxis />
          <Tooltip formatter={(value: string) => [value, "Contacted"]} />
          <Bar dataKey="value" fill={palette.chart.fill} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default OutreachChart;
