import { useServiceCall } from "@hooks/useServiceCall";
import { fetchOutreachService } from "@utils/requests";

export type FreeLeadsParams = {
  lawyerId: string;
};

export const getFreeLeads = async ({ lawyerId }: FreeLeadsParams) => {
  const options = {
    method: "GET",
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/free-leads`,
    options
  );

  return response;
};

export const useGetFreeLeads = () =>
  useServiceCall<
    {
      freeLeads: number;
    },
    FreeLeadsParams
  >({
    serviceCall: getFreeLeads,
  });
