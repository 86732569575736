import { Typography } from "@mui/material";
import React from "react";

type InfoProps = {
  text?: React.ReactNode;
};

const Info = ({ text }: InfoProps) => (
  <Typography variant="body2" component={"h2"} color="text.secondary">
    {text}
  </Typography>
);

export default Info;
