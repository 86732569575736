import { Box, Pagination, Stack } from "@mui/material";

import { useConversationsContext } from "@context/ConversationsContext";
import ConversationSearch from "@pages/conversations/conversations/ConversationSearch";
import { useEffect, useState } from "react";
import Conversations from "./Conversations";

const ConversationsPage = () => {
  // Get conversations context
  const {
    totalPages,
    setPage,
    setEntityFilters,
    conversations,
    fetchConversations,
    fetchUnreadConversations,
    fetchTotalConversations,
  } = useConversationsContext();

  // Fetch conversations
  useEffect(() => {
    fetchConversations();
    fetchUnreadConversations();
    fetchTotalConversations();
  }, [fetchConversations]);

  const [pages, setPages] = useState<number>(0);

  useEffect(() => {
    if (!totalPages) return;
    if (totalPages === pages) return;
    setPages(totalPages);
  }, [totalPages]);

  return (
    <>
      <Box mt={0} mb={0}>
        <Stack
          direction={{
            xs: "column",
            sm: "row",
          }}
          spacing={1}
          alignItems={{
            xs: "flex-start",
            sm: "center",
          }}
          justifyContent={{
            xs: "center",
            sm: "space-between",
          }}
          flexWrap={"wrap"}
          width={{ xs: "100%", sm: "auto" }}
        >
          <Box flexGrow={1} width={{ xs: "100%", sm: "auto" }}>
            <ConversationSearch
              onEntitiesFound={(filters) => setEntityFilters(filters)}
            />
          </Box>
          <Stack
            py={2}
            direction={"row"}
            width={{ xs: "100%", sm: "auto" }}
            justifyContent={{
              xs: "center",
              sm: "flex-end",
            }}
          >
            <Pagination
              count={pages}
              onChange={(event, value) => {
                setPage(value);
              }}
            />
          </Stack>
        </Stack>
      </Box>

      <Conversations conversations={conversations} />
    </>
  );
};

export default ConversationsPage;
