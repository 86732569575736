import { LawyerSettingType } from "@/models";
import { DateTime } from "luxon";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAppContext } from "./AppContext";

type MeetingAvailabilityContextProps = {
  // Add properties here
  availability: MeetingAvailability;
  copyDayToDays: (dayIndex: number, dayIndicesToCopy: number[]) => void;
  addSlot: (dayIndex: number) => void;
  removeSlot: (dayIndex: number, slotIndex: number) => void;
  updateSlot: (
    dayIndex: number,
    slotIndex: number,
    slot: MeetingAvailabilitySlot
  ) => void;
};

const MeetingAvailabilityContext =
  createContext<MeetingAvailabilityContextProps>({
    availability: [],
    copyDayToDays: (dayIndex: number, dayIndicesToCopy: number[]) => {},
    addSlot: (dayIndex: number) => {},
    removeSlot: (dayIndex: number, slotIndex: number) => {},
    updateSlot: (
      dayIndex: number,
      slotIndex: number,
      slot: MeetingAvailabilitySlot
    ) => {},
  });

type MeetingAvailabilitySlot = {
  startTime: string;
  endTime: string;
};

type MeetingAvailabilityDay = {
  dayIndex: number;
  slots: MeetingAvailabilitySlot[];
};

type MeetingAvailability = Array<MeetingAvailabilityDay>;

const DEFAULT_START_TIME = "09:00";
const DEFAULT_END_TIME = "17:00";
const DEFAULT_SLOT_DURATION = 30;

const getSlotRange = (
  startTime: string | undefined
): MeetingAvailabilitySlot => {
  if (!startTime) {
    return {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
    };
  } else {
    const start = DateTime.fromFormat(startTime, "HH:mm");
    const end = start.plus({ minutes: DEFAULT_SLOT_DURATION });
    return {
      startTime: start.toFormat("HH:mm"),
      endTime: end.toFormat("HH:mm"),
    };
  }
};

export const MeetingAvailabilityProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [availability, setAvailability] = useState<MeetingAvailability>([]);

  const { profile } = useAppContext();

  useEffect(() => {
    if (profile) {
      const lawyer = profile.lawyer;
      const settings = lawyer?.settings;
      const meetingAvailability = settings?.find(
        (setting) => setting.type === LawyerSettingType.MEETING_AVAILABILITY
      );

      if (meetingAvailability?.value) {
        const meetingAvailabilityJson = JSON.parse(
          meetingAvailability.value
        ) as MeetingAvailability;
        setAvailability(meetingAvailabilityJson);
      }
    }
  }, [profile]);

  const addSlot = (dayIndex: number) => {
    setAvailability((prevState) => {
      const newAvailability = [...prevState];
      const currentDayIndex = newAvailability.findIndex(
        (day) => day.dayIndex === dayIndex
      );

      if (currentDayIndex === -1) {
        newAvailability.push({
          dayIndex,
          slots: [getSlotRange(undefined)],
        });
      } else {
        const lastSlot =
          newAvailability[currentDayIndex].slots?.[
            newAvailability[currentDayIndex].slots.length - 1
          ];

        newAvailability[currentDayIndex].slots.push(
          getSlotRange(lastSlot?.endTime)
        );
      }
      return newAvailability;
    });
  };

  const removeSlot = (dayIndex: number, slotIndex: number) => {
    setAvailability((prevState) => {
      const newAvailability = [...prevState];
      const currentDayIndex = newAvailability.findIndex(
        (day) => day.dayIndex === dayIndex
      );

      newAvailability[currentDayIndex].slots.splice(slotIndex, 1);
      if (newAvailability[currentDayIndex].slots.length === 0) {
        newAvailability.splice(currentDayIndex, 1);
      }

      return newAvailability;
    });
  };

  const updateSlot = (
    dayIndex: number,
    slotIndex: number,
    slot: MeetingAvailabilitySlot
  ) => {
    setAvailability((prevState) => {
      const newAvailability = [...prevState];
      const currentDayIndex = newAvailability.findIndex(
        (day) => day.dayIndex === dayIndex
      );

      newAvailability[currentDayIndex].slots[slotIndex] = slot;
      return newAvailability;
    });
  };

  const copyDayToDays = (dayIndex: number, dayIndicesToCopy: number[]) => {
    setAvailability((prevState) => {
      const newAvailability = [...prevState];
      const currentDayIndex = newAvailability.findIndex(
        (day) => day.dayIndex === dayIndex
      );

      const dayToCopy = newAvailability[currentDayIndex];
      dayIndicesToCopy.forEach((copyIndex) => {
        if (copyIndex !== dayIndex) {
          const copyDayIndex = newAvailability.findIndex(
            (day) => day.dayIndex === copyIndex
          );
          if (copyDayIndex === -1) {
            newAvailability.push({
              dayIndex: copyIndex,
              slots: [...dayToCopy.slots],
            });
          } else {
            newAvailability[copyDayIndex] = {
              dayIndex: copyIndex,
              slots: [...dayToCopy.slots],
            };
          }
        }
      });

      return [...newAvailability];
    });
  };

  return (
    <MeetingAvailabilityContext.Provider
      value={{
        availability,
        addSlot,
        removeSlot,
        updateSlot,
        copyDayToDays,
      }}
    >
      {children}
    </MeetingAvailabilityContext.Provider>
  );
};

export const useMeetingAvailabilityContext = () =>
  useContext(MeetingAvailabilityContext);
