import { ServiceResponse, useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

type TestEmailCampaignTemplateParams = {
  templateId: string;
  toEmail: string;
  toName: string;
};

export const testEmailCampaignTemplate = async ({
  templateId,
  toEmail,
  toName,
}: TestEmailCampaignTemplateParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      recipient: {
        toEmail,
        toName,
      },
    },
  };

  const response = await fetchOutreachService(
    `/campaigns/templates/${templateId}/test`,
    options
  );

  return response;
};

export const useTestEmailCampaignTemplate = () =>
  useServiceCall<ServiceResponse, TestEmailCampaignTemplateParams>({
    serviceCall: testEmailCampaignTemplate,
    successMessage: "Test email sent successfully",
    errorMessage: "Error sending test email",
  });
