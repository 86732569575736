import { CampaignMemberMeetingStatus } from "@/models";
import { CheckIcon } from "@components/icons";
import { useConversationContext } from "@context/ConversationContext";
import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";

export default function ConversationMeetingChip() {
  const { conversation } = useConversationContext();

  const campaignMemberMeetingStatus = useMemo(
    () => conversation?.meetings?.[0]?.status ?? null,
    [conversation]
  );

  const isConfirmed =
    campaignMemberMeetingStatus === CampaignMemberMeetingStatus.CONFIRMED;

  return isConfirmed ? (
    <Stack direction="row" spacing={1} alignItems="center">
      <CheckIcon fontSize="small" color="success" />
      <Typography variant={"body2"} color={"success.main"}>
        Meeting Booked
      </Typography>
    </Stack>
  ) : null;
}
