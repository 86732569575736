import NewMessageSvg from "@components/icons/new_message_3.svg";
import { useAppContext } from "@context/AppContext";
import { useConversationContext } from "@context/ConversationContext";
import { Alert, Box, Button, Typography } from "@mui/material";

export const ConversationUpdatePayment = ({ center }: { center?: boolean }) => {
  const { setHighlightPaymentSetup, hasPaymentInformation } = useAppContext();
  const { invoiceUrl } = useConversationContext();

  const shouldUpdatePayment = !hasPaymentInformation || !invoiceUrl;

  const onUpdatePayment = () => {
    if (shouldUpdatePayment) {
      setHighlightPaymentSetup?.(true);
      return;
    }

    if (invoiceUrl) {
      const redirectUrl = invoiceUrl;
      window.open(redirectUrl, "_blank");
    }
  };

  return (
    <Box
      position={"absolute"}
      top={0}
      left={0}
      height={"100%"}
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={center ? "center" : "flex-end"}
      zIndex={99999}
    >
      <Alert
        severity="error"
        variant="outlined"
        elevation={2}
        icon={
          <img src={NewMessageSvg} alt="New Message" width={100} height={100} />
        }
        action={
          <Button variant="text" size="small" onClick={onUpdatePayment}>
            {shouldUpdatePayment ? "Update Payment" : "Pay Invoice"}
          </Button>
        }
      >
        <Typography variant="body2">
          You have a new lead but we couldn't charge your card.
        </Typography>
        <Typography variant="caption" color={"text.secondary"}>
          Update your payment method to view your lead.
        </Typography>
        <br />
        <Typography variant="caption" color={"text.secondary"}>
          Changes might take a while to reflect.
        </Typography>
      </Alert>
    </Box>
  );
};
