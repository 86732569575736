import { ScheduleIcon } from "@components/icons";
import { useCampaignContext } from "@context/CampaignContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 400px;
    text-align: center;
    padding: 16px;
  }
  .MuiDialogActions-root {
    justify-content: center;
  }
`;

interface ScheduleActivationDialog {
  open: boolean;
  handleClose: () => void;
  handleSave: (scheduledActivationTime: Date) => void;
}

const ScheduleActivationDialog = ({
  open,
  handleClose,
  handleSave,
}: ScheduleActivationDialog) => {
  const { campaign } = useCampaignContext(); // Access the campaign data from context
  // Set the scheduledActivationTime state to the current date + 2 days
  const [scheduledActivationTime, setScheduledActivationTime] =
    useState<Dayjs | null>(dayjs().add(2, "day"));

  useEffect(() => {
    if (campaign?.scheduledActivationTime) {
      setScheduledActivationTime(dayjs(campaign.scheduledActivationTime));
    }
  }, [campaign]);

  const handleChange = (date: Dayjs | null) => {
    setScheduledActivationTime(date);
  };

  const handleSaveClick = () => {
    if (!scheduledActivationTime) return;

    const activationTime = scheduledActivationTime.toDate(); // Convert the Dayjs object to a Date object
    handleSave(activationTime); // Call handleSave with the new name
    handleClose();
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle>Schedule Activation</DialogTitle>
      <DialogContent>
        <DateTimePicker
          value={scheduledActivationTime}
          onChange={handleChange}
          minDate={dayjs()}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSaveClick}
          disabled={!scheduledActivationTime}
          startIcon={<ScheduleIcon />}
          color="primary"
          variant="contained"
        >
          Set Activation Time
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ScheduleActivationDialog;
