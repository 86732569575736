import { ArrowLeftIcon, ArrowRightIcon } from "@components/icons";
import { IconButton, Stack, Typography } from "@mui/material";
import { formatMonthName } from "@utils/date";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";

const MIN_DATE = DateTime.fromObject({
  day: 1,
  month: 3,
  year: 2024,
}).toJSDate();

interface MonthNavigationProps {
  minDate?: Date;
  disabled?: boolean;
  onChange: (startTime: Date, endTime: Date) => void;
}

export default function MonthNavigation({
  minDate = MIN_DATE,
  disabled = false,
  onChange,
}: MonthNavigationProps) {
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  const initializeCurrentMonth = () => {
    const currentMonthStart = DateTime.now().startOf("month");
    const currentMonthEnd = DateTime.now().endOf("month");

    setStartTime(currentMonthStart.toJSDate());
    setEndTime(currentMonthEnd.toJSDate());
  };

  const handleNextMonth = () => {
    if (!startTime || !endTime) return;

    const newStartTime = DateTime.fromJSDate(startTime)
      .plus({ months: 1 })
      .startOf("month");
    const newEndTime = DateTime.fromJSDate(endTime)
      .plus({ months: 1 })
      .endOf("month");

    setStartTime(newStartTime.toJSDate());
    setEndTime(newEndTime.toJSDate());
  };

  const handlePreviousMonth = () => {
    if (!startTime || !endTime) return;

    const newStartTime = DateTime.fromJSDate(startTime)
      .minus({ months: 1 })
      .startOf("month");
    const newEndTime = DateTime.fromJSDate(endTime)
      .minus({ months: 1 })
      .endOf("month");

    setStartTime(newStartTime.toJSDate());
    setEndTime(newEndTime.toJSDate());
  };

  useEffect(() => {
    initializeCurrentMonth();
  }, []);

  // Fetch charges when the date range changes
  useEffect(() => {
    if (!startTime || !endTime) return;

    onChange(startTime, endTime);
  }, [startTime, endTime]);

  // Can go next
  const canGoNext = useMemo(() => {
    if (disabled) return false;
    if (!endTime) return false;

    // do not allow to go next if the current month is the current month
    const currentMonth = DateTime.now().startOf("month").month;
    const endMonth = DateTime.fromJSDate(endTime).startOf("month").month;

    return endMonth !== currentMonth;
  }, [disabled, endTime]);

  const canGoPrevious = useMemo(() => {
    return !disabled && startTime && startTime > minDate;
  }, [disabled, startTime]);

  return (
    <Stack spacing={2} direction={"row"} alignItems={"center"}>
      <IconButton
        onClick={handlePreviousMonth}
        disabled={!canGoPrevious}
        color="primary"
      >
        <ArrowLeftIcon />
      </IconButton>

      <Typography variant={"subtitle1"} color={"text.primary"} fontWeight={500}>
        {!!startTime && formatMonthName(startTime)}
      </Typography>

      <IconButton
        onClick={handleNextMonth}
        disabled={!canGoNext}
        color="primary"
      >
        <ArrowRightIcon />
      </IconButton>
    </Stack>
  );
}
