import AreYouSureDialog from "@components/AreYouSureDialog";
import { AutoPilotIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import {
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { UpdateLawyerParams, useUpdateLawyer } from "@services/lawyers/updateLawyer";
import { useEffect, useMemo, useState } from "react";

const AutoPilotControl = () => {
  const { profile, lawyerId, isManaged, fetchProfile } = useAppContext();

  // Update lawyer
  const [patchLawyer, { loading: isPatchingLawyer }] = useUpdateLawyer();

  const [icp, setIcp] = useState(profile?.lawyer?.icp || "");

  useEffect(() => {
    setIcp(profile?.lawyer?.icp || "");
  }, [profile?.lawyer?.icp]);

  // Show dialog
  const [showDialog, setShowDialog] = useState(false);

  const shouldShowDialog = useMemo(() => {
    return !isManaged && showDialog && !icp;
  }, [isManaged, showDialog]);

  // Handle change
  const updateIsManaged = async () => {
    if (!lawyerId) return;

    const payload: UpdateLawyerParams = {
      lawyerId,
      isManaged: !isManaged,
    }

    if (isManaged) {
      payload.icp = icp;
    }

    try {
      await patchLawyer(
        payload,
        {
          successMessage: "You have turned your auto pilot mode on.",
          errorMessage: "Failed to turn on auto pilot mode.",
        }
      );
      fetchProfile?.();
      setShowDialog(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isManaged && !icp) {
      setShowDialog(true);
    } else {
      updateIsManaged();
    }
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isManaged}
            onChange={handleChange}
            color="primary"
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={
          <Typography variant="body2">
            {isManaged ? "Auto pilot mode is on" : "Auto pilot mode is off"}
          </Typography>
        }
      />
      <AreYouSureDialog
        open={shouldShowDialog}
        canConfirm={!!icp && !isPatchingLawyer && !isManaged}
        confirmText="Turn On Auto Pilot Mode"
        title={
          <Stack direction="row" spacing={1}>
            <AutoPilotIcon color="success" />
            <Typography>Auto Pilot Mode</Typography>
          </Stack>
        }
        text={
          <Stack spacing={2}>
            {!profile?.lawyer?.icp && (
              <Stack spacing={1}>
                <Typography>
                  Please let us know your ideal customer profile below
                </Typography>
                <TextField
                  label="Your ideal customer profile"
                  multiline
                  rows={4}
                  value={icp}
                  onChange={(e) => setIcp(e.target.value)}
                />
              </Stack>
            )}
            <Typography>
              Kular will automatically draft and launch campaigns to generate
              leads from individuals within your ideal customer profile
            </Typography>
          </Stack>
        }
        onConfirm={updateIsManaged}
        onClose={() => setShowDialog(false)}
      />
    </>
  );
};

export default AutoPilotControl;
