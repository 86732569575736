import {
  ROUTE_CAMPAIGNS,
  ROUTE_CONVERSATIONS,
  ROUTE_DASHBOARD,
  ROUTE_STATISTICS,
} from "@/AuthenticatedAppRoutes";
import { TrackedEventName } from "@/third-party/tracking";
import Tracked from "@components/Tracked";
import {
  CampaignIcon,
  StatisticsIcon
} from "@components/icons";
import PageContent from "@components/layout/page/PageContent";
import { useCampaignsContext } from "@context/CampaignsContext";
import { useConversationsContext } from "@context/ConversationsContext";
import palette from "@design/palette";
import { Person2 } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Badge, Tab, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CampaignsPage from "@pages/campaigns/campaigns/CampaignsPage";
import ConversationsPage from "@pages/conversations/conversations/ConversationsPage";
import Statistics from "@pages/statistics/OverallStatistics";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
export type DashboardNavigationProps = {};

export default function DashboardNavigation(props: DashboardNavigationProps) {
  const navigationRoutes = [
    ROUTE_DASHBOARD,
    ROUTE_CAMPAIGNS,
    ROUTE_CONVERSATIONS,
    ROUTE_STATISTICS,
  ] as const;

  type NavigationRoute = (typeof navigationRoutes)[number];

  const [tabValue, setTabValue] = useState<NavigationRoute | null>(null);

  // Check if the current route matches any of the navigation routes
  // Use react, I ll import later
  const { pathname: currentPathname } = useLocation();
  const currentRoute = navigationRoutes.find(
    (route) => route === currentPathname
  );

  // If the current route is not null and it is not the same as the tab value
  // set the tab value to the current route
  useEffect(() => {
    if (currentRoute && currentRoute !== tabValue) {
      if (currentRoute === ROUTE_DASHBOARD) {
        setTabValue(ROUTE_CAMPAIGNS);
      } else {
        setTabValue(currentRoute);
      }
    } else if (!currentRoute) {
      setTabValue(null);
    }
  }, [currentRoute, tabValue]);

  // Go to page
  const navigate = useNavigate();
  const goToPage = (page: NavigationRoute) => {
    navigate(page);
  };

  // Conversations
  const {
    totalConversations,
    unreadConversations,

    isLoading: isLoadingConversations,
    fetchTotalConversations,
    fetchUnreadConversations,
  } = useConversationsContext();

  useEffect(() => {
    if (isLoadingConversations) return;

    if (!totalConversations) {
      fetchTotalConversations();
    }
    if (!unreadConversations) {
      fetchUnreadConversations();
    }
  }, [
    totalConversations,
    unreadConversations,
    isLoadingConversations,
    fetchTotalConversations,
  ]);

  // Campaigns
  const { campaigns } = useCampaignsContext();
  return tabValue ? (
    <TabContext value={tabValue}>
      <Box
        bgcolor={palette.background.paper}
        sx={{
          ".MuiTab-root": {
            textAlign: "center",
            color: palette.primary.main,
          },
        }}
      >
        <Tracked onChange={{ name: TrackedEventName.DASHBOARD_TAB_CHANGED }}>
          <TabList
            onChange={() => { }}
            aria-label="dashboard tabs"
            sx={{
              backgroundColor: "background.info",
              borderBottom: "1px solid #f0f0f0",
              overflowX: "auto",
              whiteSpace: "nowrap", // Allow content to overflow horizontally
              pt: 1,
            }}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab
              sx={{
                a: {
                  textDecoration: "none",
                },
              }}
              color={
                tabValue === ROUTE_CAMPAIGNS ? "primary" : "text.secondary"
              }
              label={
                <Box>
                  <Typography
                    variant={tabValue === ROUTE_CAMPAIGNS ? "body1" : "body2"}
                    component={"h4"}
                    flexGrow={1}
                    color={"text.primary"}
                    textTransform={"none"}
                    sx={{
                      transition: "all 0.2s ease-in",
                      fontWeight: tabValue === ROUTE_CAMPAIGNS ? "bold" : 400,
                    }}
                  >
                    Campaigns
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    textTransform={"none"}
                  >
                    {campaigns?.length} campaigns
                  </Typography>
                </Box>
              }
              value={ROUTE_CAMPAIGNS}
              icon={
                <Badge
                  badgeContent={2}
                  color="warning"
                  variant="dot"
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                >
                  <CampaignIcon fontSize="large" />
                </Badge>
              }
              iconPosition="top"
              onClick={() => goToPage(ROUTE_CAMPAIGNS)}
            />
            <Tab
              sx={{
                ml: 2,
                a: {
                  textDecoration: "none",
                  color: "inherit",
                },
              }}
              color="inherit"
              label={
                <Box textAlign={"center"}>
                  <Typography
                    variant={
                      tabValue === ROUTE_CONVERSATIONS ? "body1" : "body2"
                    }
                    color={"text.primary"}
                    component={"h4"}
                    textTransform={"none"}
                    gutterBottom={false}
                    sx={{
                      transition: "all 0.2s ease-in",
                      fontWeight:
                        tabValue === ROUTE_CONVERSATIONS ? "bold" : 400,
                    }}
                  >
                    Leads
                  </Typography>

                  <Typography
                    variant="caption"
                    color="text.secondary"
                    textTransform={"none"}
                  >
                    {totalConversations}{" "}
                    {totalConversations === 1
                      ? "lead"
                      : "leads"}{" "}
                    {!!unreadConversations
                      ? `(${unreadConversations} unreviewed)`
                      : ``}
                  </Typography>
                </Box>
              }
              disabled={isLoadingConversations}
              value={ROUTE_CONVERSATIONS}
              icon={
                <Badge
                  badgeContent={unreadConversations}
                  color="warning"
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                >
                  <Person2 fontSize="medium" />
                </Badge>
              }
              iconPosition="top"
              onClick={() => goToPage(ROUTE_CONVERSATIONS)}
            />
            <Tab
              sx={{
                a: {
                  textDecoration: "none",
                  color: "inherit",
                },
              }}
              label={
                <Box textAlign={"center"}>
                  <Typography
                    variant={tabValue === ROUTE_STATISTICS ? "body1" : "body2"}
                    component={"h4"}
                    color={"text.primary"}
                    flexGrow={1}
                    textTransform={"none"}
                    sx={{
                      transition: "all 0.2s ease-in",
                      fontWeight: tabValue === ROUTE_STATISTICS ? "bold" : 400,
                    }}
                  >
                    Statistics
                  </Typography>

                  <Typography
                    variant="caption"
                    color="text.secondary"
                    textTransform={"none"}
                  >
                    View your reports
                  </Typography>
                </Box>
              }
              value={ROUTE_STATISTICS}
              icon={<StatisticsIcon fontSize="medium" />}
              iconPosition="top"
              onClick={() => goToPage(ROUTE_STATISTICS)}
            />
          </TabList>
        </Tracked>
        <PageContent>
          <TabPanel value={ROUTE_CAMPAIGNS} sx={{ px: 0, py: 1 }}>
            <CampaignsPage />
          </TabPanel>
          <TabPanel value={ROUTE_CONVERSATIONS} sx={{ px: 0, py: 1 }}>
            <ConversationsPage />
          </TabPanel>
          <TabPanel value={ROUTE_STATISTICS} sx={{ px: 0, py: 1 }}>
            <Statistics />
          </TabPanel>
        </PageContent>
      </Box>
    </TabContext>
  ) : null;
}
