import { Prospect, prospectToContact } from "@/models";
import ContactAvatar from "@components/ContactAvatar";
import { useCampaignContext } from "@context/CampaignContext";
import { Avatar, AvatarGroup, Box } from "@mui/material";

export default function ProspectStack() {
  const { pagedProspects } = useCampaignContext();
  const firstPageOfPropsects = pagedProspects?.[1];

  if (!firstPageOfPropsects) return null;

  return (
    <Box mt={1} mr={2}>
      <AvatarGroup
        color="primary"
        max={5}
        total={0}
        renderSurplus={(surplus: number) => (
          <Avatar
            sx={{
              width: 28,
              height: 28,
              fontSize: "0.75rem",
              backgroundColor: "primary.main",
              color: "#fff",
              zIndex: 1,
            }}
          >
            {`+${surplus}`}
          </Avatar>
        )}
        sx={{
          flexDirection: "row-reverse",
          "& .MuiAvatar-root": {
            width: 28,
            height: 28,
            fontSize: "0.75rem",
          },
          ".MuiBox-root": {
            marginLeft: "-8px",
          },
        }}
      >
        {firstPageOfPropsects.map((prospect: Prospect) => {
          const contact = prospectToContact(prospect);
          return <ContactAvatar key={contact.id} contact={contact} size={12} />;
        })}
      </AvatarGroup>
    </Box>
  );
}
