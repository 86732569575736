import { ServiceResponse, useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

type DeleteLinkedinCampaignTemplateParams = {
  templateId: string;
};

export const deleteLinkedinCampaignTemplate = async ({
  templateId,
}: DeleteLinkedinCampaignTemplateParams) => {
  const options: FetchOptions = {
    method: "DELETE",
  };

  const response = await fetchOutreachService(
    `/linkedin/templates/${templateId}`,
    options
  );

  return response;
};

export const useDeleteLinkedinCampaignTemplate = () =>
  useServiceCall<ServiceResponse, DeleteLinkedinCampaignTemplateParams>({
    serviceCall: deleteLinkedinCampaignTemplate,
    successMessage: "Template deleted successfully",
    errorMessage: "Error deleting template",
  });
