import { Avatar, Box, Popover } from "@mui/material";
import { useState } from "react";

type PreviewedAvatarProps = {
  alt?: string;
  src?: string;
  size: number;
  showPreview?: boolean;
  onError?: () => void;
};

export default function PreviewedAvatar({
  src,
  alt,
  size = 44,
  showPreview = true,
  onError = () => {},
}: PreviewedAvatarProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null);

  const handleHidePreview = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Avatar
        sx={{
          width: size,
          height: size,
          backgroundColor: "primary.main",
          color: "#fff",
        }}
        alt={alt}
        src={src}
        onError={onError}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleHidePreview}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          borderRadius={200}
          width={200}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          p={1}
        >
          <img src={src} alt={alt} width={150} />
        </Box>
      </Popover>
    </Box>
  );
}
