import palette from "@design/palette";
import { Box, BoxProps, Stack, Typography } from "@mui/material";
import React from "react";
import { Watch } from "react-loader-spinner";

type ControlledBoxProps = {
  loading?: boolean;
  loadingMessage?: React.ReactNode | string;
  disabled?: boolean;
  error?: string | null;
  children: React.ReactNode;
  keepContentOnError?: boolean;
} & BoxProps;

const ControlledBox: React.FC<ControlledBoxProps> = ({
  loading = false,
  loadingMessage,
  disabled = false,
  error = null,
  children,
  keepContentOnError = false,
  ...boxProps
}) => {
  const shouldShowContent = !!(!loading && (keepContentOnError || !error));

  return (
    <Box p={2} {...boxProps}>
      {loading && (
        <Stack p={4} px={2} direction="row" alignItems="center" spacing={1}>
          <Watch
            height="40"
            width="40"
            radius="24"
            color={palette.primary.main}
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            visible={true}
          />
          {loadingMessage && typeof loadingMessage === "string" && (
            <Typography variant="body1" color={"text.secondary"}>
              {loadingMessage}
            </Typography>
          )}
          {loadingMessage && typeof loadingMessage !== "string" && (
            <>{loadingMessage}</>
          )}
        </Stack>
      )}
      {error && (
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      )}
      {shouldShowContent && <>{children}</>}
    </Box>
  );
};

export default ControlledBox;
