import { TrackedEventName } from "@/third-party/tracking";
import Tracked from "@components/Tracked";
import { useAppContext } from "@context/AppContext";
import { PauseCircleOutline as PauseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { usePauseAccount } from "@services/lawyers/pauseAccount";
import { useState } from "react";

type PauseAccountDialogButtonProps = {} & ButtonProps;

const PauseAccountDialogButton = ({
  ...props
}: PauseAccountDialogButtonProps) => {
  const { profile, isPaused: isAppPaused, fetchProfile } = useAppContext();

  // Pause acount
  const [pauseAccount, { loading: isLoading }] = usePauseAccount();

  // Pause account dialog
  const [showPauseAccountDialog, setShowPauseAccountDialog] = useState(false);

  const [isPaused, setIsPaused] = useState<boolean | null>(isAppPaused ?? null);

  const closeDialog = () => {
    setIsPaused(null);
    setShowPauseAccountDialog(false);
  };

  const handlePauseAccount = async () => {
    if (!profile?.lawyer?.id) return;

    // Simulate API call to pause the account
    await pauseAccount({
      lawyerId: profile?.lawyer?.id || "",
      isPaused: true,
    });
    // Replace the following setTimeout with your actual API call

    fetchProfile?.();
    closeDialog();
  };

  const handleUnpauseAccount = async () => {
    if (!profile?.lawyer?.id) return;
    await pauseAccount({
      lawyerId: profile?.lawyer?.id || "",
      isPaused: false,
    });

    fetchProfile?.();
    closeDialog();
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setShowPauseAccountDialog(true)}
        {...props}
      >
        {isPaused ? "Unpause Account" : "Pause account"}
      </Button>
      <Dialog open={showPauseAccountDialog} onClose={() => closeDialog}>
        <DialogTitle>
          <Stack direction="row" alignItems="center" spacing={2}>
            <PauseIcon />
            <Typography variant="h6">
              {isPaused ? "Unpause Account" : "Pause Account"}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            spacing={2}
            alignItems={"flex-start"}
            sx={{ width: 400 }}
          >
            <Typography variant="body2" color={"text.secondary"}>
              {isPaused
                ? "Your account is currently paused. "
                : "Pause your account for a week. You won't receive notifications during this period."}
            </Typography>
            {isPaused && (
              <Typography variant="body2" color={"text.secondary"}>
                Your account is paused.
              </Typography>
            )}
            <Stack direction="row" spacing={1} alignItems="center">
              <Button variant="outlined" color="primary" onClick={closeDialog}>
                Cancel
              </Button>
              <Tracked
                onClick={{
                  name: isPaused
                    ? TrackedEventName.ACCOUNT_UNPAUSED
                    : TrackedEventName.ACCOUNT_PAUSED,
                }}
              >
                <LoadingButton
                  variant={isPaused ? "contained" : "outlined"}
                  color={isPaused ? "primary" : "error"}
                  onClick={isPaused ? handleUnpauseAccount : handlePauseAccount}
                  loading={isLoading}
                >
                  {isPaused ? "Unpause Account" : "Pause account for a week"}
                </LoadingButton>
              </Tracked>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PauseAccountDialogButton;
