import { CampaignMemberMeetingStatus } from "@/models";
import ControlledBox from "@components/ControlledBox";
import InfoBox from "@components/InfoBox";
import { CheckIcon } from "@components/icons";
import {
  CampaignMemberMeetingProvider,
  useCampaigmMemberMeetingContext,
} from "@context/CampaigmMemberMeetingContext";
import {
  Avatar,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useBookMeeting } from "@services/meetings/bookMeeting";
import {
  CronofyElementPermission,
  useGetCronofyElementToken,
} from "@services/meetings/getCronofyElementToken";
import { getMeetingDisplayDate } from "@utils/date";
import { UUID } from "@utils/text";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BookMeeting from "./BookMeeting";

export default function CampaignMemberMeetingPage() {
  const { campaignMemberMeetingId } = useParams();
  return campaignMemberMeetingId ? (
    <CampaignMemberMeetingProvider
      campaignMemberMeetingId={campaignMemberMeetingId as UUID}
    >
      <CampaignMemberMeeting />
    </CampaignMemberMeetingProvider>
  ) : null;
}
function CampaignMemberMeeting() {
  // get campaign member meeting id from url param
  const {
    meetingNotFound,
    lawyer,
    contact,
    company,
    campaignMemberMeeting,
    isGettingCampaignMemberMeeting,
    campaignMemberMeetingError,
    fetchCampaignMemberMeeting,
  } = useCampaigmMemberMeetingContext();

  const { campaignMemberMeetingId } = useParams();

  const [contactTimezone, setContactTimezone] = useState<string | null>(null);

  useEffect(() => {
    if (contact?.timezone) {
      setContactTimezone(contact.timezone);
    }
  }, [contact?.timezone]);

  // Confirm meeting
  // Book meeting
  const [bookMeeting, { loading: isBookingMeeting }] = useBookMeeting();

  const handleBookMeeting = async (
    start: string,
    end: string,
    timezone: string
  ) => {
    if (!campaignMemberMeetingId) return;
    if (!lawyer?.id) return;

    await bookMeeting({
      campaignMemberMeetingId: campaignMemberMeetingId as UUID,
      lawyerId: lawyer.id,
      start: start,
      end: end,
    });
    fetchCampaignMemberMeeting();

    if (timezone) {
      setContactTimezone(timezone);
    }
  };

  // Book meeting
  const canBookMeeting =
    campaignMemberMeeting?.status.toString() ===
      CampaignMemberMeetingStatus.SCHEDULED &&
    lawyer?.id &&
    !isBookingMeeting;

  const isMeetingBooked =
    campaignMemberMeeting?.status?.toString() ===
    CampaignMemberMeetingStatus.CONFIRMED;

  //
  const [
    getCronofyElementToken,
    { data: cronofyElementTokenData, loading: isGettingCronofyElementToken },
  ] = useGetCronofyElementToken();

  useEffect(() => {
    if (lawyer?.id) {
      getCronofyElementToken({
        lawyerId: lawyer.id,
        permissions: [
          CronofyElementPermission.AVAILABILITY,
          CronofyElementPermission.MANAGED_AVAILABILITY,
        ],
      });
    }
  }, [lawyer?.id]);

  return (
    <ControlledBox
      bgcolor="background.paper"
      p={8}
      alignItems={"center"}
      loading={isGettingCampaignMemberMeeting || isGettingCronofyElementToken}
      minHeight={"100vh"}
      error={
        campaignMemberMeetingError
          ? "Can not book meetings. Please try again later."
          : null
      }
    >
      <Stack direction="column" spacing={3} alignItems="center">
        {!!lawyer?.logoSrc && (
          <Avatar
            src={lawyer.logoSrc}
            alt={lawyer.name}
            sx={{
              width: 80,
              height: 80,
              img: {
                objectFit: "contain",
              },
            }}
          />
        )}
        <Stack direction={"column"} spacing={2} textAlign={"center"}>
          <Typography variant={"h6"}>Hi {contact?.firstName},</Typography>
          <Typography variant={"subtitle1"}>
            Book a meeting with <b>{lawyer?.name}</b> from{" "}
            <b>{lawyer?.firmName}</b>
          </Typography>
        </Stack>
      </Stack>
      {isBookingMeeting && (
        <Stack direction={"column"} spacing={2} alignItems={"center"} p={4}>
          <CircularProgress size={24} />
          <Typography variant={"h5"} color="primary">
            Booking meeting...
          </Typography>
        </Stack>
      )}
      {isMeetingBooked && (
        <InfoBox mt={2}>
          <Stack direction={"column"} spacing={2} alignItems={"center"} p={4}>
            <Stack direction="row" spacing={1} alignItems="center">
              <CheckIcon fontSize="medium" color="success" />
              <Typography variant={"h5"} color="success.main">
                Meeting booked
              </Typography>
            </Stack>
            <Typography variant={"body1"} color="text.secondary">
              {lawyer?.name} ({lawyer?.firmName}){" <-> "} {contact?.firstName}{" "}
              {contact?.lastName} ({company?.name})
            </Typography>
            <Typography variant={"body1"}>
              {getMeetingDisplayDate(
                campaignMemberMeeting?.startTime,
                campaignMemberMeeting?.endTime,
                contactTimezone
              )}
            </Typography>
          </Stack>
        </InfoBox>
      )}
      {meetingNotFound && <MeetingNotFound />}
      <Box mt={2}>
        {canBookMeeting && <BookMeeting onDateSelect={handleBookMeeting} />}
      </Box>
    </ControlledBox>
  );
}

const MeetingNotFound = () => {
  return <div>Meeting Not Found</div>;
};
