import { ServiceResponse, useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

export type PatchLinkedinMessagePayload = {
  lawyerViewedTime: Date;
};

export type PatchLinkedinMessageParams = {
  linkedinMessageId: string;
} & PatchLinkedinMessagePayload;

export const patchLinkedinMessage = async ({
  linkedinMessageId,
  lawyerViewedTime,
}: PatchLinkedinMessageParams) => {
  const options: FetchOptions = {
    method: "PATCH",
    payload: {
      lawyerViewedTime: lawyerViewedTime.toISOString(),
    },
  };

  const response = await fetchOutreachService(
    `/linkedin-messages/${linkedinMessageId}`,
    options
  );

  return response;
};

export const usePatchLinkedinMessage = () =>
  useServiceCall<ServiceResponse, PatchLinkedinMessageParams>({
    serviceCall: patchLinkedinMessage,
    successMessage: "LinkedinMessage message updated successfully",
    errorMessage: "Failed to update linkedin message",
  });
