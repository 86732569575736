import "@aws-amplify/ui-react/styles.css";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import FAQPage from "@pages/account/FAQPage";
import LogoutPage from "@pages/account/LogOutPage";
import CampaignPage from "@pages/campaigns/campaign/CampaignPage";
import BlacklistPage from "@pages/campaigns/campaigns/BlacklistPage";
import CampaignCreatePage from "@pages/campaigns/create/CampaignCreatePage";
import ConversationPage from "@pages/conversations/conversation/ConversationPage";
import ConversationsPage from "@pages/conversations/conversations/ConversationsPage";
import DashboardPage from "@pages/dashboard/DashboardPage";
import AuthenticateCalendarPage from "@pages/meetings/AuthenticateCalendarPage";
import OnboardingPage from "@pages/onboarding/OnboardingPage";
import ProfilePage from "@pages/profile/ProfilePage";
import BillingPage from "@pages/profile/billing/BillingPage";

export const ROUTE_DASHBOARD = "/";
export const ROUTE_ONBOARDING = "/onboarding";
export const ROUTE_CAMPAIGNS = "/campaigns";
export const ROUTE_CAMPAIGN = "/campaigns/:id";
export const ROUTE_CAMPAIGN_CREATE = "/campaigns/new";
export const ROUTE_CONVERSATIONS = "/conversations";
export const ROUTE_CONVERSATION = "/conversations/:id";
export const ROUTE_MEETINGS = "/meetings";
export const ROUTE_WARM_REPLIES = "/warm-replies";
export const ROUTE_CONNECTIONS = "/linkedin-connections";
export const ROUTE_ACCOUNT = "/account";
export const ROUTE_SETTINGS = "/settings";
export const ROUTE_LOGOUT = "/logout";

export const ROUTE_STATISTICS = "/statistics";
export const ROUTE_PROFILE = "/profile";
export const ROUTE_TESTIMONIALS = "/testimonials";
export const ROUTE_BLACKLIST = "/blacklist";
export const ROUTE_SENDER_PROFILES = "/sender-profiles";
export const ROUTE_LINKEDIN_AUTHENTICATION = "/linkedin-authentication";

export const ROUTE_MEETINGS_AUTHENTICATE = "/meetings/authenticate";
export const ROUTE_MEETINGS_BOOK = "/meetings/book/:campaignMemberMeetingId";
export const ROUTE_FAQ = "/faq";
export const ROUTE_BILLING = "/payments";

export const getRoute = (
  route: string,
  params?: Record<string, any> | null,
  hash?: string
) => {
  let returnRoute = route;

  if (params) {
    returnRoute = Object.keys(params).reduce((acc, param) => {
      return acc.replace(`:${param}`, params[param]);
    }, route);
  }

  // Add hash to route
  if (hash) {
    returnRoute = `${returnRoute}#${hash}`;
  }

  return returnRoute;
};

const AuthenticatedAppRoutes: React.FC = (props) => {
  return (
    <Routes>
      <Route path={ROUTE_CAMPAIGN} element={<CampaignPage />} />
      <Route path={ROUTE_CAMPAIGNS} element={<DashboardPage />} />
      <Route path={ROUTE_CONVERSATIONS} element={<DashboardPage />} />
      <Route path={ROUTE_CONVERSATION} element={<ConversationPage />} />
      <Route path={ROUTE_CAMPAIGN_CREATE} element={<CampaignCreatePage />} />
      <Route path={ROUTE_PROFILE} element={<ProfilePage />} />
      <Route path={ROUTE_CONNECTIONS} element={<ConversationsPage />} />
      <Route path={ROUTE_STATISTICS} element={<DashboardPage />} />
      <Route path={ROUTE_BLACKLIST} element={<BlacklistPage />} />
      <Route path={ROUTE_BILLING} element={<BillingPage />} />
      <Route path={ROUTE_ONBOARDING} element={<OnboardingPage />} />
      <Route path={ROUTE_LOGOUT} element={<LogoutPage />} />
      <Route
        path={ROUTE_MEETINGS_AUTHENTICATE}
        element={<AuthenticateCalendarPage />}
      />
      <Route path={ROUTE_FAQ} element={<FAQPage />} />
      <Route path="*" element={<Navigate to={ROUTE_CAMPAIGNS} replace />} />
    </Routes>
  );
};

export default AuthenticatedAppRoutes;
