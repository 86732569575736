import { ServiceResponse, useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

type CreateEmailCampaignTemplate = {
  campaignId: string;
  content: string;
  orderIndex: number;
  delay: number;
  status: string;
};

export const createEmailCampaignTemplate = async ({
  campaignId,
  content,
  orderIndex,
  delay,
  status,
}: CreateEmailCampaignTemplate) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      delay,
      campaignId,
      orderIndex,
      status,
      content,
    },
  };

  const response = await fetchOutreachService(`/campaigns/templates`, options);

  return response;
};

export const useCreateEmailCampaignTemplate = () =>
  useServiceCall<ServiceResponse, CreateEmailCampaignTemplate>({
    serviceCall: createEmailCampaignTemplate,
    successMessage: "Your message was added!",
    errorMessage: "Can not add your message right now..",
  });
