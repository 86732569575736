import { Box, BoxProps } from "@mui/material";

type RoundedBoxProps = {
  children: React.ReactNode;
} & BoxProps;

const RoundedBox: React.FC<RoundedBoxProps> = ({
  children,
  ...boxProps
}: RoundedBoxProps) => {
  return (
    <Box
      bgcolor={(theme) => theme.palette.background.paper}
      border={1}
      borderColor={(theme) => theme.palette.divider}
      borderRadius={2}
      overflow={"hidden"}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export default RoundedBox;
