import { Lawyer } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

export type UpdateLawyerParams = {
  lawyerId: UUID;
  websiteUrl?: string | null;
  linkedinUrl?: string | null;
  liVerificationCode?: string;
  lawyerDescription?: string;
  firmDescription?: string;
  leadsPerMonth?: number | null;
  isPaused?: boolean;
  icp?: string;
  isManaged?: boolean;
  timezone?: string;
};

export const updateLawyer = async ({
  lawyerId,
  liVerificationCode,
  lawyerDescription,
  firmDescription,
  websiteUrl,
  linkedinUrl,
  leadsPerMonth,
  icp,
  isManaged,
  isPaused,
  timezone,
}: UpdateLawyerParams) => {
  const options: FetchOptions = {
    method: "PUT",
    payload: {
      liVerificationCode,
      lawyerDescription,
      firmDescription,
      websiteUrl,
      linkedinUrl,
      leadsPerMonth,
      icp,
      isManaged,
      isPaused,
      timezone,
    },
  };

  const response = await fetchOutreachService(`/lawyers/${lawyerId}`, options);

  return response;
};

export const useUpdateLawyer = () =>
  useServiceCall<Lawyer, UpdateLawyerParams>({
    serviceCall: updateLawyer,
    successMessage: "Updated your profile successfully",
    errorMessage: "Failed to update profile",
  });
