import { TrackedEventName } from "@/third-party/tracking";
import Tracked from "@components/Tracked";
import { LinkIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import palette from "@design/palette";
import { Box, Button } from "@mui/material";
import { useGetStripeManageBillingLink } from "@services/subscription/getStripeManageBillingLink";
import { useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";

const PaymentButtons = () => {
  const { profile } = useAppContext();

  const [
    fetchManageBillingLink,
    {
      data: manageBillingLink,
      error: manageBillingLinkError,
      loading: fetchingData,
    },
  ] = useGetStripeManageBillingLink();
  const handleManageBilling = () => {
    if (!profile?.lawyer?.id) return;

    fetchManageBillingLink({ lawyerId: profile?.lawyer?.id });
  };

  useEffect(() => {
    if (manageBillingLinkError) {
      console.error(
        "Failed to fetch manage billing link:",
        manageBillingLinkError
      );
    } else if (manageBillingLink?.redirectUrl) {
      const { redirectUrl } = manageBillingLink;
      window.open(redirectUrl, "_self");
    }
  }, [manageBillingLink, manageBillingLinkError]);

  if (!profile?.lawyer?.stripeCustomerId) {
    return null;
  }

  return (
    <Box>
      <Tracked
        onClick={{
          name: TrackedEventName.BILLING_PORTAL_VIEWED,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          startIcon={
            fetchingData ? (
              <RotatingLines
                strokeColor={palette.primary.main}
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              <LinkIcon fontSize="small" />
            )
          }
          onClick={handleManageBilling}
        >
          Open Billing Portal
        </Button>
      </Tracked>
    </Box>
  );
};

export default PaymentButtons;
