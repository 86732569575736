import { useGetCampaignMemberMeetingLazyQuery } from "@/graphql/generated";
import {
  Campaign,
  CampaignMemberMeeting,
  Company,
  Contact,
  Lawyer,
} from "@/models";
import { normalizeGraphqlResult } from "@utils/graphql";
import { UUID } from "@utils/text";
import { createContext, useContext, useEffect } from "react";

// CampaigmMemberMeetingContextProps is a type that defines the shape of the context
type CampaigmMemberMeetingContextProps = {
  campaignMemberMeeting: CampaignMemberMeeting | null;
  lawyer: Lawyer | null;
  campaign: Campaign | null;
  contact: Contact | null;
  company: Company | null;

  meetingNotFound: boolean;
  isGettingCampaignMemberMeeting: boolean;
  campaignMemberMeetingError: string | null;
  fetchCampaignMemberMeeting: () => void;
};

// CampaigmMemberMeetingContext is the context itself
const CampaigmMemberMeetingContext =
  createContext<CampaigmMemberMeetingContextProps>({
    campaignMemberMeeting: null,
    lawyer: null,
    campaign: null,
    contact: null,
    company: null,
    meetingNotFound: false,
    isGettingCampaignMemberMeeting: false,
    campaignMemberMeetingError: null,
    fetchCampaignMemberMeeting: () => {},
  });

interface CampaignMemberMeetingProviderProps {
  campaignMemberMeetingId: UUID;
  children: React.ReactNode;
}

export const CampaignMemberMeetingProvider = ({
  children,
  campaignMemberMeetingId,
}: CampaignMemberMeetingProviderProps) => {
  const [
    getCampaignMemberMeeting,
    { data, loading: isGettingCampaignMemberMeeting, error },
  ] = useGetCampaignMemberMeetingLazyQuery();

  const fetchCampaignMemberMeeting = () => {
    if (campaignMemberMeetingId) {
      getCampaignMemberMeeting({
        variables: {
          campaignMemberMeetingId,
        },
      });
    }
  };

  useEffect(() => {
    fetchCampaignMemberMeeting();
  }, [campaignMemberMeetingId]);

  const campaignMemberMeeting =
    normalizeGraphqlResult(data)?.campaignMemberMeeting;
  const member = campaignMemberMeeting?.campaignMember;
  const contact = member?.contact;
  const company = contact?.company;
  const campaign = member?.campaign;
  const lawyer = campaign?.lawyer;

  const meetingNotFound = !!(
    data &&
    !campaignMemberMeeting &&
    !isGettingCampaignMemberMeeting &&
    !error
  );

  return (
    <CampaigmMemberMeetingContext.Provider
      value={{
        campaignMemberMeeting,
        lawyer,
        campaign,

        contact,
        company,
        meetingNotFound,
        isGettingCampaignMemberMeeting,
        campaignMemberMeetingError: error?.message || null,
        fetchCampaignMemberMeeting,
      }}
    >
      {children}
    </CampaigmMemberMeetingContext.Provider>
  );
};

export const useCampaigmMemberMeetingContext = () =>
  useContext(CampaigmMemberMeetingContext);
