import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

export type PatchEmailMemberFeedbackParams = {
  campaignId: string;
  memberId: string;
  feedbackId: string;
  rating?: string;
  note?: string;
};

export const patchEmailMemberFeedback = async ({
  campaignId,
  memberId,
  feedbackId,
  rating,
  note,
}: PatchEmailMemberFeedbackParams) => {
  const options: FetchOptions = {
    method: "PATCH",
    payload: {
      rating,
      note,
    },
  };

  const response = await fetchOutreachService(
    `/campaigns/${campaignId}/members/${memberId}/feedback/${feedbackId}`,
    options
  );

  return response;
};

export const usePatchEmailMemberFeedback = () =>
  useServiceCall({
    serviceCall: patchEmailMemberFeedback,
    successMessage: "Thank you for your feedback!",
    errorMessage: "An error occurred while submitting your feedback.",
  });
