import {
  CronofyElementPermission,
  useGetCronofyElementToken,
} from "@services/meetings/getCronofyElementToken";
import { UUID } from "@utils/text";
import { createContext, useContext, useEffect, useMemo } from "react";

// CronofyElementContextProps is a type that defines the shape of the context
type CronofyElementContextProps = {
  elementToken: string | null;
  subs: string[] | null;
  fetchCronofyElementToken: () => void;
};

// CronofyElementContext is the context itself
const CronofyElementContext = createContext<CronofyElementContextProps>({
  elementToken: null,
  subs: null,
  fetchCronofyElementToken: () => {},
});

interface CronofyElementProviderProps {
  children: React.ReactNode;
  lawyerId: UUID;
  permissions: Array<CronofyElementPermission>;
}

export const CronofyElementProvider = ({
  lawyerId,
  permissions,
  children,
}: CronofyElementProviderProps) => {
  const [
    getCronofyElementToken,
    {
      data: cronofyElementTokenData,
      loading: isGettingCronofyElementToken,
      error: cronofyElementTokenError,
    },
  ] = useGetCronofyElementToken();

  const fetchCronofyElementToken = () => {
    if (!lawyerId || !permissions) {
      return;
    }

    getCronofyElementToken({
      lawyerId,
      permissions,
    });
  };

  useEffect(() => {
    fetchCronofyElementToken();
  }, [lawyerId]);

  const elementToken = useMemo(() => {
    return cronofyElementTokenData?.token || null;
  }, [cronofyElementTokenData]);

  const subs = useMemo(() => {
    return cronofyElementTokenData?.subs || null;
  }, [cronofyElementTokenData]);

  return (
    <CronofyElementContext.Provider
      value={{
        elementToken,
        subs,
        fetchCronofyElementToken,
      }}
    >
      {children}
    </CronofyElementContext.Provider>
  );
};

export const useCronofyElementContext = () => useContext(CronofyElementContext);
