import { List, ListItem, Paper, Typography } from '@mui/material';
import React from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { Link } from 'react-router-dom';

interface MuiMarkdownProps {
  children: string;
}

const MuiMarkdown: React.FC<MuiMarkdownProps> = ({ children }) => {
  const components: Components = {
    h1: ({ node, ref, ...props }) => <Typography variant="h1" {...props} />,
    h2: ({ node, ref, ...props }) => <Typography variant="h2" {...props} />,
    h3: ({ node, ref, ...props }) => <Typography variant="h3" {...props} />,
    h4: ({ node, ref, ...props }) => <Typography variant="h4" {...props} />,
    h5: ({ node, ref, ...props }) => <Typography variant="h5" {...props} />,
    h6: ({ node, ref, ...props }) => <Typography variant="h6" {...props} />,
    p: ({ node, ref, ...props }) => <Typography whiteSpace="pre-wrap" {...props} />,
    a: ({ node, href, ...props }) => <Link to={href!} {...props} />,
    pre: ({ node, ...props }) => (
      <Paper elevation={3} sx={{ padding: '1rem', marginBottom: '1rem' }}>
        <pre {...props} />
      </Paper>
    ),
    ul: ({ node, ref,...props }) => (
      <List
        component="ul"
        sx={{
          listStyleType: 'disc',
          pl: 4,
          '& .MuiListItem-root': {
            display: 'list-item',
            pl: 0,
          },
        }}
        {...props}
      />
    ),
    ol: ({ node, ref,...props }) => (
      <List
        component="ol"
        sx={{
          listStyleType: 'decimal',
          pl: 4,
          '& .MuiListItem-root': {
            display: 'list-item',
            pl: 0,
          },
        }}
        {...props}
      />
    ),
    li: ({ node, ref, ...props }) => (
      <ListItem sx={{ display: 'list-item', marginLeft: '1rem' }} {...props}/>
    ),
  };

  return <ReactMarkdown components={components}>{children}</ReactMarkdown>;
};

export default MuiMarkdown;