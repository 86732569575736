import {
  AddIcon,
  BackIcon,
  CheckSingleIcon,
  DraftIcon,
  NextIcon,
} from "@components/icons";
import Page from "@components/layout/page/Page";
import SectionCard from "@components/layout/SectionCard";

import ControlledBox from "@components/ControlledBox";
import PageContent from "@components/layout/page/PageContent";
import {
  CampaignCreateContextProvider,
  CampaignCreateSteps,
  useCampaignCreateContext,
} from "@context/CampaignCreateContext";
import {
  Box,
  Button,
  Divider,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import StepCompanyTargeting from "./StepCompanyTargeting";
import StepConfirm from "./StepConfirm";
import StepFocuses from "./StepFocuses";
import StepProspects from "./StepProspects";

type CampaignStep = {
  type: CampaignCreateSteps;
  label: string;
  description: string;
  caption?: string | null;
  Component: React.FC;
};

const STEPS: Array<CampaignStep> = [
  {
    type: CampaignCreateSteps.TARGETING,
    label: "Target Companies",
    description:
      "Define your target companies by industry, size, location, and more.",
    caption: null,
    Component: StepCompanyTargeting,
  },

  {
    type: CampaignCreateSteps.PROSPECTS,
    label: "Sample Matches",
    description: "View some example contacts that match your criteria.",
    Component: StepProspects,
  },

  {
    type: CampaignCreateSteps.FOCUSES,
    label: "Your Offer",
    description: "Define your offer and the value you provide.",
    Component: StepFocuses,
  },
  {
    type: CampaignCreateSteps.CONFIRM,
    label: "Confirm",
    description: `Review and draft your campaign.`,
    Component: StepConfirm,
  },
];

function CampaignCreatePage() {
  return (
    <CampaignCreateContextProvider>
      <CampaignCreatePageContent />
    </CampaignCreateContextProvider>
  );
}

function CampaignCreatePageContent() {
  const {
    companyTargetRequest,
    canCreateCampaign,
    isCreatingCampaign,
    activeStep,
    focuses,
    isSearchingProspects,
    isCreatingCampaignRequest,
    estimatedLeadsText,
    setActiveStep,
    handleCreateCampaign,
  } = useCampaignCreateContext();

  // Lock the campaign once the user clicks create campaign
  const [isCampaignLocked, setIsCampaignLocked] = useState<boolean>(false);

  // Lock the campaign
  useEffect(() => {
    if (!isCreatingCampaign) return;
    setIsCampaignLocked(true);
  }, [isCreatingCampaign]);

  // Steps
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const stepStates = useMemo(() => {
    return {
      [CampaignCreateSteps.TARGETING]: {
        next: !!companyTargetRequest,
        prev: null,
      },
      [CampaignCreateSteps.PROSPECTS]: {
        next: true,
        prev: !isSearchingProspects && !isCreatingCampaignRequest,
      },
      [CampaignCreateSteps.FOCUSES]: {
        next: !!focuses,
        prev: true,
      },
      [CampaignCreateSteps.CONFIRM]: {
        prev: true,
        next: null,
      },
    };
  }, [
    canCreateCampaign,
    companyTargetRequest,
    focuses,
    isCreatingCampaignRequest,
    isSearchingProspects,
  ]);

  const steps = useMemo(() => {
    return STEPS.map((step) => {
      if (step.type === CampaignCreateSteps.TARGETING) {
        return {
          ...step,
          caption: companyTargetRequest,
        };
      }

      if (step.type === CampaignCreateSteps.FOCUSES) {
        return {
          ...step,
          caption: focuses,
        };
      }

      if (step.type === CampaignCreateSteps.PROSPECTS) {
        return {
          ...step,
          caption: !!estimatedLeadsText ? estimatedLeadsText : null,
        };
      }

      return step;
    });
  }, [companyTargetRequest, focuses, estimatedLeadsText]);

  return (
    <Page>
      <PageContent>
        <SectionCard
          skinny
          withBackButton
          title="Draft new campaign"
          titleIcon={<AddIcon color="primary" />}
          titleDescription="Define Your Perfect Customers & Create Your Campaign"
        >
          <Divider />

          <ControlledBox
            loading={isCampaignLocked}
            loadingMessage={
              "Drafting your campaign... This should take a minute."
            }
          >
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    onClick={() => {
                      if (index > activeStep) return;
                      setActiveStep(index);
                    }}
                    sx={{
                      cursor: "pointer",
                    }}
                    StepIconComponent={(props) => {
                      return (
                        <Box
                          borderRadius={"50%"}
                          border={`2px solid ${
                            index === activeStep
                              ? "primary.main"
                              : index < activeStep
                              ? "success.main"
                              : "text.secondary"
                          }`}
                          width={28}
                          height={28}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          mr={1}
                          ml={-1.1}
                          sx={{
                            color: "white",
                            bgcolor:
                              index === activeStep
                                ? "primary.main"
                                : index < activeStep
                                ? "success.main"
                                : "text.secondary",
                          }}
                        >
                          {index >= activeStep && props.icon}
                          {index < activeStep && (
                            <CheckSingleIcon fontSize="small" />
                          )}
                        </Box>
                      );
                    }}
                  >
                    <Stack direction="column" spacing={0}>
                      <Typography
                        variant="subtitle1"
                        color={
                          index === activeStep ? "primary" : "text.secondary"
                        }
                      >
                        {step.label}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={
                          index === activeStep ? "primary" : "text.secondary"
                        }
                      >
                        {step.description}
                      </Typography>
                      {!!step.caption && index !== activeStep && (
                        <Typography variant="body2" color={"success.main"}>
                          {step.caption}
                        </Typography>
                      )}
                    </Stack>
                  </StepLabel>
                  <StepContent TransitionProps={{ unmountOnExit: false }}>
                    <Box mt={2}>{step.Component && <step.Component />}</Box>
                    <Stack
                      mt={4}
                      direction="row"
                      spacing={1}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      {stepStates[step.type].prev !== null && (
                        <Button
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                          disabled={!stepStates[step.type].prev}
                          startIcon={<BackIcon fontSize="small" />}
                        >
                          Back
                        </Button>
                      )}
                      {stepStates[step.type].next !== null && (
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                          disabled={!stepStates[step.type].next}
                          endIcon={<NextIcon fontSize="small" />}
                        >
                          Continue
                        </Button>
                      )}
                      {index === STEPS.length - 1 && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleCreateCampaign}
                          disabled={!canCreateCampaign}
                          sx={{ mt: 1, mr: 1 }}
                          startIcon={<DraftIcon fontSize="small" />}
                        >
                          Draft Campaign
                        </Button>
                      )}
                    </Stack>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </ControlledBox>
        </SectionCard>
      </PageContent>
    </Page>
  );
}

export default CampaignCreatePage;
