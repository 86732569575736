// Campaigns Context

import { useGetAllCampaignsByLawyerIdLazyQuery } from "@/graphql/generated";
import { Campaign, CampaignStatus } from "@/models";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { normalizeGraphqlResult } from "../utils/graphql";
import { useAppContext } from "./AppContext";

const CAMPAIGN_STATUS_ORDER = [
  CampaignStatus.PENDING,
  CampaignStatus.ACTIVE,
  CampaignStatus.REVISED,
  CampaignStatus.INACTIVE,
];

type CampaignsContextProps = {
  campaigns: Array<Campaign> | null;
  isLoading: boolean;
  campaignIds?: Array<string> | null;
  fetchCampaigns?: () => void;
};

const initialValues: CampaignsContextProps = {
  campaigns: null,
  isLoading: false,
  campaignIds: [],
  fetchCampaigns: () => {},
};

const CampaignsContext = createContext<CampaignsContextProps>(initialValues);

export const CampaignsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { profile } = useAppContext();
  const [state, setState] = useState<CampaignsContextProps>(initialValues);

  const [getCampaigns, { data, loading, refetch: fetchCampaigns }] =
    useGetAllCampaignsByLawyerIdLazyQuery();

  useEffect(() => {
    if (profile?.lawyer?.id) {
      getCampaigns({
        variables: {
          lawyerId: profile.lawyer.id,
        },
      });
    }
  }, [getCampaigns, profile?.lawyer?.id]);

  const allCampaigns = useMemo(() => {
    if (!data) return null;

    const { campaigns } = normalizeGraphqlResult(data);

    // Sort by status
    campaigns.sort((a: Campaign, b: Campaign) => {
      return (
        CAMPAIGN_STATUS_ORDER.indexOf(a.status as CampaignStatus) -
        CAMPAIGN_STATUS_ORDER.indexOf(b.status as CampaignStatus)
      );
    });

    return campaigns as Array<Campaign>;
  }, [data]);

  useEffect(() => {
    if (allCampaigns) {
      // Filter out deleted campaigns and invisible statuses
      const filteredCampaigns = allCampaigns
        ?.filter((campaign: Campaign) =>
          [
            CampaignStatus.PENDING,
            CampaignStatus.ACTIVE,
            CampaignStatus.INACTIVE,
          ].includes(campaign.status as CampaignStatus)
        )
        .filter((campaign: Campaign) => !campaign.deleted);

      setState((prevState) => ({
        ...prevState,
        campaigns: filteredCampaigns,
      }));
    }
  }, [data]);

  const campaignIds = useMemo(() => {
    return allCampaigns?.map((campaign: Campaign) => campaign.id) || null;
  }, [allCampaigns]);

  return (
    <CampaignsContext.Provider
      value={{
        campaigns: state.campaigns,
        isLoading: loading,
        campaignIds,
        fetchCampaigns,
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
};

export const useCampaignsContext = () => useContext(CampaignsContext);
