import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { ReactNode } from "react";

interface AreYouSureDialogProps {
  title?: ReactNode;
  text?: ReactNode;
  cancelText?: string;
  confirmText?: string;
  open: boolean;
  canConfirm?: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const AreYouSureDialog: React.FC<AreYouSureDialogProps> = ({
  title = "For real?",
  text = "Are you sure?",
  cancelText = "Cancel",
  confirmText = "Confirm",
  open,
  canConfirm = true,
  onClose,
  onConfirm,
}) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {cancelText}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={!canConfirm}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AreYouSureDialog;
