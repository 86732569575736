import { Form } from "formik";
import styled from "styled-components";

const StyledForm = styled(Form)`
  button {
    margin-top: 16px;
  }
`;

export default StyledForm;
