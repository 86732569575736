import { Contact } from "@/models";
import { getFullName } from "@utils/text";
import { useEffect, useState } from "react";
import PreviewedAvatar from "./PreviewedAvatar";

type ContactAvatarProps = {
  contact?: Contact | null;
  size: number;
  showPreview?: boolean;
};

export default function ContactAvatar({
  contact,
  size = 44,
  showPreview = false,
}: ContactAvatarProps) {
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  const handleFailedImage = () => {
    if (imageUrl === contact?.company?.logoUrl) return;

    console.warn(
      `Failed to load image for contact ${contact?.id} - ${contact?.firstName} ${contact?.lastName} - ${contact?.company?.logoUrl}`
    );
    setImageUrl(contact?.company?.logoUrl || undefined);
  };

  useEffect(() => {
    setImageUrl(contact?.photoUrl || contact?.company?.logoUrl || undefined);
  }, [contact?.photoUrl, contact?.company?.logoUrl]);

  return contact ? (
    <PreviewedAvatar
      src={imageUrl}
      alt={getFullName(contact.firstName, contact.lastName)}
      size={size}
      showPreview={showPreview}
      onError={handleFailedImage}
    />
  ) : null;
}
