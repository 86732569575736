import { isEqual } from "lodash";
import { UUID, camelToSnakeCase, snakeToCamelCase } from "./text";

const convertObjectKeys = (
  o: any,
  keyConverter: (key: string) => string
): any => {
  let newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = convertObjectKeys(value, keyConverter);
      }
      return value;
    });
  } else {
    newO = {} as { [key: string]: any };
    for (origKey in o) {
      if (Object.prototype.hasOwnProperty.call(o, origKey)) {
        newKey = keyConverter(origKey);
        value = o[origKey];

        if (value === undefined) {
          continue;
        }

        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = convertObjectKeys(value, keyConverter);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};

export const camelToSnakeObject = (o: any): any => {
  return convertObjectKeys(o, camelToSnakeCase);
};

export const snakeToCamelObject = (o: any): any => {
  return convertObjectKeys(o, snakeToCamelCase);
};

export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  return isEqual(obj1, obj2);
};

export const removeUndefinedValues = (obj: any): any => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === undefined) {
      delete newObj[key];
    }
  });

  return newObj;
};

export const getUniqueValues = (
  arr: any[] | undefined | null,
  keys: string[]
): any[] => {
  const uniqueValues = new Set();

  arr?.forEach((item) => {
    keys.forEach((key) => {
      uniqueValues.add(item[key]);
    });
  });

  return Array.from(uniqueValues);
};

export const getRandomUUID = (): UUID => {
  return Math.random().toString(36).substring(2) as UUID;
};
