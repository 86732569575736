import Demographics from "@components/statistics/Demographics";
import OutreachChart from "@components/statistics/OutreachChart";
import { useCampaignContext } from "@context/CampaignContext";
import { Box, Stack, Typography } from "@mui/material";

const CampaignStatistics = () => {
  const { campaign } = useCampaignContext();

  return !!campaign ? (
    <Stack spacing={2}>
      <Box mb={2}>
        <Typography variant="h6">Daily Volume</Typography>
        <Typography variant="body2" color="textSecondary">
          Number of emails sent each day to new contacts
        </Typography>
      </Box>
      <OutreachChart campaignId={campaign.id} />
      <Box>
        <Typography variant="h6">Demographics</Typography>
        <Typography variant="body2" color="textSecondary">
          Demographics of the prospects of your campaign
        </Typography>
      </Box>
      <Demographics campaignId={campaign.id} />
    </Stack>
  ) : null;
};

export default CampaignStatistics;
