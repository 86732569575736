import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";

type LinkDialogProps = {
  initialValue?: {
    text: string;
    url: string;
  } | null;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (text: string, url: string) => void;
};

const LinkDialog = ({
  initialValue = {
    text: "",
    url: "",
  },
  isOpen,
  onClose,
  onConfirm,
}: LinkDialogProps) => {
  const [text, setText] = useState<string | undefined>(initialValue?.text);
  const [url, setUrl] = useState<string | undefined>(initialValue?.url);

  useEffect(() => {
    if (!initialValue) {
      return;
    }

    setText(initialValue.text);
    setUrl(initialValue.url);
  }, [initialValue]);

  const handleConfirm = () => {
    if (!text || !url) {
      return;
    }

    onConfirm(text, url);
    onClose();
  };

  return (
    <StyledLinkDialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add Link</DialogTitle>
      <DialogContent>
        <Box mt={1} mb={2}>
          <TextField
            label="Text"
            type="text"
            id="link-text"
            fullWidth
            defaultValue={initialValue?.text}
            onChange={(e) => setText(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="URL"
            type="text"
            id="link-url"
            fullWidth
            defaultValue={initialValue?.url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={!text || !url}
        >
          Add
        </Button>
      </DialogActions>
    </StyledLinkDialog>
  );
};

const StyledLinkDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 400px;
    background-color: white;

    .MuiDialogTitle-root {
    }
  }
`;

export default LinkDialog;
