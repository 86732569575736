import { ServiceResponse, useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

export type ReviseEmailCampaignTemplateParams = {
  content: string;
  templateId: string;
};

export const reviseEmailCampaignTemplate = async ({
  content,
  templateId,
}: ReviseEmailCampaignTemplateParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      content,
    },
  };

  const response = await fetchOutreachService(
    `/campaigns/templates/${templateId}/revise`,
    options
  );

  return response;
};

export const useReviseEmailCampaignTemplate = () =>
  useServiceCall<ServiceResponse, ReviseEmailCampaignTemplateParams>({
    serviceCall: reviseEmailCampaignTemplate,
    successMessage: "Template updated successfully",
    errorMessage: "Error updating template",
  });
