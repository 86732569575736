import { Box, BoxProps, Stack, Typography } from "@mui/material";
import EmptyIcon from "./icons/empty.svg";

type EmptyMessageBoxProps = {
  title: string;
  subtitle?: React.ReactNode;
  actionButton?: React.ReactNode;
  icon?: string | null;
} & BoxProps;

const EmptyMessageBox: React.FC<EmptyMessageBoxProps> = ({
  title,
  subtitle,
  actionButton,
  icon = EmptyIcon,
  ...rest
}: EmptyMessageBoxProps) => {
  return (
    <Box p={6} {...rest}>
      <Stack
        direction={"column"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
        textAlign={"center"}
      >
        <img src={icon!} alt="Empty" height={100} />
        <Box>
          <Typography variant={"subtitle1"}>{title}</Typography>
          {typeof subtitle === "string" ? (
            <Typography variant={"body2"} color={"text.secondary"}>
              {subtitle}
            </Typography>
          ) : (
            subtitle
          )}
        </Box>
        {actionButton}
      </Stack>
    </Box>
  );
};

export default EmptyMessageBox;
