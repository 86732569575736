import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

interface GetLinkedinLoginUrlParamsRequired {
  lawyerId: string;
}

type GetLinkedinLoginUrlResponse = string;

type GetLinkedinLoginUrlParams = Partial<GetLinkedinLoginUrlParamsRequired>;

export const getLinkedinLoginUrl = async ({
  lawyerId,
}: GetLinkedinLoginUrlParams) => {

  const options: FetchOptions = {
    method: "GET",
  };

  const response = await fetchOutreachService(
    `/unipile/manage/login?lawyer_id=${lawyerId}`,
    options
  );
  return response;
};


export const useGetLinkedinLoginUrl = () =>
  useServiceCall<GetLinkedinLoginUrlResponse, GetLinkedinLoginUrlParams>({
    serviceCall: getLinkedinLoginUrl,
  });
