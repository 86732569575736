//CampaignMembersContext
import { useGetCampaignMembershipLazyQuery } from "@/graphql/generated";
import { Campaign, CampaignMember } from "@/models";
import { normalizeGraphqlResult } from "@utils/graphql";
import { UUID } from "@utils/text";
import { createContext, useContext, useState } from "react";
import { useAppContext } from "./AppContext";

type GetCampaignMemberParams = {
  contactId: UUID;
  campaignId?: UUID;
  campaignMemberId?: UUID;
  useCached?: boolean;
};

type CampaignMembersContextProps = {
  contactMemberships: {
    [key: UUID]: CampaignMember;
  };
  getCampaignMember: ({
    contactId,
    campaignId,
    campaignMemberId,
  }: GetCampaignMemberParams) => Promise<CampaignMember | null>;
};

const CampaignMembersContext = createContext<CampaignMembersContextProps>({
  contactMemberships: {},
  getCampaignMember: () => Promise.resolve(null),
});

type CampaignMembersProviderProps = {
  children: React.ReactNode;
};

export const CampaignMembersProvider = ({
  children,
}: CampaignMembersProviderProps) => {
  const { profile } = useAppContext();

  const [getCampaignMembership, { data: campaignMemberships }] =
    useGetCampaignMembershipLazyQuery();

  const [contactMemberships, setContactMemberships] = useState<{
    [key: UUID]: CampaignMember;
  }>({});

  const getCampaignMember = async ({
    contactId,
    campaignId,
    campaignMemberId,
    useCached = true,
  }: GetCampaignMemberParams) => {
    if (!contactId) return null;
    if (!profile?.lawyer?.id) return null;

    if (useCached && contactMemberships[contactId]) {
      return contactMemberships[contactId];
    }

    const campaignMemberships = await getCampaignMembership({
      variables: {
        contactId,
        lawyerId: profile?.lawyer?.id,
        campaignMemberId,
      },
    });

    const { campaigns } = normalizeGraphqlResult(campaignMemberships.data);

    const members = campaigns?.reduce(
      (acc: CampaignMember[], campaign: Campaign) => {
        const { members = [] } = campaign;

        const membersWithCampaignChannel = members.map((member) => ({
          ...member,
          campaign,
        }));

        acc = [...acc, ...membersWithCampaignChannel];

        return acc;
      },
      []
    );

    let result: CampaignMember | null = null;
    if (campaignMemberId) {
      const member = members?.find(
        (member: CampaignMember) => member.id === campaignMemberId
      );
      result = member;
    }

    if (campaignId) {
      const member = members?.find(
        (member: CampaignMember) => member.campaignId === campaignId
      );
      result = member;
    }

    // Order by updated time
    members.sort((a: CampaignMember, b: CampaignMember) => {
      const dateA = new Date(a.updatedTime);
      const dateB = new Date(b.updatedTime);

      return dateA.getTime() - dateB.getTime();
    });

    result = members?.[0];

    if (result) {
      setContactMemberships((prev) => ({
        ...prev,
        [contactId]: result as CampaignMember,
      }));
    }

    return result;
  };

  return (
    <CampaignMembersContext.Provider
      value={{
        contactMemberships,
        getCampaignMember,
      }}
    >
      {children}
    </CampaignMembersContext.Provider>
  );
};

export const useCampaignMembersContext = () =>
  useContext(CampaignMembersContext);
