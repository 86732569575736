import { Typography } from "@mui/material";

const PaymentMethodNotice = () => {
  return (
    <Typography variant="body2">
      <b>Enter Your Payment Details</b> To Activate Your Account. 
    </Typography>
  )
};

export default PaymentMethodNotice;