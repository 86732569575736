import { useGetCampaignMemberChargesLazyQuery } from "@/graphql/generated";
import { CampaignMemberCharge } from "@/models";
import { useFilterChargeIds } from "@services/payments/filterChargeIds";
import { normalizeGraphqlResult } from "@utils/graphql";
import { UUID } from "crypto";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppContext } from "./AppContext";

type BillingContextProps = {
  chargeIds: Array<UUID> | null;
  charges: Array<CampaignMemberCharge> | null;
  isLoadingCharges: boolean;
  startTime: Date;
  endTime: Date;
  setStartTime: (startTime: Date) => void;
  setEndTime: (endTime: Date) => void;

  getCharges: (startTime: Date, endTime: Date) => void;
};

const BillingContext = createContext<BillingContextProps>({
  chargeIds: null,
  charges: null,
  isLoadingCharges: false,
  startTime: new Date(),
  endTime: new Date(),
  setStartTime: () => {},
  setEndTime: () => {},
  getCharges: () => {},
});

type BillingProviderProps = {
  children: ReactNode;
};

export const BillingProvider = ({ children }: BillingProviderProps) => {
  const { lawyerId } = useAppContext();

  // Start time, end time
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());

  // Filter charge ids
  const [filterChargeIds, { data: chargeIds, loading: isFilteringChargeIds }] =
    useFilterChargeIds();

  // Get campaign member charges
  const [
    getCampaignMemberCharges,
    { data: chargesResponse, loading: isGettingCharges },
  ] = useGetCampaignMemberChargesLazyQuery();

  // Get charges
  const charges = useMemo(() => {
    if (!chargesResponse) return null;

    return normalizeGraphqlResult(chargesResponse).charges || null;
  }, [chargesResponse]);

  const getCharges = useCallback(
    (startTime: Date, endTime: Date) => {
      if (!lawyerId) return;

      filterChargeIds({
        lawyerId,
        startTime,
        endTime,
      });
    },
    [lawyerId]
  );

  useEffect(() => {
    if (chargeIds) {
      getCampaignMemberCharges({
        variables: {
          ids: chargeIds,
        },
      });
    }
  }, [chargeIds]);

  return (
    <BillingContext.Provider
      value={{
        chargeIds,
        charges,
        isLoadingCharges: isFilteringChargeIds || isGettingCharges,
        startTime,
        endTime,
        setStartTime,
        setEndTime,
        getCharges,
      }}
    >
      {children}
    </BillingContext.Provider>
  );
};

export const useBillingContext = () => {
  return useContext(BillingContext);
};
