import { ROUTE_CAMPAIGN, getRoute } from "@/AuthenticatedAppRoutes";
import { Campaign } from "@/models";
import InfoBox from "@components/InfoBox";
import { AboveIcon, AverageIcon, BelowIcon } from "@components/icons";
import NavButton from "@components/layout/navigation/NavButton";

import {
  Box,
  Chip,
  Collapse,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { UUID } from "@utils/text";
import { useState } from "react";

const ConversionText = ({
  name,
  performance,
  variant,
}: {
  name: string;
  performance: number;
  variant?: "body1" | "body2";
}) => {
  if (!performance) {
    return null;
  }

  const isAboveAverage = performance > 10;
  const isBelowAverage = performance < -10;
  const isAverage = !isAboveAverage && !isBelowAverage;

  return (
    <Stack direction="row" spacing={1} alignItems={"center"}>
      <Typography variant={variant}>
        {name}'s conversion rate is{" "}
        {isAverage ? "around" : isAboveAverage ? "above" : "below"} average by
      </Typography>
      <ConversionPerformance performance={performance} />
    </Stack>
  );
};

interface ConversionPerformanceProps {
  performance: number;
}

const ConversionPerformance = ({ performance }: ConversionPerformanceProps) => {
  if (!performance) {
    return null;
  }

  const isAboveAverage = performance > 10;
  const isBelowAverage = performance < -10;
  const isAverage = !isAboveAverage && !isBelowAverage;

  if (isNaN(performance)) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1}>
      <Chip
        size="small"
        label={`${Math.floor(performance)}%`}
        color={isAverage ? "warning" : isAboveAverage ? "success" : "error"}
        icon={
          isAboveAverage ? (
            <AboveIcon color="success" fontSize="small" />
          ) : isBelowAverage ? (
            <BelowIcon color="error" fontSize="small" />
          ) : (
            <AverageIcon fontSize="small" />
          )
        }
      />
    </Stack>
  );
};

const getOrdinalByNumber = (n: number) => {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const calculateConversionPerformance = (
  performance: number,
  average: number,
  stdDeviation: number
) => {
  return ((performance - average) / stdDeviation) * 100;
};

interface ConversionsTableProps {
  campaigns: any[];
}

const PAGE_SIZE = 6;

type CampaignWithPerformance = Campaign & {
  performance: number;
};

interface ConversionsTableRowProps {
  campaign: CampaignWithPerformance;
  isViewed: boolean;
  onClick: (campaignId: UUID) => void;
}

const ConversionsTableRow = ({
  campaign,
  isViewed,
  onClick,
}: ConversionsTableRowProps) => {
  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          cursor: "pointer",
        }}
        onClick={() => onClick(campaign.id)}
        hover
      >
        <TableCell width={100} align="left">
          <ConversionPerformance performance={campaign.performance} />
        </TableCell>
        <TableCell align="left" width={"100%"}>
          <Typography variant="body2" color="text.primary">
            {campaign.name}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={isViewed} timeout="auto">
            <Box width={"100%"}>
              <Divider />
            </Box>
            <Stack
              p={2}
              spacing={2}
              bgcolor={"background.paper"}
              borderRadius={1}
              alignItems={"flex-start"}
              width={"100%"}
            >
              <ConversionText
                name={`Your campaign`}
                performance={campaign.performance}
              />
              {campaign.performance < 0 && (
                <InfoBox width={"100%"}>
                  <Typography variant="body2" color="text.secondary">
                    You can improve your conversion rate by taking these
                    actions:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body2" color="text.secondary">
                        Update your copy. We recommend short and catchy
                        messaging.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2" color="text.secondary">
                        Schedule a copy review with your Customer Success
                        Manager.
                      </Typography>
                    </li>
                  </ul>
                </InfoBox>
              )}
              {!!campaign.templates?.length && (
                <Box mt={2} pl={1}>
                  <Typography
                    variant="subtitle2"
                    color="text.primary"
                    gutterBottom
                  >
                    Conversion rates by email
                  </Typography>
                  <Stack spacing={1} flexGrow={1}>
                    {campaign.templates
                      .sort((template: any, template2: any) => {
                        return template.orderIndex - template2.orderIndex;
                      })
                      .map((template: any) => (
                        <ConversionText
                          key={template.id}
                          name={`${getOrdinalByNumber(
                            template.orderIndex + 1
                          )} email`}
                          performance={template.performance}
                          variant="body2"
                        />
                      ))}
                  </Stack>
                </Box>
              )}
              <NavButton
                size="small"
                variant="contained"
                to={`${getRoute(ROUTE_CAMPAIGN, {
                  id: campaign.id.toString(),
                })}`}
                label="View campaign"
              />
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={2}
          style={{
            padding: 0,
            height: 1,
          }}
        >
          <Divider />
        </TableCell>
      </TableRow>
    </>
  );
};

const ConversionsTable = ({ campaigns }: ConversionsTableProps) => {
  const [page, setPage] = useState(0);
  const [viewedCampaign, setViewedCampaign] = useState<any>(null);

  const pagedCampaigns = campaigns.slice(
    page * PAGE_SIZE,
    (page + 1) * PAGE_SIZE
  );

  return (
    <TableContainer component={Paper}>
      <Table
        aria-label="collapsible table"
        style={{
          tableLayout: "fixed",
        }}
      >
        <TableBody>
          {pagedCampaigns.map((campaign: any) => (
            <ConversionsTableRow
              key={campaign.id}
              campaign={campaign}
              isViewed={viewedCampaign === campaign.id}
              onClick={() =>
                setViewedCampaign(
                  viewedCampaign === campaign.id ? null : campaign.id
                )
              }
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPage={6}
              rowsPerPageOptions={[]} // hide rows per page
              count={campaigns.length}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ConversionsTable;
