import { CampaignMember } from "@/models";
import InfoBox from "@components/InfoBox";
import { Box, BoxProps, Stack, Typography } from "@mui/material";
import ConversationFeedback from "../conversations/ConversationFeedback";

interface ConversationFeedbackBoxProps extends BoxProps {
  campaignMember: CampaignMember;
}

function ConversationFeedbackBox({
  campaignMember,
  ...boxProps
}: ConversationFeedbackBoxProps) {
  return (
    <InfoBox {...boxProps}>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="h6">
            Do you want more leads like this?
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Tell us if you would like more leads like this or fewer leads like
            this
          </Typography>
        </Box>
        <Box flexGrow={1}>
          <ConversationFeedback
            withNote
            withRatingLabel
            campaignMemberId={campaignMember.id}
          />
        </Box>
      </Stack>
    </InfoBox>
  );
}

export default ConversationFeedbackBox;
