import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

type SectionTitleProps = {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  icon?: React.ReactNode;
  to?: string;
};

const SectionTitle = ({ title, subTitle, icon, to }: SectionTitleProps) => (
  <Stack direction="row" spacing={2} alignItems="center">
    {icon}
    <Stack direction="column" spacing={0} alignItems="flex-start">
      <Typography
        variant="h6"
        fontWeight={600}
        color="text.primary"
        {...{
          component: to ? Link : "h2",
          to,
        }}
        sx={{
          textDecoration: "none",
        }}
      >
        {title}
      </Typography>
      {!!subTitle && (
        <Typography variant="body2" component={"h2"} color="text.secondary">
          {subTitle}
        </Typography>
      )}
    </Stack>
  </Stack>
);

export default SectionTitle;
