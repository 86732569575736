import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

interface GetReferralsParams {
  lawyerId: string;
}

export const getReferrals = async ({
  lawyerId,
}: GetReferralsParams) => {
  const options: FetchOptions = {
    method: "GET",
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/referrals`,
    options
  );

  return response;
};

export const useGetReferrals = () =>
  useServiceCall<Blob, GetReferralsParams>({
    serviceCall: getReferrals,
  });