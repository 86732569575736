import { useGetActivePoliciesQuery } from "@/graphql/generated";
import { CheckIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import { Button, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Stack, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAcceptPolicy } from "@services/lawyers/agreePolicy";
import { UUID } from "@utils/text";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../Logo";

interface PolicyAgreementDialogProps {
}

const UnsignedAgreementList = styled(List)`
  width: 500px;
`;

const PolicyAgreementDialog: React.FC<PolicyAgreementDialogProps> = () => {
  const { profile, fetchProfile } = useAppContext();

  const [
    agreeToPolicy,
    { loading: isCreatingPolicyAgreement },
  ] = useAcceptPolicy();
  const { data: activePolicies } = useGetActivePoliciesQuery();


  const acceptPolicy = async (policyId: UUID) => {
    if (!profile?.id) return;
    await agreeToPolicy({
      profileId: profile.id,
      policyId,
    })
    fetchProfile?.();
  }

  const unAcceptedPolicies = useMemo(() => {
    return activePolicies?.policies?.nodes.filter(
      (policy) =>
        !profile?.policyAgreements.find(
          (agreement: any) => agreement?.policy?.id === policy?.id,
        ),
    );
  }, [profile, activePolicies]);

  // const open = useMemo(() => {
  //   return !!unAcceptedPolicies?.length;
  // }, [unAcceptedPolicies]);

  // Disabled for now
  const open = false;

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>
          <Stack
            direction="column"
            spacing={2}
            color="primary.main"
            alignItems={"flex-start"}
          >
            <Logo height={20} />
            <Typography
              variant="h6"
              color={"text.primary"}
              gutterBottom={false}
            >
              User Agreements
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
        <UnsignedAgreementList>
        {unAcceptedPolicies?.map((agreement) => (
          <div key={agreement?.id}>
            <ListItem>
              <ListItemText
                primary={
                  <Link to={agreement!.url} target='_blank'>
                    {agreement?.name}
                  </Link>
                }
                secondary={agreement?.version}
              />
              <ListItemSecondaryAction>
                <Button
                  startIcon={<CheckIcon />}
                  color='primary'
                  variant='contained'
                  disabled={isCreatingPolicyAgreement || !profile?.id}
                  onClick={() => acceptPolicy(agreement?.id)}
                >
                  Accept
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </div>
        ))}
      </UnsignedAgreementList>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default PolicyAgreementDialog;
