import InfoBox from "@components/InfoBox";

import { useAppContext } from "@context/AppContext";
import { useCampaignCreateContext } from "@context/CampaignCreateContext";
import { Stack, Typography } from "@mui/material";

const StepConfirm = () => {
  const { campaignChannel, companyTargetRequest, focuses } =
    useCampaignCreateContext();
  const { profile } = useAppContext();

  return (
    <InfoBox>
      <Stack direction="column" spacing={3} width={"100%"}>
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2" color="text.secondary">
            Company Targeting
          </Typography>
          <Typography variant="body1">{companyTargetRequest}</Typography>
        </Stack>

        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2" color="text.secondary">
            Your Offer
          </Typography>
          <Typography variant="body1">{focuses}</Typography>
        </Stack>
      </Stack>
    </InfoBox>
  );
};

export default StepConfirm;
