import {
  ROUTE_BLACKLIST,
  ROUTE_CAMPAIGN_CREATE,
} from "@/AuthenticatedAppRoutes";
import { CampaignStatus, ChargedPer } from "@/models";
import { TrackedEventName, track } from "@/third-party/tracking";
import ControlledBox from "@components/ControlledBox";
import EmptyMessageBox from "@components/EmptyMessageBox";
import Tip from "@components/Tip";
import { AddIcon, BlacklistIcon } from "@components/icons";
import WelcomeIcon from "@components/icons/welcome.svg";
import CalendarConnectDialog from "@components/layout/page/CalendarConnectDialog";
import PolicyAgreementDialog from "@components/layout/page/PolicyDialog";
import { useAppContext } from "@context/AppContext";
import { CampaignProvider } from "@context/CampaignContext";
import { useCampaignsContext } from "@context/CampaignsContext";
import { ConversationsProvider } from "@context/ConversationsContext";
import { Box, Button, Divider, Stack } from "@mui/material";
import AutoPilotControl from "@pages/profile/AutoPilotControl";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import ActiveCampaign from "./ActiveCampaign";
import CampaignSettings from "./CampaignSettings";
import InactiveCampaign from "./InactiveCampaign";
import PendingCampaign from "./PendingCampaign";

const CampaignsPage = () => {
  const { isCalendarConnected, isCreatingProfile, profile } = useAppContext();
  const { campaigns, isLoading } = useCampaignsContext();

  const shouldShowCalendarConnect = useMemo(() => {
    return (
      !isCalendarConnected &&
      !isCreatingProfile &&
      profile?.lawyer?.chargedPer === ChargedPer.MEETING
    );
  }, [isCalendarConnected, isCreatingProfile, profile]);



  return (
    <ControlledBox loading={isLoading} p={0}>
      <CalendarConnectDialog open={shouldShowCalendarConnect} />
      <PolicyAgreementDialog />
      <Stack
        direction={"row"}
        spacing={2}
        alignItems="center"
        justifyContent={"flex-end"}
        mb={1}
      >
        <Button
          variant="text"
          color="primary"
          component={Link}
          to={ROUTE_CAMPAIGN_CREATE}
          startIcon={<AddIcon />}
          onClick={() => {
            track({
              name: TrackedEventName.CAMPAIGN_DRAFT_CLICKED,
            });
          }}
        >
          Draft New Campaign
        </Button>

        <Box flexGrow={1} />
        <Button
          variant="text"
          color="primary"
          size="small"
          component={Link}
          to={ROUTE_BLACKLIST}
          startIcon={<BlacklistIcon />}
        >
          Blacklist contacts
        </Button>

        <Tip title="You can let Kular to manage your campaigns automatically.">
          <AutoPilotControl />
        </Tip>
      </Stack>

      <Divider />
      {campaigns?.length === 0 && (
        <EmptyMessageBox
          title="You don't have any campaigns yet."
          subtitle="Create and activate your campaigns to get leads."
          icon={WelcomeIcon}
          actionButton={
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              startIcon={<AddIcon />}
              to={ROUTE_CAMPAIGN_CREATE}
              onClick={() => {
                track({
                  name: TrackedEventName.FIRST_CAMPAIGN_DRAFT_CLICKED,
                });
              }}
            >
              Draft New Campaign
            </Button>
          }
        />
      )}
      {campaigns?.map((campaign, index) => (
        <Box
          key={campaign.id}
          borderBottom={(theme) =>
            index !== campaigns?.length - 1
              ? `1px solid ${theme.palette.divider}`
              : "none"
          }
          onClick={() => {
            track({
              name: TrackedEventName.CAMPAIGN_VIEWED,
              props: {
                campaignId: campaign.id,
              },
            });
          }}
        >
          <CampaignProvider
            campaign={campaign}
            campaignId={campaign.id}
            withProspects={campaign.status === CampaignStatus.PENDING}
          >
            <ConversationsProvider campaignId={campaign?.id}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <CampaignSettings />
                <Box flexGrow={1}>
                  {campaign.status === CampaignStatus.PENDING && (
                    <PendingCampaign campaign={campaign} />
                  )}
                  {campaign.status === CampaignStatus.ACTIVE && (
                    <ActiveCampaign campaign={campaign} />
                  )}
                  {campaign.status === CampaignStatus.INACTIVE && (
                    <InactiveCampaign campaign={campaign} />
                  )}
                </Box>
              </Stack>
            </ConversationsProvider>
          </CampaignProvider>
        </Box>
      ))}
    </ControlledBox>
  );
};

export default CampaignsPage;
