import palette from "@design/palette";
import "codemirror";
import { Editor, EditorConfiguration } from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/handlebars/handlebars";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/theme/neo.css";
import * as Handlebars from "handlebars";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import styled from "styled-components";
import { Typo } from "typo-js-ts";

const typo = new Typo("en_US", undefined, undefined, {
  dictionaryPath: "/dictionaries",
  flags: {
    debug: false,
  },
});
const rx_word = '!"#$%&()*+,-./:;<=>?@[\\\\\\]^_`{|}~';

type Link = {
  from: number;
  to: number;
  name: string;
  url: string;
};

function parseLinks(text: string): Link[] {
  const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
  const matches = [];
  let match;

  while ((match = regex.exec(text)) !== null) {
    const [, name, url] = match;
    const from = match.index;
    const to = from + match[0].length;

    matches.push({ name, url, from, to });
  }

  return matches;
}

export const TEMPLATE_PLACEHOLDERS: string[] = [
  "first_name",
  "last_name",
  "company_name",
  "title",
  "website_url",
  "email",
  "firm_name",
];

export const LINK_PLACEHOLDER_REGEX = new RegExp(
  `{{{?link '(.+)' '(.+)'}?}}`,
  "g"
);
export const LINK_REGEX = new RegExp(
  "(https://www.|http://www.|https://|http://)?[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})(\\.[a-zA-Z]{2,})?/[a-zA-Z0-9]{2,}|((https://www.|http://www.|https://|http://)?[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})(\\.[a-zA-Z]{2,})?)|(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}\\.[a-zA-Z0-9]{2,}\\.[a-zA-Z0-9]{2,}(\\.[a-zA-Z0-9]{2,})?",
  "g"
);

// HTML a tags
export const ANCHOR_LINK_REGEX = new RegExp(
  '<a href="(https://www.|http://www.|https://|http://)?[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})(\\.[a-zA-Z]{2,})?/[a-zA-Z0-9]{2,}|((https://www.|http://www.|https://|http://)?[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})(\\.[a-zA-Z]{2,})?)|(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}\\.[a-zA-Z0-9]{2,}\\.[a-zA-Z0-9]{2,}(\\.[a-zA-Z0-9]{2,})?">[a-zA-Z0-9]{2,}</a>',
  "g"
);

const PLACEHOLDER_REGEX = new RegExp(
  `{{{?((${TEMPLATE_PLACEHOLDERS.join("|")})+)}?}}`,
  "g"
);

const CodemirrorWrapper = styled.div`
  &.editor {
    .link-marker {
      cursor: pointer;
    }
  }

  &.readonly {
    .CodeMirror-lines {
      cursor: default;
    }
  }

  .placeholder-marker {
    background-color: ${palette.primary.main};
    color: white;
    padding: 4px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 12px;

    &.link-marker {
      text-decoration: underline;
      background-color: transparent;
      color: ${palette.primary.main};
      padding: 0;
      border-radius: 0;
      font-weight: bold;
      font-size: 1em;
    }
  }

  .CodeMirror-linenumber {
    color: #a0a0a0;
  }

  .CodeMirror {
    position: relative;
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 2em !important;
    background-color: transparent;
    height: auto;

    .cm-error-line {
      background-color: rgba(255, 0, 0, 0.3);
    }
  }
`;

const extractLineNumberFromHandlebarsError = (errorMessage: string): number => {
  const match = errorMessage.match(/Line: (\d+)/);
  return match ? parseInt(match[1]) : -1;
};

interface HandlebarsError {
  line: number;
  message: string;
}

interface HandlebarsEditorProps {
  value: string;
  readonly?: boolean;
  onSetLink?: (text: string, url: string) => void;
  onClick?: () => void;
  onChange?: (newTemplate: string) => void;
  onEditorMount?: (editor: Editor) => void;
  onError?: (error: HandlebarsError | null) => void;
}

const preprocessTemplate = (template: string): string => {
  // Remove {{previous_email}} or {{{previous_email}}} from the template content
  // If there is no previous emails tags, return the template content as is
  let content =
    template
      ?.replace(/{{previous_email}}/g, "")
      .replace(/{{{previous_email}}}/g, "") || "";

  return content;
};

const TemplateEditor: React.FC<HandlebarsEditorProps> = ({
  value,
  readonly = false,
  onSetLink = () => {},
  onClick = () => {},
  onEditorMount = () => {},
  onChange = () => {},
  onError = () => {},
}) => {
  const editorRef = useRef<any | null>(null);
  const [content, setContent] = useState<string>(preprocessTemplate(value));
  const [templateError, setTemplateError] = useState<HandlebarsError | null>(
    null
  );

  // If initial template changes, update content
  useEffect(() => {
    if (!value) {
      return;
    }

    setContent(value);
  }, [value]);

  const handleDecorators = () => {
    clearDecorations();
    handlePlaceholderDecorators();
    handleLinkDecorators();
    if (!readonly) {
      //handleSpellCheckDecorators();
    }
  };

  // Handle spell check
  const [spellCheckIsReady, setSpellCheckIsReady] = useState(false);
  useEffect(() => {
    if (spellCheckIsReady) {
      return;
    }

    typo.ready.then(() => {
      setSpellCheckIsReady(true);
    });
  }, [spellCheckIsReady]);

  const handleSpellCheckDecorators = () => {
    if (!spellCheckIsReady) {
      return;
    }

    const editor = editorRef.current as Editor;
    if (!editor) {
      return;
    }

    const doc = editor.getDoc();
    const content = doc.getValue();

    // Apply new decorations
    const matches: any[] = Array.from(content.matchAll(/\w+/g));

    if (!matches) {
      return;
    }

    for (const match of Array.from(matches)) {
      const from = doc.posFromIndex(match.index!);
      const to = doc.posFromIndex(match.index! + match[0].length);
      const word = match[0];

      // If word is a number, ignore it
      if (!isNaN(word as any)) {
        continue;
      }

      // Check if the word is misspelled
      const isMisspelled = !typo.check(word);

      if (isMisspelled) {
        const suggested = typo.suggest(word);
        const span = document.createElement("span");
        span.textContent = word;
        span.contentEditable = "true"; // Disable editing for the placeholder
        //span.classList.add("placeholder-marker");
        span.classList.add("cm-error-line");

        // span.title = `Did you mean: ${suggested.join(", ")}`;
        setTemplateError({
          line: from.line,
          message: `Did you mean: ${suggested}`,
        });

        doc.markText(from, to, {
          replacedWith: span,
        });
      }
    }
  };

  // Handle error
  const handleTemplateError = useCallback(() => {
    if (readonly) {
      return;
    }

    try {
      Handlebars.parse(content);
      Handlebars.compile(content);
      setTemplateError(null);
    } catch (error: any) {
      const message = error.message;
      const line = extractLineNumberFromHandlebarsError(message);

      setTemplateError({ line, message });
    }
  }, [content, readonly]);

  useEffect(() => {
    if (!templateError) {
      return;
    }
    console.error(templateError);
    onError(templateError);
  }, [templateError, onError]);

  // Clear previous decorations
  const clearDecorations = () => {
    const editor = editorRef.current as Editor;
    if (!editor) {
      return;
    }
    const doc = editor.getDoc();

    // Remove previous decorations
    const prevDecorations = doc.getAllMarks();
    for (let i = 0; i < prevDecorations.length; i++) {
      prevDecorations[i].clear();
    }
  };

  // Mark TEMPLATE_PLACEHOLDERS
  const handlePlaceholderDecorators = () => {
    const editor = editorRef.current as Editor;
    if (!editor) {
      return;
    }
    const doc = editor.getDoc();
    const content = doc.getValue();

    // Apply new decorations
    const matches: any[] = Array.from(content.matchAll(PLACEHOLDER_REGEX));

    // If we wanted to match all but not only TEMPLATE_PLACEHOLDERS, we could use this regex:
    // const matches = Array.from(content.matchAll(new RegExp(`{{{?([a-zA-Z0-9_]+)}?}}`, 'g')));
    if (!matches) {
      return;
    }

    const markers: any[] = [];
    for (const match of matches) {
      const from = doc.posFromIndex(match.index!);
      const to = doc.posFromIndex(match.index! + match[0].length);
      const span = document.createElement("span");
      span.textContent = match[1].replace(/_/g, " ");
      span.contentEditable = "false"; // Disable editing for the placeholder
      span.classList.add("placeholder-marker");

      const marker = doc.markText(from, to, {
        replacedWith: span,
      });
      markers.push(marker);
    }
  };

  const handleLinkDecorators = () => {
    const editor = editorRef.current as Editor;
    if (!editor) {
      return;
    }
    const doc = editor.getDoc();
    const content = doc.getValue();

    // Apply new decorations
    const matches: any[] = Array.from(
      content.matchAll(new RegExp(LINK_PLACEHOLDER_REGEX))
    );

    if (!matches) {
      return;
    }

    for (const match of Array.from(matches)) {
      const from = doc.posFromIndex(match.index!);
      const to = doc.posFromIndex(match.index! + match[0].length);
      const span = document.createElement("span");
      const text = match[1];
      const url = match[2];
      // How could we create this span with a React component?
      // Assume its name is LinkMarker
      // const span = <LinkMarker text={text} url={url} onClick={() => {}} />;
      // const span = document.createElement('span');
      //

      span.textContent = text;
      span.contentEditable = "false"; // Disable editing for the placeholder
      span.classList.add("placeholder-marker");
      span.classList.add("link-marker");
      span.title = url;

      span.onclick = () => {
        // Select the link so we can always replace the selection
        const editor = editorRef.current as Editor;
        const doc = editor.getDoc();
        const from = doc.posFromIndex(match.index!);
        const to = doc.posFromIndex(match.index! + match[0].length);

        console.log(from, to, text, url);
        doc.setSelection(from, to);

        onSetLink(text, url);

        if (readonly) {
          // Open the link in a new tab
          window.open(url, "_blank");
        }
      };

      doc.markText(from, to, {
        replacedWith: span,
      });
    }
  };

  // Change [here](https://pay.legalvision.co.uk/pdf/lvconnect-pro)
  // to {{{link 'here' 'https://pay.legalvision.co.uk/pdf/lvconnect-pro'}}}
  const handleLinkTexts = () => {
    const editor = editorRef.current as Editor;
    if (!editor) {
      return;
    }
    const doc = editor.getDoc();
    const content = doc.getValue();

    // Apply new decorations
    const linkPlaceholders: any[] = parseLinks(content);

    if (!linkPlaceholders) {
      return;
    }

    for (const link of linkPlaceholders) {
      const from = doc.posFromIndex(link.from);
      const to = doc.posFromIndex(link.to);
      const span = document.createElement("span");
      const text = link.name;
      const url = link.url;

      span.textContent = text;
      span.contentEditable = "false"; // Disable editing for the placeholder
      span.classList.add("placeholder-marker");
      span.classList.add("link-marker");
      span.title = url;

      span.onclick = () => {
        // Select the link so we can always replace the selection
        const editor = editorRef.current as Editor;
        const doc = editor.getDoc();
        const from = doc.posFromIndex(link.from);
        const to = doc.posFromIndex(link.to);
        doc.setSelection(from, to);
        console.log(from, to, text, url);

        onSetLink(text, url);

        if (readonly) {
          // Open the link in a new tab
          window.open(url, "_blank");
        }
      };

      doc.markText(from, to, {
        replacedWith: span,
      });
    }
  };

  // Handle content updates
  useEffect(() => {
    //handleTemplateError();
    handleDecorators();
    handleLinkTexts();

    // focus editor
    if (!readonly) {
      const editor = editorRef.current as Editor;
      if (!editor) {
        return;
      }

      const isFocused = editor.hasFocus();

      if (!isFocused) {
        editor.focus();
      }
    }
  }, [content]);

  // Handle editor change
  const handleEditorChange = (_: Editor, _data: any, value: string) => {
    if (readonly) {
      return;
    }

    if (value === content) {
      return;
    }

    setContent(value);
  };

  // Update parent component
  useEffect(() => {
    onChange(content);
  }, [content]);

  // Editor configuration
  const options: EditorConfiguration = {
    mode: { name: "handlebars", base: "text/html" },
    theme: "neo",
    lineNumbers: !readonly,
    readOnly: readonly,
    cursorBlinkRate: readonly ? -1 : 600,
    lineWrapping: true,
  };

  return (
    <CodemirrorWrapper
      onClick={onClick}
      className={readonly ? "readonly" : "editor"}
    >
      <CodeMirror
        value={content || ""}
        options={options}
        onBeforeChange={handleEditorChange}
        editorDidMount={(editor: any, _: any) => {
          editorRef.current = editor;
          handlePlaceholderDecorators();
          onEditorMount(editor);
        }}
      />
    </CodemirrorWrapper>
  );
};

export default TemplateEditor;
