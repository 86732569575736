import { RadioGroup, RadioGroupProps } from "@mui/material";
import { Field, FieldProps } from "formik";
import styled from "styled-components";

export default function FormikRadioGroup({
  name,
  ...muiFields
}: RadioGroupProps) {
  return <Field name={name} component={CustomRadioGroup} {...muiFields} />;
}

const Wrapper = styled.div`
  position: relative;
`;

function CustomRadioGroup({
  field,
  form,
  ...muiProps
}: RadioGroupProps & FieldProps) {
  return (
    <Wrapper>
      <RadioGroup {...field} {...muiProps} />
    </Wrapper>
  );
}
