import WaitingSvg from "@components/icons/waiting.svg";
import palette from "@design/palette";
import { Stack, Typography } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

export default function LoadingSpinner() {
  return (
    <Stack direction={"column"} spacing={2} alignItems="center" p={6}>
      <img src={WaitingSvg} alt="Search People" height={100} />
      <Stack
        direction={"row"}
        spacing={2}
        alignItems="center"
        color="grey.500"
        p={6}
      >
        <RotatingLines
          strokeColor={palette.primary.main}
          strokeWidth="5"
          animationDuration="0.75"
          width="24"
          visible={true}
        />
        <Typography variant="body1">Loading</Typography>
      </Stack>
    </Stack>
  );
}
