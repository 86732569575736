import { UUID } from "@utils/text";
// Conversation Context

import { VisibilityState as VisibilityStateGraphQL } from "@/graphql/generated";

export enum LinkedinLoginStatus {
  LOGGED_OUT = "LOGGED_OUT",
  LOGGED_IN = "LOGGED_IN",
  WAITING_FOR_VERIFICATION = "WAITING_FOR_VERIFICATION",
  VERIFICATION_FAILED = "VERIFICATION_FAILED",
  INCORRECT_CREDS = "INCORRECT_CREDS",
}

export enum PaymentMethod {
  STRIPE = "STRIPE",
  STRIPE_PAYG = "STRIPE_PAYG",
  OTHER = "OTHER",
}

export enum PaymentSetupStatus {
  NOT_STARTED = "NOT_STARTED",
  REQUIRES_ACTION = "REQUIRES_ACTION",
  REQUIRES_PAYMENT_METHOD = "REQUIRES_PAYMENT_METHOD",
  REQUIRES_CONFIRMATION = "REQUIRES_CONFIRMATION",
  REQUIRES_CAPTURE = "REQUIRES_CAPTURE",
  CANCELED = "CANCELED",
  PROCESSING = "PROCESSING",
  SUCCEEDED = "SUCCEEDED",
}

export enum LinkedinLoginResult {
  SUCCESS = "SUCCESS",
  MISSING_EMAIL = "MISSING_EMAIL",
  MISSING_PASSWORD = "MISSING_PASSWORD",
  MISSING_COUNTRY = "MISSING_COUNTRY",
  INCORRECT_EMAIL = "INCORRECT_EMAIL",
  INCORRECT_PASSWORD = "INCORRECT_PASSWORD",
  INCORRECT_EMAIL_OR_PASSWORD = "INCORRECT_EMAIL_OR_PASSWORD",
  VERIFICATION_CODE = "VERIFICATION_CODE",
  CODE_2FA = "CODE_2FA",
  UNSOLVED_CAPTCHA = "UNSOLVED_CAPTCHA",
  UNKNOWN_FAILURE = "UNKNOWN_FAILURE",
  ID_REQUIRED = "ID_REQUIRED",
}

export enum VisibilityState {
  VISIBLE = "VISIBLE",
  BLURRED = "BLURRED",
  BLURRED_BUDGET = "BLURRED_BUDGET",
  HIDDEN = "HIDDEN",
  FREE = "FREE",
  FREE_EXTRA = "FREE_EXTRA",
}

export const FREE_VISIBILITY_STATES = [
  VisibilityState.FREE,
  VisibilityState.FREE_EXTRA,
];

export const CONVERSATION_VISIBILITY_STATES = [
  VisibilityState.VISIBLE,
  ...FREE_VISIBILITY_STATES,
];

export const BLURRED_VISIBILITY_STATES = [
  VisibilityState.BLURRED,
  VisibilityState.BLURRED_BUDGET,
];

export const DISPLAYED_VISIBILITY_STATES = [
  VisibilityState.VISIBLE,
  VisibilityState.BLURRED,
  VisibilityState.BLURRED_BUDGET,
  ...FREE_VISIBILITY_STATES,
];
export const DISPLAYED_VISIBILITY_STATES_GRAPHQL =
  DISPLAYED_VISIBILITY_STATES.map(
    (visibility) => visibility.toString() as VisibilityStateGraphQL
  );

export type Profile = {
  id: UUID;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  userId: string;
  photoUrl: string;
  lawyer: Lawyer;
  policyAgreements: PolicyAgreement[];
};
export type PolicyAgreement = {
  agreedtime: Date;
  policy: Policy;
};
export type Policy = {
  id: UUID;
  name: string;
  isActive: boolean;
  type: string;
  url: string;
  version: string;
  createdTime: Date;
};
export type CronofyAuth = {
  id: UUID;
};

export enum ChargedPer {
  LEAD = "LEAD",
  MEETING = "MEETING",
}

export enum LawyerAcquisitionType {
  REFERRAL = "REFERRAL",
  K4K = "K4K",
  SELF_SERVE = "SELF_SERVE",
  LEGACY = "LEGACY",
  OTHER = "OTHER",
}

export enum LawyerOnboardingType {
  HUMAN_ONBOARDING = "HUMAN_ONBOARDING",
  SELF_SERVE = "SELF_SERVE",
}

export type Lawyer = {
  id: UUID;
  name?: string;
  email?: string;
  firmName: string;
  status: string;
  logoSrc?: string | null;
  websiteUrl?: string | null;
  calendlyLink?: string | null;
  liEmail?: string | null;
  liLoginStatus?: LinkedinLoginStatus | null;
  linkedinUrl?: string | null;
  liVerificationCode?: string | null;
  countryCode?: string | null;
  paymentMethod?: PaymentMethod;
  postalCode?: string | null;
  stripeCustomerId?: string | null;
  freeLeads?: number;
  leadsPerMonth?: number;
  isPaused?: boolean | null;
  cronofyAuths?: CronofyAuth[];
  chargedPer?: ChargedPer;
  timezone?: string | null;
  isManaged?: boolean;
  icp?: string | null;
  publicId?: string | null;
  acquisitionType?: LawyerAcquisitionType;
  acquisitionChannel?: string | null;
  onboardingType?: LawyerOnboardingType;
  priority?: LawyerPriority;
  stripePriceId?: string | null;
  settings?: LawyerSetting[];
};

export enum LawyerPriority {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export type Company = {
  id: UUID;
  name?: string | null;
  websiteUrl?: string | null;
  facebookUrl?: string | null;
  summary?: string | null;
  twitterUrl?: string | null;
  linkedinUrl?: string | null;
  logoUrl?: string | null;
};

export type Contact = {
  id: UUID;
  title?: string;
  email?: string;
  emailMessages?: OutreachMessage[];
  twitterUrl?: string;
  timezone?: string;
  photoUrl?: string;
  phoneNumber?: string;
  linkedinUrl?: string;
  firstName?: string;
  lastName?: string;
  facebookUrl?: string;
  summary?: string;
  company?: Company;
  linkedinMessages?: OutreachMessage[];
  meetingInvitees?: MeetingInvitee[];
};

type MeetingInvitee = {
  id: UUID;
  meeting?: Meeting;
  contact?: Contact;
  rescheduleUrl?: string | null;
  cancelUrl?: string | null;
  calendlyUri?: string | null;
};

/* Messaging */
enum OutreachMessageStatus {
  SCHEDULED = "SCHEDULED",
  SENT = "SENT",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
}

export enum IntentType {
  UNSUBSCRIBE = "unsubscribe",
  YES_WHEN = "yes_when",
  YES_WITH_AVAILABILITY = "yes_with_availability",
  YES_BOOKING_LINK = "yes_booking_link",
  YES_IN_PERSON = "yes_in_person",
  NEED_MORE_INFORMATION = "need_more_information",
  NO = "no",
  REACH_OUT_AGAIN = "reach_out_again",
  REACH_OUT_TO_X = "reach_out_to_x",
  DATA_ORIGIN = "data_origin",
  UNKNOWN = "unknown",
  OUT_OF_OFFICE = "out_of_office",
  VERIFY_HUMAN = "verify_human",
}

export const CONVERSATION_INTENTS = [
  IntentType.NEED_MORE_INFORMATION,
  IntentType.REACH_OUT_AGAIN,
  IntentType.REACH_OUT_TO_X,
  IntentType.YES_BOOKING_LINK,
  IntentType.YES_IN_PERSON,
  IntentType.YES_WHEN,
  IntentType.YES_WITH_AVAILABILITY,
];

export const POSITIVE_INTENTS = [
  ...CONVERSATION_INTENTS,
  IntentType.UNKNOWN,
  IntentType.VERIFY_HUMAN,
];

export const NEGATIVE_INTENTS = [
  IntentType.NO,
  IntentType.UNSUBSCRIBE,
  IntentType.OUT_OF_OFFICE,
  IntentType.DATA_ORIGIN,
];

export type HedwigMessagePayload = {
  hedwigIdentities: {
    name: string;
    email: string;
    identityType: "TO_RECIPIENT" | "SENDER" | "CC_RECIPIENT" | "BCC_RECIPIENT";
  }[];
};

export type Recipients = {
  to: EmailAddress[];
  cc: EmailAddress[];
  bcc: EmailAddress[];
  from?: EmailAddress;
};

const EMPTY_RECIPIENTS: Recipients = {
  to: [],
  cc: [],
  bcc: [],
};

export const getRecipientsFromHedwigMessagePayload = (
  hedwigMessagePayload: HedwigMessagePayload | null | undefined
): Recipients => {
  if (!hedwigMessagePayload) {
    return EMPTY_RECIPIENTS;
  }

  return {
    to: hedwigMessagePayload.hedwigIdentities
      .filter((identity) => identity.identityType === "TO_RECIPIENT")
      .map((identity) => ({
        name: identity.name,
        email: identity.email,
      })),
    cc: hedwigMessagePayload.hedwigIdentities
      .filter((identity) => identity.identityType === "CC_RECIPIENT")
      .map((identity) => ({
        name: identity.name,
        email: identity.email,
      })),
    bcc: hedwigMessagePayload.hedwigIdentities
      .filter((identity) => identity.identityType === "BCC_RECIPIENT")
      .map((identity) => ({
        name: identity.name,
        email: identity.email,
      })),
    from: hedwigMessagePayload.hedwigIdentities
      .filter((identity) => identity.identityType === "SENDER")
      .map((identity) => ({
        name: identity.name,
        email: identity.email,
      }))
      .at(0),
  };
};
export const getSenderNameFromMessage = (
  message: OutreachMessage | null,
  contactFullName: string | null
): string | null => {
  if (!message?.senderProfile) {
    return null;
  }

  let fromName = null;
  const recipients = getRecipientsFromHedwigMessagePayloads(
    message.hedwigMessagePayloads
  );

  if (recipients.from) {
    fromName = recipients.from.name;
  } else if (!message.isLawyer) {
    fromName = contactFullName;
  }

  if (message.isLawyer) {
    fromName = [fromName, "Kular"].filter(Boolean).join(" - ");
  }

  return fromName;
};

export const getRecipientsFromHedwigMessagePayloads = (
  hedwigMessagePayloads: HedwigMessagePayload[] | null | undefined
): Recipients => {
  if (!hedwigMessagePayloads) {
    return EMPTY_RECIPIENTS;
  }

  return combineRecipients(
    hedwigMessagePayloads.map(getRecipientsFromHedwigMessagePayload)
  );
};

export const combineRecipients = (
  recipients: Recipients[] | null | undefined
): Recipients => {
  return (recipients ?? []).reduce((acc, recipient) => {
    return {
      to: dedupeEmailAddresses([...acc.to, ...recipient.to]),
      cc: dedupeEmailAddresses([...acc.cc, ...recipient.cc]),
      bcc: dedupeEmailAddresses([...acc.bcc, ...recipient.bcc]),
      from: recipient.from,
    };
  }, EMPTY_RECIPIENTS);
};

export const flattenRecipients = (
  recipients: Recipients | null | undefined
): EmailAddress[] => {
  if (!recipients) {
    return [];
  }

  return dedupeEmailAddresses(
    [
      ...recipients.to,
      ...recipients.cc,
      ...recipients.bcc,
      ...(recipients.from ? [recipients.from] : []),
    ].filter(Boolean)
  );
};

export type OutreachMessage = {
  id: UUID;
  isLawyer: boolean;
  intent?: IntentType | null;
  repliedIntent?: string | null;
  repliedTime?: Date | null;
  campaignId?: UUID | null;
  campaignMemberId?: UUID | null;
  companyId?: UUID | null;
  contactId?: UUID;
  content?: string | null;
  forwardedInternallyTime?: Date | null;
  forwardedToLawyerTime?: Date | null;
  ccLawyerTime?: Date | null;
  reviewedTime?: Date | null;
  sentTime?: Date;
  subject?: string;
  createdTime?: Date;
  isReviewed?: boolean;
  hedwigMessagePayloads?: HedwigMessagePayload[];
  senderProfile?: SenderProfile | null;
  template?: Template | null;
  contact?: Contact | null;
  campaign?: Campaign | null;
  campaignMember?: CampaignMember | null;
  company?: Company | null;
  lawyer?: Lawyer;
  lawyerViewedTime?: Date | null;
  campaignChannel?: CampaignChannel;
  status?: OutreachMessageStatus;
  sendAt?: Date;
  members?: CampaignMember[];
};

export type SenderProfile = {
  id: UUID;
  firstName: string | null;
  lastName: string | null;
  profileType: string | null;
  email: string | null;
  photoUrl: string | null | null;
  title: string | null;
  firmName: string | null;
  logoSrc: string | null;
};

export enum TemplateStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

export type Template = {
  id: string;
  orderIndex: number;
  content: string | null;
  conversionScore: number;
  campaign: Campaign;
  status: TemplateStatus;
};

export type Email = {
  id: UUID;
  status: string;
};

export type CampaignMember = {
  id: UUID;
  contactId: UUID;
  campaignId?: UUID;
  status: CampaignMemberStatus;
  createdTime?: string | null;
  nextActionTime?: string | null;
  emails?: Array<Email> | null;
  linkedinConnections?: Array<LinkedInConnection> | null;
  contact?: Contact;
  campaign?: Campaign;
  connections?: LinkedInConnection[];
  updatedTime: string;
  feedbacks?: CampaignMemberFeedback[];
  meetings?: CampaignMemberMeeting[];
  charges?: CampaignMemberCharge[];
  lawyerViewedTime?: Date | null;
  emailMessages?: OutreachMessage[];
  linkedinMessages?: OutreachMessage[];
  visibility: VisibilityState;
  latestEmailMessageCreatedTime?: Date | null;
};

export enum CampaignMemberMeetingStatus {
  SCHEDULED = "SCHEDULED",
  CONFIRMED = "CONFIRMED",
  HELD = "HELD",
  CANCELLED = "CANCELLED",
}

export type CampaignMemberMeeting = {
  id: UUID;
  status: CampaignMemberMeetingStatus;
  description: string;
  summary: string;
  startTime: Date;
  endTime: Date;
  updatedTime: string;
  campaignMemberId: UUID;
  campaignMember: CampaignMember;
};

export type MeetingFeedbackRating =
  | "VERY_BAD"
  | "BAD"
  | "OK"
  | "GOOD"
  | "VERY_GOOD";

export type CampaignMemberFeedback = {
  id: UUID;
  campaignMemberId: UUID;
  note: string;
  rating: MeetingFeedbackRating;
  createdTime: Date;
};

export enum CampaignMemberStatus {
  SCHEDULED_OUTREACH = "SCHEDULED_OUTREACH", // Show
  CONTACTED = "CONTACTED", // Show
  COMPLETED = "COMPLETED", // Show
  REPLIED = "REPLIED", // Show
  REJECTED = "REJECTED", // Do not show
  UNSUBSCRIBED = "UNSUBSCRIBED", // Do not
  WARM_REPLY = "WARM_REPLY", // Show
  SCHEDULING = "SCHEDULING", // Show
  LAWYER_HANDLING = "LAWYER_HANDLING", // Show
  MEETING_BOOKED = "MEETING_BOOKED", // Show
  WORK_BILLED = "WORK_BILLED", // Show
  NEVER_AGAIN = "NEVER_AGAIN", // Do not show
  CANCELED = "CANCELED", // Do not show
}

export const CONTACTED_MEMBER_STATUSES: Array<CampaignMemberStatus> = [
  CampaignMemberStatus.CONTACTED,
  CampaignMemberStatus.REPLIED,
  CampaignMemberStatus.WARM_REPLY,
  CampaignMemberStatus.SCHEDULING,
  CampaignMemberStatus.LAWYER_HANDLING,
  CampaignMemberStatus.MEETING_BOOKED,
  CampaignMemberStatus.WORK_BILLED,
  CampaignMemberStatus.COMPLETED,
];

export type CampaignMemberCreate = {
  contactId: UUID;
  status?: string;
};

export enum CampaignChannel {
  EMAIL = "EMAIL",
  LINKEDIN = "LINKEDIN",
}

export type Campaign = {
  id: UUID;
  name: string;
  subject?: string;
  status: CampaignStatus;
  deleted: boolean;
  createdTime: Date;
  members?: Array<CampaignMember>;
  channel: CampaignChannel;
  emailTemplates: Array<Template>;
  linkedinTemplates: Array<Template>;
  targetingRequest: string;
  lawyerViewedTime?: Date | null;
  lawyerId: UUID;
  suggestedTargetingId: UUID;
  suggestedTargeting?: SuggestedTargeting | null;
  scheduledActivationTime: Date | null;
  templates: Array<Template>;
  conversionScore: number;
};

export enum CampaignStatus {
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
  REVISED = "REVISED",
  REJECTED = "REJECTED",
  ACTIVE = "ACTIVE",
}

export type WarmReply = {
  degree: string;
  text: string;
  email: string;
};

export type LinkedInConnection = {
  id: number;
  messageText?: string;
  status?: string;
  sendAt?: string;
  campaignMember?: CampaignMember;
};

export type Meeting = {
  id: UUID;
  calendlyUri: string;
  canceledBy: string | null;
  cancelerType: string | null;
  cancellationReason: string | null;
  contactFeedbackRequested: boolean;
  createdTime: string;
  startTime: string;
  endTime: string;
  locationAddress: string | null;
  name: string;
  locationType: string | null;
  locationStatus: string | null;
  invitees: MeetingInvitee[];
};

export type PersonalisationType = {
  id: UUID;
  name: string;
};

export interface PersonalisationQuery {
  rules?: (
    | {
        field: string;
        operator: string;
        value: string[] | string | number;
      }
    | PersonalisationQuery
  )[];
  combinator?: string;
}

export type Prospect = {
  companyId: UUID;
  companyLinkedinUrl: string;
  companyName: string;
  companyPhotoUrl: string;
  companyWebsiteUrl: string;
  contactEmail: string;
  contactFirstName: string;
  contactId: UUID;
  contactLastName: string;
  contactLinkedinUrl: string;
  contactPhotoUrl: string;
  contactTitle: string;
  score: number;
  summary?: string;
  reasoning?: string;
};

export const prospectToContact = (prospect: Prospect): Contact => {
  return {
    id: prospect.contactId,
    email: prospect.contactEmail,
    firstName: prospect.contactFirstName,
    lastName: prospect.contactLastName,
    title: prospect.contactTitle,
    photoUrl: prospect.contactPhotoUrl,
    linkedinUrl: prospect.contactLinkedinUrl,
    summary: prospect.summary,
    company: {
      id: prospect.companyId,
      name: prospect.companyName,
      logoUrl: prospect.companyPhotoUrl,
      websiteUrl: prospect.companyWebsiteUrl,
      linkedinUrl: prospect.companyLinkedinUrl,
    },
  };
};

export type BlacklistedOutreach = {
  id: UUID;
  email?: string;
  emailDomain?: string | null;
  linkedinUrl?: string;
  reason?: string;
};

export type UploadContactBlacklist = {
  message: string;
};

export type CampaignMemberCharge = {
  id: UUID;
  chargedAt?: Date | null;
  reportedAt?: Date | null;
  stripeInvoiceId?: string | null;
  stripeInvoiceUrl?: string | null;
  paidAt?: Date | null;
  freeAt?: Date | null;
  failedAt?: Date | null;
  amount: number;
  taxAmount: number;

  campaignMemberId?: string; // Assuming UUID is represented as a string
  campaignMember?: CampaignMember | null; // Assuming CampaignMember is defined elsewhere
  refunds?: Array<Refund> | null;
};

export enum RefundStatus {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum RefundApprovalStatus {
  VALID = "VALID",
  WITH_DISPUTE = "WITH_DISPUTE",
}

export type Refund = {
  stripeCreditNoteUrl: string;
  reason: string;
  status: RefundStatus;
  approvalStatus: RefundApprovalStatus;
  note: string;
  id: UUID;
  updatedTime: Date;
  amount: number;
  createdTime: Date;
};

export type Conversation = {
  campaignMemberId: UUID;
  contact: Contact;
  campaign: Campaign;
  visibility: VisibilityState;
  invoiceUrl: string | null;
  updatedTime: Date;
  lastReplyTime: Date;
  isUnread: boolean;
  charge: CampaignMemberCharge;
};

export type EmailAddress = {
  name: string;
  email: string;
};

export function emailAddressToString(
  emailAddress: EmailAddress
): string {
  if (emailAddress.name) {
    return `${emailAddress.name} <${emailAddress.email}>`;
  }
  return emailAddress.email;
}

export function dedupeEmailAddresses(
  addresses: EmailAddress[]
): EmailAddress[] {
  return Array.from(new Set(addresses.map((e) => e.email))).map((email) => {
    return addresses.find((e) => e.email === email)!;
  });
}

export type SuggestedTargeting = {
  id: UUID;
  personalisationQuery: PersonalisationQuery;
  targetCompanyDescription: string;
  focuses: string[];
  title: string;
  minimumCompanyCount?: number;
  companyCount?: number;
};

export type CampaignRequest = {
  id: string;
  createdTime: string;
  updatedTime: string;
  suggestedTargeting: SuggestedTargeting;
}[];

export type PersonalisationStatistics = {
  id: UUID;
  persValue: string;
  value: number;
  type: PersonalisationType;
};

export enum LawyerSettingType {
  MEETING_AVAILABILITY = "MEETING_AVAILABILITY",
}

export type LawyerSetting = {
  id: UUID;
  type: LawyerSettingType;
  value: string;
};
