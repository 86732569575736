import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

export type CreateSubscriptionParams = {
  lawyerId: string;
  couponCode?: string;
  postalCode?: string;
  countryCode?: string | null;
};

export const createSubscription = async ({
  lawyerId,
  couponCode,
  postalCode,
  countryCode,
}: CreateSubscriptionParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      collectionMethod: "charge_automatically",
      couponCode,
      postalCode,
      countryCode,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/payments/create-subscription`,
    options
  );

  return response;
};

export type CreateSubscriptionResponse = {
  latestInvoice: {
    paymentIntent: {
      clientSecret: string;
    };
  };
};

//latest_invoice?.payment_intent?.client_secret;
export const useCreateSubscription = () =>
  useServiceCall<CreateSubscriptionResponse, CreateSubscriptionParams>({
    serviceCall: createSubscription,
  });
