import { useServiceCall } from "@hooks/useServiceCall";
import { removeUndefinedValues } from "@utils/object";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

export type PatchCampaignMemberParams = {
  campaignId: string;
  memberId: string;
  status?: string;
  nextActionTime?: string;
  lawyerViewedTime?: Date;
};

export const patchCampaignMember = async ({
  campaignId,
  memberId,
  status,
  nextActionTime,
  lawyerViewedTime,
}: PatchCampaignMemberParams) => {
  const payload = removeUndefinedValues({
    status,
    nextActionTime,
    lawyerViewedTime: lawyerViewedTime?.toISOString(),
  });

  const options: FetchOptions = {
    method: "PATCH",
    payload,
  };

  const response = await fetchOutreachService(
    `/campaigns/${campaignId}/members/${memberId}`,
    options
  );

  return response;
};

export const usePatchCampaignMember = () =>
  useServiceCall({
    serviceCall: patchCampaignMember,
    successMessage: "Campaign member updated successfully",
    errorMessage: "Failed to update campaign member",
  });
