import { CampaignRequest } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

type CreateRequestParams = {
  lawyerId: string;
  raw?: string;
  description?: string;
  focuses?: string;
  createdByLawyer?: boolean;
};

export const createCampaignRequest = async ({
  lawyerId,
  raw,
  description,
  focuses,
  createdByLawyer,
}: CreateRequestParams) => {
  const options: FetchOptions = {
    method: "POST",
    timeout: 120000,
    payload: {
      lawyerId,
      raw,
      description,
      focuses,
      createdByLawyer,
    },
  };

  const response = await fetchOutreachService(
    `/campaign-requests/guess`,
    options
  );

  return response;
};

export const useCreateCampaignRequest = () =>
  useServiceCall<CampaignRequest, CreateRequestParams>({
    serviceCall: createCampaignRequest,
  });
