import React, { useEffect, useRef } from "react";

export type CallbackFunction = () => void;

export const useInterval = (
  callback: CallbackFunction,
  delay: number | null
): React.MutableRefObject<number | null> => {
  const intervalRef = useRef<number | null>(null);
  const savedCallback = useRef<CallbackFunction>(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();

    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(tick, delay);
      return () => {
        if (intervalRef.current !== null) {
          window.clearInterval(intervalRef.current);
        }
      };
    }
  }, [delay]);

  return intervalRef;
};
