import { OutreachMessage } from "@/models";
import { DateTime } from "luxon";

export const getDisplayDate = (utcString: string) => {
  return DateTime.fromISO(utcString).toLocaleString(DateTime.DATETIME_MED);
};

export const getMeetingDisplayDate = (
  startTimeUtc: Date,
  endTimeUtc: Date,
  timezoneStr?: string | null
) => {
  const timeZone = timezoneStr || "utc";
  const startTime = DateTime.fromJSDate(startTimeUtc).setZone(timeZone);
  const endTime = DateTime.fromJSDate(endTimeUtc).setZone(timeZone);

  const startHourAndMinute = startTime.toLocaleString(DateTime.TIME_SIMPLE);
  const endHourAndMinute = endTime.toLocaleString(DateTime.TIME_SIMPLE);
  const day = startTime.toLocaleString(DateTime.DATE_MED);

  const timeZoneInfo = startTime.toFormat("ZZZZ");

  return `${day}, ${startHourAndMinute} - ${endHourAndMinute} ${timeZoneInfo}`;
};

export const getMessageDisplayDate = (
  message: OutreachMessage | null | undefined
) => {
  if (!message) {
    return null;
  }

  const { sentTime, sendAt } = message;

  return [sentTime?.toISOString(), sendAt?.toISOString()]
    .filter(Boolean)
    .map((sentTime) => getDisplayDate(sentTime!))[0];
};

export function formatTimestamp(
  timestamp: number,
  timezone?: string | null
): string {
  if (!timestamp) {
    return "";
  }

  const timeZone = timezone || "utc";
  //
  const date = DateTime.fromSeconds(timestamp, { zone: "utc" });
  const rezoned = date.setZone(timeZone);
  return rezoned.toFormat("D");
}

// Value can be any type, but we only want to parse strings
export const tryParseDate = (value: any): Date | string | null | undefined => {
  // If Date object
  if (value instanceof Date) {
    return value;
  }

  if (typeof value !== "string") {
    return value; // Return the original value if it's not a string
  }

  try {
    const dateValue = DateTime.fromISO(value).toJSDate();
    if (!isNaN(dateValue.getTime())) {
      return dateValue;
    } else {
      return value; // Return the original string if parsing fails
    }
  } catch (e) {
    return value; // Return the original string if an error occurs during parsing
  }
};

export const formatMonthName = (date: Date) => {
  return DateTime.fromJSDate(date).toFormat("LLL yyyy");
};
