import { ROUTE_CAMPAIGN, getRoute } from "@/AuthenticatedAppRoutes";
import { Campaign } from "@/models";
import { Card, CardActionArea, Stack } from "@mui/material";
import CampaignIconWithState from "@pages/campaigns/campaigns/components/CampaignIconWithState";
import CampaignConversationsLabel from "./components/CampaignConversationsLabel";
import CampaignName from "./components/CampaignName";
import CampaignStatusLabel from "./components/CampaignStatusLabel";
import LastViewedTimeAgoText from "./components/LastViewedTimeAgoText";

export type InactiveCampaignProps = {
  campaign: Campaign;
};

const InactiveCampaign = ({ campaign }: InactiveCampaignProps) => {
  return (
    <Card
      sx={{
        boxShadow: "none",
        border: "none",
        px: 0,
      }}
    >
      <CardActionArea
        href={getRoute(ROUTE_CAMPAIGN, {
          id: campaign.id,
        })}
      >
        <Stack
          p={2}
          spacing={2}
          direction={{
            xs: "column",
            sm: "row",
          }}
          alignItems={{
            xs: "flex-start",
            sm: "center",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <CampaignStatusLabel />
            <CampaignIconWithState />
          </Stack>
          <Stack
            flexGrow={1}
            spacing={2}
            direction="row"
            width={{
              xs: "100%",
              sm: "auto",
            }}
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
          >
            <Stack
              direction="column"
              spacing={0}
              alignItems={"flex-start"}
              flexGrow={1}
            >
              <CampaignName />
              <LastViewedTimeAgoText />
            </Stack>
            <CampaignConversationsLabel />
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default InactiveCampaign;
