import {
  useGetAllPersonalisationTypesLazyQuery,
  useGetPersStatisticsForLawyerLazyQuery,
} from "@/graphql/generated";
import { PersonalisationType } from "@/models";
import { normalizeGraphqlResult } from "@utils/graphql";
import { UUID, snakeCaseToDisplayText } from "@utils/text";
import { useMemo } from "react";

const VALID_ORDERED_PERS_NAMES = [
  "COUNTRY",
  "STATE",
  "CITY",
  "JOB_MANAGEMENT_LEVEL",
  "JOB_DEPARTMENT",
  "LAST_FUNDING_ROUND",
];

interface GetPersStatisticsForLawyerContact {
  strValues: {
    values: {
      count: {
        value: string;
      };

      keys: string | UUID[];
    }[];
  };
  intValues: {
    values: {
      count: {
        value: string;
      };

      keys: string | UUID[];
    }[];
  };
  dateValues: {
    values: {
      count: {
        value: string;
      };
      keys: string | UUID[];
    }[];
  };
}

type UsePersonalisationStatsParams = {};

export type UsePersonalisationStatsResponse = {
  stats: {
    [key: string]: {
      [key: string]: number;
    };
  };
  isLoading: boolean;
  hasNoData: boolean;
  fetch: (contactIds: UUID[]) => void;
};

const usePersonalisationStats =
  ({}: UsePersonalisationStatsParams): UsePersonalisationStatsResponse => {
    const [getPersonalisationTypes, { data: personalisationTypesData }] =
      useGetAllPersonalisationTypesLazyQuery();

    const personalisationTypes: Record<string, string> = useMemo(() => {
      if (!personalisationTypesData) {
        return null;
      }

      const { personalisationTypes } = normalizeGraphqlResult(
        personalisationTypesData
      );

      return personalisationTypes?.reduce(
        (
          acc: {
            [key: string]: string;
          },
          type: PersonalisationType
        ) => {
          acc[type.id] = type.name;
          return acc;
        },
        {}
      );
    }, [personalisationTypesData]);

    // Get pers statistics
    const [
      getPersStatistics,
      { data: persStatisticsData, loading: isLoading },
    ] = useGetPersStatisticsForLawyerLazyQuery();

    const fetch = (contactIds: UUID[]) => {
      if (contactIds.length) {
        getPersStatistics({
          variables: {
            contactIds: contactIds,
          },
        });
        getPersonalisationTypes();
      }
    };

    const stats: {
      [key: string]: {
        [key: string]: number;
      };
    } = useMemo(() => {
      if (!persStatisticsData) {
        return null;
      }

      if (!personalisationTypesData) {
        return null;
      }

      const { contacts } = normalizeGraphqlResult(persStatisticsData);

      return contacts?.reduce(
        (
          acc: {
            [key: string]: {
              [key: string]: number;
            };
          },
          contact: GetPersStatisticsForLawyerContact
        ) => {
          const { strValues, intValues, dateValues } = contact;

          const values = [
            ...strValues.values,
            ...intValues.values,
            ...dateValues.values,
          ];

          values.forEach(({ count, keys }) => {
            const persTypeId = keys?.[0];
            const persName = snakeCaseToDisplayText(
              personalisationTypes[persTypeId]
            );
            const persValue = snakeCaseToDisplayText(keys?.[1]);
            const persCount = parseInt(count?.value, 10);

            if (!persTypeId || !persValue || !persCount) {
              return acc;
            }

            if (
              !VALID_ORDERED_PERS_NAMES.includes(
                personalisationTypes[persTypeId]
              )
            ) {
              return acc;
            }

            if (!acc[persName]) {
              acc[persName] = {};
            }

            if (!acc[persName][persValue]) {
              acc[persName][persValue] = 0;
            }

            acc[persName][persValue] += persCount;

            return acc;
          });

          return acc;
        },
        {}
      );
    }, [persStatisticsData, personalisationTypesData]);

    return {
      fetch,
      stats,
      isLoading,
      hasNoData: !!(
        persStatisticsData &&
        (!stats || Object.keys(stats)?.length === 0)
      ),
    };
  };

export default usePersonalisationStats;
