import { Contact } from "@/models";
import { Typography, TypographyProps } from "@mui/material";

interface ContactTitleProps extends TypographyProps {
  contact?: Contact | null;
}

export default function ContactTitleText({
  contact,
  ...muiProps
}: ContactTitleProps) {
  return (
    <Typography
      variant="body2"
      color={"text.secondary"}
      whiteSpace={"nowrap"}
      {...muiProps}
    >
      {`${contact?.title || ""}${contact?.title ? " @ " : ""}${
        contact?.company?.name || ""
      }`}
    </Typography>
  );
}
