import { Prospect } from "@/models";
import { TrackedEventName } from "@/third-party/tracking";
import ContactBox from "@components/ContactBox";
import ControlledBox from "@components/ControlledBox";
import FewMatchesEmptyState from "@components/FewMatchesEmptyState";
import Tracked from "@components/Tracked";
import { ArrowLeftIcon, ArrowRightIcon } from "@components/icons";
import { useCampaignContext } from "@context/CampaignContext";
import { Masonry } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import EstimatedLeads from "../EstimatedLeads";

const CampaignMatches = () => {
  const {
    campaign,
    pagedProspects,
    isSearchingProspects,
    prospectsPage,
    setProspectsPage,
  } = useCampaignContext();

  const totalProspects = useMemo(() => {
    return Object.values(pagedProspects || {})?.reduce((acc, curr) => {
      return acc + curr.length;
    }, 0);
  }, [pagedProspects]);

  // Estimated Leads
  const [campaignEstimatedLeads, setCampaignEstimatedLeads] = useState<number>(
    campaign?.suggestedTargeting?.companyCount ?? 0
  );

  useEffect(() => {
    if (campaign?.suggestedTargeting?.companyCount) {
      setCampaignEstimatedLeads(campaign.suggestedTargeting.companyCount);
    }
  }, [campaign?.suggestedTargeting]);

  return (
    <>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={1}
        alignItems="center"
        justifyContent={{
          xs: "flex-start",
          sm: "space-between",
        }}
      >
        <Box>
          {!!totalProspects && !!pagedProspects && (
            <>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                We found the following matches for your target description.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We will add these matches <b>automatically</b> throughout your
                campaign.
              </Typography>
            </>
          )}
        </Box>

        {!!totalProspects ? (
          <Stack
            textAlign={"center"}
            alignItems={"flex-end"}
            direction={"row"}
            spacing={1}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={prospectsPage === 1 || isSearchingProspects}
              onClick={() => setProspectsPage(prospectsPage - 1)}
              startIcon={<ArrowLeftIcon />}
            >
              Back
            </Button>
            <Tracked
              onClick={{
                name: TrackedEventName.MORE_MATCHES_VIEWED,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                disabled={!pagedProspects || isSearchingProspects}
                onClick={() => setProspectsPage(prospectsPage + 1)}
                endIcon={<ArrowRightIcon />}
              >
                More
              </Button>
            </Tracked>
          </Stack>
        ) : null}
      </Stack>
      <EstimatedLeads
        suggestedTargetingId={campaign?.suggestedTargetingId}
        estimatedLeads={campaignEstimatedLeads}
      />

      <ControlledBox
        id="prospects"
        loading={isSearchingProspects}
        loadingMessage={
          pagedProspects && Object.keys(pagedProspects).length === 15
            ? "Loving the enthusiasm!"
            : pagedProspects
            ? "Finding more matches"
            : "Finding Sample Matches"
        }
        p={0}
        minHeight={1000}
      >
        {!totalProspects && <FewMatchesEmptyState />}
        <Box mt={2} />
        {!!pagedProspects && (
          <Masonry
            columns={{
              xs: 1,
              sm: 2,
              md: 3,
              lg: 3,
              xl: 4,
            }}
            spacing={2}
          >
            {pagedProspects?.[prospectsPage]?.map((prospect: Prospect) => (
              <ContactBox
                key={prospect.contactId}
                prospect={prospect}
                showBlacklistButton
              />
            ))}
          </Masonry>
        )}
      </ControlledBox>
    </>
  );
};

export default CampaignMatches;
