import {
  ChargedPer,
  Lawyer,
  LawyerAcquisitionType,
  LawyerOnboardingType,
  LawyerPriority,
} from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

export type AddressInfo = {
  country?: string | null;
  countryCode?: string | null;
  state?: string | null;
  timeZone?: string | null;
  postalCode?: string | null;
  city?: string | null;
};

export type LawyerCreate = {
  email: string;
  name: string;
  firmName: string;
  websiteUrl: string;
  linkedinUrl: string;

  title: string;
  senderBrandColor?: string;
  senderTitle?: string;
  senderLogoSrc?: string;
  stripePriceId: string;
  freeLeads?: number;
  signatureHtml: string;
  signatureSendgridId?: string;
  aliases?: string;
  wantsToChooseDomains?: boolean;
  chargedPer?: ChargedPer;
  acquisitionType: LawyerAcquisitionType;
  acquisitionChannel?: string;
  onboardingType: LawyerOnboardingType;
  icp: string;
  valueProposition: string;

  priority?: LawyerPriority;
  leadsPerMonth?: number;
} & AddressInfo;

export const createLawyer = async ({
  email,
  name,
  firmName,
  timeZone,
  countryCode,
  websiteUrl,
  linkedinUrl,
  postalCode,
  city,
  senderBrandColor,
  senderTitle,
  senderLogoSrc,
  stripePriceId,
  freeLeads,
  signatureHtml,
  signatureSendgridId,
  priority,
  aliases,
  wantsToChooseDomains,
  chargedPer,
  acquisitionType,
  acquisitionChannel,
  onboardingType,
  icp,
  valueProposition,
}: LawyerCreate) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      email,
      name,
      firmName,
      timeZone,
      countryCode,
      websiteUrl,
      linkedinUrl,
      postalCode,
      city,
      senderBrandColor,
      senderTitle,
      senderLogoSrc,
      stripePriceId,
      freeLeads,
      signatureHtml,
      signatureSendgridId,
      priority,
      aliases,
      wantsToChooseDomains,
      chargedPer,
      acquisitionType,
      acquisitionChannel,
      onboardingType,
      icp,
      valueProposition,
    },
  };

  const response = await fetchOutreachService(`/lawyers`, options);

  return response;
};

export const useCreateLawyer = () =>
  useServiceCall<Lawyer, LawyerCreate>({
    serviceCall: createLawyer,
    successMessage: "Lawyer created successfully",
  });
