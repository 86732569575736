import * as React from "react";
import { IconProps } from "./IconProps";

export type LogoIconProps = IconProps & React.SVGProps<SVGSVGElement>;

const LogoIcon = ({ height, width }: LogoIconProps) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 380 72">
    <path d="M71.7603.0328919V14.3981H57.4116L35.872 35.9377l21.5396 21.5067h14.3487v14.3652H54.5484c-2.0404 0-3.7847-.724-5.2163-2.1556L22.7738 43.0957h-8.425v28.6975H0V.0164209h14.3488V28.714h8.425L49.3321 2.1556C50.7637.724016 52.508 0 54.5484 0h17.2119v.0328919ZM136.347 71.8097H93.284c-2.6328 0-5.0352-.6417-7.2073-1.9252-2.1885-1.2835-3.9163-3.0113-5.1998-5.1998-1.2835-2.172-1.9252-4.5909-1.9252-7.2402V.032959h14.3487V57.4445h43.0626V.032959h14.349V57.4445c0 2.6328-.642 5.0517-1.925 7.2402-1.267 2.1885-3.012 3.9163-5.2 5.1998-2.189 1.2835-4.591 1.9252-7.207 1.9252h-.033Zm86.076 0h-50.204c-2.633 0-5.036-.6417-7.208-1.9252-2.188-1.2835-3.916-3.0113-5.2-5.1998-1.283-2.172-1.925-4.5909-1.925-7.2402V.032959h14.349V57.4445h50.204v14.3652h-.016ZM243.945.032959h43.063c2.633 0 5.035.641768 7.207 1.925261 2.189 1.28349 3.933 3.02768 5.2 5.19974 1.284 2.18851 1.925 4.59094 1.925 7.24024v57.4115h-14.348V43.1122h-43.063v28.6975H229.58V14.3982c0-2.6328.642-5.05173 1.925-7.24024 1.284-2.17206 3.012-3.91625 5.2-5.19974C238.877.674727 241.28.032959 243.912.032959h.033Zm0 14.365241V28.747h43.063V14.3982h-43.063Zm133.418 36.0694-8.722-8.7212c.971-.3126 1.926-.7075 2.831-1.2341 2.188-1.2835 3.932-3.0113 5.2-5.1998 1.283-2.1721 1.925-4.5909 1.925-7.2402V14.3817c0-2.6328-.642-5.05165-1.925-7.24017-1.268-2.17206-3.012-3.9163-5.2-5.19979-2.189-1.283493-4.591-1.9252605-7.208-1.9252605h-55.733V71.7933h14.349V43.0957h26.822l15.451 15.4513v13.2463h14.365V55.6838c0-2.0404-.724-3.7847-2.155-5.2162ZM322.88 14.3982h41.384V28.747H322.88V14.3982Z" fill="currentColor" />
  </svg>
);

export default LogoIcon;
