import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

type DisconnectCalendarParams = {
  lawyerId: UUID;
  profileId: string;
};

export const disconnectCalendar = async ({
  lawyerId,
  profileId,
}: DisconnectCalendarParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      profileId,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/meetings/calendar-profiles/disconnect`,
    options
  );

  return response;
};

export type DisconnectCalendarResponse = {
  disconnected: boolean;
};

export const useDisconnectCalendar = () =>
  useServiceCall<DisconnectCalendarResponse, DisconnectCalendarParams>({
    serviceCall: disconnectCalendar,
  });
