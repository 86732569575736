import { EmailAddress, dedupeEmailAddresses } from "@/models";
import palette from "@design/palette";
import { Box, FilterOptionsState, TextField, Typography } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const filter = createFilterOptions<EmailAddress>();

const Wrapper = styled.div`
  .MuiChip-label {
    color: ${palette.primary.main};
  }
`;

interface SelectEmailAddressProps {
  label: string;
  value: EmailAddress[] | undefined;
  emails: EmailAddress[];
  disabled?: boolean;
  onChange: (selectedEmails: EmailAddress[]) => void;
}

const SelectEmailAddress: React.FC<SelectEmailAddressProps> = ({
  label = "Select Email Addresses",
  value = [],
  emails,
  disabled = false,
  onChange,
}) => {

  const [selectedEmailAddresses, setSelectedEmailAddresses] = useState<EmailAddress[]>(dedupeEmailAddresses(value));

  // Update the value when the prop changes
  useEffect(() => {
    setSelectedEmailAddresses((selectedAddresses) => dedupeEmailAddresses([...selectedAddresses, ...value]));
  }, [value]);

  const remainingEmails = dedupeEmailAddresses(emails.filter(
    (email) => !selectedEmailAddresses.find((v) => v.email === email.email)
  ));

  const filterOptions = (options: EmailAddress[], params: FilterOptionsState<EmailAddress>) => {
    const filtered = filter(remainingEmails, params);

    const inputValueIsAlreadyAnOption = options.some((option) => params.inputValue === option.email);
    if (params.inputValue !== "" && !inputValueIsAlreadyAnOption) {
      filtered.push({
        name: "",
        email: params.inputValue,
      });
    }
    return filtered;
  }

  return (
    <Wrapper>
      <Autocomplete
        size="small"
        multiple
        freeSolo
        autoSelect
        disabled={disabled}
        value={selectedEmailAddresses}
        limitTags={2}
        onChange={(event, newValue) => {
          // Convert all string values to EmailAddress objects to ensure our types are valid
          const newValueAsEmailAddresses = newValue.map((value) => {
            if (typeof value === "string") {
              return {
                name: "",
                email: value,
              };
            }
            return value;
          });
          setSelectedEmailAddresses(dedupeEmailAddresses(newValueAsEmailAddresses));
          onChange(dedupeEmailAddresses(newValueAsEmailAddresses as EmailAddress[]));
        }}
        filterOptions={filterOptions}
        options={remainingEmails || []}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }

          const emailAddress = option as EmailAddress;
          if (emailAddress.name) {
            return emailAddress.name;
          }

          return emailAddress.email;
        }}
        renderOption={(props, option) => {
          const emailAddress = option as EmailAddress;
  
          return (
            // This key needs to be overridden as we often have duplicate names (which are the default key)
            <li {...props} key={emailAddress.email}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {emailAddress.name ? `${emailAddress.name} <${emailAddress.email}>` : `${emailAddress.email}`}
                </Typography>
              </Box>
            </li>
          );
        }}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </Wrapper>
  );
};

export default SelectEmailAddress;
