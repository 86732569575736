import { VisibilityState } from "@/models";
import { FreeLeadIcon } from "@components/icons";
import { Box, Chip } from "@mui/material";

const DEFAULT_FREE_LABEL = "Free Lead";
const LABEL_NAME: { [key in VisibilityState]?: string } = {
  [VisibilityState.FREE]: DEFAULT_FREE_LABEL,
  [VisibilityState.FREE_EXTRA]: DEFAULT_FREE_LABEL, // TODO(DJRHails): Change this to "Extra Lead"
};

interface FreeLeadChipProps {
  visibility?: VisibilityState;
}

const FreeLeadChip = ({ visibility }: FreeLeadChipProps) => {
  return (
    <Box mb={1}>
      <Chip
        label={
          LABEL_NAME[visibility ?? VisibilityState.FREE] ?? DEFAULT_FREE_LABEL
        }
        color="success"
        size="small"
        variant="filled"
        avatar={<FreeLeadIcon fontSize="small" />}
      />
    </Box>
  );
};

export default FreeLeadChip;
