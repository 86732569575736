import NewMessageSvg from "@components/icons/new_message_3.svg";
import { useAppContext } from "@context/AppContext";
import { Alert, Box, Button, Typography } from "@mui/material";

export const ConversationIncreaseBudget = ({ center }: { center?: boolean }) => {
  const { setHighlightPaymentSetup } = useAppContext();

  const onIncreaseBudget = () => {
    setHighlightPaymentSetup?.(true);
  };

  return (
    <Box
      position={"absolute"}
      top={0}
      left={0}
      height={"100%"}
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={center ? "center" : "flex-end"}
      zIndex={99999}
    >
      <Alert
        severity="error"
        variant="outlined"
        elevation={2}
        icon={
          <img src={NewMessageSvg} alt="New Message" width={100} height={100} />
        }
        action={
          <Button variant="text" size="small" onClick={onIncreaseBudget}>
            {/* Increase Lead Budget */}
            Increase Lead Cap
          </Button>
        }
      >
        <Typography variant="body2">
          You have a new lead that you cannot see yet.
        </Typography>
        <Typography variant="caption" color={"text.secondary"}>
          You'll not be able to view this lead until your lead cap is increased or reset.
        </Typography>
        <br />
        <Typography variant="caption" color={"text.secondary"}>
          Changes might take a while to reflect.
        </Typography>
      </Alert>
    </Box>
  );
};
