import { CampaignStatus } from "@/models";
import { PendingIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import { useCampaignContext } from "@context/CampaignContext";
import { Chip } from "@mui/material";

export default function CampaignStatusLabel() {
  const { campaign } = useCampaignContext();
  const { isPaused } = useAppContext();

  if (isPaused) {
    return <Chip label="Paused" size="small" color="primary" />;
  }

  return campaign?.status === CampaignStatus.ACTIVE ? (
    <Chip label="Active" color="primary" size="small" />
  ) : campaign?.status === CampaignStatus.PENDING ? (
    <Chip
      label="Draft"
      color="warning"
      size="small"
      icon={<PendingIcon color="warning" />}
    />
  ) : (
    <Chip label="Inactive" color="error" size="small" />
  );
}
