import config from "@/config";
import { camelToSnakeObject } from "@utils/object";

import { useAppContext } from "@context/AppContext";
import { isInternalEmail } from "@utils/email";
import { getFullName } from "@utils/text";
import { useEffect } from "react";

declare global {
  interface Window {
    analytics: any;
  }
}

// https://segment.com/docs/connections/spec/common/#context
const defaultOptions = {
  context: {
    app: {
      name: "web",
      version: "1.0.0",
      build: "1",
    },
  },
};
const LoadTracking = () => {
  const { profile } = useAppContext();

  useEffect(() => {
    loadAnalytics();
  }, []);

  // Identify user
  useEffect(() => {
    if (!profile?.id) return;

    const fullName = getFullName(profile?.firstName, profile?.lastName);

    if (
      profile.lawyer &&
      profile.email &&
      !isInternalEmail(profile.email)
    ) {
      identify(profile.lawyer.id, {
        name: fullName,
        given_name: profile.firstName,
        family_name: profile.lastName,
        email: profile.email || profile.lawyer.email,
        phone_number: profile.phoneNumber,
      });
    } else {
      identify("kula");
    }
  }, [profile]);

  // Track page view
  useEffect(() => {
    page();
  }, []);

  return <div></div>;
};

export default LoadTracking;

export function loadAnalytics() {
  if (config.ENABLE_TRACKING && window.analytics?.load)
    window.analytics.load(config.SEGMENT_WRITE_KEY);
}

// todo: Identify user after sign up is great but we might need to consider identifying logged in users.
export const identify = (
  userId: string,
  traits: any = {},
  options: object = defaultOptions,
  callback?: () => void
) => {
  const trackingIsEnabled =
    config.ENABLE_TRACKING && !isInternalEmail(traits?.email);

  if (trackingIsEnabled && window.analytics?.identify) {
    window.analytics.identify(userId, traits, defaultOptions, callback);
  }
};

export const page = () => {
  if (config.ENABLE_TRACKING) {
    window.analytics.page();
    track({
      name: TrackedEventName.PAGE_VIEWED,
      props: {
        page: window.location.pathname,
      },
    });
  }
};

export const alias = (userId: string) => {
  if (config.ENABLE_TRACKING) {
    window.analytics.alias(userId);
  }
};

export type TrackOptions = {
  name: TrackedEventName;
  props?: any;
  options?: any;
};

export function track({
  name,
  props = {},
  options = defaultOptions,
}: TrackOptions) {
  if (config.ENABLE_TRACKING && window.analytics?.track) {
    const snakeProps = camelToSnakeObject(props);
    const flatProps = flatten(snakeProps);

    flatProps["page"] = window.location.pathname;
    flatProps["app"] = "web";

    window.analytics.track(name, flatProps, options);
  }
}

const blacklistKeys = [
  "__typename",
  "apollo_enrichment_payload",
  "salesflow_token",
  "salesflow_id",
  "nylas_access_token",
  "slack_client_id",
  "slack_client_secret",
  "calendly_uri",
  "statistics_payload",
  "status_payload",
];

const flatten = (obj: any) =>
  Object.assign(
    {},
    ...(function _flatten(o: any, prefix = ""): any {
      return [].concat(
        ...Object.keys(o)
          .filter((k) => !blacklistKeys.includes(k))
          .map((k) =>
            o[k] && typeof o[k] === "object"
              ? _flatten(o[k], k)
              : { [prefix ? `${prefix}_${k}` : k]: o[k] }
          )
      );
    })(obj)
  );

export const enum TrackedEventName {
  ACCOUNT_PAUSED = "Account Paused",
  ACCOUNT_UNPAUSED = "Account Unpaused",
  ACCOUNT_PAUSE_ATTEMPTED = "Account Pause Attempted",
  BILLING_PORTAL_VIEWED = "Billing Portal Viewed",
  CAMPAIGN_ACTIVATED = "Campaign Activated",
  CAMPAIGN_CONVERSATIONS_VIEWED = "Campaign Conversations Viewed",
  CAMPAIGN_DUPLICATED = "Campaign Duplicated",
  CAMPAIGN_INACTIVATED = "Campaign Inactivated",
  CAMPAIGN_MESSAGING_VIEWED = "Campaign Messaging Viewed",
  CAMPAIGN_NAME_UPDATED = "Campaign Name Updated",
  CAMPAIGN_REJECTED = "Campaign Rejected",
  CAMPAIGN_STATISTICS_VIEWED = "Campaign Statistics Viewed",
  CAMPAIGN_TAB_CHANGED = "Campaign Tab Changed",
  CAMPAIGN_TARGETING_UPDATED = "Campaign Targeting Updated",
  CAMPAIGN_VIEWED = "Campaign Viewed",
  CAMPAIGNS_MENU_EXPANDED = "Campaigns Menu Expanded",
  CAMPAIGN_MENU_EXPANDED = "Campaign Menu Expanded",
  CAMPAIGN_DRAFT_CLICKED = "Campaign Draft Clicked",
  FIRST_CAMPAIGN_DRAFT_CLICKED = "First Campaign Draft Clicked",
  CONVERSATION = "Conversation",
  CONVERSATION_NOTE_ADDED = "Conversation Note Added",
  CONVERSATION_NOTE_VIEWED = "Conversation Note Viewed",
  CONVERSATION_OVERVIEW_CLICKED = "Conversation Overview Clicked",
  CONVERSATION_OVERVIEW_VIEWED = "Conversation Overview Viewed",
  CONVERSATION_RATED = "Conversation Rated",
  CONVERSATION_REPLIED = "Conversation Replied",
  CONVERSATION_SEARCHED = "Conversation Searched",
  CONVERSATION_TAB_CHANGED = "Conversation Tab Changed",
  CONVERSATION_VIEWED = "Conversation Viewed",
  CONTACT_INFO_VIEWED = "Contact Info Viewed",
  CONTACT_LINKEDIN_VIEWED = "Contact Linkedin Viewed",
  CONTACT_WEBSITE_VIEWED = "Contact Website Viewed",
  CONTACTED_MEMBERS_VIEWED = "Contacted Members Viewed",
  DASHBOARD_TAB_CHANGED = "Dashboard Tab Changed",
  INVOICE_CLICKED = "Invoice Clicked",
  LINKEDIN_AUTHENTICATED = "Linkedin Authenticated",
  LINKEDIN_AUTHENTICATION_ATTEMPTED = "LinkedIn Authentication Attempted",
  LOGGED_OUT = "Logged Out",
  CONTACT_BLACKLISTED = "Contact Blacklisted",
  CONTACT_SCHEDULED = "Contact Scheduled",
  MORE_MATCHES_VIEWED = "More Matches Clicked",
  PAYMENT_FAILED = "Payment Failed",
  PAYMENT_INTENT = "Payment Intented",
  PAYMENT_SUCCESS = "Payment Success",
  PROFILE_UPDATED = "Profile Updated",
  SEND_TEST_EMAIL_ATTEMPTED = "Send Test Email Attempted",
  SETUP_PAYMENT_ALERT_CLICKED = "Setup Payment Alert Clicked",
  SCHEDULED_CONTACTS_VIEWED = "Scheduled Contacts Viewed",
  TARGETING_TAB_CHANGED = "Targetting Tab Changed",
  TEMPLATE_ADDED = "Template Added",
  TEMPLATE_DELETED = "Template Deleted",
  TEMPLATE_EDIT_ATTEMPTED = "Template Edit Attempted",
  TEMPLATE_EDITED = "Template Edited",
  TEST_EMAIL_ATTEMPTED = "Test Email Attempted",
  TEST_EMAIL_SENT = "Test Email Sent",
  USER_LOGGED_IN = "User Logged In",
  USER_SIGNED_OUT = "User Signed Out",
  USER_SIGNED_UP = "User Signed Up",
  PAGE_VIEWED = "Page Viewed",

  ONBOARDING_STARTED = "Onboarding Started",
  ONBOARDING_COMPLETED = "Onboarding Completed",
  ONBOARDING_STEP_COMPLETED = "Onboarding Step Completed",
  ONBOARDING_FIELD_UPDATED = "Onboarding Field Updated",
}
