import { useAppContext } from "@context/AppContext";
import { useDebounce } from "@hooks/useDebounce";
import { Typography } from "@mui/material";
import { useGetMonthlyBudget } from "@services/payments/getMonthlyBudget";
import { useEffect, useMemo } from "react";

interface MonthlyBudgetProps {
  leadsPerMonth: number;
  onBudgetChange?: (budget: number) => void;
}

const MonthlyBudget: React.FC<MonthlyBudgetProps> = ({
  leadsPerMonth,
  onBudgetChange,
}) => {
  const { lawyerId } = useAppContext();
  const [getMonthlyBudget, { data: monthlyBudgetResponse }] =
    useGetMonthlyBudget();
  const monthlyCap = useMemo(() => {
    return (monthlyBudgetResponse?.monthlyBudget || 0) / 100;
  }, [monthlyBudgetResponse]);

  const debouncedLeadsPerMonth = useDebounce(leadsPerMonth, 100);

  useEffect(() => {
    if (!lawyerId) return;
    if (!debouncedLeadsPerMonth) return;

    getMonthlyBudget({
      lawyerId,
      leadsPerMonth: debouncedLeadsPerMonth,
    });
  }, [debouncedLeadsPerMonth, lawyerId]);

  useEffect(() => {
    if (monthlyCap) {
      onBudgetChange?.(monthlyCap);
    }
  }, [monthlyCap]);

  return (
    <Typography variant="body2" color={"text.secondary"}>
      {monthlyCap
        ? `Your monthly cap is $${monthlyCap?.toLocaleString()}`
        : " "}
    </Typography>
  );
};

export default MonthlyBudget;
