import { useServiceCall } from "@hooks/useServiceCall";
import { fetchOutreachService } from "@utils/requests";

export type GetPaymentSubscriptionPriceParams = {
  lawyerId: string;
};

export const getPaymentSubscriptionPrice = async ({
  lawyerId,
}: GetPaymentSubscriptionPriceParams) => {
  const options = {
    method: "GET",
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/payments/subscription`,
    options
  );

  return response;
};

export type SubscriptionTier = {
  unitAmount: number;
  upTo?: number | null;
};

export type GetPaymentSubscriptionPriceResponse = {
  tiers?: SubscriptionTier[];
  unitAmount: number | null;
};

export const useGetPaymentSubscriptionPrice = () =>
  useServiceCall<
    GetPaymentSubscriptionPriceResponse,
    GetPaymentSubscriptionPriceParams
  >({
    serviceCall: getPaymentSubscriptionPrice,
  });
