import isValidProp from "@emotion/is-prop-valid";
import { ReactNode } from "react";
import styled, { StyleSheetManager } from "styled-components";

export type BlurredProps = {
  children: ReactNode;
  isBlurred?: boolean;
};

const Blurred = ({ isBlurred, children }: BlurredProps) => {
  return (
    <StyleSheetManager shouldForwardProp={isValidProp}>
      <BlurredWrapper isBlurred={isBlurred}>{children}</BlurredWrapper>
    </StyleSheetManager>
  );
};

export default Blurred;

const BlurredWrapper = styled.div<{
  isBlurred?: boolean;
}>`
  position: relative;
  ${({ isBlurred }) =>
    isBlurred &&
    ` -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -ms-filter: blur(5px);
        -o-filter: blur(5px);    
        filter: url("data:image/svg+xml;utf8,<svg height='0' xmlns='http://www.w3.org/2000/svg'><filter id='svgBlur' x='-5%' y='-5%' width='110%' height='110%'><feGaussianBlur in='SourceGraphic' stdDeviation='5'/></filter></svg>#svgBlur");
        filter: progid: DXImageTransform.Microsoft.Blur(PixelRadius = '3');
        filter: blur(5px);
  `}
`;
