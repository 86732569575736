import { Contact } from "@/models";
import { TrackedEventName } from "@/third-party/tracking";
import { useAppContext } from "@context/AppContext";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box, Button, Popper, Stack, Typography } from "@mui/material";
import { useCreateBlacklistedOutreach } from "@services/campaigns/createBlacklistedOutreach";
import { MouseEvent, useState } from "react";
import Tracked from "./Tracked";
import { BlacklistIcon } from "./icons";

type BlacklistButtonProps = {
  contact: Contact;
} & LoadingButtonProps;

const BlacklistButton = ({
  contact,
  children,
  ...buttonProps
}: BlacklistButtonProps) => {
  const { profile } = useAppContext();

  // Confirmation
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? "blacklist-button" : undefined;

  // Show confirmation
  const handleShowConfirmation = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Blacklist
  const [blacklistContact, { loading }] = useCreateBlacklistedOutreach();

  const handleBlackList = async () => {
    setAnchorEl(null);

    if (!profile?.lawyer?.id) {
      return;
    }

    await blacklistContact({
      lawyerId: profile.lawyer.id,
      email: contact.email,
      linkedinUrl: contact.linkedinUrl,
      reason: "Manually blacklisted",
    });
  };

  // Cancel
  const handleCancel = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <LoadingButton
        variant="text"
        size="small"
        color="error"
        loading={loading}
        startIcon={<BlacklistIcon />}
        {...buttonProps}
        onClick={handleShowConfirmation}
      >
        {children}
      </LoadingButton>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Box
          sx={{
            border: 1,
            p: 2,
            bgcolor: "background.paper",
            borderColor: "error.main",
            borderRadius: 2,
          }}
        >
          <Stack direction="column" spacing={1}>
            <Typography variant="body2">
              Are you sure you want to blacklist this contact?
            </Typography>
            <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
              <Button variant="outlined" size="small" onClick={handleCancel}>
                Cancel
              </Button>
              <Tracked onClick={{ name: TrackedEventName.CONTACT_BLACKLISTED }}>
                <LoadingButton
                  variant="contained"
                  size="small"
                  color="error"
                  loading={loading}
                  onClick={handleBlackList}
                >
                  Blacklist
                </LoadingButton>
              </Tracked>
            </Stack>
          </Stack>
        </Box>
      </Popper>
    </>
  );
};

export default BlacklistButton;
