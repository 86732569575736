import { CampaignChannel } from "@/models";
import { TrackedEventName } from "@/third-party/tracking";
import Tracked from "@components/Tracked";
import LinkDialog from "@components/editor/LinkDialog";
import PlaceholderSelector from "@components/editor/PlaceholderSelect";
import TemplateEditor, {
  ANCHOR_LINK_REGEX,
  LINK_PLACEHOLDER_REGEX,
  LINK_REGEX,
} from "@components/editor/TemplateEditor";
import { useCampaignContext } from "@context/CampaignContext";
import { LoadingButton } from "@mui/lab";

import {
  Alert,
  Box,
  Collapse,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useCreateEmailCampaignTemplate } from "@services/campaigns/createEmailCampaignTemplate";
import { useCreateLinkedinCampaignTemplate } from "@services/campaigns/createLinkedinCampaignTemplate";
import { Editor } from "codemirror";
import { useCallback, useEffect, useMemo, useState } from "react";

const EMAIL_TEMPLATE_DELAYS = [
  0, 518400, 1036800, 1555200, 2073600, 2592000,
];

type AddTemplateProps = {
  onAdd: () => void;
  onClose: () => void;
};

const AddTemplate = ({ onAdd, onClose }: AddTemplateProps) => {
  const [editor, setEditor] = useState<Editor | null>(null);
  const { campaign, templates } = useCampaignContext();
  const [content, setContent] = useState<string>("");

  // Handle insert placeholder
  const handleInsertPlaceholder = useCallback(
    (keyword: string) => {
      if (editor) {
        const keywordText = `{{{${keyword}}}}`;
        editor.replaceSelection(keywordText);

        editor.focus();
      }
    },
    [editor]
  );

  // Handle insert link
  const handleInsertLink = (text: string, url: string) => {
    // If there is a selection replace the selection text with {{{link 'text' 'url'}}}
    // Otherwise insert {{{link 'text' 'url'}}} at the cursor position
    if (!editor) {
      return;
    }

    const doc = editor.getDoc();
    const selection = doc.getSelection();

    if (selection) {
      doc.replaceSelection(`{{{link '${text}' '${url}'}}}`);
    } else {
      const cursor = doc.getCursor();
      doc.replaceRange(`{{{link '${text}' '${url}'}}}`, cursor);
    }

    // Move cursor to the end of the inserted link
    const cursor = doc.getCursor();
    const line = doc.getLine(cursor.line);
    const lineLength = line.length;
    doc.setCursor({ line: cursor.line, ch: lineLength });

    // Focus the editor
    editor.focus();
  };

  // Word count
  const maxWordCount = campaign?.channel === CampaignChannel.EMAIL ? 150 : 100;

  const wordCount = useMemo(() => {
    const text = content?.trim() || "";
    const words = text.split(/\s+/).filter((word) => word.length > 0);
    return words.length;
  }, [content]);

  const readTimeInSec = useMemo(() => {
    return Math.ceil((wordCount / 200) * 60);
  }, [wordCount]);

  // Add link button state
  // Disable when there is no selection or when there is a placeholder in the selection
  const [linkDialogValue, setLinkDialogValue] = useState<{
    text: string;
    url: string;
  } | null>(null);

  const [enableAddLink, setEnableAddLink] = useState(false);
  const checkEnableAddLink = useCallback(() => {
    if (!editor) {
      return;
    }

    const selection = editor.getSelection();

    if (selection) {
      // If selection has more than just text
      // Disable the add link button
      const isTextOnly =
        selection.indexOf("{") === -1 && selection.indexOf("}") === -1;

      setEnableAddLink(isTextOnly);
    } else {
      setEnableAddLink(false);
    }
  }, [editor]);

  // Adjust add link button state on selection change or cursor activity
  useEffect(() => {
    if (editor) {
      editor.on("beforeSelectionChange", checkEnableAddLink);

      editor.on("cursorActivity", checkEnableAddLink);

      return () => {
        editor.off("beforeSelectionChange", checkEnableAddLink);
        editor.off("cursorActivity", checkEnableAddLink);
      };
    }
  }, [editor]);

  // Show links warning
  const [showLinksWarning, setShowLinksWarning] = useState<boolean>(false);
  const hasLinks = useMemo(
    () =>
      !!(
        content &&
        (LINK_PLACEHOLDER_REGEX.test(content.trim()) ||
          ANCHOR_LINK_REGEX.test(content.trim()) ||
          LINK_REGEX.test(content.trim()))
      ),
    [content]
  );
  useEffect(() => {
    const showWarning = hasLinks && campaign?.channel === CampaignChannel.EMAIL;
    setShowLinksWarning(showWarning);
  }, [hasLinks, campaign?.channel, setShowLinksWarning]);

  // Add Template
  const [createEmailCampaignTemplate, { loading: isAddingEmailTemplate }] =
    useCreateEmailCampaignTemplate();
  const [
    createLinkedinCampaignTemplate,
    { loading: isAddingLinkedinTemplate },
  ] = useCreateLinkedinCampaignTemplate();

  const isAddingTemplate =
    isAddingEmailTemplate || isAddingLinkedinTemplate || false;

  const addTemplate = useCallback(async () => {
    if (!campaign?.id) return;

    if (campaign.channel === CampaignChannel.EMAIL) {
      await createEmailCampaignTemplate({
        campaignId: campaign?.id,
        content,
        status: "ACTIVE",
        delay: EMAIL_TEMPLATE_DELAYS[templates?.length || 0],
        orderIndex: templates?.length || 0,
      });
    } else {
      await createLinkedinCampaignTemplate({
        campaignId: campaign?.id,
        content,
        status: "ACTIVE",
        name: `Template ${templates?.length + 1}`,
        orderIndex: templates?.length || 0,
      });
    }

    onAdd();
  }, [
    campaign?.id,
    campaign?.channel,
    content,
    createEmailCampaignTemplate,
    createLinkedinCampaignTemplate,
    onAdd,
  ]);

  return (
    <Box bgcolor={"#FAFAFA"} borderRadius={1} px={4} py={2}>
      <Typography variant="body2" mb={1} color="text.secondary">
        New Message
      </Typography>
      <Divider />
      <Collapse in={showLinksWarning}>
        <Box mb={2}>
          <Alert severity="warning">
            Adding hyperlinks to an email makes it more likely to go to spam.
            Please remove any hyperlinks from your email.
          </Alert>
        </Box>
      </Collapse>
      <Box mt={2} />
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent={"flex-start"}
        mb={1}
      >
        <PlaceholderSelector onSelected={handleInsertPlaceholder} />

        {/* <Button
          disabled={!enableAddLink}
          size="small"
          color="primary"
          startIcon={<LinkIcon />}
          onClick={() => {
            if (editor) {
              const selection = editor.getSelection();
              if (selection) {
                setLinkDialogValue({
                  text: selection,
                  url: "",
                });
              } else {
                setLinkDialogValue({
                  text: "",
                  url: "",
                });
              }
            }
          }}
        >
          <Tip title="Add Link">
            <>Link</>
          </Tip>
        </Button> */}
      </Stack>
      <Box p={2} borderRadius={1} bgcolor={"#FFF"}>
        <TemplateEditor
          value={content}
          onChange={(value: string) => {
            setContent(value);
          }}
          onEditorMount={(editor) => setEditor(editor)}
          onSetLink={(text: string, url: string) => {
            setLinkDialogValue({ text, url });
          }}
        />
      </Box>
      <Stack direction="row" spacing={1} mt={1} alignItems="center">
        <Typography variant="caption" color="textSecondary">
          {readTimeInSec} seconds read
        </Typography>
        <Typography
          variant="caption"
          color={wordCount > maxWordCount ? "error" : "textSecondary"}
        >
          {wordCount}/{maxWordCount} words
        </Typography>
      </Stack>{" "}
      <Stack direction="row" spacing={1} mt={2}>
        <LoadingButton
          variant="outlined"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </LoadingButton>
        <Tracked onClick={{ name: TrackedEventName.TEMPLATE_ADDED }}>
          <LoadingButton
            variant="contained"
            onClick={addTemplate}
            loading={isAddingTemplate}
            disabled={!content || hasLinks}
          >
            Save
          </LoadingButton>
        </Tracked>
      </Stack>
      <LinkDialog
        isOpen={!!linkDialogValue}
        initialValue={linkDialogValue}
        onClose={() => {
          setLinkDialogValue(null);
        }}
        onConfirm={(text: string, url: string) => {
          handleInsertLink(text, url);
        }}
      />
    </Box>
  );
};

export default AddTemplate;
