import { MenuItem, Select, SelectProps } from "@mui/material";
import React, { useMemo } from "react";

type TimeSelectProps = SelectProps & {
  rangeDuration?: number; // Duration in minutes
  minTime?: string; // Format: "HH:MM"
  maxTime?: string; // Format: "HH:MM"
};

const TimeSelect: React.FC<TimeSelectProps> = ({
  rangeDuration = 30,
  minTime = "00:00",
  maxTime = "23:59",
  ...props
}) => {
  const parseTime = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const generateTimeRanges = (
    duration: number,
    minTime: string,
    maxTime: string
  ) => {
    const ranges = [];
    const minMinutes = parseTime(minTime);
    const maxMinutes = parseTime(maxTime);

    for (let i = minMinutes; i <= maxMinutes; i += duration) {
      const hours = Math.floor(i / 60);
      const minutes = i % 60;
      const formattedTime = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}`;
      ranges.push(formattedTime);
    }
    return ranges;
  };

  const timeRanges = useMemo(
    () => generateTimeRanges(rangeDuration, minTime, maxTime),
    [rangeDuration, minTime, maxTime]
  );

  return (
    <Select {...props}>
      {timeRanges.map((time, index) => (
        <MenuItem key={index} value={time}>
          {time}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TimeSelect;
