import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

interface GetLinkedinEnabledForParamsRequired {
  lawyerId: string;
}

type GetLinkedinEnabledForResponse = boolean;

type GetLinkedinEnabledForParams = Partial<GetLinkedinEnabledForParamsRequired>;

export const getLinkedinEnabledFor = async ({
  lawyerId,
}: GetLinkedinEnabledForParams) => {

  const options: FetchOptions = {
    method: "GET",
  };

  const response = await fetchOutreachService(
    `/unipile/manage/ff-enabled?lawyer_id=${lawyerId}`,
    options
  );
  return response;
};



export const useGetLinkedinEnabledFor = () =>
  useServiceCall<GetLinkedinEnabledForResponse, GetLinkedinEnabledForParams>({
    serviceCall: getLinkedinEnabledFor,
  });
