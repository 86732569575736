import { CampaignChannel, CampaignStatus } from "@/models";
import { useCampaignContext } from "@context/CampaignContext";
import { SvgIconProps } from "@mui/material";
import { LinkedinIcon } from "../../../../components/icons";

type CampaignIconWithStateProps = {} & SvgIconProps;

export default function CampaignIconWithState({
  fontSize = "medium",
  ...muiProps
}: CampaignIconWithStateProps) {
  const { campaign } = useCampaignContext();
  const color =
    campaign?.status === CampaignStatus.ACTIVE
      ? "primary"
      : campaign?.status === CampaignStatus.PENDING
      ? "warning"
      : "error";

  return (
    <>
      {campaign?.channel === CampaignChannel.LINKEDIN && (
        <LinkedinIcon color={color} fontSize={fontSize} {...muiProps} />
      )}
    </>
  );
}
