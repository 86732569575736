import { ROUTE_DASHBOARD } from "@/AuthenticatedAppRoutes";
import Page from "@components/layout/page/Page";
import { useAppContext } from "@context/AppContext";

import {
  Box,
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";

import { ReactElement, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { TrackedEventName, track } from "@/third-party/tracking";
import AddressSelect, { AddressSelectValue } from "@components/AddressSelect";
import InfoBox from "@components/InfoBox";
import {
  CancelIcon,
  CheckSingleIcon,
  CompanyIcon,
  FreeLeadIcon,
  JobTitleIcon,
  LinkedinIcon,
  TargetingIcon,
  WebsiteIcon,
} from "@components/icons";
import ClientSvg from "@components/icons/client.svg";
import BusinessInfoSvg from "@components/icons/kingqueen.svg";
import SecureSvg from "@components/icons/secure.svg";
import WelcomeSvg from "@components/icons/welcome.svg";
import Logo from "@components/layout/Logo";
import {
  DEFAULT_FREE_LEADS,
  DEFAULT_PRICE_IN_DOLLARS,
  LawyerCreateForm,
  LawyerCreateFormField,
  OnboardingProvider,
  OnboardingStepType,
  useOnboardingContext,
} from "@context/OnboardingContext";
import { LoadingButton } from "@mui/lab";
import { MONTHLY_COST_THRESHOLD } from "@pages/profile/billing/TargetLeads";
import { LawyerCreate, useCreateLawyer } from "@services/lawyers/createLawyer";
type FilterKeys<T, U> = {
  [K in keyof T]: K extends keyof U ? K : never;
};

export const mapToLawyerCreate = <T extends LawyerCreateForm>(
  form: T
): LawyerCreate => {
  const result: Partial<LawyerCreate> = {};

  Object.keys(form).forEach((key) => {
    const formKey = key as keyof LawyerCreateForm;
    if (formKey in form) {
      const sourceKey = formKey as keyof LawyerCreate;
      const value = form[formKey];

      // Exclude null and undefined from the type of value
      if (value !== null && value !== undefined) {
        result[sourceKey] = value as any;
      }
    }
  });

  return result as LawyerCreate;
};

export default function OnboardingPage() {
  return (
    <OnboardingProvider>
      <OnboardingPageContent />
    </OnboardingProvider>
  );
}

function OnboardingPageContent() {
  const navigate = useNavigate();
  const { profileCreate, isCreatingProfile } =
    useAppContext();

  const { lawyerCreateForm, currentStep, setCurrentStep } =
    useOnboardingContext();

  const [createLawyer, { loading: isCreatingLawyer }] = useCreateLawyer();

  useEffect(() => {
    if (!profileCreate) {
      navigate(ROUTE_DASHBOARD);
    } else {
      track({
        name: TrackedEventName.ONBOARDING_STARTED,
        props: { ...(profileCreate || {}) },
      });
    }
  }, [profileCreate]);

  // Is Busy
  const isBusy = isCreatingProfile || isCreatingLawyer;

  const steps = [
    {
      title: `Welcome, ${profileCreate?.firstName}!`,
      description: "Lets get started with your free trial.",
      type: OnboardingStepType.FREE_LEADS,
      component: FreeLeads,
      image: WelcomeSvg,
      canGoNext: (lawyerCreateForm: LawyerCreateForm) => {
        return !!(
          lawyerCreateForm?.acquisitionChannelOther ||
          lawyerCreateForm?.acquisitionChannel
        );
      },
    },
    {
      title: "Your Profile",
      description: "Tell us more about your business.",
      type: OnboardingStepType.PERSONAL_INFO,
      image: BusinessInfoSvg,
      component: BusinessInfo,
      canGoNext: (lawyerCreateForm: LawyerCreateForm) => {
        return (
          !!lawyerCreateForm?.firmName &&
          !!lawyerCreateForm?.websiteUrl &&
          !!lawyerCreateForm?.title &&
          !!lawyerCreateForm?.linkedinUrl &&
          lawyerCreateForm?.addressIsOk
        );
      },
    },
    {
      title: "Your Targeting",
      description: "Define your ideal client.",
      type: OnboardingStepType.TARGETTING,
      component: Targetting,
      image: ClientSvg,
      canGoNext: (lawyerCreateForm: LawyerCreateForm) => {
        return (
          !!lawyerCreateForm?.icp &&
          !!lawyerCreateForm?.focus &&
          !!lawyerCreateForm?.socialProof
        );
      },
    },
    {
      title: "Your Budget",
      description: "You can change your budget at any time.",
      type: OnboardingStepType.BUDGET,
      component: TargetLeads,
      image: SecureSvg,
      canGoNext: (lawyerCreateForm: LawyerCreateForm) => {
        return !!lawyerCreateForm?.budgetIsOk;
      },
    },
  ];

  const canGoNext = useMemo(() => {
    const step = steps.find((step) => step.type === currentStep);
    return step?.canGoNext?.(lawyerCreateForm!) && !isBusy;
  }, [currentStep, lawyerCreateForm, isBusy]);

  const handleContinue = () => {
    if (!lawyerCreateForm) {
      return;
    }
    if (currentStep === steps.length - 1) {
      if (!lawyerCreateForm) {
        return;
      }
      track({
        name: TrackedEventName.ONBOARDING_COMPLETED,
        props: {
          lawyerCreateForm,
          ...(profileCreate || {}),
        },
      });
      createLawyer?.(lawyerCreateForm);
    } else {
      setCurrentStep(currentStep + 1);
      track({
        name: TrackedEventName.ONBOARDING_STEP_COMPLETED,
        props: {
          step: currentStep,
          lawyerCreateForm,
          ...(profileCreate || {}),
        },
      });
    }
  };

  // Calculate progress from current step
  const progress = useMemo(() => {
    const totalSteps = steps.length;
    const currentStepIndex = steps.findIndex(
      (step) => step.type === currentStep
    );

    return ((currentStepIndex + 1) / totalSteps) * 100;
  }, [currentStep]);

  return (
    <Page allowGuest>
      <Stack
        spacing={3}
        alignItems={"center"}
        bgcolor={"background.info"}
        py={3}
      >
        <InfoBox maxWidth={500} bgcolor={"background.paper"}>
          <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
            <Box color="primary.main">
              <Logo height={20} />
            </Box>
            <LinearProgress
              variant="determinate"
              color="success"
              value={progress}
              sx={{
                width: "100%",
              }}
            />

            <Box>
              {steps.map((step, index) => (
                <Collapse
                  in={currentStep === step.type}
                  key={index}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Stack spacing={4} width={"100%"}>
                    <Stack
                      spacing={3}
                      pt={1}
                      direction={"row"}
                      alignItems={"center"}
                    >
                      <img src={step.image} alt={step.title} height={100} />
                      <Stack spacing={1}>
                        <Typography variant="h6">{step.title}</Typography>
                        <Typography variant="body2">
                          {step.description}
                        </Typography>
                      </Stack>
                    </Stack>
                    <step.component />
                  </Stack>
                  <Divider sx={{ width: "100%", mt: 2 }} />

                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"center"}
                    mt={2}
                  >
                    {index !== 0 ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={index === 0}
                        onClick={() => setCurrentStep(step.type - 1)}
                      >
                        Back
                      </Button>
                    ) : null}
                    <LoadingButton
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={!canGoNext}
                      loading={isBusy}
                      onClick={handleContinue}
                    >
                      {index === steps.length - 1 ? "Finish" : "Continue"}
                    </LoadingButton>
                  </Stack>
                </Collapse>
              ))}
            </Box>
          </Stack>
        </InfoBox>
      </Stack>
    </Page>
  );
}

const BusinessInfo = () => {
  const { updateFormValues } = useOnboardingContext();

  return (
    <Stack spacing={3}>
      <OnboardingTextField
        label="Company Name"
        name="firmName"
        startIcon={<CompanyIcon />}
      />
      <OnboardingTextField
        label="Your Title"
        name="title"
        helperText="Ex: CEO, Founder, etc."
        startIcon={<JobTitleIcon />}
      />
      <OnboardingTextField
        label="Company Website"
        name="websiteUrl"
        helperText="Ex: https://www.your-website.com"
        startIcon={<WebsiteIcon />}
      />
      <OnboardingTextField
        label="Your LinkedIn Profile"
        helperText="Ex: https://www.linkedin.com/in/your-profile"
        name="linkedinUrl"
        startIcon={<LinkedinIcon />}
      />
      <AddressSelect
        onChange={(addressInfo: AddressSelectValue) => {
          updateFormValues?.(addressInfo);
        }}
      />
    </Stack>
  );
};

enum LawyerAcquisitionChannel {
  FRIEND_COLLEAGUES = "FRIEND_COLLEAGUES",
  EMAIL = "EMAIL",
  LINKEDIN = "LINKEDIN",
  OTHER = "OTHER",
}

const FreeLeads = () => {
  const { lawyerCreateForm, updateFormValue } = useOnboardingContext();

  const wantsFreeLeads = useMemo(() => {
    return lawyerCreateForm?.freeLeads === DEFAULT_FREE_LEADS;
  }, [lawyerCreateForm?.freeLeads]);

  const [acquisitionChannel, setAcquisitionChannel] = useState<
    string | undefined
  >(undefined);

  // Show other acquisition channel field
  const [showOther, setShowOther] = useState<boolean>(false);

  useEffect(() => {
    if (acquisitionChannel !== LawyerAcquisitionChannel.OTHER) {
      updateFormValue?.("acquisitionChannel", acquisitionChannel);
    } else {
      updateFormValue?.("acquisitionChannel", undefined);
      setShowOther(true);
    }
  }, [acquisitionChannel]);

  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Stack spacing={1} direction={"row"} alignItems={"center"} mt={2}>
          <FreeLeadIcon color="success" fontSize="small" />
          <Typography variant="subtitle1" color="success.main">
            How did you hear about us?
          </Typography>
        </Stack>
        <RadioGroup
          aria-labelledby="free-leads"
          value={acquisitionChannel}
          onChange={(e) => {
            const channel = e.target.value as LawyerAcquisitionChannel;
            setAcquisitionChannel(channel);
          }}
        >
          <FormControlLabel
            value={LawyerAcquisitionChannel.FRIEND_COLLEAGUES}
            control={<Radio size="small" />}
            label={`Friends or family`}
          />
          <FormControlLabel
            value={LawyerAcquisitionChannel.EMAIL}
            control={<Radio size="small" />}
            label="Email"
          />

          <FormControlLabel
            value={LawyerAcquisitionChannel.LINKEDIN}
            control={<Radio size="small" />}
            label="LinkedIn"
          />

          <FormControlLabel
            value={LawyerAcquisitionChannel.OTHER}
            control={<Radio size="small" />}
            label="Other"
          />
        </RadioGroup>
        <Collapse in={showOther}>
          <OnboardingTextField
            fullWidth
            rows={2}
            label="How did you hear about us?"
            name="acquisitionChannelOther"
          />
        </Collapse>
      </Stack>
      <Stack spacing={1}>
        <Stack spacing={1} direction={"row"} alignItems={"center"} mt={2}>
          <FreeLeadIcon color="success" fontSize="small" />
          <Typography variant="subtitle1" color="success.main">
            Do you want {DEFAULT_FREE_LEADS} free leads?
          </Typography>
        </Stack>
        <RadioGroup
          aria-labelledby="free-leads"
          value={wantsFreeLeads}
          onChange={(e) => {
            if (e.target.value === "true") {
              updateFormValue?.("freeLeads", DEFAULT_FREE_LEADS);
            } else {
              updateFormValue?.("freeLeads", 0);
            }
          }}
        >
          <FormControlLabel
            value={true}
            control={<Radio size="small" />}
            label={`Yes, I want ${DEFAULT_FREE_LEADS} free leads`}
          />
          <FormControlLabel
            value={false}
            control={<Radio size="small" />}
            label="No, I don't want free leads"
          />
        </RadioGroup>
      </Stack>
    </Stack>
  );
};

const Targetting = () => {
  return (
    <Stack spacing={3}>
      <OnboardingTextField
        label="Who Are Your Clients?"
        helperText="E.g. Founders of technology startups in the USA"
        name="icp"
        multiline
        rows={3}
      />
      <OnboardingTextField
        label="What Is Your Service?"
        helperText="E.g. Filing software patents"
        name="focus"
        multiline
        rows={3}
      />
      <OnboardingTextField
        label="What Makes You Special?"
        helperText="E.g. An award or customer testimonial."
        name="socialProof"
        multiline
        rows={3}
      />
    </Stack>
  );
};

const TargetLeads = () => {
  const { lawyerCreateForm, updateFormValue } = useOnboardingContext();
  const monthlyBudgetInDollars = useMemo(() => {
    if (!lawyerCreateForm?.leadsPerMonth) return null;

    return DEFAULT_PRICE_IN_DOLLARS * lawyerCreateForm?.leadsPerMonth!;
  }, [lawyerCreateForm?.leadsPerMonth]);

  const isMonthlyBudgetOk = useMemo(() => {
    return (
      !!monthlyBudgetInDollars &&
      MONTHLY_COST_THRESHOLD <= monthlyBudgetInDollars
    );
  }, [monthlyBudgetInDollars]);

  useEffect(() => {
    updateFormValue?.("budgetIsOk", isMonthlyBudgetOk);
  }, [isMonthlyBudgetOk]);

  return (
    <>
      <Box>
        <Stack spacing={2}>
          <Stack spacing={1} direction={"row"} alignItems={"center"} mt={2}>
            <TargetingIcon color="success" fontSize="small" />
            <Typography variant="subtitle1" color="success.main">
              How many leads do you want each month?
            </Typography>
          </Stack>
          <OnboardingTextField
            label="Maximum leads per month"
            name="leadsPerMonth"
            type="number"
            helperText={
              !!monthlyBudgetInDollars
                ? `Your monthly budget will be $${monthlyBudgetInDollars?.toLocaleString() || " -"
                }`
                : undefined
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Leads</InputAdornment>
              ),
            }}
            validator={(_) => !!isMonthlyBudgetOk}
          />
          {monthlyBudgetInDollars && !isMonthlyBudgetOk ? (
            <Typography variant="body2" color="primary">
              Your monthly budget is below the minimum threshold of $
              {MONTHLY_COST_THRESHOLD.toLocaleString()}. This is the minimum
              amount required to run your campaigns. You will be able to
              start/stop your campaigns or pause your account at any time.
            </Typography>
          ) : null}

          {!!lawyerCreateForm?.freeLeads ? (
            <Stack spacing={1} direction={"row"} alignItems={"center"}>
              <CheckSingleIcon color="success" fontSize="small" />
              <Typography variant="body2" color="primary">
                Your first {lawyerCreateForm?.freeLeads} leads are free. You can
                cancel anytime, there is no commitment.
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </Box>
    </>
  );
};

type OnboardingTextFieldProps = TextFieldProps & {
  startIcon?: ReactElement;
  validator?: (value: any) => boolean;
};

const OnboardingTextField = (props: OnboardingTextFieldProps) => {
  const { profileCreate, isCreatingProfile } =
    useAppContext();
  const { lawyerCreateForm, currentStep, updateFormValue } =
    useOnboardingContext();

  const isBusy = useMemo(
    () => isCreatingProfile,
    [isCreatingProfile]
  );

  const fieldName = props.name as LawyerCreateFormField;
  const value = lawyerCreateForm?.[fieldName] || null;

  const isValid = props.validator ? props.validator(value) : !!value;

  // Track field changes on blur
  const handleBlur = () => {
    if (isValid) {
      track({
        name: TrackedEventName.ONBOARDING_FIELD_UPDATED,
        props: {
          fieldName,
          value: lawyerCreateForm?.[fieldName],
          step: currentStep,
          ...(profileCreate || {}),
        },
      });
    }
  };

  return lawyerCreateForm ? (
    <TextField
      {...props}
      value={value}
      onChange={(e) => {
        updateFormValue?.(fieldName, e.target.value);
      }}
      disabled={isBusy}
      onBlur={handleBlur}
      InputProps={{
        endAdornment:
          !!value && isValid ? (
            <InputAdornment position="end">
              <CheckSingleIcon color="success" />
            </InputAdornment>
          ) : !!value && !isValid ? (
            <InputAdornment position="end">
              <CancelIcon color="error" />
            </InputAdornment>
          ) : null,
      }}
    />
  ) : null;
};
