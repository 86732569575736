import config from "@/config";
import Auth from "@aws-amplify/auth";
import _ from "lodash";
import { camelToSnakeObject, removeUndefinedValues } from "./object";

export const defaultOutreachServiceHeaders: RequestOptions["headers"] = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
  "Accept-Encoding": "gzip, deflate, br",
  "Access-Control-Allow-Headers": "Content-Type",
  "Access-Control-Allow-Methods": "*",
  "content-encoding": "gzip",
  // "Content-Type": "application/json",
};

export const defaultOptions: RequestInit = {
  headers: defaultOutreachServiceHeaders,
  mode: "cors",
};

interface RequestOptions extends RequestInit {
  timeout?: number;
}

async function getAuthOptions() {
  try {
    const session = await Auth.currentSession();
    if (!session) {
      return {};
    }

    const token = session.getIdToken().getJwtToken();
    if (!token) {
      return {};
    }
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } catch (error) {
    console.error(error);
    return {};
  }
}

async function fetchWithTimeout(
  resource: string,
  options: RequestOptions = {}
): Promise<Response> {
  try {
    const authOptions = await getAuthOptions();
    const { timeout = 2 * 60 * 1000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => {
      console.error(
        `Request timed out: ${resource}, timeout: ${timeout}, options: ${JSON.stringify(
          options
        )}`
      );
      controller.abort();
    }, timeout);

    const mergedOptions = _.merge({}, defaultOptions, options, authOptions);

    const response = await fetch(resource, {
      ...mergedOptions,
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function mergePaths(apiPath: string, relativePath: string): string {
  const apiPathWithoutTrailingSlash = apiPath.replace(/\/$/, "");
  const relativePathWithoutLeadingSlash = relativePath.replace(/^\//, "");

  return `${apiPathWithoutTrailingSlash}/${relativePathWithoutLeadingSlash}`;
}

export type FetchOptions = {
  payload?: any;
  query?: any;
  excludeContentTypeHeader?: boolean;
} & RequestOptions;

export const fetchOutreachService = async (
  relativeResource: string,
  options: FetchOptions
): Promise<Response> => {
  const { query = {}, payload, ...fetchOptions } = options;

  // Make it easy to pass in a query object with camelCase keys
  if (payload) {
    let body = camelToSnakeObject(payload);

    // If we're doing a PATCH request, remove undefined values from the payload
    if (fetchOptions.method === "PATCH") {
      body = removeUndefinedValues(body);
    }

    // Convert array values to JSON strings
    body = JSON.stringify(body);

    fetchOptions.body = body;
  }

  // Make it easy to pass in a query object with camelCase keys
  const snakeCaseQuery = camelToSnakeObject(query);
  const queryString = new URLSearchParams(snakeCaseQuery).toString();
  let url = mergePaths(config.OUTREACH_SERVICE_URL, relativeResource);

  if (queryString) {
    url = `${url}?${queryString}`;
  }

  if (!fetchOptions.excludeContentTypeHeader) {
    fetchOptions.headers = {
      ...fetchOptions.headers,
      "Content-Type": "application/json",
    };
  }
  return fetchWithTimeout(url, fetchOptions);
};

export const fetchWorkspaceLinkedInService = async (
  relativeResource: string,
  options: any = {}
) => {
  return fetchWithTimeout(
    mergePaths(config.WORKSPACE_API_URL!, relativeResource),
    options
  );
};
