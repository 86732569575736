import { StartIcon } from "@components/icons";

import { Campaign } from "@/models";
import { useCampaignContext } from "@context/CampaignContext";
import palette from "@design/palette";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Stack,
  Typography,
} from "@mui/material";
import CampaignIconWithState from "@pages/campaigns/campaigns/components/CampaignIconWithState";
import { RotatingLines } from "react-loader-spinner";
import CampaignName from "./components/CampaignName";
import CampaignStatusLabel from "./components/CampaignStatusLabel";
import CreatedTimeAgoText from "./components/CreatedTimeAgoText";
import ProspectStack from "./components/ProspectStack";

export type PendingCampaignProps = {
  campaign: Campaign;
};

const PendingCampaign = ({ campaign }: PendingCampaignProps) => {
  const { isSearchingProspects } = useCampaignContext();

  return (
    <Card
      sx={{
        boxShadow: "none",
        border: "none",
        px: 0,
      }}
    >
      <CardActionArea href={`/campaigns/${campaign.id}`}>
        <Stack
          p={2}
          direction={{
            xs: "column",
            sm: "row",
          }}
          spacing={2}
          alignItems="flex-start"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <CampaignStatusLabel />
            <CampaignIconWithState />
          </Stack>
          <Stack
            flexGrow={1}
            direction="row"
            spacing={2}
            alignItems={"flex-start"}
            flexWrap={"nowrap"}
            width={{
              xs: "100%",
              sm: "auto",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignItems={"flex-start"}
              flexGrow={1}
            >
              <Box flexGrow={1}>
                <CampaignName />
                <CreatedTimeAgoText />
                <Box mt={1} />
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  startIcon={<StartIcon color="success" />}
                >
                  Review & Start
                </Button>
              </Box>
              <Stack direction="row" spacing={1} alignItems={"center"} py={2}>
                {isSearchingProspects && (
                  <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <RotatingLines
                      strokeColor={palette.primary.main}
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={true}
                    />
                    <Typography variant="caption">Finding matches</Typography>
                  </Stack>
                )}
                <ProspectStack />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default PendingCampaign;
