import { ServiceResponse, useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

export type ReviseLinkedinCampaignTemplateParams = {
  content: string;
  templateId: string;
};

export const reviseLinkedinCampaignTemplate = async ({
  content,
  templateId,
}: ReviseLinkedinCampaignTemplateParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      content,
    },
  };

  const response = await fetchOutreachService(
    `/linkedin/templates/${templateId}/revise`,
    options
  );

  return response;
};

export const useReviseLinkedinCampaignTemplate = () =>
  useServiceCall<ServiceResponse, ReviseLinkedinCampaignTemplateParams>({
    serviceCall: reviseLinkedinCampaignTemplate,
    successMessage: "Template updated successfully",
    errorMessage: "Error updating template",
  });
