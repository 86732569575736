import { Button, Checkbox, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { ArrowDownIcon } from "./icons";

interface DaysMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onChange: (selectedDays: number[]) => void;
  buttonText: string;
}

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const DaysMenu: React.FC<DaysMenuProps> = ({
  anchorEl,
  onClose,
  onChange,
  buttonText,
}) => {
  const [selectedDays, setSelectedDays] = useState<number[]>([]);

  const handleToggle = (index: number) => {
    const currentIndex = selectedDays.indexOf(index);
    const newChecked = [...selectedDays];

    if (currentIndex === -1) {
      newChecked.push(index);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedDays(newChecked);
  };

  const handleApply = () => {
    onChange(selectedDays);
    onClose();
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      {daysOfWeek.map((day, index) => (
        <MenuItem key={day} onClick={() => handleToggle(index)} dense>
          <Checkbox checked={selectedDays.indexOf(index) !== -1} />
          <ListItemText primary={day} />
        </MenuItem>
      ))}
      <MenuItem sx={{ justifyContent: "center" }}>
        <Button onClick={handleApply} size="large" variant="contained">
          {buttonText}
        </Button>
      </MenuItem>
    </Menu>
  );
};

interface DayPickerProps {
  buttonText: string;
  onChange: (selectedDays: number[]) => void;
}

const DayPicker: React.FC<DayPickerProps> = ({ buttonText, onChange }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedDays, setSelectedDays] = useState<number[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDaysChange = (selectedDays: number[]) => {
    setSelectedDays(selectedDays);
    onChange(selectedDays);
  };

  return (
    <div>
      <Button
        size="medium"
        variant="outlined"
        aria-controls="days-menu"
        aria-haspopup="true"
        endIcon={<ArrowDownIcon />}
        onClick={handleClick}
      >
        {buttonText}
      </Button>
      <DaysMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        onChange={handleDaysChange}
        buttonText={buttonText}
      />
    </div>
  );
};

export default DayPicker;
