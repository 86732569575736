import { Autocomplete, Box, Collapse, TextField } from "@mui/material";
import { AddressInfo } from "@services/lawyers/createLawyer";
import {
  City,
  Country,
  ICity,
  ICountry,
  IState,
  State,
} from "country-state-city";
import { useMemo, useState } from "react";

export type AddressSelectValue = {
  addressIsOk: boolean;
} & AddressInfo;

export interface AddressSelectProps {
  onChange?: (addressInfo: AddressSelectValue) => void;
}

export const COUNTRY_CODES_WITH_POSTAL_CODE = ["US", "CA"];
export const countryNeedsPostalCode = (countryIsoCode: string) =>
  COUNTRY_CODES_WITH_POSTAL_CODE.includes(countryIsoCode);

export default function AddressSelect({ onChange }: AddressSelectProps) {
  // Get all countries
  const countries = useMemo(() => Country.getAllCountries(), []);

  // Values
  const [country, setCountry] = useState<ICountry | null>(null);
  const [state, setState] = useState<IState | null>(null);
  const [city, setCity] = useState<ICity | null>(null);
  const [postalCode, setPostalCode] = useState<string | null>(null);

  // Options
  const states = useMemo(() => {
    if (!country) return [];
    return State.getStatesOfCountry(country.isoCode);
  }, [country]);

  const cities = useMemo(() => {
    if (!country) return [];
    if (!state) return [];

    return City.getCitiesOfState(country?.isoCode, state.isoCode);
  }, [country, state]);

  // Show postal code if needed
  const showPostalCode = useMemo(() => {
    if (!country) return false;
    return countryNeedsPostalCode(country.isoCode);
  }, [country]);

  // Trigger onChange
  useMemo(() => {
    if (!onChange) return;
    // Check if the country has only 1 timezone
    // Then we can pass the timezone to the address info
    // If not, our enrichment should pick the timezone
    const timeZone =
      country?.timezones?.length === 1 ? country.timezones[0].zoneName : null;

    const countryIsOk = !!country;
    const stateIsOk = (!!states?.length && !!state) || !states?.length;
    const cityIsOk = (!!cities?.length && !!city) || !cities?.length;
    const postalCodeIsOk = !showPostalCode || !!postalCode;

    const addressInfo: AddressSelectValue = {
      country: country?.name,
      countryCode: country?.isoCode,
      postalCode,
      city: city?.name || null,
      state: state?.name || null,
      timeZone,
      addressIsOk: countryIsOk && stateIsOk && cityIsOk && postalCodeIsOk,
    };
    onChange(addressInfo);
  }, [country, state, city, postalCode]);

  return (
    <Box>
      <Autocomplete
        size="medium"
        fullWidth
        options={countries}
        getOptionLabel={(option) => option.name}
        value={country}
        onChange={(_, option) => {
          setCountry(option);
          setState(null);
          setCity(null);
          setPostalCode(null);
        }}
        renderInput={(params) => <TextField {...params} label="Country" />}
      />
      <Collapse in={!!states?.length}>
        <Autocomplete
          key={country?.isoCode}
          size="medium"
          fullWidth
          options={states}
          getOptionLabel={(option) => option.name}
          value={state}
          sx={{ mt: 2 }}
          onChange={(_, option) => {
            setState(option);
            setCity(null);
            setPostalCode(null);
          }}
          renderInput={(params) => <TextField {...params} label="State" />}
        />
      </Collapse>
      <Collapse in={!!cities?.length}>
        <Autocomplete
          key={state?.isoCode}
          size="medium"
          fullWidth
          options={cities}
          sx={{ mt: 2 }}
          getOptionLabel={(option) => option.name}
          value={city}
          onChange={(_, option) => {
            setCity(option);
            setPostalCode(null);
          }}
          renderInput={(params) => <TextField {...params} label="City" />}
        />
      </Collapse>
      <Collapse in={showPostalCode}>
        <TextField
          key={country?.isoCode}
          fullWidth
          size="medium"
          label="Postal Code"
          value={postalCode}
          sx={{ mt: 2 }}
          onChange={(e) => setPostalCode(e.target.value)}
        />
      </Collapse>
    </Box>
  );
}
