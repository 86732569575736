import { TrackedEventName } from "@/third-party/tracking";
import Tracked from "@components/Tracked";
import { ReceiptIcon } from "@components/icons";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

interface InvoiceLinkProps {
  invoiceUrl?: string | null;
}

const InvoiceLink = ({ invoiceUrl }: InvoiceLinkProps) => {
  return (
    <>
      {invoiceUrl ? (
        <Tracked
          onClick={{
            name: TrackedEventName.INVOICE_CLICKED,
          }}
        >
          <Button
            variant="text"
            size="small"
            color="primary"
            component={Link}
            to={invoiceUrl}
            target="_blank"
            startIcon={<ReceiptIcon />}
          >
            View Invoice
          </Button>
        </Tracked>
      ) : null}
    </>
  );
};

export default InvoiceLink;
