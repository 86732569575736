import { ServiceResponse, useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

type DeleteBlacklistedOutreachParams = {
  lawyerId: UUID;
  blacklistedOutreachId: string;
};

export const deleteBlacklistedOutreach = async ({
  lawyerId,
  blacklistedOutreachId,
}: DeleteBlacklistedOutreachParams) => {
  const options: FetchOptions = {
    method: "DELETE",
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/blacklist/${blacklistedOutreachId}`,
    options
  );

  return response;
};

export const useDeleteBlacklistedOutreach = () =>
  useServiceCall<ServiceResponse, DeleteBlacklistedOutreachParams>({
    serviceCall: deleteBlacklistedOutreach,
    successMessage: "Successfully removed from blacklist",
  });
