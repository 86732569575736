import { Lawyer } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

type CreateRefundRequestParams = {
  lawyerId: UUID;
  campaignMemberId: UUID;
  reason: string;
};

export const createRefundRequest = async ({
  lawyerId,
  campaignMemberId,
  reason,
}: CreateRefundRequestParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      campaignMemberId,
      reason,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/refunds`,
    options
  );

  return response;
};

export const useCreateRefundRequest = () =>
  useServiceCall<Lawyer, CreateRefundRequestParams>({
    serviceCall: createRefundRequest,
    successMessage: "Refund request created successfully",
    errorMessage: "Failed to create refund request",
  });
