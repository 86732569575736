import { ArrowDownIcon } from "@components/icons";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";

interface SamplesDropdownProps {
  options: string[];
  onOptionSelected: (option: string) => void;
}

const SamplesDropdown: React.FC<SamplesDropdownProps> = ({
  options,
  onOptionSelected,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = useState<string>("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: string) => {
    setSelectedValue(value);
    handleClose();
  };

  useEffect(() => {
    if (selectedValue) {
      onOptionSelected?.(selectedValue);
    }
  }, [selectedValue]);

  return (
    <div>
      <Button onClick={handleClick} variant="text" endIcon={<ArrowDownIcon />}>
        View samples
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleMenuItemClick(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SamplesDropdown;
