import { Template } from "@/models";
import { TrackedEventName } from "@/third-party/tracking";
import ContactAvatar from "@components/ContactAvatar";
import Tracked from "@components/Tracked";
import { ForwardedIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import { useConversationContext } from "@context/ConversationContext";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTestEmailCampaignTemplate } from "@services/campaigns/testEmailCampaignTemplate";
import { getFullName } from "@utils/text";
import { useEffect, useState } from "react";

type TestTemplateDialogProps = {
  template: Template;
} & DialogProps;

const TestTemplateDialog = ({
  template,
  ...props
}: TestTemplateDialogProps) => {
  const [email, setEmail] = useState<string>("");
  const { conversation, contact, company, contactFullName } =
    useConversationContext();
  const { profile } = useAppContext();

  // Initialize email
  useEffect(() => {
    setEmail(profile?.email || "");
  }, [profile?.email]);

  const closeDialog = () => {
    setEmail("");
    props.onClose?.({}, "escapeKeyDown");
  };

  // Test Template
  const [testEmailCampaignTemplate, { loading: isTestingTemplate }] =
    useTestEmailCampaignTemplate();

  const handleTestTemplate = async () => {
    if (!profile?.email) {
      return;
    }
    await testEmailCampaignTemplate({
      templateId: template.id,
      toEmail: profile?.email,
      toName: getFullName(profile?.firstName, profile?.lastName),
    });

    closeDialog();
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={2}>
          <ForwardedIcon />
          <Typography variant="h6">Send a test email</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          spacing={2}
          alignItems={"flex-start"}
          sx={{ width: 400 }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <ContactAvatar contact={conversation} size={55} />
            <Box>
              <Typography variant="body1" fontWeight="bold">
                {contactFullName}
              </Typography>
              <Typography
                variant="body2"
                color={"text.secondary"}
                whiteSpace={"nowrap"}
              >
                {`${contact?.title || ""}${contact?.title ? " @ " : ""}${
                  company?.name || ""
                }`}
              </Typography>
            </Box>
          </Stack>
          <Box mb={2}>
            <Typography variant="body2" color={"text.secondary"}>
              We will send a test email to this address.
            </Typography>
          </Box>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            disabled={isTestingTemplate}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Stack direction="row" spacing={1} alignItems="center">
            <Button variant="outlined" color="primary" onClick={closeDialog}>
              Cancel
            </Button>
            <Tracked onClick={{ name: TrackedEventName.TEST_EMAIL_SENT }}>
              <LoadingButton
                variant="contained"
                color="primary"
                startIcon={<ForwardedIcon />}
                loading={isTestingTemplate}
                onClick={() => handleTestTemplate()}
              >
                Send
              </LoadingButton>
            </Tracked>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default TestTemplateDialog;
