import { TrackedEventName } from "@/third-party/tracking";
import Tracked from "@components/Tracked";
import { useCampaignContext } from "@context/CampaignContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";

interface EditCampaignNameDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSave: (newName: string) => void;
}

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 500px;
  }
`;

const EditCampaignNameDialog = ({
  open,
  handleClose,
  handleSave,
}: EditCampaignNameDialogProps) => {
  const { campaign } = useCampaignContext(); // Access the campaign data from context
  const [newName, setNewName] = useState(campaign?.name); // Initialize state with current campaign name

  // Initialize
  useEffect(() => {
    setNewName(campaign?.name);
  }, [campaign?.name]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewName(event.target.value);
  };

  const handleSaveClick = () => {
    if (!newName) return;

    handleSave(newName); // Call handleSave with the new name
    handleClose();
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle>Change Campaign Name</DialogTitle>
      <DialogContent>
        <TextareaAutosize
          id="outlined-basic"
          placeholder="Campaign Name"
          defaultValue={newName}
          onChange={handleChange}
          style={{
            minWidth: "100%",
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Tracked onClick={{ name: TrackedEventName.CAMPAIGN_NAME_UPDATED }}>
          <Button onClick={handleSaveClick} color="primary" variant="contained">
            Save
          </Button>
        </Tracked>
      </DialogActions>
    </StyledDialog>
  );
};

export default EditCampaignNameDialog;
