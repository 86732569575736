import { tryParseDate } from "./date";

// Extract the data from the graphql result
export const normalizeGraphqlResult = (result: any): any => {
  if (result === null) {
    return result;
  }

  if (typeof result !== "object") {
    return tryParseDate(result);
  }

  if (Array.isArray(result)) {
    return result.map((item) => normalizeGraphqlResult(item));
  }

  const { __typename, edges, nodes, node, ...rest } = result;

  if (edges) {
    return edges.map((edge: any) => {
      return normalizeGraphqlResult(edge);
    });
  } else if (nodes) {
    return nodes.map((node: any) => {
      return normalizeGraphqlResult(node);
    });
  } else if (node) {
    return normalizeGraphqlResult(node);
  } else {
    const normalizedResult: any = {};
    Object.keys(rest).forEach((key) => {
      normalizedResult[key] = normalizeGraphqlResult(rest[key]);
    });

    return normalizedResult;
  }
};
