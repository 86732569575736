import { Lawyer } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

type PauseAccountParams = {
  lawyerId: UUID;
  isPaused: boolean;
};

export const pauseAccount = async ({
  lawyerId,
  isPaused,
}: PauseAccountParams) => {
  const options: FetchOptions = {
    method: "PUT",
    payload: {
      isPaused,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/pause`,
    options
  );

  return response;
};

export const usePauseAccount = () =>
  useServiceCall<Lawyer, PauseAccountParams>({
    serviceCall: pauseAccount,
    successMessage: "Updated your profile successfully",
    errorMessage: "Failed to update profile",
  });
