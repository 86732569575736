import { Box, Container } from "@mui/material";
import { styled } from "styled-components";

type PageContentProps = React.HTMLAttributes<HTMLDivElement>;

const PageContent = ({ children }: PageContentProps) => {
  // const [isMounted, setIsMounted] = useState(false);
  // const navigate = useNavigate();
  // const location = useLocation();

  // useEffect(() => {
  //   setIsMounted(true);
  // }, []);

  // useEffect(() => {
  //   if (isMounted) {
  //     navigate(location.pathname, { replace: true });
  //   }
  // }, [location.pathname, isMounted, navigate]);

  return (
    <AnimatedContent className="animated-page">
      <Container maxWidth="xl" sx={{ p: 0 }}>
        <Box py={2} px={2} flexGrow={1}>
          {children}
        </Box>
      </Container>
    </AnimatedContent>
  );
};

export default PageContent;

/** Smooth page transitions */
const AnimatedContent = styled.div`
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;
