import { Prospect } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

type SearchProspectsByIdParams = {
  campaignId: string;
  page?: number;
  size?: number;
};

const MAX_CONCURRENT_SEARCHES = 5;
let search_count = 0;

export const searchProspectsByCampaign = async ({
  campaignId,
  page = 1,
  size = 20,
}: SearchProspectsByIdParams) => {
  const options: FetchOptions = {
    method: "GET",
    query: {
      useCache: true,
      page,
      size,
    },
  };

  search_count++;

  if (search_count > MAX_CONCURRENT_SEARCHES) {
    return new Response(
      `{"error": "Too many concurrent searches"}`,
      { status: 429 }
    )
  }

  const response = await fetchOutreachService(
    `/campaigns/${campaignId}/contacts/search`,
    options
  );

  search_count--;

  return response;
};

type SearchProspectsResponse = {
  page: number;
  size: number;
  items: Prospect[];
};

export const useSearchProspectsByCampaign = () =>
  useServiceCall<SearchProspectsResponse, SearchProspectsByIdParams>({
    serviceCall: searchProspectsByCampaign,
  });
