import { LawyerSetting, LawyerSettingType } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

export type LawyerSettingSet = {
  lawyerId: UUID;
  type: LawyerSettingType;
  value: string;
};

export const setLawyerSetting = async ({
  lawyerId,
  type,
  value,
}: LawyerSettingSet) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      type,
      value,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/settings`,
    options
  );
  return response;
};

export const useSetLawyerSetting = () =>
  useServiceCall<LawyerSetting, LawyerSettingSet>({
    serviceCall: setLawyerSetting,
  });
