type ConfigProps = {
  ENV: "development" | "production";
  OUTREACH_SERVICE_URL: string;
  GRAPHQL_URL: string;
  GRAPHQL_API_KEY?: string;
  AWS_REGION: string;
  AWS_COGNITO_USER_POOL_ID: string;
  AWS_COGNITO_USER_POOL_WEB_CLIENT_ID: string;
  WORKSPACE_API_URL: string;
  STRIPE_CALLBACK_URL: string;
  STRIPE_API_KEY: string;
  ENABLE_TRACKING?: boolean;
  SEGMENT_WRITE_KEY?: string;
  INTERCOM_APP_ID?: string;
  PUBLIC_URL: string;
  HOTJAR_ID: string;
  LAUNCHDARKLY_CLIENT_ID: string;
  CRONOFY_CLIENT_ID: string;
  TOS_URL: string;
  PRIVACY_URL: string;
};

const config: ConfigProps = {
  ENV:
    process.env.REACT_APP_ENV === "production" ? "production" : "development",
  OUTREACH_SERVICE_URL:
    process.env.REACT_APP_OUTREACH_SERVICE_URL || "http://localhost:8080",
  GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_URL || "http://localhost:3001",
  GRAPHQL_API_KEY: process.env.REACT_APP_GRAPHQL_API_KEY,
  AWS_REGION: process.env.REACT_APP_AWS_COGNITO_REGION || "eu-west-2",
  AWS_COGNITO_USER_POOL_ID: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID!,
  AWS_COGNITO_USER_POOL_WEB_CLIENT_ID:
    process.env.REACT_APP_AWS_COGNITO_WEB_CLIENT_ID!,
  WORKSPACE_API_URL: process.env.REACT_APP_WORKSPACE_API_URL!,
  STRIPE_CALLBACK_URL: process.env.REACT_APP_STRIPE_CALLBACK_URL!,
  STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY!,
  INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID,
  PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL!,
  HOTJAR_ID: process.env.REACT_APP_HOTJAR_ID!,
  SEGMENT_WRITE_KEY: process.env.REACT_APP_SEGMENT_WRITE_KEY,
  LAUNCHDARKLY_CLIENT_ID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID!,
  ENABLE_TRACKING: process.env.REACT_APP_ENABLE_TRACKING === "true",
  CRONOFY_CLIENT_ID: process.env.REACT_APP_CRONOFY_CLIENT_ID!,
  TOS_URL: process.env.REACT_APP_TOS_URL || "https://www.kular.ai/terms",
  PRIVACY_URL:
    process.env.REACT_APP_PRIVACY_URL || "https://www.kular.ai/privacy-policy",
};

export default config;
