import { Box, BoxProps } from "@mui/material";

type InfoBoxProps = BoxProps;

const InfoBox = ({ children, ...muiProps }: InfoBoxProps) => {
  return (
    <Box
      px={4}
      py={2}
      borderRadius={1}
      border={`1px solid #f0f0f0`}
      {...muiProps}
    >
      {children}
    </Box>
  );
};

export default InfoBox;
