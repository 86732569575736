import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

interface GetUnsubscribeListParams {
  lawyerId: string;
  campaignId?: string;
}

export const getUnsubscribeList = async ({
  lawyerId,
  campaignId,
}: GetUnsubscribeListParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      lawyerId,
      campaignId,
    }
  };

  const response = await fetchOutreachService(
    `/lawyers/campaigns/unsubscribe-list`,
    options
  );

  return response;
};

export const useGetUnsubscribeList = () =>
  useServiceCall<Blob, GetUnsubscribeListParams>({
    serviceCall: getUnsubscribeList,
    successMessage: "Your have downloaded your unsubscribe list.",
    errorMessage: "Error downloading your unsubscribe list.",
  });