import { LawyerSettingType } from "@/models";
import DayPicker from "@components/DayPicker";
import TimeSelect from "@components/TimeSelect";
import { AddIcon, CheckSingleIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import {
  MeetingAvailabilityProvider,
  useMeetingAvailabilityContext,
} from "@context/MeetingAvailabilityContext";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ClearIcon, TimeIcon } from "@mui/x-date-pickers";
import { useSetLawyerSetting } from "@services/lawyers/setLawyerSetting";
import { useUpdateLawyer } from "@services/lawyers/updateLawyer";
import { FC, useEffect, useMemo, useState } from "react";

import TimezoneSelect from "react-timezone-select";

const daysOfTheWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

interface MeetingAvailabilityProps {}

const MeetingAvailability: FC<MeetingAvailabilityProps> = () => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <MeetingAvailabilityProvider>
      <Button
        variant="outlined"
        onClick={() => setShowDialog(true)}
        startIcon={<TimeIcon />}
      >
        Manage Availability
      </Button>
      <AvailabilityDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
      />
    </MeetingAvailabilityProvider>
  );
};

interface AvailabilityDialogProps extends DialogProps {}

const AvailabilityDialog: FC<AvailabilityDialogProps> = ({ open, onClose }) => {
  const [expandedDay, setExpandedDay] = useState<number | null>(null);
  const handleChangeExpandedDay = (dayIndex: number) => {
    if (expandedDay === dayIndex) {
      setExpandedDay(-1);
    } else {
      setExpandedDay(dayIndex);
    }
  };

  // Lawyer timezone
  const { profile, fetchProfile } = useAppContext();

  const timeZone = useMemo(() => profile?.lawyer?.timezone || "UTC", [profile]);

  const [currentTimeZone, setCurrentTimeZone] = useState(timeZone);

  useEffect(() => {
    setCurrentTimeZone(timeZone);
  }, [timeZone]);

  const canUpdateTimezone = profile?.lawyer?.timezone !== currentTimeZone;

  // Update lawyer settings
  const [
    setLawyerSetting,
    { loading: isSettingLawyerSetting, error: setLawyerSettingError },
  ] = useSetLawyerSetting();

  // Patch lawyer
  const [updateLawyer, { loading: isUpdatingLawyer }] = useUpdateLawyer();

  const handleUpdateTimeZone = async () => {
    if (!profile?.lawyer?.id) return;

    await updateLawyer(
      {
        lawyerId: profile.lawyer.id,
        timezone: currentTimeZone,
      },
      {
        successMessage: "Updated your timezone successfully",
      }
    );

    fetchProfile?.();
  };

  const { availability } = useMeetingAvailabilityContext();
  const handleSetAvailability = async () => {
    if (!profile?.lawyer?.id) return;
    if (!availability) return;

    try {
      const availablityString = JSON.stringify(availability);

      await setLawyerSetting(
        {
          lawyerId: profile.lawyer.id,
          type: LawyerSettingType.MEETING_AVAILABILITY,
          value: availablityString,
        },
        {
          successMessage: "Updated your availability successfully",
        }
      );

      fetchProfile?.();
      onClose?.({}, "backdropClick");
    } catch (e) {
      console.error(e);
    }
  };

  const isBusy = isSettingLawyerSetting || isUpdatingLawyer;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          width: 400,
          backgroundColor: "background.info",
        },
      }}
    >
      <DialogTitle>
        <Typography variant={"h6"}>Meeting Availability</Typography>
        <Stack spacing={0}>
          <Typography variant={"body2"} color={"text.secondary"}>
            Set your availability for your meetings.
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          spacing={1}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant={"body1"} noWrap color={"text.secondary"}>
            Your timezone:
          </Typography>
          <Box sx={{ width: 200 }}>
            <TimezoneSelect
              isDisabled={isBusy}
              value={currentTimeZone}
              onChange={(e) => setCurrentTimeZone(e.value)}
            />
          </Box>
        </Stack>
        <Collapse in={canUpdateTimezone}>
          <Box mt={2} />
          <Box textAlign={"center"}>
            <LoadingButton
              disabled={isBusy}
              loading={isUpdatingLawyer}
              variant={"contained"}
              size="large"
              onClick={handleUpdateTimeZone}
            >
              Update timezone
            </LoadingButton>
          </Box>
        </Collapse>
        <Box mt={2} />
        <Stack direction={"column"} spacing={1}>
          {daysOfTheWeek.map((day, index) => (
            <AvailabilityDayAccordion
              disabled={isBusy}
              key={index}
              dayIndex={index}
              onChange={() => handleChangeExpandedDay(index)}
              expanded={expandedDay === index}
            />
          ))}
        </Stack>
        <Stack
          spacing={1}
          mt={2}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            variant={"outlined"}
            onClick={() => onClose?.({}, "escapeKeyDown")}
          >
            Cancel
          </Button>
          <LoadingButton
            variant={"contained"}
            loading={isSettingLawyerSetting}
            disabled={isBusy}
            onClick={() => handleSetAvailability()}
          >
            Save
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

interface AvailabilityDayAccordionProps extends Partial<AccordionProps> {
  dayIndex: number;
}

const AvailabilityDayAccordion: FC<AvailabilityDayAccordionProps> = ({
  dayIndex,
  ...muiProps
}) => {
  const { availability, addSlot, removeSlot, updateSlot, copyDayToDays } =
    useMeetingAvailabilityContext();

  const dayAvailability = useMemo(
    () => availability.find((day) => day.dayIndex === dayIndex),
    [availability, dayIndex]
  );

  const slots = dayAvailability?.slots || [];
  const slotsText = useMemo(() => {
    if (slots.length === 0) {
      return "Unavailable";
    }

    const maxSlotsToShow = 2;
    // Show the first 3 or less slots and append +x for any more
    const text = slots
      .slice(0, maxSlotsToShow)
      .map((slot) => `${slot.startTime} - ${slot.endTime}`)
      .join(", ");

    if (slots.length > maxSlotsToShow) {
      return `${text} +${slots.length - maxSlotsToShow} more`;
    }

    return text;
  }, [[...slots]]);

  const canAddSlot =
    slots.length === 0 || !slots.find((slot) => slot.endTime === "23:30");

  return (
    <Accordion
      {...muiProps}
      sx={{
        ".MuiAccordionSummary-root.Mui-expanded": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
      }}
    >
      <AccordionSummary>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {!!slots?.length && <CheckSingleIcon color="success" />}
          {!slots?.length && <ClearIcon color="error" />}
          <Stack spacing={0}>
            <Typography
              fontWeight={"bold"}
              color={!!slots?.length ? "text.primary" : "text.secondary"}
            >
              {daysOfTheWeek[dayIndex]}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {slotsText}
            </Typography>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1} alignItems={"flex-start"}>
          <Stack spacing={1}>
            {slots.map((slot, index) => (
              <Stack
                key={index}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <Stack direction={"row"} spacing={1}>
                  <TimeSelect
                    value={slot.startTime}
                    size="small"
                    minTime={index === 0 ? "00:00" : slots[index - 1].endTime}
                    maxTime={slot.endTime}
                    onChange={(e) =>
                      updateSlot(dayIndex, index, {
                        ...slot,
                        startTime: e.target.value as string,
                      })
                    }
                  />
                  <TimeSelect
                    value={slot.endTime}
                    size="small"
                    minTime={slot.startTime}
                    onChange={(e) =>
                      updateSlot(dayIndex, index, {
                        ...slot,
                        endTime: e.target.value as string,
                      })
                    }
                  />
                </Stack>
                <IconButton onClick={() => removeSlot(dayIndex, index)}>
                  <ClearIcon />
                </IconButton>
              </Stack>
            ))}
          </Stack>
          <Collapse in={canAddSlot}>
            <Stack spacing={1} alignItems={"flex-start"}>
              <Button
                variant={"text"}
                onClick={() => addSlot(dayIndex)}
                size="small"
                startIcon={<AddIcon />}
              >
                Add available slot
              </Button>
            </Stack>
          </Collapse>
          {!!slots?.length && (
            <Box>
              <Box mt={2}>
                <DayPicker
                  buttonText={"Apply to days"}
                  onChange={(selectedDays) =>
                    copyDayToDays(dayIndex, selectedDays)
                  }
                />
              </Box>
            </Box>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default MeetingAvailability;
