import { Lawyer } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

type AuthenticateCalendarParams = {
  lawyerId: UUID;
  code: string;
};

export const authenticateCalendar = async ({
  lawyerId,
  code,
}: AuthenticateCalendarParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      code,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/meetings/authenticate`,
    options
  );

  return response;
};

export const useAuthenticateCalendar = () =>
  useServiceCall<Lawyer, AuthenticateCalendarParams>({
    serviceCall: authenticateCalendar,
    successMessage: "You have successfully connected your calendar.",
    errorMessage: "Failed to connect your calendar.",
  });
