import { UIMessage, useUIMessageContext } from "@context/UIMessageContext";
import { Alert, Box, Snackbar } from "@mui/material";
import { calculateReadTimeInMilliSeconds } from "@utils/text";

const UIMessages: React.FC = () => {
  const { uiMessages, removeUIMessage } = useUIMessageContext();

  const handleClose = (id?: string) => {
    if (!id) {
      return;
    }
    removeUIMessage(id);
  };

  return (
    <Box sx={{ width: 500 }}>
      {uiMessages?.map((message: UIMessage) => {
        return (
          <Snackbar
            key={`snackbar-${message.id}-${message.type}}`}
            open={true}
            autoHideDuration={
              message.type === "error"
                ? null
                : calculateReadTimeInMilliSeconds(message.message)
            }
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => handleClose(message.id)}
          >
            <Alert
              key={message.id}
              severity={message.type}
              variant="outlined"
              sx={{ width: "100%" }}
              onClose={() => handleClose(message.id)}
            >
              {message.message}
            </Alert>
          </Snackbar>
        );
      }) || null}
    </Box>
  );
};

export default UIMessages;
