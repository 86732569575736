import { TrackOptions, track } from "@/third-party/tracking";
import { useUIMessageContext } from "@context/UIMessageContext";
import { snakeToCamelObject } from "@utils/object";
import { useCallback, useState } from "react";

class ServiceError extends Error {
  constructor(message: string, public detail: string, public payload: Record<string, any>) {
    super(message);
    this.name = "ServiceError";
    this.detail = detail;
    this.message = message;
    this.payload = payload;
    Object.setPrototypeOf(this, ServiceError.prototype);
  }
}

export type UseServiceOptions = {
  successMessage?: string | null;
  errorMessage?: string | null;
  silent?: boolean;
  track?: TrackOptions;
};

export interface ServiceCallResult<TData> {
  data: TData | null;
  error: ServiceError | null;
  loading: boolean;
}

// Currently we are using graphql for reading data and REST for writing data
// So we are not concerned about the response type for now
export type ServiceResponse = ServiceCallResult<any>;

type UseServiceCallParams<TData, TArgs> = {
  serviceCall: (args: TArgs, options?: UseServiceOptions) => Promise<Response>;
  initialData?: TData | null;
  successMessage?: string;
  errorMessage?: string;
};

export function useServiceCall<TData, TArgs>({
  serviceCall,
  initialData = null,
  successMessage,
  errorMessage,
}: UseServiceCallParams<TData, TArgs>): [
    (args: TArgs, options?: UseServiceOptions) => Promise<TData | null>,
    ServiceCallResult<TData>
  ] {
  // State
  const [data, setData] = useState<TData | null>(initialData);
  const [error, setError] = useState<ServiceError | null>(null);
  const [loading, setLoading] = useState(false);

  const { showErrorMessage, showSuccessMessage } = useUIMessageContext();

  // Show error message
  const handleError = (
    error: ServiceError,
    options?: UseServiceOptions | null
  ) => {
    if (options?.silent) {
      return;
    }
    const baseMessage = options?.errorMessage || errorMessage;
    const detailMessage = error?.detail?.slice(0, 100);
    const message = baseMessage ? `${baseMessage} ${detailMessage}` : error?.message;

    if (message) {
      showErrorMessage(message);
    }

    setError(error);
  };

  // Show success message
  const handleSuccess = (options?: UseServiceOptions | null) => {
    if (options?.silent) {
      return;
    }

    const message = options?.successMessage || successMessage;

    if (message) {
      showSuccessMessage(message);
    }
    if (options?.track) {
      if (!options.track.props && data) {
        options.track.props = data;
      }

      track(options?.track);
    }
  };

  // Function to initiate the service call
  const fetch = useCallback(
    async (args: TArgs, options?: UseServiceOptions): Promise<TData | null> => {
      if (loading) {
        return null;
      }
      let responseData = null;
      try {
        setLoading(true);
        setError(null);
        //setData(null);

        // Make the service call
        const response = await serviceCall(args);

        if (!response.ok) {
          // Handle error response
          let errorText = await response.text();
          let errorPayload = null;
          let errorDetail = null;
          // Sometimes the error message comes as a string with a detail field
          // Try to parse it as JSON and get the detail field
          try {
            errorPayload = JSON.parse(errorText);
            errorText = errorPayload.detail;
            errorDetail = errorPayload.detail;
          } catch (e) {
            // Do nothing
            console.error(e);
          }

          handleError(new ServiceError(errorText, errorDetail, errorPayload), options);
        } else {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("text/csv")) {
            const blob = await response.blob();
            responseData = blob;
          } else {
            // Handle success response
            responseData = await response.json();
            // Convert python snake case to camel case
            responseData = responseData ? snakeToCamelObject(responseData) : null;
          }
          setData(responseData);

          handleSuccess(options);
        }
      } catch (error: any) {
        console.error(error);
        handleError(error, options);
      } finally {
        setLoading(false);
      }

      return responseData;
    },
    [serviceCall]
  );

  // Return the fetch function and the result object
  return [fetch, { data, error, loading }];
}
