import { useCampaignContext } from "@context/CampaignContext";
import { Typography } from "@mui/material";
import { useMemo } from "react";
import ReactTimeAgo from "react-time-ago";

export default function LastViewedTimeAgoText() {
  const { campaign } = useCampaignContext();

  // adjust for browser timezone
  const viewedTimeWithBrowserTimezone = useMemo(() => {
    if (!campaign?.lawyerViewedTime) return null;

    const viewedTime = new Date(campaign.lawyerViewedTime);
    const offset = viewedTime.getTimezoneOffset();
    viewedTime.setMinutes(viewedTime.getMinutes() - offset);
    return viewedTime;
  }, [campaign?.lawyerViewedTime]);

  return viewedTimeWithBrowserTimezone ? (
    <Typography variant="caption">
      Last viewed <ReactTimeAgo date={viewedTimeWithBrowserTimezone} />
    </Typography>
  ) : null;
}
