import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

/* agenda, account_management, availability, managed_availability */
type BookMeetingParams = {
  lawyerId: UUID;
  campaignMemberMeetingId: UUID;
  start: string;
  end: string;
};

export const bookMeeting = async ({
  lawyerId,
  campaignMemberMeetingId,
  start,
  end,
}: BookMeetingParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      campaignMemberMeetingId,
      startTime: start,
      endTime: end,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/meetings/book`,
    options
  );

  return response;
};

export const useBookMeeting = () =>
  useServiceCall<any, BookMeetingParams>({
    serviceCall: bookMeeting,
  });
