import { UploadContactBlacklist } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

type UploadContactBlacklistParams = {
  lawyerId: string;
  file: File;
  originType: string;
};

export const uploadContactBlacklist = async ({
  lawyerId,
  file,
  originType,
}: UploadContactBlacklistParams) => {

  const formData = new FormData();
  formData.append('file', file);
  formData.append('origin_type', originType);

  const options: FetchOptions = {
    method: "POST",
    body: formData,
    excludeContentTypeHeader: true,
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/blacklist/upload-contacts`,
    options
  );

  return response;
};

export const useUploadContactBlacklist = () =>
  useServiceCall<UploadContactBlacklist, UploadContactBlacklistParams>({
    serviceCall: uploadContactBlacklist,
    successMessage: "Successfully added contacts to blacklist",
    errorMessage: "Error in adding contacts to blacklist",
  });
