import { Box, Stack, Typography, TypographyProps } from "@mui/material";

type PageTitleProps = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  icon?: React.ReactNode;
  titleProps?: TypographyProps;
  subtitleProps?: TypographyProps;
};
const PageTitle = ({
  title,
  subtitle,
  icon,
  titleProps,
  subtitleProps,
}: PageTitleProps) => (
  <Stack direction="row" spacing={2} alignItems="center">
    {icon}
    <Box>
      <Typography
        variant="subtitle1"
        {...titleProps}
        fontWeight={500}
        color="text.primary"
      >
        {title}
      </Typography>
      {!!subtitle && (
        <Typography
          color={"text.secondary"}
          variant={"body2"}
          lineHeight={"1.5em"}
          {...subtitleProps}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  </Stack>
);

export default PageTitle;
