import { useCampaignCreateContext } from "@context/CampaignCreateContext";
import { Stack, TextField } from "@mui/material";
import SamplesDropdown from "./SamplesDropdown";

const StepFocuses = () => {
  const { focuses, setFocuses } = useCampaignCreateContext();

  return (
    <Stack direction="column" spacing={0}>
      <SamplesDropdown
        options={[
          "Protect your ideas with intellectual property law services",
          "Win the right new customers with digital marketing",
          "Free up time for strategic HR with a new software platform",
          "Fundraise effectively with first-tier legal services",
          "Improve access to data with Artificial Intelligence",
        ]}
        onOptionSelected={(option) => {
          setFocuses(option);
        }}
      />

      <TextField
        fullWidth
        value={focuses}
        placeholder="How can you help?"
        id="outlined-basic"
        variant="outlined"
        multiline
        rows={3}
        onChange={(e) => setFocuses(e.target.value)}
      />
    </Stack>
  );
};

export default StepFocuses;
