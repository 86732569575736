import { TextField, TextFieldProps } from "@mui/material";
import { Field, FieldProps } from "formik";
import styled from "styled-components";

export type FormikInputProps = TextFieldProps;

export default function FormikInput({ name, ...muiFields }: FormikInputProps) {
  return <Field name={name} component={Input} {...muiFields} />;
}

const Wrapper = styled.div`
  position: relative;

  .MuiInputBase-root {
    background-color: white;
  }
`;

function Input({
  field,
  form,
  variant = "outlined",
  size = "small",
  margin = "normal",
  type,
  placeholder,
  helperText,
  ...muiProps
}: TextFieldProps & FieldProps) {
  const error =
    field.name && form.touched[field.name] && form.errors[field.name];

  return (
    <Wrapper>
      <TextField
        {...field}
        type={type}
        error={!!error}
        variant={variant}
        margin={margin}
        size={size}
        placeholder={placeholder}
        fullWidth
        helperText={(error as string) || helperText}
        {...muiProps}
      />
    </Wrapper>
  );
}
