import { MenuItem, Select, SelectProps } from "@mui/material";
import { Field, FieldProps } from "formik";
import { getNames, registerLocale } from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

type CountryPickerProps = {
  name: string;
};

const Wrapper = styled.div`
  select {
    border-radius: 4px;
    border-color: #c0c0c0;
    padding: 8px 10px;
    font-size: 16px;
    color: inherit;
  }
`;

function CountryPicker({
  field,
  form,
  variant = "outlined",
  ...muiProps
}: SelectProps & FieldProps) {
  const [country, setCountry] = useState(field.value || "");

  // Set Formik field values when country and country are selected
  useEffect(() => {
    form.setFieldValue(field.name, country);
  }, [country, field.name]);

  const error = useMemo(() => {
    return field.name && form.touched[field.name] && form.errors[field.name];
  }, [field.name, form.errors[field.name], form.touched[field.name]]);

  const countries = useMemo(() => {
    registerLocale(en);
    return getNames("en");
  }, []);

  return (
    <Wrapper>
      <Select
        {...field}
        value={country}
        onChange={(event) => setCountry(event.target.value)}
        error={!!error}
        fullWidth
        variant={variant}
        {...muiProps}
      >
        {Object.entries(countries).map(([code, name]) => (
          <MenuItem key={code} value={code} dense>
            {name}
          </MenuItem>
        ))}
      </Select>
    </Wrapper>
  );
}

export default function FormikCountryPicker({
  name,
  ...muiFields
}: CountryPickerProps) {
  return <Field name={name} component={CountryPicker} {...muiFields} />;
}
