import Page from "@components/layout/page/Page";
import DashboardNavigation from "./DashboardNavigation";

export type PageProps = {};

const DashboardPage: React.FC<PageProps> = ({}: PageProps) => {
  return (
    <Page>
      <DashboardNavigation />
    </Page>
  );
};

export default DashboardPage;
