import { OutreachMessage } from "@/models";
import ContactAvatar from "@components/ContactAvatar";
import ContactTitleText from "@components/ContactTitleText";
import { AddPersonIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import { useConversationContext } from "@context/ConversationContext";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCCEmailMessage } from "@services/email/ccEmailMessage";
import { useEffect, useState } from "react";

type ConversationCCDialogProps = {
  message: OutreachMessage;
} & DialogProps;

const ConversationCCDialog = ({
  message,
  ...props
}: ConversationCCDialogProps) => {
  const [email, setEmail] = useState<string>("");
  const { conversation, contact, contactFullName } = useConversationContext();
  const { profile } = useAppContext();

  // Initialize email
  useEffect(() => {
    setEmail(profile?.email || "");
  }, [profile?.email]);

  const closeDialog = () => {
    setEmail("");
    props.onClose?.({}, "escapeKeyDown");
  };

  // CC
  const [ccEmailMessage, { loading: isLoading }] = useCCEmailMessage();

  const handleCCEmailMessage = async () => {
    if (!conversation?.id) return;

    await ccEmailMessage({
      emailMessageId: message.id,
      ccEmail: email,
    });

    closeDialog();
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={2}>
          <AddPersonIcon />
          <Typography variant="h6">CC me in this conversation</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          spacing={2}
          alignItems={"flex-start"}
          sx={{ width: 400 }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <ContactAvatar contact={conversation} size={55} />
            <Box>
              <Typography variant="body1" fontWeight="bold">
                {contactFullName}
              </Typography>

              <ContactTitleText contact={contact} />
            </Box>
          </Stack>
          <Box mb={2}>
            <Typography variant="body2" color={"text.secondary"}>
              We will add you as a CC in this conversation. You will receive all
              replies from this conversation to the email below.
            </Typography>
          </Box>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            disabled={isLoading}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Stack direction="row" spacing={1} alignItems="center">
            <Button variant="outlined" color="primary" onClick={closeDialog}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              startIcon={<AddPersonIcon />}
              onClick={handleCCEmailMessage}
              loading={isLoading}
            >
              CC me
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default ConversationCCDialog;
