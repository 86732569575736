import { CampaignStatus } from "@/models";
import { TrackedEventName } from "@/third-party/tracking";
import AreYouSureDialog from "@components/AreYouSureDialog";
import ControlledBox from "@components/ControlledBox";
import Tip from "@components/Tip";
import Tracked from "@components/Tracked";
import {
  DeleteIcon,
  EditIcon,
  MessagingIcon,
  ScheduleIcon,
  StartIcon,
  StatisticsIcon,
  StopIcon,
  TargetingIcon,
} from "@components/icons";
import SectionCard from "@components/layout/SectionCard";
import Page from "@components/layout/page/Page";
import PageContent from "@components/layout/page/PageContent";
import { useAppContext } from "@context/AppContext";
import { CampaignProvider, useCampaignContext } from "@context/CampaignContext";
import { useCampaignsContext } from "@context/CampaignsContext";
import { ConversationsProvider } from "@context/ConversationsContext";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  ButtonGroup,
  IconButton,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import CampaignIconWithState from "@pages/campaigns/campaigns/components/CampaignIconWithState";
import { getDisplayDate } from "@utils/date";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import CampaignStatusLabel from "../campaigns/components/CampaignStatusLabel";
import CampaignMessaging from "./CampaignMessaging";
import CampaignStatistics from "./CampaignStatistics";
import CampaignTargeting from "./CampaignTargeting";
import EditCampaignNameDialog from "./EditCampaignNameDialog";
import ScheduleActivationDialog from "./ScheduleActivationDialog";

enum CampaignTab {
  TARGETING = "TARGETING",
  MESSAGING = "MESSAGING",
  STATISTICS = "STATISTICS",
}

const CampaignPage = () => {
  // Get Campaign id from url
  const { id: campaignId } = useParams();

  return (
    <Page>
      <PageContent>
        {campaignId && (
          <CampaignProvider campaignId={campaignId} withProspects>
            <CampaignContent />
          </CampaignProvider>
        )}
      </PageContent>
    </Page>
  );
};

const CampaignContent = () => {
  const { canUseService, hasPaymentInformation, isInternalProfile } = useAppContext();
  const { campaign, patchCampaign, isPatchingCampaign, fetchCampaign } =
    useCampaignContext();
  const { campaigns } = useCampaignsContext();
  const [tabValue, setTabValue] = useState(CampaignTab.TARGETING);

  // Update lawyer viewed time
  useEffect(() => {
    if (!campaign?.id) return;
    if (isInternalProfile) return;

    patchCampaign(
      {
        campaignId: campaign.id,
        lawyerViewedTime: new Date(),
      },
      {
        silent: true,
      }
    );
  }, [campaign?.id, isInternalProfile]);

  const handleStartCampaign = async () => {
    if (!campaign?.id) return;

    await patchCampaign(
      {
        campaignId: campaign.id,
        status: CampaignStatus.ACTIVE,
      },
      {
        successMessage: "Campaign started successfully",
        errorMessage: "Failed to start campaign",
      }
    );
  };

  const handleStopCampaign = async () => {
    if (!campaign?.id) return;

    await patchCampaign(
      {
        campaignId: campaign.id,
        status: CampaignStatus.INACTIVE,
      },
      {
        successMessage: "Campaign stopped successfully",
        errorMessage: "Failed to stop campaign",
      }
    );

    setShowStopCampaignDialog(false);
  };

  const handleRejectCampaign = async () => {
    if (!campaign?.id) return;

    await patchCampaign(
      {
        campaignId: campaign.id,
        status: CampaignStatus.REJECTED,
      },
      {
        successMessage: "Campaign rejected successfully",
        errorMessage: "Failed to reject campaign",
      }
    );
  };

  // Show schedule start dialog
  const [showScheduleStartDialog, setShowScheduleStartDialog] =
    useState<boolean>(false);

  const saveScheduledStart = async (scheduledActivationTime?: Date) => {
    if (!campaign?.id) return;

    await patchCampaign(
      {
        campaignId: campaign.id,
        scheduledActivationTime: scheduledActivationTime || new Date(),
      },
      {
        successMessage: "Campaign scheduled to activate successfully",
        errorMessage: "Failed to schedule activation for your campaign",
      }
    );
  };

  // Edit campaign name
  const [showEditCampaignName, setShowEditCampaignName] =
    useState<boolean>(false);

  const handleShowEditNameDialog = () => {
    setShowEditCampaignName(true);
  };

  const handleCloseEditNameDialog = () => {
    setShowEditCampaignName(false);
  };

  const handleSaveCampaignName = async (newName: string) => {
    // Implement logic to save the edited campaign name
    if (!campaign?.id) return;

    await patchCampaign(
      {
        campaignId: campaign.id,
        name: newName,
      },
      {
        successMessage: "You have changed your campaign name!",
        errorMessage: "Failed to update campaign name",
      }
    );
    fetchCampaign?.();
    handleCloseEditNameDialog();
  };

  // Stop campaign dialog
  const showStopCampaignAlert = useMemo(() => {
    return (
      campaigns?.filter((campaign) => campaign.status === CampaignStatus.ACTIVE)
        .length === 1
    );
  }, [campaigns]);

  const [showStopCampaignDialog, setShowStopCampaignDialog] =
    useState<boolean>(false);

  return (
    <ControlledBox loading={!campaign} p={0}>
      <SectionCard
        skinny
        title={
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle1" fontWeight={600}>
              {campaign?.name}
            </Typography>
            <IconButton size="small" onClick={handleShowEditNameDialog}>
              <EditIcon fontSize="small" />
            </IconButton>
            <EditCampaignNameDialog
              open={showEditCampaignName}
              handleClose={handleCloseEditNameDialog}
              handleSave={handleSaveCampaignName}
            />
          </Stack>
        }
        titleDescription={
          <Stack direction="row" spacing={1} alignItems="center">
            <CampaignStatusLabel />
            <Typography variant="caption">
              {`Created on ${getDisplayDate(
                campaign?.createdTime?.toISOString() || ""
              )}`}
            </Typography>
          </Stack>
        }
        titleIcon={<CampaignIconWithState />}
        titleAction={
          <Stack direction="row" spacing={2} mr={2} alignItems="center">
            {campaign?.status === CampaignStatus.ACTIVE && (
              <Tracked
                onClick={{ name: TrackedEventName.CAMPAIGN_INACTIVATED }}
              >
                <LoadingButton
                  size="small"
                  startIcon={<StopIcon fontSize="small" />}
                  variant="outlined"
                  color="error"
                  loading={isPatchingCampaign}
                  onClick={() => {
                    if (showStopCampaignAlert) {
                      setShowStopCampaignDialog(true);
                    } else {
                      handleStopCampaign();
                    }
                  }}
                >
                  Stop Campaign
                </LoadingButton>
                <AreYouSureDialog
                  title={
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <StopIcon fontSize="small" color="error" />
                      <Typography variant="h6" color="error">
                        Stop Campaign
                      </Typography>
                    </Stack>
                  }
                  text={
                    <Stack spacing={1}>
                      <Typography>
                        Are you sure you want to stop this campaign?
                      </Typography>
                      <Typography variant="body1">
                        If you de-activate all of your campaigns, there will be
                        a 2 week delay when you re-activate your campaigns.
                      </Typography>
                      <Typography variant="body2">
                        In order to send emails for you, we buy mailboxes and
                        set up email accounts. We incur costs to maintain the
                        mailboxes, and we can re-use them for other accounts.
                        So, when users de-activate, we re-allocate those
                        mailboxes to other users, to avoid wasting any money.
                      </Typography>
                    </Stack>
                  }
                  open={showStopCampaignDialog}
                  onClose={() => {
                    setShowStopCampaignDialog(false);
                  }}
                  onConfirm={handleStopCampaign}
                  confirmText="Stop Campaign"
                />
              </Tracked>
            )}
            <ButtonGroup>
              {campaign?.status === CampaignStatus.PENDING && (
                <Tracked onClick={{ name: TrackedEventName.CAMPAIGN_REJECTED }}>
                  <LoadingButton
                    size="small"
                    startIcon={<DeleteIcon fontSize="small" />}
                    variant="outlined"
                    color="error"
                    loading={isPatchingCampaign}
                    onClick={handleRejectCampaign}
                  >
                    Reject Campaign
                  </LoadingButton>
                </Tracked>
              )}

              {campaign?.status !== CampaignStatus.ACTIVE && (
                <>
                  <Tracked
                    onClick={{ name: TrackedEventName.CAMPAIGN_ACTIVATED }}
                  >
                    <Tip
                      title={
                        !canUseService &&
                        "Setup your payment method to start campaigns"
                      }
                    >
                      <LoadingButton
                        loading={isPatchingCampaign}
                        size="small"
                        startIcon={<ScheduleIcon fontSize="small" />}
                        variant="outlined"
                        color="primary"
                        disabled={!canUseService}
                        onClick={() => setShowScheduleStartDialog(true)}
                      >
                        {campaign?.scheduledActivationTime
                          ? `${DateTime.fromJSDate(
                              campaign.scheduledActivationTime
                            ).toLocaleString(DateTime.DATETIME_MED)}`
                          : "Schedule Start"}
                      </LoadingButton>
                    </Tip>
                  </Tracked>
                  <ScheduleActivationDialog
                    open={showScheduleStartDialog}
                    handleClose={() => setShowScheduleStartDialog(false)}
                    handleSave={saveScheduledStart}
                  />
                </>
              )}
              {campaign?.status !== CampaignStatus.ACTIVE && (
                <Tracked
                  onClick={{ name: TrackedEventName.CAMPAIGN_ACTIVATED }}
                >
                  <Tip
                    title={
                      !hasPaymentInformation &&
                      "Setup your payment method to start campaigns"
                    }
                  >
                    <LoadingButton
                      size="small"
                      startIcon={<StartIcon fontSize="small" />}
                      variant="contained"
                      color="success"
                      disabled={!hasPaymentInformation}
                      loading={isPatchingCampaign}
                      onClick={handleStartCampaign}
                      sx={{
                        color: "white",
                      }}
                    >
                      Start Campaign
                    </LoadingButton>
                  </Tip>
                </Tracked>
              )}
            </ButtonGroup>
          </Stack>
        }
        withBackButton
        backButtonTitle="Back to Campaigns"
      >
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tracked onChange={{ name: TrackedEventName.CAMPAIGN_TAB_CHANGED }}>
              <TabList
                onChange={(e, value) => setTabValue(value)}
                aria-label="campaign tabs"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab
                  label="Targeting"
                  value={CampaignTab.TARGETING}
                  icon={<TargetingIcon />}
                />
                <Tab
                  label="Messaging"
                  value={CampaignTab.MESSAGING}
                  icon={<MessagingIcon />}
                />
                <Tab
                  label={"Statistics"}
                  value={CampaignTab.STATISTICS}
                  icon={<StatisticsIcon />}
                />
              </TabList>
            </Tracked>
          </Box>
          <TabPanel value={CampaignTab.TARGETING}>
            <ConversationsProvider campaignId={campaign?.id}>
              <CampaignTargeting />
            </ConversationsProvider>
          </TabPanel>
          <TabPanel value={CampaignTab.MESSAGING}>
            <CampaignMessaging />
          </TabPanel>
          <TabPanel value={CampaignTab.STATISTICS}>
            <CampaignStatistics />
          </TabPanel>
        </TabContext>
      </SectionCard>
    </ControlledBox>
  );
};

export default CampaignPage;
