import { ROUTE_DASHBOARD } from "@/AuthenticatedAppRoutes";
import LogoIcon from "@components/icons/LogoIcon";
import { useNavigate } from "react-router-dom";

type LogoProps = {
  height?: number;
};

const Logo = ({ height = 10 }: LogoProps) => {
  const navigate = useNavigate();

  return (
    <LogoIcon
      height={height}
      onClick={() => {
        navigate(ROUTE_DASHBOARD);
      }}
    />
  );
};

export default Logo;
