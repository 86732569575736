import { Refund, RefundStatus } from "@/models";
import ContactAvatar from "@components/ContactAvatar";
import ContactTitleText from "@components/ContactTitleText";
import Tip from "@components/Tip";
import { useAppContext } from "@context/AppContext";
import { useConversationContext } from "@context/ConversationContext";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCreateRefundRequest } from "@services/payments/createRefundRequest";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function RefundConversation() {
  const { isPayG } = useAppContext();
  const { refund, isFree, invoiceUrl } = useConversationContext();

  if (!isPayG) {
    return null;
  }

  if (isFree) {
    return null;
  }

  if (!invoiceUrl) {
    return null;
  }

  if (refund?.status === RefundStatus.REQUESTED) {
    return <RefundRequested />;
  }

  if (refund?.status === RefundStatus.APPROVED) {
    return <RefundApproved refund={refund} />;
  }

  if (refund?.status === RefundStatus.REJECTED) {
    return <RefundRejected refund={refund} />;
  }

  return <RequestRefund />;
}

const RequestRefund = () => {
  const { lawyerId } = useAppContext();
  const { conversation, contactFullName, fetchConversation } =
    useConversationContext();
  const [reason, setReason] = useState("");
  const [open, setOpen] = useState(false);

  // Create refund request
  const [createRefundRequest, { loading: isCreatingRefundRequest }] =
    useCreateRefundRequest();

  const handleCreateRefundRequest = async () => {
    if (!lawyerId) return;
    if (!conversation) return;

    await createRefundRequest({
      lawyerId,
      campaignMemberId: conversation.id,
      reason,
    });
    fetchConversation?.();
    handleClose();
  };

  // Handle Open and Close
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (isCreatingRefundRequest) return;
    setOpen(false);
  };

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        color="error"
        onClick={handleOpen}
      >
        Request Refund
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            minWidth: "250px",
          },
        }}
      >
        <DialogTitle>
          <Stack direction="column" alignItems="center" spacing={1}>
            <ContactAvatar contact={conversation} size={200} />
            <Box textAlign={"center"}>
              <Typography variant="h5" component={"h1"}>
                {contactFullName}
              </Typography>
              <ContactTitleText contact={conversation} />
            </Box>

            <Box>
              <Typography
                variant="body2"
                textAlign={"center"}
                color="text.secondary"
              >
                When your refund is approved, you will be credited the amount
                you paid for this conversation.
              </Typography>
              <Typography
                variant="body2"
                textAlign={"center"}
                color="error.main"
              >
                We will let {contactFullName} know that you are not interested
                in this conversation.
              </Typography>
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack textAlign={"center"} spacing={2} mt={1} alignItems={"center"}>
            <TextField
              fullWidth
              label="Refund reason"
              multiline
              rows={4}
              placeholder="Why is this not a qualified lead?"
              value={reason}
              disabled={isCreatingRefundRequest}
              onChange={(e) => setReason(e.target.value)}
            />

            <LoadingButton
              variant="outlined"
              color="error"
              size="small"
              loading={isCreatingRefundRequest}
              disabled={isCreatingRefundRequest || !reason}
              onClick={handleCreateRefundRequest}
            >
              Request Refund
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const RefundRequested = () => {
  return (
    <Tip title="We are processing your refund request">
      <Chip label="Refund Requested" color="warning" />
    </Tip>
  );
};

export interface RefundApprovedProps {
  refund: Refund;
}

const RefundApproved = ({ refund }: RefundApprovedProps) => {
  return (
    <Stack spacing={2} direction={"row"} alignItems={"center"}>
      {refund.stripeCreditNoteUrl && (
        <Button
          size="small"
          variant="text"
          to={refund.stripeCreditNoteUrl}
          target="_blank"
          component={Link}
        >
          View Credit Note
        </Button>
      )}
      <Tip
        title={`You have been refunded £${refund.amount
          } on ${refund.updatedTime?.toDateString()}`}
      >
        <Chip label="Refund Approved" color="success" />
      </Tip>
    </Stack>
  );
};

export interface RefundRejectedProps {
  refund: Refund;
}

const RefundRejected = ({ refund }: RefundRejectedProps) => {
  return (
    <Tip
      title={refund.note || "Contact support@kular.ai for more information."}
      placement="top"
    >
      <Chip label="Refund Rejected" color="error" />
    </Tip>
  );
};
