import {
  useGetAllPersonalisationTypesLazyQuery,
  useGetPersonalisationStatisticsLazyQuery,
} from "@/graphql/generated";
import ControlledBox from "@components/ControlledBox";
import InfoBox from "@components/InfoBox";
import { useAppContext } from "@context/AppContext";
import { useCampaignsContext } from "@context/CampaignsContext";
import palette from "@design/palette";
import { Masonry } from "@mui/lab";

import { Stack, Typography } from "@mui/material";
import { UUID, capitalizeWords } from "@utils/text";
import { useEffect, useMemo } from "react";
import {
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from "recharts";

interface DemographicsProps {
  campaignId?: UUID;
}

const Demographics = ({ campaignId }: DemographicsProps) => {
  const { lawyerId } = useAppContext();

  // Get personalisation types
  const [getPersonalisationTypes, { data: personalisationTypes }] =
    useGetAllPersonalisationTypesLazyQuery();

  useEffect(() => {
    getPersonalisationTypes();
  }, []);

  const [
    getPersonalisationStatistics,
    { data: personalisationStatistics, loading: isLoadingPersonalisationStats },
  ] = useGetPersonalisationStatisticsLazyQuery();

  // Gather chart datas
  const charDataByTypeId = useMemo(() => {
    if (!personalisationStatistics) {
      return {};
    }

    if (!personalisationTypes) {
      return {};
    }

    const sumsByTypeAndPersValue =
      personalisationStatistics.statistics?.groupedAggregates;

    return sumsByTypeAndPersValue?.reduce((acc: any, stat: any) => {
      const typeId = stat?.keys[0];
      const persValue = stat?.keys[1]?.replaceAll("_", " ");
      const value = stat?.sum.value;
      const name = personalisationTypes?.personalisationTypes?.nodes
        ?.find((type) => type?.id === typeId)
        ?.name?.replaceAll("_", " ")
        ?.toLocaleUpperCase();

      if (!acc[typeId] && name) {
        acc[typeId] = {
          name,
          data: [],
        };
      }

      acc[typeId].data.push({
        name: capitalizeWords(persValue),
        value: parseInt(value || 0, 10),
      });
      return acc;
    }, {});
  }, [personalisationStatistics, personalisationTypes]);

  // Filters
  const { campaignIds } = useCampaignsContext();

  useEffect(() => {
    if (lawyerId && campaignIds) {
      getPersonalisationStatistics({
        variables: {
          lawyerId,
          campaignIds: campaignId ? [campaignId] : campaignIds,
        },
      });
    }
  }, [lawyerId, campaignIds, campaignId]);

  // Is Busy
  const isBusy = isLoadingPersonalisationStats;

  return (
    <ControlledBox loading={isBusy} loadingMessage="Analysing your outreach">
      <Masonry
        columns={{
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
        }}
        spacing={2}
      >
        {Object.keys(charDataByTypeId).map((key) => {
          const { name, data } = charDataByTypeId[key];

          return (
            <InfoBox width={"100%"} key={key}>
              <Stack
                direction="column"
                spacing={1}
                key={key}
                alignItems={"center"}
              >
                <Typography variant="subtitle1" textTransform={"capitalize"}>
                  {name}
                </Typography>
                <ResponsiveContainer height={300}>
                  <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="name" fontSize={12} />

                    <Radar
                      name="Contacts"
                      dataKey="value"
                      stroke={palette.chart.fill}
                      fill={palette.chart.fill}
                      fillOpacity={0.6}
                      capHeight={3}
                    />
                  </RadarChart>
                </ResponsiveContainer>
              </Stack>
            </InfoBox>
          );
        })}
      </Masonry>
    </ControlledBox>
  );
};

export default Demographics;
