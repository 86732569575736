import { useAppContext } from "@context/AppContext";
import { useEffect } from "react";

export default function LogoutPage() {
  const { logOut } = useAppContext();

  useEffect(() => {
    logOut?.();
  }, [logOut]);

  return <></>;
}
