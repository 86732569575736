import Tip from "@components/Tip";
import { InternalNavIcon } from "@components/icons";
import { Button, ButtonProps, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

type NavButtonProps = {
  icon?: React.ReactNode;
  label?: string;
  to: string;
  title?: string;
} & ButtonProps;

const NavButton = ({
  icon = <InternalNavIcon fontSize="small" />,
  label,
  to,
  title,
  ...buttonProps
}: NavButtonProps) => {
  const navigate = useNavigate();

  const goToUrl = () => {
    navigate(to);
  };

  return label ? (
    <Button
      title={title || label}
      onClick={goToUrl}
      endIcon={icon}
      sx={{
        textTransform: "none",
      }}
      {...buttonProps}
    >
      {label}
    </Button>
  ) : (
    <Tip title={title}>
      <IconButton onClick={goToUrl}>{icon}</IconButton>
    </Tip>
  );
};

export default NavButton;
