import { ROUTE_CAMPAIGNS } from "@/AuthenticatedAppRoutes";
import { useGetBlacklistedOutreachesByLawyerIdLazyQuery } from "@/graphql/generated";
import { BlacklistedOutreach } from "@/models";
import ControlledBox from "@components/ControlledBox";
import FormikInput from "@components/FormikInput";
import FormikRadioGroup from "@components/FormikRadioGroup";
import {
  AddIcon,
  BlacklistIcon,
  DeleteIcon,
  EmailIcon,
  GoogleIcon,
  LinkedinIcon,
  MicrosoftIcon
} from "@components/icons";
import SectionCard from "@components/layout/SectionCard";
import Page from "@components/layout/page/Page";
import PageContent from "@components/layout/page/PageContent";
import { useAppContext } from "@context/AppContext";
import { useUIMessageContext } from "@context/UIMessageContext";

import {
  Box,
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  Typography,
} from "@mui/material";
import { useCreateBlacklistedOutreach } from "@services/campaigns/createBlacklistedOutreach";
import { useDeleteBlacklistedOutreach } from "@services/campaigns/deleteBlacklistedOutreach";
import { useUploadContactBlacklist } from "@services/campaigns/uploadContactBlacklist";
import { normalizeGraphqlResult } from "@utils/graphql";

import { EMAIL_DOMAIN_REGEX, EMAIL_REGEX, UUID } from "@utils/text";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";

function BlacklistPage() {
  const { profile } = useAppContext();

  return (
    <Page>
      <PageContent>
        {profile?.lawyer?.id && (
          <BlacklistedOutreaches lawyerId={profile?.lawyer?.id} />
        )}
      </PageContent>
    </Page>
  );
}

function BlacklistedOutreaches({ lawyerId }: { lawyerId: UUID }) {
  const { profile } = useAppContext();

  // Show messages
  const { showErrorMessage } = useUIMessageContext();

  // Show add blacklisted outreach
  const [showAddBlacklistedOutreach, setShowAddBlacklistedOutreach] =
    useState(false);

  // Pagination State
  const [pageSize, setPageSize] = useState(500);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState(false);

  // Get Blacklisted Outreaches
  const [
    getBlacklistedOutreachesByLawyerId,
    { loading, error, data, refetch: refetchBlacklistedOutreaches },
  ] = useGetBlacklistedOutreachesByLawyerIdLazyQuery();

  useEffect(() => {
    if (!lawyerId) {
      return;
    }

    getBlacklistedOutreachesByLawyerId({
      variables: {
        lawyerId,
        first: pageSize,
        after: endCursor,
      },
    });
  }, [getBlacklistedOutreachesByLawyerId, lawyerId]);

  const blacklistedOutreaches = useMemo(() => {
    return normalizeGraphqlResult(data)?.blacklistedOutreaches;
  }, [data?.blacklistedOutreaches?.nodes]);

  const totalCount = useMemo(() => {
    return data?.blacklistedOutreaches?.totalCount || 0;
  }, [data?.blacklistedOutreaches?.totalCount]);

  useEffect(() => console.log(data), [data]);

  useEffect(() => {
    if (!data?.blacklistedOutreaches?.pageInfo) return;

    setHasNextPage(data.blacklistedOutreaches.pageInfo.hasNextPage);
    setEndCursor(data.blacklistedOutreaches.pageInfo.endCursor);
  }, [data?.blacklistedOutreaches?.pageInfo]);

  const handleNextPage = () => {
    if (!hasNextPage) return;
    if (!endCursor) return;

    getBlacklistedOutreachesByLawyerId({
      variables: {
        lawyerId,
        first: pageSize,
        after: endCursor,
      },
    })
  };

  const handlePreviousPage = () => {
    if (!hasNextPage) return;

    getBlacklistedOutreachesByLawyerId({
      variables: {
        lawyerId,
        first: pageSize,
        after: null,
      },
    })
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    refetchBlacklistedOutreaches({
      lawyerId,
      first: newPageSize,
      after: null,
    });
    setEndCursor(null);
  };

  // Create Blacklisted Outreach
  const [
    createBlackListedOutreach,
    {
      data: createBlacklistedOutreachData,
      loading: isCreatingBlacklistedOutreach,
      error: createBlacklistedOutreachError,
    },
  ] = useCreateBlacklistedOutreach();

  // Upload Blacklisted Outreach
  const [
    uploadContactBlacklist,
    {
      data: uploadContactBlacklistData,
      loading: isUploadContactBlacklist,
      error: uploadContactBlacklistError,
    },
  ] = useUploadContactBlacklist();

  // Handle create success
  useEffect(() => {
    if (createBlacklistedOutreachData && !createBlacklistedOutreachError) {
      setShowAddBlacklistedOutreach(false);
    }
  }, [createBlacklistedOutreachData, createBlacklistedOutreachError]);

  // Handle create error
  useEffect(() => {
    if (!isCreatingBlacklistedOutreach && createBlacklistedOutreachError) {
      setShowAddBlacklistedOutreach(false);
    }
  }, [createBlacklistedOutreachError, isCreatingBlacklistedOutreach]);

  const [originType, setOriginType] = useState('');
  // Handle add blacklisted outreach
  const handleAddBlacklistedOutreach = useCallback(
    async ({
      addressType,
      email,
      emailDomain,
      linkedinUrl,
      reason,
    }: {
      addressType: string;
      email: string;
      emailDomain: string;
      linkedinUrl: string;
      reason: string;
    }) => {
      const existing = !!blacklistedOutreaches?.find(
        (existing: BlacklistedOutreach) =>
          existing?.email === email ||
          existing?.linkedinUrl === linkedinUrl ||
          existing?.emailDomain === emailDomain
      );

      if (existing) {
        showErrorMessage("Contact already blacklisted");
        return;
      }

      const addresses: any = { email, emailDomain, linkedinUrl };
      const update: any = {
        reason,
        lawyerId,
      };

      update[addressType] = addresses[addressType];

      await createBlackListedOutreach(update);
      refetchBlacklistedOutreaches();
    },
    [
      createBlackListedOutreach,
      lawyerId,
      refetchBlacklistedOutreaches,
      blacklistedOutreaches,
    ]
  );

  const handleUploadContactToBlacklist = useCallback(
    async ({
      file,
      originType,
    }: {
      file: File;
      originType: string;
    }) => {
      const update: any = {
        lawyerId,
        file,
        originType,
      };

      await uploadContactBlacklist(update);
      refetchBlacklistedOutreaches();
    },
    [uploadContactBlacklist, lawyerId, refetchBlacklistedOutreaches]
  );

  // Delete Blacklisted Outreach
  const [
    deleteBlacklistedOutreach,
    { loading: isRemovingBlacklistedOutreach },
  ] = useDeleteBlacklistedOutreach();

  const handleRemoveBlacklistedOutreach = useCallback(
    async (blacklistedOutreachId: UUID) => {
      await deleteBlacklistedOutreach({
        blacklistedOutreachId,
        lawyerId,
      });
      refetchBlacklistedOutreaches();
    },
    [
      deleteBlacklistedOutreach,
      refetchBlacklistedOutreaches,
      profile?.lawyer?.id,
    ]
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    await handleUploadContactToBlacklist({
      file,
      originType,
    });

    // Clear the input after processing to allow the same file to be selected again if needed
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  const isBusy = useMemo(() => {
    return (
      loading || isCreatingBlacklistedOutreach || isRemovingBlacklistedOutreach
    );
  }, [loading, isCreatingBlacklistedOutreach, isRemovingBlacklistedOutreach]);

  return (
    <SectionCard
      skinny
      withBackButton
      backButtonUrl={ROUTE_CAMPAIGNS}
      backButtonTitle="Back to Conversations"
      title={"Blacklisted Contacts"}
      titleIcon={<BlacklistIcon color="error" />}
      subTitle={
        "Blacklisted contacts will be excluded from your outreach campaigns."
      }
      titleAction={
        [<Button
          size="small"
          variant="text"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setShowAddBlacklistedOutreach(true)}
        >
          Add Contact
        </Button>,
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
        />,
        <Button
          size="small"
          variant="text"
          color="primary"
          onClick={() => {
            setOriginType('GOOGLE');
            fileInputRef.current?.click();
          }}
          startIcon={<GoogleIcon />}
        >
          Upload From Gmail
        </Button>,
        <Button
          size="small"
          variant="text"
          color="primary"
          onClick={() => {
            setOriginType('MICROSOFT');
            fileInputRef.current?.click();
          }}
          startIcon={<MicrosoftIcon />}
        >
          Upload From Outlook
        </Button>,
        ]
      }
    >
      <ControlledBox loading={loading}>
        <Collapse in={showAddBlacklistedOutreach} unmountOnExit>
          <Box mb={3} px={4}>
            <AddBlacklistedOutreachForm
              disabled={isBusy}
              onAdd={(values) => handleAddBlacklistedOutreach(values)}
              onClose={() => setShowAddBlacklistedOutreach(false)}
            />
          </Box>
        </Collapse>
        <Divider />
        <List>
          {blacklistedOutreaches?.map(
            (blacklistedOutreach: BlacklistedOutreach) => (
              <div key={blacklistedOutreach?.id}>
                <BlacklistedOutreachItem
                  blacklistedOutreach={blacklistedOutreach}
                  onRemove={async (blacklistedOutreachId) =>
                    handleRemoveBlacklistedOutreach(blacklistedOutreachId)
                  }
                />
                <Divider />
              </div>
            )
          )}
        </List>
        {totalCount === 0 && (
          <Box p={4} textAlign={"center"}>
            <Typography variant="subtitle1" color={"primary"}>
              You do not have any blacklisted contacts yet
            </Typography>
            <Typography variant="body2" color={"text.secondary"}>
              Add contacts to blacklist them from your campaigns.
            </Typography>
          </Box>
        )}
        <Box mt={2} display="flex" justifyContent="center" alignItems="center">
          <Button 
            onClick={handlePreviousPage} 
            disabled={!endCursor}
          >
            Previous
          </Button>
          <Box mx={2}>
            <Typography variant="body2">
              Showing {blacklistedOutreaches?.length} of {totalCount}
            </Typography>
          </Box>
          <Button 
            onClick={handleNextPage} 
            disabled={!hasNextPage}
          >
            Next
          </Button>
        </Box>
      </ControlledBox>
    </SectionCard>
  );
}

function AddBlacklistedOutreachForm({
  disabled,
  onClose,
  onAdd,
}: {
  disabled?: boolean;
  onClose: () => void;
  onAdd: (blacklistedOutreach: any) => void;
}) {
  return (
    <Formik
      initialValues={{
        email: "",
        emailDomain: "",
        linkedinUrl: "",
        addressType: "email",
        reason: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().matches(EMAIL_REGEX, "Must be a valid email"),
        emailDomain: Yup.string().matches(
          EMAIL_DOMAIN_REGEX,
          "Must be a valid email domain"
        ),
        linkedinUrl: Yup.string().matches(
          /^(https:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm,
          "Must be a valid LinkedIn url"
        ),
        addressType: Yup.string()
          .required("Required")
          .oneOf(["email", "emailDomain", "linkedinUrl"]),
        reason: Yup.string(),
      })}
      onSubmit={async (
        { email, emailDomain, reason, addressType, linkedinUrl },
        { setSubmitting, resetForm }
      ) => {
        setSubmitting(true);
        await onAdd({ email, emailDomain, reason, addressType, linkedinUrl });
        resetForm();
        setSubmitting(false);
      }}
    >
      {({
        handleSubmit,
        errors,
        values,
        resetForm,
        isSubmitting,
        setFieldValue,
        touched,
      }) => {
        return (
          <div>
            <Form>
              <Typography variant="h6" gutterBottom>
                Add a new blacklisted contact
              </Typography>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                Please enter a valid email, email domain or a LinkedIn url
              </Typography>

              <FormikRadioGroup aria-label="is LinkedIn url" name="addressType">
                <FormControlLabel
                  control={<Radio />}
                  value={"email"}
                  onClick={() => {
                    setFieldValue("linkedinUrl", "");
                    setFieldValue("emailDomain", "");
                  }}
                  label={
                    <Typography variant="body2" color="textSecondary">
                      Email
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={<Radio />}
                  value={"emailDomain"}
                  onClick={() => {
                    setFieldValue("linkedinUrl", "");
                    setFieldValue("email", "");
                  }}
                  label={
                    <Typography variant="body2" color="textSecondary">
                      Email Domain
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={<Radio />}
                  value={"linkedinUrl"}
                  onClick={() => {
                    setFieldValue("email", "");
                    setFieldValue("emailDomain", "");
                  }}
                  label={
                    <Typography variant="body2" color="textSecondary">
                      LinkedIn Url
                    </Typography>
                  }
                />
              </FormikRadioGroup>
              {values.addressType === "email" && (
                <FormikInput
                  name="email"
                  placeholder="Blacklisted email. Examples: harvey@specter.com"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon color="error" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {values.addressType === "emailDomain" && (
                <FormikInput
                  name="emailDomain"
                  placeholder="Blacklisted email domain. Examples: specter.com"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon color="error" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {values.addressType === "linkedinUrl" && (
                <FormikInput
                  name="linkedinUrl"
                  placeholder="Blacklisted LinkedIn url"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedinIcon color="error" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              <Box mt={2}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  gutterBottom
                >
                  You can improve your results by adding a reason for
                  blacklisting (Optional)
                </Typography>
                <FormikInput
                  name="reason"
                  label="Reason"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        resetForm();
                        onClose();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={
                        Object.keys(errors).length > 0 ||
                        isSubmitting ||
                        disabled ||
                        Object.keys(touched).length === 0
                      }
                      onClick={(e) => handleSubmit()}
                    >
                      Blacklist Contact
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}

function BlacklistedOutreachItem({
  blacklistedOutreach,
  onRemove,
}: {
  blacklistedOutreach: BlacklistedOutreach;
  onRemove: (blacklistedOutreachId: UUID) => void;
}) {
  const [disabled, setDisabled] = useState(false);
  const key = useMemo(() => {
    return (
      blacklistedOutreach?.email ||
      blacklistedOutreach?.linkedinUrl ||
      blacklistedOutreach?.emailDomain
    );
  }, [
    blacklistedOutreach?.email,
    blacklistedOutreach?.linkedinUrl,
    blacklistedOutreach?.emailDomain,
  ]);

  const handleRemove = useCallback(async () => {
    setDisabled(true);
    await onRemove(blacklistedOutreach?.id);
    setDisabled(false);
  }, [blacklistedOutreach?.id, onRemove]);

  return (
    <BlacklistedOutreachWrapper>
      <ListItem dense>
        <ListItemAvatar>
          {blacklistedOutreach?.linkedinUrl ? (
            <LinkedinIcon color={"action"} />
          ) : (
            <EmailIcon color={"action"} />
          )}
        </ListItemAvatar>
        <ListItemText primary={key} />
        <ListItemSecondaryAction>
          <Button
            disabled={disabled}
            onClick={() => handleRemove()}
            color="secondary"
          >
            <DeleteIcon color={"primary"} />
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    </BlacklistedOutreachWrapper>
  );
}

const BlacklistedOutreachWrapper = styled.div`
  :hover {
  }
`;

export default BlacklistPage;
