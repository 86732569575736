import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

type GetCalendarProfilesParams = {
  lawyerId: UUID;
};

export const getCalendarProfiles = async ({
  lawyerId,
}: GetCalendarProfilesParams) => {
  const options: FetchOptions = {
    method: "GET",
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/meetings/calendar-profiles`,
    options
  );
  return response;
};

export type Calendar = {
  calendarId: string;
  calendarName: string;
  calendarReadonly: boolean;
  calendarDeleted: boolean;
  calendarPrimary: boolean;
  calendarIntegratedConferencingAvailable: boolean;
  calendarAttachmentsAvailable: boolean;
  permissionLevel: string;
};

export type CalendarProfile = {
  providerName: string;
  providerId: string;
  profileId: string;
  providerService: string;
  profileConnected: boolean;
  profileInitialSyncRequired: boolean;
  profileCalendars: Calendar[];
};

export type GetCalendarProfilesResponse = {
  profiles: Array<CalendarProfile>;
};

export const useGetCalendarProfiles = () =>
  useServiceCall<GetCalendarProfilesResponse, GetCalendarProfilesParams>({
    serviceCall: getCalendarProfiles,
  });
