import { Prospect } from "@/models";

// Very naive score based on what looks good in the UI
export function prospectDisplayScore(prospect: Prospect) {
  const propertiesToCheck = [
    { property: prospect.contactPhotoUrl, score: 5 },
    { property: prospect.companyPhotoUrl, score: 5 },
    { property: prospect.companyWebsiteUrl, score: 1 },
    { property: prospect.companyLinkedinUrl, score: 1 },
    { property: prospect.summary, score: 3 }
  ];
  
  const score = propertiesToCheck
    .filter(item => item.property)
    .reduce((totalScore, item) => totalScore + item.score, 0);

  return score;
}