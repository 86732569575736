import { CampaignMemberStatus } from "@/models";
import usePersonalisationStats, {
  UsePersonalisationStatsResponse,
} from "@hooks/usePersonalisationStats";
import { FilteredCampaignMember, useGetFilteredMembers } from "@services/members/filterCampaignMembers";
import { UUID } from "crypto";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useCampaignsContext } from "./CampaignsContext";

type StatisticsContextProps = {
  campaignId?: UUID | null;
  personalisationStatistics: UsePersonalisationStatsResponse["stats"];
  isLoadingPersonalisationStats: boolean;
  hasNoPersonalisationStats: boolean;
};

const StatisticsContext = createContext<StatisticsContextProps>({
  campaignId: null,
  personalisationStatistics: {},
  isLoadingPersonalisationStats: false,
  hasNoPersonalisationStats: false,
});

type StatisticsProviderProps = {
  children: ReactNode;
  campaignId?: UUID;
};

export const StatisticsProvider = ({
  children,
  campaignId,
}: StatisticsProviderProps) => {
  const [filterCampaignMembers, { data: pageOfMembers }] = useGetFilteredMembers();
  const { campaignIds } = useCampaignsContext();

  // TODO(DJRHails): This is a crazy inefficient way to do this; and makes so much sense why it was causing
  // intense load. We need to fix this.
  useEffect(() => {
    const _campaignIds = campaignId ? [campaignId] : campaignIds;

    if (!!_campaignIds?.length) {
      filterCampaignMembers({
        campaignIds: campaignIds as UUID[],
        statuses: [
          CampaignMemberStatus.COMPLETED,
          CampaignMemberStatus.CONTACTED,
          CampaignMemberStatus.MEETING_BOOKED,
          CampaignMemberStatus.LAWYER_HANDLING,
          CampaignMemberStatus.REPLIED,
          CampaignMemberStatus.SCHEDULED_OUTREACH,
          CampaignMemberStatus.SCHEDULING,
          CampaignMemberStatus.WARM_REPLY,
        ],
        offset: 0,
        first: 100,
      });
    }
  }, [campaignIds, campaignId]);

  // Use contactIds to fetch personalisation stats
  const contactIds: Array<UUID> | null = useMemo(() => {
    if (!pageOfMembers) return null;

    return (
      pageOfMembers?.items?.map((membership: FilteredCampaignMember) => membership.contactId) ||
      null
    );
  }, [pageOfMembers]);

  const {
    fetch: _fetchPesonalisationStats,
    stats: personalisationStats,
    isLoading: isLoadingPersonalisationStats,
    hasNoData: hasNoPersonalisationStats,
  } = usePersonalisationStats({});

  useEffect(() => {
    if (contactIds) {
      _fetchPesonalisationStats(contactIds);
    }
  }, [contactIds]);

  return (
    <StatisticsContext.Provider
      value={{
        campaignId,
        personalisationStatistics: personalisationStats,
        isLoadingPersonalisationStats,
        hasNoPersonalisationStats,
      }}
    >
      {children}
    </StatisticsContext.Provider>
  );
};

export const useStatisticsContext = () => {
  return useContext(StatisticsContext);
};
