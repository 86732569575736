import { useAppContext } from "@context/AppContext";
import { Avatar } from "@mui/material";
import { getFullName } from "@utils/text";

type ProfileAvatarProps = {
  size: number;
};

export default function ProfileAvatar({ size = 44 }: ProfileAvatarProps) {
  const { profile } = useAppContext();
  const imageUrl = profile?.photoUrl || "";

  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        backgroundColor: "primary.main",
        color: "#fff",
      }}
      alt={getFullName(profile?.firstName, profile?.lastName) || ""}
      src={imageUrl}
    />
  );
}
