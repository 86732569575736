import {
  CampaignChannel,
  EmailAddress,
  combineRecipients,
  dedupeEmailAddresses,
  flattenRecipients,
  getRecipientsFromHedwigMessagePayloads,
} from "@/models";
import { TrackedEventName, track } from "@/third-party/tracking";
import InfoBox from "@components/InfoBox";
import { ReplyIcon, SendIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import { useConversationContext } from "@context/ConversationContext";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Collapse,
  Grid,
  LinearProgress,
  Stack,
  Typography
} from "@mui/material";
import {
  ReplyCreate,
  ReplyEmailResponse,
  useReplyToEmailMessage,
} from "@services/email/replyToEmailMessage";
import { getFullName } from "@utils/text";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import ConversationFeedbackBox from "../ConversationFeedbackBox";
import SelectEmailAddress from "./SelectEmailAddress";

const { Quill } = ReactQuill;
const Block = Quill.import("blots/block");
Block.tagName = "div";
Quill.register(Block);

const StyledEditor = styled(ReactQuill)`
  .ql-editor {
    min-height: 200px;
  }
`;

interface ReplyEditorProps {
  onReply?: (replyCreate: ReplyEmailResponse) => void;
}

function ReplyEditor({ onReply }: ReplyEditorProps) {
  const { profile } = useAppContext();
  const { conversation, messages, lastReply, contactFullName, contact } =
    useConversationContext();
  const [content, setContent] = useState("");
  const [to, setTo] = useState<EmailAddress[]>([]);

  const previousMessageRecipients = useMemo(() => {
    return getRecipientsFromHedwigMessagePayloads(
      lastReply?.hedwigMessagePayloads
    );
  }, [lastReply?.hedwigMessagePayloads]);

  const allParticipants = useMemo(() => {
    return flattenRecipients(
      combineRecipients(
        messages?.map((message) =>
          getRecipientsFromHedwigMessagePayloads(message.hedwigMessagePayloads)
        )
      )
    );
  }, [messages]);

  const replyFrom: EmailAddress = useMemo(() => {
    return {
      name: getFullName(
        lastReply?.senderProfile?.firstName,
        lastReply?.senderProfile?.lastName
      ),
      email: lastReply?.senderProfile?.email!,
    };
  }, [lastReply]);

  const replyTo: EmailAddress | undefined = useMemo(() => {
    const recipients = getRecipientsFromHedwigMessagePayloads(
      lastReply?.hedwigMessagePayloads
    );

    if (recipients?.from) {
      return {
        name: recipients.from.name,
        email: recipients.from.email,
      };
    }

    return {
      name: contactFullName!,
      email: contact?.email!,
    };
  }, [lastReply]);

  const [cc, setCc] = useState<EmailAddress[]>(previousMessageRecipients.cc);
  const [bcc, setBcc] = useState<EmailAddress[]>(previousMessageRecipients.bcc);
  const [attachments, setAttachments] = useState<File[]>([]);

  const [
    replyToEmailMessage,
    { data: emailReplyResponse, loading: isReplyingEmail },
  ] = useReplyToEmailMessage();

  const handleReply = useCallback(() => {
    if (!lastReply?.id) return;

    if (!to || to.length === 0) return;

    if (lastReply?.campaignChannel === CampaignChannel.EMAIL) {
      const replyCreate: ReplyCreate = {
        emailMessageId: lastReply.id,
        to,
        bcc,
        cc,
        content,
        attachments,
      };

      // Track
      track({
        name: TrackedEventName.CONVERSATION_REPLIED,
        props: {
          conversationId: conversation?.id,
        },
      });

      // Reply
      replyToEmailMessage(replyCreate);
    }
  }, [lastReply?.id, bcc, cc, content, attachments, replyTo]);


  const allEmailAddresses = useMemo(() => {
    const emailAddresses = allParticipants;
    if (profile) {
      emailAddresses.push({
        name: getFullName(profile?.firstName, profile?.lastName),
        email: profile?.email,
      });
    }
    return dedupeEmailAddresses(emailAddresses);
  }, [profile]);

  const selectedEmailAddresses = useMemo(() => {
    return [replyFrom, replyTo, ...cc, ...bcc];
  }, [cc, bcc]);

  const availableEmailAddresses = useMemo(() => {
    return allEmailAddresses.filter(
      (emailAddress) =>
        !selectedEmailAddresses.find(
          (selectedEmailAddress) =>
            selectedEmailAddress.email === emailAddress.email
        )
    );
  }, [allEmailAddresses, selectedEmailAddresses]);

  const replySent = useMemo(() => {
    return !!emailReplyResponse;
  }, [emailReplyResponse]);

  useEffect(() => {
    if (emailReplyResponse) {
      // fetchConversation?.();

      onReply?.(emailReplyResponse);
    }
  }, [emailReplyResponse]);

  useEffect(() => {
    if (replyTo) {
      setTo([replyTo]);
    }
  }, [replyTo]);

  const shouldShowFeedbackBox = useMemo(() => {
    if (!conversation) {
      return false;
    }
    if (isReplyingEmail) {
      return false;
    }
    if (!replySent) {
      return false;
    }
    return conversation?.feedbacks?.length === 0;
  }, [replySent, isReplyingEmail, conversation]);

  if (shouldShowFeedbackBox) {
    return conversation && <ConversationFeedbackBox campaignMember={conversation} />
  }

  return conversation ? (
    <>
      <InfoBox>
        {isReplyingEmail && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Collapse in={!replySent}>
          <Stack spacing={2} direction="column" mt={1}>
            <Stack spacing={2} direction="column" mt={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <ReplyIcon />
                <Typography variant="subtitle1">
                  Reply to {contactFullName}{" "}
                  {lastReply?.campaignChannel === CampaignChannel.LINKEDIN
                    ? "on Linkedin "
                    : ""}
                </Typography>
              </Stack>
              {lastReply?.campaignChannel === CampaignChannel.EMAIL && (
                <Box>
                  <Stack spacing={1} direction={"column"}>
                    <Typography variant="body2" color="textSecondary">
                      <b>From:</b>{" "}
                      {replyFrom.name
                        ? `${replyFrom.name} (${replyFrom.email})`
                        : replyFrom.email}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <SelectEmailAddress
                        label="To"
                        value={to}
                        emails={availableEmailAddresses}
                        onChange={(emailAddresses: Array<EmailAddress>) => {
                          setTo(emailAddresses);
                        }}
                      />
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <b>Subject:</b> RE: {lastReply?.subject}
                    </Typography>
                  </Stack>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    mt={1}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <SelectEmailAddress
                        label="Cc"
                        value={cc}
                        disabled={isReplyingEmail}
                        emails={availableEmailAddresses}
                        onChange={(emailAddresses: Array<EmailAddress>) => {
                          setCc(emailAddresses);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <SelectEmailAddress
                        label="Bcc"
                        value={bcc}
                        emails={availableEmailAddresses}
                        disabled={isReplyingEmail}
                        onChange={(emailAddresses: Array<EmailAddress>) => {
                          setBcc(emailAddresses);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Stack>
            <StyledEditor
              theme="snow"
              value={content}
              onChange={setContent}
              readOnly={isReplyingEmail}
            />
            <Box textAlign={"center"}>
              <LoadingButton
                variant="contained"
                color="primary"
                startIcon={<SendIcon />}
                loading={isReplyingEmail}
                onClick={handleReply}
              >
                Reply
              </LoadingButton>
            </Box>
          </Stack>
        </Collapse>
      </InfoBox>
    </>
  ) : null;
}

export default ReplyEditor;
