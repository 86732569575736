import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

type AcceptPolicyParams = {
  profileId: UUID;
  policyId: UUID;
};

export const acceptPolicy = async ({
  profileId,
  policyId,
}: AcceptPolicyParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      profileId,
      policyId,
    },
  };

  const response = await fetchOutreachService(
    `/policies/agreements`,
    options
  );

  return response;
};

export const useAcceptPolicy = () =>
  useServiceCall<null, AcceptPolicyParams>({
    serviceCall: acceptPolicy,
    errorMessage: "Failed to accept policy",
  });
