import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

interface GetLinkedinHasProfileParamsRequired {
  lawyerId: string;
}

type GetLinkedinHasProfileResponse = boolean;

type GetLinkedinHasProfileParams = Partial<GetLinkedinHasProfileParamsRequired>;

export const getLinkedinHasProfile = async ({
  lawyerId,
}: GetLinkedinHasProfileParams) => {

  const options: FetchOptions = {
    method: "GET",
  };

  const response = await fetchOutreachService(
    `/unipile/manage/has-profile?lawyer_id=${lawyerId}`,
    options
  );

  return response;
};



export const useGetLinkedinHasProfile = () =>
  useServiceCall<GetLinkedinHasProfileResponse, GetLinkedinHasProfileParams>({
    serviceCall: getLinkedinHasProfile,
  });
