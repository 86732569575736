import { ServiceResponse, useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

type CreateLinkedinCampaignTemplateParams = {
  name: string;
  campaignId: string;
  content: string;
  orderIndex: number;
  status: string;
};

export const createLinkedinCampaignTemplate = async ({
  name,
  campaignId,
  content,
  orderIndex,
  status,
}: CreateLinkedinCampaignTemplateParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      name,
      campaignId,
      orderIndex,
      status,
      content,
    },
  };

  const response = await fetchOutreachService("/linkedin/templates", options);

  return response;
};

export const useCreateLinkedinCampaignTemplate = () =>
  useServiceCall<ServiceResponse, CreateLinkedinCampaignTemplateParams>({
    serviceCall: createLinkedinCampaignTemplate,
    successMessage: "Your message was added!",
    errorMessage: "Can not add your message right now..",
  });
