import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

interface DuplicateCampaignParams {
  lawyerId: string;
  campaignId: string;
}

export const duplicateCampaign = async ({
  lawyerId,
  campaignId,
}: DuplicateCampaignParams) => {
  const options: FetchOptions = {
    method: "POST",
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/campaigns/${campaignId}/duplicate`,
    options
  );

  return response;
};

type DuplicateCampaignResponse = {
  id: UUID;
};

export const useDuplicateCampaign = () =>
  useServiceCall<DuplicateCampaignResponse, DuplicateCampaignParams>({
    serviceCall: duplicateCampaign,
    successMessage: "Your have duplicated your campaign.",
    errorMessage: "Error creating your campaign",
  });
