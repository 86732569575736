import { ServiceResponse, useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

type DeleteEmailCampaignTemplateParams = {
  templateId: string;
};

export const deleteEmailCampaignTemplate = async ({
  templateId,
}: DeleteEmailCampaignTemplateParams) => {
  const options: FetchOptions = {
    method: "DELETE",
  };

  const response = await fetchOutreachService(
    `/campaigns/templates/${templateId}`,
    options
  );

  return response;
};

export const useDeleteEmailCampaignTemplate = () =>
  useServiceCall<ServiceResponse, DeleteEmailCampaignTemplateParams>({
    serviceCall: deleteEmailCampaignTemplate,
    successMessage: "Template deleted successfully",
    errorMessage: "Error deleting template",
  });
