import { DownloadIcon } from "@components/icons";
import { useBillingContext } from "@context/BillingContext";
import { Button } from "@mui/material";
import { getFullName } from "@utils/text";
import { formatChargeDate } from "./ChargeFilters";

export default function DownloadChargesButton() {
  const { charges, isLoadingCharges, startTime, endTime } = useBillingContext();

  const chargesToCsvBlob = () => {
    if (!charges?.length) return;

    const csvData = charges.map((charge) => {
      const contact = charge.campaignMember?.contact;
      const company = contact?.company;

      const contactEmail = contact?.email;
      const contactName = getFullName(contact?.firstName, contact?.lastName);
      const contactLinkedIn = contact?.linkedinUrl;
      const companyName = company?.name;
      const companyWebsite = company?.websiteUrl;
      const paidTime = charge.paidAt;
      const creditedTime = charge.refunds?.[0]?.updatedTime;
      const creditedAmount = charge.refunds?.[0]?.amount;
      const creditUrl = charge.refunds?.[0]?.stripeCreditNoteUrl;
      const invoiceLink = charge.stripeInvoiceUrl;

      // Keep the empty strings for proper CSV formatting
      return {
        "Contact Email": contactEmail ? contactEmail : "",
        "Contact Name": contactName ? contactName : "",
        "Contact LinkedIn": contactLinkedIn ? contactLinkedIn : "",
        "Company Name": companyName ? companyName : "",
        "Company Website": companyWebsite ? companyWebsite : "",
        "Paid Amount": charge.freeAt
          ? "Free"
          : `$${(charge.amount + charge.taxAmount) / 100}`,
        "Paid Time": paidTime ? paidTime : "",
        "Credited Amount": creditedAmount ? `$${creditedAmount / 100}` : "",
        "Credited Time": creditedTime ? creditedTime : "",
        "Credit Link": creditUrl ? creditUrl : "",
        "Invoice Link": invoiceLink ? invoiceLink : "",
      };
    });

    const csv = [
      Object.keys(csvData[0]).join(","),
      ...csvData.map((row) =>
        Object.values(row)
          .map((col) => {
            if (typeof col === "string") {
              return `"${col}"`;
            }
            return col;
          })
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csv], { type: "text/csv" });

    return blob;
  };

  const downloadCharges = () => {
    const blob = chargesToCsvBlob();
    if (blob) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      const fileName = `Kular Charges ${formatChargeDate(
        startTime
      )}-${formatChargeDate(endTime)}.csv`;
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={downloadCharges}
      disabled={isLoadingCharges || !charges?.length}
      startIcon={<DownloadIcon fontSize="small" />}
    >
      Download Charges
    </Button>
  );
}
