import { useCampaignCreateContext } from "@context/CampaignCreateContext";
import { Stack, TextField } from "@mui/material";
import SamplesDropdown from "./SamplesDropdown";

const StepCompanyTargeting = () => {
  const { companyTargetRequest, setCompanyTargetRequest } =
    useCampaignCreateContext();

  return (
    <Stack direction="column" spacing={0}>
      <SamplesDropdown
        options={[
          "Founders of technology startups in the USA",
          "Owners of small and medium-sized businesses in California",
          "Heads of HR at multinational energy companies",
          "Founders of software startups in the UK",
          "Heads of Data Analytics at major consulting companies",
        ]}
        onOptionSelected={(option) => {
          setCompanyTargetRequest(option);
        }}
      />

      <TextField
        fullWidth
        value={companyTargetRequest}
        placeholder="What type of companies do you want to target to?"
        id="outlined-basic"
        variant="outlined"
        multiline
        rows={3}
        onChange={(e) => setCompanyTargetRequest(e.target.value)}
      />
    </Stack>
  );
};

export default StepCompanyTargeting;
