import { Prospect } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

type SearchProspectsParams = {
  suggestedTargetingId?: UUID;
  page?: number;
  size?: number;
};

export const searchProspects = async ({
  suggestedTargetingId,
  page = 1,
  size = 20,
}: SearchProspectsParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      suggestedTargetingId,
    },
    query: {
      useCache: true,
      page,
      size,
    },
  };

  const response = await fetchOutreachService(`/contacts/search`, options);

  return response;
};

type SearchProspectsResponse = {
  page: number;
  size: number;
  items: Prospect[];
};

export const useSearchProspects = () =>
  useServiceCall<SearchProspectsResponse, SearchProspectsParams>({
    serviceCall: searchProspects,
  });
