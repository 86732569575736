import config from "@/config";
import { CancelIcon } from "@components/icons";
import Page from "@components/layout/page/Page";
import PageContent from "@components/layout/page/PageContent";
import MuiMarkdown from "@components/MuiMarkdown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

export enum FAQTag {
  CAMPAIGN = "Campaigns",
  CONVERSATIONS = "Conversations",
  PAYMENTS = "Payments",
  PRIVACY = "Privacy",
  ACCOUNT = "Account",
}

type FAQItem = {
  question: string;
  answer: string;
  tags: FAQTag[];
};
const FAQs: Array<FAQItem> = [
  {
    question: "How do I add entries to my blacklist?",
    answer: `With Kular you can blacklist either an individual person via their email or LinkedIn, or a company via their website domain. This will mean these individuals and companies will not be messaged by Kular on your behalf.

This can be done in one of two ways:

1. In the Kular web app:
    1. Click on the ‘blacklist contacts’ button on the right hand side of the campaigns page
    2. Here you can view your blacklist, and add entries by clicking on the ‘add contact’ button in the top right
    3. Once you have filled in the information in the form, click ‘blacklist contact’ and this entry will be added
2. Email support@kular.ai with a csv file containing the emails, LinkedIn URLs and/or the company domains you would like to blacklist`,
    tags: [FAQTag.CAMPAIGN, FAQTag.CONVERSATIONS],
  },
  {
    question: "When will my campaigns start sending emails?",
    answer: `Once you click ‘Start Campaign’ on your first campaign, Kular will automatically begin creating and warming your outreach mailboxes. This process takes up to 2 days. Once this has been complete, your first emails will start to be sent.

Your mailboxes will start with a small volume, that will gradually increase to full volume over the course of 2 weeks.

This process only needs to be done once, so once you have activated your first campaign and set up is complete, all current and future campaigns will send at maximum capacity.`,
    tags: [FAQTag.CAMPAIGN],
  },
  {
    question: "How can I get refunds for leads I am unhappy with?",
    answer: `If you have any leads that you believe are not qualified leads, you can request a refund in one of two ways:

1. In the web app:
    1. In the ‘conversations’ tab, click on the lead you would like to request a refund for
    2. In the top right of the lead page, click on ‘request refund’
    3. Fill in the text field, letting us know what you don’t like about this lead. This will help us to make sure that you don’t get more leads like this
    4. Click the ‘request refund’ below the text field
    5. The lead will then be reviewed, and the outcome will be displayed in the conversation once it is available
2. Email support@kular.ai with the full name or email address of the lead you would like a refund for, and what it is you don’t like about them.`,
    tags: [FAQTag.PAYMENTS],
  },
  {
    question: "Can I choose/see my domains?",
    answer: `If you would like to be able to select the domains that Kular uses for your outreach, email support@kular.ai asking to be able to select your outreach domains. Make sure to do this before activating your first campaign, as otherwise the system will choose domains automatically for you.

A list will be sent of available domains, from which you can make a selection. This will then be implemented once you activate your first campaign.

If you would like to see which domains Kular has acquired, email support@kular.ai requesting the list of your domains, and a list will be provided to you.`,
    tags: [FAQTag.PAYMENTS],
  },
  {
    question: "Can I set up an alias for my sender?",
    answer: `Once you click ‘Start Campaign’ on your first campaign, Kular will automatically begin creating and warming your outreach mailboxes. During this process, Kular will by default use your account information to create the mailboxes.

If you would like to use an alias for sending emails, email support@kular.ai before activating your first campaign, including the name and title of the alias you would like to use, and this will be implemented when you activate your first campaign.`,
    tags: [FAQTag.CAMPAIGN],
  },
  {
    question: "When will I be billed?",
    answer: `Kular will generate an invoice whenever a qualified lead enters your conversations tab. Once an invoice is generated, your stored payment method will then be charged and the invoice paid.

If you don’t currently have a payment method added, we will email you once you have a qualified lead to ask for payment details, which can be added via the web app.

You can view all invoices from your billing portal, which can be accessed from the settings page of your web app. You can also view the invoice for each conversation, by clicking on the conversation, and then clicking on ‘view invoice’ in the top right.`,
    tags: [FAQTag.PAYMENTS],
  },
  {
    question: "What counts as a qualified lead?",
    answer: `Kular considers qualified leads to be contacts which send a response to your outreach that shows interest in engaging with the sales process. Examples of this include:

- asking to have a meeting or schedule a call
- asking for information regarding pricing
- asking for further information on your product or services (such as a demo, a video, further material or specific questions about you or your offering)

Kular does not consider the following as qualified leads:

- asking to unsubscribe
- out of office replies
- rejections
- offers to ‘keep you in mind’ for an indefinite period`,
    tags: [FAQTag.CONVERSATIONS],
  },
  {
    question: "What can I add to targeting information?",
    answer: `Kular will send messages to contacts that match the targeting provided in the target description of your campaign. This information looks at information on both the contact and the company the contact works at to determine whether or not it is a match.

You can include the following information in your targeting description:

- Locations - this can include both inclusions and exclusions
- Sizes (this can be in the form of numbers of employees, or denominations such as small, medium or large)
- Contact job titles, departments and/or seniorities - this can exclude inclusions and exclusions
- A description of what your ideal company does (for example, companies that develop and sell a software product) - this can also be used to exclude descriptions of companies you do not want to contact (for example, exclude companies that provide IT consulting services)`,
    tags: [FAQTag.CAMPAIGN],
  },
  {
    question: "How do I edit templates?",
    answer: `Each Kular campaign will send a messaging sequence using the templates contained within that campaign. These templates can be viewed in the ‘messaging’ section for each campaign.

From here, you can edit the templates to alter their content by clicking the ‘edit’ button on the top right of the message you would like to edit.

You can enter text, or any of the placeholders which are provided in a menu, which can be accessed by clicking ‘placeholder’ in the top left of the template you are editing.

Once you have made your changes and are happy with your messaging, click ‘save’ on the bottom left of the message you are editing. This new messaging will be applied to all emails sent after you have saved your edits.

Please note that messages which were sent before this time, but had not yet been replied to by the contact, may still come through to your conversations tab even after you have saved these changes.

If you would like to add another template to your messaging sequence, click the ‘add message’ button displayed below the last message in your sequence.

To delete a whole message from your sequence, click ‘delete’ in the top right corner of the message you would like to delete`,
    tags: [FAQTag.CAMPAIGN],
  },
  {
    question: "What can I put in my templates?",
    answer: `Kular templates can contain free text, and select placeholders, which are blocks that can be used to enter personalised information about the contact receiving the message such as their name, company name or job title.

Kular templates cannot contain videos, images, links or hyperlinks. This is because emails which contain these things are much more likely to be marked as spam, which negatively impacts your results.`,
    tags: [FAQTag.CAMPAIGN],
  },
  {
    question: "How do I follow up with leads/conversations?",
    answer: `Once a qualified lead is generated, there are three ways which you can respond to them. You can email support@kular.ai to select which 3 of these methods you would prefer.

1. Via the web app:
    1. go to the ‘conversations’ tab of your web app
    2. click on the lead you would like to reply to
    3. at the bottom of the chain, type the reply you would like to send to the contact in the field provided. You may add attachments with the ‘attach files’ button. You may also add emails to the chain via CC or BCC.
    4. click the ‘reply’ button in the lower centre of the page
2. Via CC:
    1. An email with the following text will be sent to the contact
    ”Thank you for your email. I'm CC'ing my primary inbox and will follow up shortly. Best,”
    2. This email will add the email address on your account as a CC recipient. You will then receive this email to your inbox, from which you can reply directly to the contact
3. Via forward
    1. support@kular.ai will send you a message containing the contact’s message, and their information
    2. Reply to this email with the reply you would like to send in response, and this message will be sent automatically to the contact`,
    tags: [FAQTag.CONVERSATIONS],
  },
  {
    question: "Can I add a custom signature?",
    answer: `At present Kular cannot add custom signatures. The system will add a professional sign off to all emails for you automatically.`,
    tags: [FAQTag.CAMPAIGN],
  },
  {
    question: "What are experiments and how do they work?",
    answer: `Kular runs sets of experiments to gather data and determine what will guarantee you the best results possible. This can include using certain types of sign offs, subject lines or varying the time emails are sent to contacts. The system will run these experiments in the background, and use the results to adjust your outreach so that you get the best possible results from Kular.

At present, these experiments can’t be disabled, but are something we monitor very closely, to guarantee your outreach is always professional as well as effective.`,
    tags: [FAQTag.CAMPAIGN],
  },
  {
    question: "How do replies work and how can I control these?",
    answer: `Once a contact replies to one of your messages in your campaign sequence, the sequence will stop, and Kular will take over handling replies. Kular will then reply to contacts’ messages, depending on what they have said, in order to try and convert as many leads as possible for you.

If you would like to provide messaging, attachments, or any other rules or guidance for Kular to use in handling your replies, you can email this to support@kular.ai and this will be applied to your account.

If you would prefer to handle replies yourself, email support@kular.ai to set up a rule to allow you to respond to all messages. This can be done via the web app, CCing or forwarding replies to you.`,
    tags: [FAQTag.CONVERSATIONS],
  },
  {
    question: "How do I start campaigns?",
    answer: `To activate a campaign, just click ‘start campaign’ in the top right of your campaign and Kular will automatically begin sending messages for you. If this is your first campaign, Kular will also build your mailboxes and begin warming them automatically, in which case you can expect your first messages to start sending after 48 hours.`,
    tags: [FAQTag.CAMPAIGN],
  },
  {
    question: "How do I stop campaigns and what happens if I do?",
    answer: `If you would like to stop a campaign:

1. log in to your web app
2. go to the campaigns tab
3. click on the campaign you would like to stop
4. click ‘stop campaign’ in the top right of the screen

If you pause one campaign, don’t worry, all your other campaigns will continue as normal and be unaffected.

If you would like to stop all campaigns, we’d love to hear from you to see how we can help improve your Kular experience. Please contact support@kular.ai, and we will be happy to assist however we can.

If all campaigns are paused and we don’t hear from you, we will close your account. This will mean all your mailboxes will be deleted. If any campaigns are later activated after your account is reactivated following deletion, your mailboxes will need to be rebuilt, meaning they will once again need 2 days to create, and 2 weeks thereafter to reach full volume, which could delay you getting awesome results from Kular.`,
    tags: [FAQTag.CAMPAIGN],
  },
  {
    question: "How can I control my spending with Kular?",
    answer: `Kular has a budget feature within the web app. This feature allows you to set a monthly budget. This budget will never be exceeded. Its similar Google, LinkedIn, or Facebook- tell us how much you want to spend, and we will deliver.

To set your budget:

1. Log in to your web app
2. go to your settings by clicking your name in the top right
3. scroll down to the budget section. Here you can enter how many leads you would like to receive per month, and this will be saved and also display the cost to you in $ per month`,
    tags: [FAQTag.PAYMENTS],
  },
  {
    question: "How do I add a payment method?",
    answer: `You can add a payment method via the Kular web app.

1. Log in to the web app
2. Go to settings by clicking on your name in the top right
3. go to the payment section. Here you can enter the details you would like to add. If you already have a payment method saved with us, a green check mark will appear and no payment fields will be displayed`,
    tags: [FAQTag.PAYMENTS],
  },
  {
    question: "How do I change my payment method?",
    answer:
      "If you would like to change your payment method, email support@kular.ai and we can send you a payment link which you can use to update your details.",
    tags: [FAQTag.PAYMENTS],
  },
  {
    question: "How do I create a new campaign?",
    answer: `If you would like to create a new campaign, you can do so via the web app:

1. Log in to the web app
2. In the campaigns tab, click ‘draft new campaign’ in the top left of the page
3. Fill in the information asked for in the form, which will ask you for information about who you would like to target, what you would like to offer them, and what your value proposition is
4. Click ‘draft campaign’
5. When it’s ready, your campaign will appear as a draft campaign in your web app, where you can make edits to the messaging, targeting, and when you are ready launch your new campaign`,
    tags: [FAQTag.CAMPAIGN],
  },
  {
    question: "Do you provide Open Rates?",
    answer: `We don't provide open rates for our campaigns. The 2 reasons for this are:
1. Tracking open rates requires adding a tracking link to our outreach, and adding these links makes it more likely the email will be marked as spam

2. Most email providers will automatically open emails before they are received by the users as part of their virus scanning procedure, and so these rates tend to be highly innaccurate.`,
    tags: [FAQTag.CAMPAIGN],
  },
  {
    question: "Is Kular GDPR compliant?",
    answer: `Kular takes data privacy compliance very seriously. To make sure we stay compliant with GDPR, we have taken the following steps:
We’ve taken advice on this from a major European law firm
We obtain contact details by buying them from publicly listed, GDPR compliant vendors who have large in-house privacy teams
You are only involved in the data flow once a contact has specifically opted in to future contact with you over email
We are proud to say that as a result, we have GDPR specialised lawyers as customers, and several customers have hired privacy attorneys to assess before signup up and then signed up
Please be aware we are not a law firm and we do not provide legal advice.`,
    tags: [FAQTag.PRIVACY],
  },
  {
    question: "Is Kular CANSPAM compliant?",
    answer: `We include unsubscribe language in each email sent. 
For example: “if you don’t want to hear from me again, just reply saying unsubscribe”.

Please be aware we are not a law firm and we do not provide legal advice.`,
    tags: [FAQTag.PRIVACY],
  },
  {
    question: "Does Kular comply with attorney ethics rules?",
    answer: `The professional ethics rules to which attorneys are subject vary according the jurisdiction in which an attorney practices, and the professional association which regulates that attorney.
Kular has conducted detailed research on the professional ethics rules in key jurisdictions, including most states in the United States and the United Kingdom.

Please reach out to Kular Support at support@kular.ai if you would like to read Kular’s analysis of the professional ethics rules in any particular jurisdiction, and how they related to Kular.

Please be aware Kular is not a law firm: we are not regulated by bar associations or similar organisations, and we do not provide legal advice. You should conduct your own research before relying on our analysis.`,
    tags: [FAQTag.PRIVACY],
  },
  {
    question: "Does Kular offer account management or other additional support?",
    answer: `Our customer success team is always here to help our users, providing account management services including feedback calls, account monitoring and campaign targeting for all customers who have a minimum budget of $500 per month.
    
For users who set a monthly cap of $1000 or more per month Kular provides a dedicated managed service. This means we will, as well as providing you with full access to our customer success team, add you to a slack or whatsapp chat that gives you faster and more direct access to the team to help manage your account and ensure delivery of the volume you have requested. We also provide additional support with campaign suggestions and message drafting.
    `,
    tags: [FAQTag.ACCOUNT],
  },
  {
    question: "Can you transfer domains back to me?",
    answer: `As part of the Kular service, we purchase a set of domains, which we then use to send your email outreach.

Kular can transfer these domains once your services with Kular are cancelled. We cannot transfer domains to you whilst you are still using the service, as once the domains are transferred, our outreach system can no longer connect to the domains, and therefore cannot send any emails for you.

If this is something you would like, please email support@kular.ai stating that you would like the domains to be transferred at any time during the cancellation process, and we will arrange for the transfer of domains to you.`,
    tags: [FAQTag.ACCOUNT],
  },
  {
    question: "Can you transfer domains between accounts on Kular?",
    answer: `Currently it is not possible to transfer domains from one Kular account to another`,
    tags: [FAQTag.ACCOUNT],
  },
  {
    question:
      "We’d like more people on our team to use your product. What’s the best way to do this?",
    answer: `Currently there are two ways you can add more people from your team to Kular:

1. Add team member as a user to your Kular account
This allows your team members to log in to your Kular account and perform any actions the primary user can (for example, edit messaging or activate campaigns).
If you would like to do this, just send an email to support@kular.ai and we can set up your team members as users for your Kular account

2. Create separate Kular accounts for your team member
This will set up a completely separate account for your team member, where they can run their own campaigns, with their own sender information, completely independently of your own Kular outreach.
If you would like to do this, you can send an email to support@kular.ai and we can set up a new account for your team member`,
    tags: [FAQTag.ACCOUNT],
  },
  {
    question: "How do I add entries to my blacklist?",
    answer: `With Kular you can blacklist either an individual person via their email or LinkedIn profile URL, or a company via their website domain. This will mean these individuals and companies will not be messaged by Kular on your behalf.

This can be done in one of three ways:

1. In the Kular web app:
    a. Click on the ‘blacklist contacts’ button on the right hand side of the campaigns page
    b. Here you can view your blacklist, and add entries by clicking on the ‘add contact’ button in the top right
    c. Once you have filled in the information in the form, click ‘blacklist contact’ and this entry will be added
2. Upload a Google or Outlook CSV - you can find a tutorial for how to do this here: https://www.loom.com/share/4c93d0d7726b46afa59dd2da901750f3
3. Email support@kular.ai with a csv file containing the emails, LinkedIn URLs and/or the company domains you would like to blacklist`,
    tags: [FAQTag.CAMPAIGN, FAQTag.CONVERSATIONS],
  },
  {
    question: "When will my campaigns start sending emails?",
    answer: `Once you have paid the onboarding fee, Kular will automatically begin creating and warming your outreach mailboxes. This process takes up to 2 days. Once this has been complete, your first emails will start to be sent as soon as you have an active campaign.

    Your mailboxes will start with a small volume, that will gradually increase to full volume over the course of 2 weeks.
    
    This process only needs to be done once, so once set up is complete, all current and future campaigns will immediately send at maximum capacity.`,
    tags: [FAQTag.CAMPAIGN],
  },
  {
    question: "Can I see/choose my domains?",
    answer: `If you would like to be able to select the domains that Kular uses for your outreach, email support@kular.ai asking to be able to select your outreach domains. Make sure to do this before paying your onboarding fee, as otherwise the system will choose domains automatically for you.

A list will be sent of available domains, from which you can select 5. This will then be implemented once you activate your first campaign.

If you would like to see which domains Kular has acquired, email support@kular.ai requesting the list of your domains, and a list will be provided to you. We would always recommending whitelisting these domains in your mailbox, so you don’t miss any messages.`,
    tags: [FAQTag.ACCOUNT],
  },
  {
    question: "Where can I find your Terms of Service?",
    answer: `Kular's Terms of Service can be found [here](${config.TOS_URL})`,
    tags: [FAQTag.ACCOUNT],
  },
  {
    question: "Can I set up an alias for my sender?",
    answer: `Once you have paid your onboarding fee, Kular will automatically begin creating and warming your outreach mailboxes. During this process, Kular will by default use your account information to create the mailboxes.

    If you would like to use an alias for sending emails, email support@kular.ai before paying onboarding, including the name and title of the alias you would like to use, and this will be implemented when you activate your first campaign.`,
    tags: [FAQTag.ACCOUNT],
  },
  {
    question: "When will I be billed?",
    answer: `Kular will generate an invoice whenever a qualified lead enters your conversations tab. Once an invoice is generated, your stored payment method will then be charged and the invoice paid.

  If you don’t currently have a payment method added, we will email you once you have a qualified lead to ask for payment details, which can be added via the web app.
  
  You can view all invoices from your billing portal, which can be accessed from the payments page of your web app, which can be accessed from the settings page by clicking on your name in the top right. You can also view the invoice for each conversation, by clicking on the conversation, and then clicking on ‘view invoice’ in the top right.`,
    tags: [FAQTag.PAYMENTS],
  },
  {
    question: "How can I view my invoices/charged leads/refunds?",
    answer: `You can view your invoices from your billing portal. To access your billing portal:
1. log in to you kular web app
2. go to settings by clicking your name in the top right
3. scroll down and click on ‘Go to Payments’
4. in the payments page, click on 'Open Billing Portal'

If you would like to view the invoice for a particular conversation:
1. go to your conversations tab
2. click on the ‘view invoice’ button on the right hand side of the conversation you would like to see the invoice for`,
    tags: [FAQTag.PAYMENTS],
  },
  {
    question: "How do I follow up with leads/conversations?",
    answer: `Once a qualified lead is generated, there are three ways which you can respond to them. You can email support@kular.ai to select which 3 of these methods you would prefer.

1. Via the web app:
    a. go to the ‘conversations’ tab of your web app
    b. click on the lead you would like to reply to
    c. at the bottom of the chain, type the reply you would like to send to the contact in the field provided. You may add attachments with the ‘attach files’ button. You may also add emails to the chain via CC or BCC.
    d. click the ‘reply’ button in the lower centre of the page
2. Via CC:
    a. An email with the following text will be sent to the contact
    ”Thank you for your email. I'm CC'ing my primary inbox and will follow up shortly. Best,”
    b. This email will add the email address on your account as a CC recipient. You will then receive this email to your inbox, from which you can reply directly to the contact. Please make sure to check your spam, in case the email landed there.
3. Via forward
    a. support@kular.ai will send you a message containing the contact’s message, and their information. Please make sure to check your spam, in case the email landed there.
    b. Reply to this email with the reply you would like to send in response, and this message will be sent automatically to the contact`,
    tags: [FAQTag.CONVERSATIONS],
  },
  {
    question: "How do I start campaigns?",
    answer: `To activate a campaign, just click ‘start campaign’ in the top right of your campaign and Kular will automatically begin sending messages for you.

If you have recently onboarded with us, there may be a delay on outreach starting, depending on when your infrastructure is set up following payment of your onboarding fee.`,
    tags: [FAQTag.CAMPAIGN],
  },
  {
    question: "How can I control my spending with Kular?",
    answer: `Kular has a budget feature within the web app. This feature allows you to set a monthly budget. This budget will never be exceeded. Its similar to Google, LinkedIn, or Facebook- tell us how much you want to spend, and we will deliver.

To set your budget:

1. Log in to your web app
2. go to your settings by clicking your name in the top right
3. scroll down to the payment section and click go to payments. Here you can enter how many leads you would like to receive per month, and this will be saved and also display the cost to you in $ per month
4. Budgets cannot be less than $500 per month`,
    tags: [FAQTag.PAYMENTS],
  },
  {
    question: "How do I add a payment method?",
    answer: `You can add a payment method via the Kular web app.

1. Log in to the web app
2. Go to settings by clicking on your name in the top right
3. go to the payment section. Here you can enter the details you would like to add. If you already have a payment method saved with us, a green check mark will appear and no payment fields will be displayed
4. Once your details are entered, the system will update with these details. Please note it may take a few minutes for the web app to update the display to show a payment method has been added. If you have any questions, please don’t hesitate to email support@kular.ai`,
    tags: [FAQTag.PAYMENTS],
  },
  {
    question: "Does Kular offer account management or other additional support?",
    answer: `Our customer success team is always here to help our users, providing account management services including feedback calls, account monitoring and campaign targeting for all customers who have a minimum budget of $500 per month.

For users who set a monthly budget of $1000 or more per month Kular provides a dedicated managed service. This means we will, as well as providing you with full access to our customer success team, add you to a slack or whatsapp chat that gives you faster and more direct access to the team to help manage your account and ensure delivery of the volume you have requested. We also provide additional support with campaign suggestions and message drafting.

For users who want to take a more hands off approach, you can also turn on auto pilot mode. Once you do, you will be asked to provide your ideal customer profile. This should be as broad as possible, as this will serve as the full scope for our team. Once this is entered, we will create and launch campaigns for you, so you only have to step in when leads are generated.`,
    tags: [FAQTag.ACCOUNT],
  },
  {
    question: "Can you transfer domains back to me?",
    answer: `As part of the Kular service, we purchase a set of domains, which we then use to send your email outreach.

Kular can transfer these domains once your services with Kular are cancelled. We cannot transfer domains to you whilst you are still using the service, as once the domains are transferred, our outreach system can no longer connect to the domains, and therefore cannot send any emails for you.

If this is something you would like, you can email [support@kular.ai](mailto:support@kular.ai) to request a domain transfer, or you can book in with our team for a domain transfer call here: https://meetings.hubspot.com/tom-jeffrey

Please note if you book in directly via the calendar link above, once the call is complete your account will be closed.`,
    tags: [FAQTag.ACCOUNT],
  },
  {
    question: "Where will my emails be sent from?",
    answer: `The emails are sent from you. We set up new inboxes for you to send the emails for 2 reasons:
1. So they don't go to spam
2. To protect security/reputation of your primary email address`,
    tags: [FAQTag.ACCOUNT],
  },
  {
    question: "What is Autopilot mode?",
    answer: `Auto pilot mode asks Kular to manage your account. Our team will do everything required to deliver your target leads, and you can sit back and watch the leads role in.`,
    tags: [FAQTag.ACCOUNT],
  },
];

export default function FAQPage() {
  // Search
  const [searchText, setSearchText] = useState<string>("");
  const [selectedTag, setSelectedTag] = useState<FAQTag | null>(null);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const filteredFAQs = FAQs.filter((faq) => {
    const faqSeachText = `${faq.question} ${faq.answer} ${faq.tags.join(" ")}`;

    const filters = [
      searchText
        ? faqSeachText.toLowerCase().includes(searchText.toLowerCase())
        : true,
      selectedTag ? faq.tags.includes(selectedTag) : true,
    ];

    return filters.every(Boolean);
  });

  // Show tags and a link to expand intercom if there are no search results
  const foundNoResults = filteredFAQs.length === 0;

  const handleSearch = (searchText: string) => {
    setSearchText(searchText);
    setExpandedIndex(0);
  };

  const handleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleTagSelect = (tag: FAQTag | null) => {
    setSearchText("");
    setSelectedTag(tag);
  };

  const handleShowIntercom = () => {
    // Show intercom, click .intercom-launcher
    if (window.Intercom) {
      window.Intercom("show");
    }
  };

  return (
    <Page hideTodoList>
      <PageContent>
        <Typography variant="h5" color="text.primary">
          Frequently Asked Questions
        </Typography>
        <Box mt={2} />
        <TextField
          fullWidth
          variant="outlined"
          label="How can we help you?"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        />
        {foundNoResults && (
          <Stack mt={4} direction="column" spacing={2} alignItems="flex-start">
            <Stack
              mt={2}
              direction="column"
              spacing={1}
              alignItems="flex-start"
            >
              <Typography variant="subtitle1" color="primary.main">
                No results found
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Try a different search or select a category to see more results
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle2" color="text.secondary">
                  Categories:
                </Typography>
                {Object.values(FAQTag).map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    variant={selectedTag === tag ? "filled" : "outlined"}
                    color={"primary"}
                    onClick={() =>
                      selectedTag === tag
                        ? handleTagSelect(null)
                        : handleTagSelect(tag)
                    }
                  />
                ))}
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="column" spacing={1} alignItems="flex-start">
              <Typography variant="body1" color="text.secondary">
                If you still can't find what you're looking for, please contact
                us.
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleShowIntercom}
              >
                Contact us
              </Button>
            </Stack>
          </Stack>
        )}

        {selectedTag && !foundNoResults && (
          <Box mt={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle2" color="text.secondary">
                Showing results for:
              </Typography>
              <Chip
                label={selectedTag}
                variant="outlined"
                color={"primary"}
                deleteIcon={<CancelIcon fontSize="small" />}
                onDelete={() => handleTagSelect(null)}
              />
            </Stack>
          </Box>
        )}
        <Box mt={2} />
        {filteredFAQs.map((faq, index) => (
          <Accordion
            elevation={1}
            key={index}
            expanded={expandedIndex === index}
            onChange={() => handleExpand(index)}
          >
            <AccordionSummary>
              <Typography variant="subtitle1" color="text.primary" fontWeight={500}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="column" spacing={2} alignItems="flex-start">
                <MuiMarkdown>{faq.answer}</MuiMarkdown>
                <Stack direction="row" spacing={1}>
                  {faq.tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      variant="outlined"
                      onClick={() => handleTagSelect(tag)}
                    />
                  ))}
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
        <Box mt={2} />
      </PageContent>
    </Page>
  );
}
