// Conversations Context

import {
  useGetConversationContactIdsLazyQuery,
  useGetConversationMembersLazyQuery,
  useGetTotalConversationsLazyQuery,
  useGetUnreadConversationsLazyQuery,
} from "@/graphql/generated";
import { CampaignMember, DISPLAYED_VISIBILITY_STATES_GRAPHQL } from "@/models";
import { ConversationSearchFilters } from "@pages/conversations/conversations/ConversationSearch";
import { normalizeGraphqlResult } from "@utils/graphql";
import { UUID } from "@utils/text";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppContext } from "./AppContext";
import { useCampaignsContext } from "./CampaignsContext";

const CONVERSATIONS_PAGE_SIZE = 20;

type ConversationsContextProps = {
  conversations: Array<CampaignMember> | null;
  contactIds: Array<UUID> | null;
  isLoading: boolean;
  totalPages: number;
  totalConversations?: number | null;
  unreadConversations?: number | null;
  page: number;
  fetchConversations: () => void;
  fetchTotalConversations: () => void;
  fetchUnreadConversations: () => void;
  setPage: (page: number) => void;
  setEntityFilters: (filters: ConversationSearchFilters | null) => void;
};

const initialValues: ConversationsContextProps = {
  conversations: null,
  contactIds: null,
  isLoading: true,
  totalPages: 0,
  totalConversations: null,
  unreadConversations: null,
  page: 1,
  fetchTotalConversations: () => {},
  fetchUnreadConversations: () => {},
  fetchConversations: () => {},
  setPage: () => {},
  setEntityFilters: () => {},
};

const ConversationsContext =
  createContext<ConversationsContextProps>(initialValues);

export type ConversationsProviderProps = {
  children: React.ReactNode;
  campaignId?: UUID;
};

export const ConversationsProvider = ({
  children,
  campaignId,
}: ConversationsProviderProps) => {
  // Get Profile
  const { lawyerId } = useAppContext();

  // All Campaign ids
  const { campaignIds: allCampaignIds } = useCampaignsContext();

  // All Contact ids
  const [allContactIds, setAllContactIds] = useState<Array<UUID> | null>(null);

  // State
  const [state, setState] = useState<ConversationsContextProps>(initialValues);

  // Pagination
  const [page, setPage] = useState<number>(1);

  // Entity Filters
  const [entityFilters, setEntityFilters] =
    useState<ConversationSearchFilters | null>(null);

  // Filtered contact ids
  const filteredContactIds = useMemo(() => {
    if (!entityFilters) {
      return null;
    }

    return entityFilters?.contacts?.map((contact) => contact.id) || [];
  }, [entityFilters]);

  // Get conversation contactids
  const [getConversationContactIds, { data: conversationContactIds }] =
    useGetConversationContactIdsLazyQuery();

  const [getTotalConversations, { data: totalConversationsResponse }] =
    useGetTotalConversationsLazyQuery();

  // Get unread conversations
  const [getUnreadConversations, { data: unreadConversationsResponse }] =
    useGetUnreadConversationsLazyQuery();

  // Get conversation members
  const [
    getConversationMembers,
    { data: conversationMembers, loading: isLoadingConversations },
  ] = useGetConversationMembersLazyQuery();

  const conversations = useMemo(() => {
    return (
      (normalizeGraphqlResult(conversationMembers)
        ?.campaignMembers as Array<CampaignMember>) || null
    );
  }, [conversationMembers]);

  const currentCampaignIds = useMemo(() => {
    return campaignId ? [campaignId] : allCampaignIds;
  }, [campaignId, allCampaignIds]);

  const currentContactIds = useMemo(() => {
    return filteredContactIds || allContactIds;
  }, [filteredContactIds, allContactIds]);

  // Set total conversations, unread conversations, and contact ids
  const [totalConversations, setTotalConversations] = useState<number | null>(
    null
  );
  const [unreadConversations, setUnreadConversations] = useState<number | null>(
    null
  );

  // Update total conversations
  useEffect(() => {
    const newTotalConversations =
      totalConversationsResponse?.campaignMembers?.totalCount;

    if (totalConversations !== newTotalConversations && newTotalConversations) {
      setTotalConversations(newTotalConversations);
    }
  }, [totalConversationsResponse, totalConversations]);

  // Update unread conversations
  useEffect(() => {
    const newUnreadConversations =
      unreadConversationsResponse?.campaignMembers?.totalCount;

    if (
      unreadConversations !== newUnreadConversations &&
      newUnreadConversations
    ) {
      setUnreadConversations(newUnreadConversations);
    }
  }, [unreadConversationsResponse]);

  // Update contact ids
  useEffect(() => {
    const newContactIds = conversationContactIds?.campaignMembers?.nodes?.map(
      (member) => member?.contactId as UUID
    );

    if (allContactIds?.length !== newContactIds?.length && newContactIds) {
      setAllContactIds(newContactIds);
    }
  }, [conversationContactIds]);

  // Fetch total conversations
  const fetchTotalConversations = useCallback(() => {
    if (totalConversations) {
      return;
    }
    if (!lawyerId) {
      return;
    }
    if (!currentCampaignIds?.length) {
      return;
    }

    getTotalConversations({
      variables: {
        campaignIds: currentCampaignIds,
        visibilities: DISPLAYED_VISIBILITY_STATES_GRAPHQL,
      },
    });
  }, [totalConversations, currentCampaignIds, lawyerId]);

  // Fetch unread conversations
  const fetchUnreadConversations = useCallback(() => {
    if (!lawyerId) {
      return;
    }

    if (!currentCampaignIds?.length) {
      return;
    }

    getUnreadConversations({
      variables: {
        campaignIds: currentCampaignIds,
        visibilities: DISPLAYED_VISIBILITY_STATES_GRAPHQL,
      },
    });
  }, [currentCampaignIds, lawyerId]);

  // Fetch conversations
  const fetchConversations = useCallback(() => {
    if (!lawyerId) {
      return;
    }

    const first = CONVERSATIONS_PAGE_SIZE;
    const offset = (page - 1) * first;

    if (!currentCampaignIds?.length) {
      return;
    }

    getConversationContactIds({
      variables: {
        campaignIds: currentCampaignIds,
        visibilities: DISPLAYED_VISIBILITY_STATES_GRAPHQL,
      },
    });

    if (!currentContactIds) {
      return;
    }

    getConversationMembers({
      variables: {
        campaignIds: currentCampaignIds,
        contactIds: currentContactIds,
        visibilities: DISPLAYED_VISIBILITY_STATES_GRAPHQL,
        first,
        offset,
      },
    });
  }, [currentCampaignIds, currentContactIds, lawyerId, page]);

  // Total pages
  const totalPages = useMemo(() => {
    if (!totalConversations) {
      return 0;
    }

    return Math.ceil(totalConversations / CONVERSATIONS_PAGE_SIZE);
  }, [totalConversations]);

  // Is loading
  const isLoading = useMemo(() => {
    return isLoadingConversations; //|| isGettingConversationsSummary;
  }, [isLoadingConversations]);

  // Keep things updated
  useEffect(() => {
    setState({
      conversations,
      contactIds: allContactIds,
      isLoading,
      totalConversations,
      unreadConversations,
      totalPages,
      page,
      fetchTotalConversations,
      fetchUnreadConversations,
      fetchConversations,
      setPage,
      setEntityFilters,
    });
  }, [
    conversations,
    isLoading,
    totalConversations,
    unreadConversations,
    totalPages,
    allContactIds,
  ]);

  return (
    <ConversationsContext.Provider
      value={{
        ...state,
        fetchTotalConversations,
        fetchUnreadConversations,
        fetchConversations,
        setPage,
        setEntityFilters,
      }}
    >
      {children}
    </ConversationsContext.Provider>
  );
};

export const useConversationsContext = () => useContext(ConversationsContext);
