import { ROUTE_MEETINGS_AUTHENTICATE } from "@/AuthenticatedAppRoutes";
import { Box, Button, Stack, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo";

interface CalendarConnectDialogProps {
  open: boolean;
  textContent?: string;
}

const CalendarConnectDialog: React.FC<CalendarConnectDialogProps> = ({
  open,
  textContent,
}) => {
  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>
          <Stack
            direction="column"
            spacing={2}
            color="primary.main"
            alignItems={"flex-start"}
          >
            <Logo height={20} />
            <Typography
              variant="h6"
              color={"text.primary"}
              gutterBottom={false}
            >
              Connect your calendar
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" color={"text.primary"}>
            {textContent ||
              "As you prefer to pay for your meetings, we need to connect to your calendar to schedule them."}
          </Typography>
          <Box mt={2} />
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={ROUTE_MEETINGS_AUTHENTICATE}
          >
            Connect your calendar
          </Button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default CalendarConnectDialog;
