import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

/* agenda, account_management, availability, managed_availability */
export enum CronofyElementPermission {
  AGENDA = "agenda",
  ACCOUNT_MANAGEMENT = "account_management",
  AVAILABILITY = "availability",
  MANAGED_AVAILABILITY = "managed_availability",
}

type GetCronofyElementTokenParams = {
  lawyerId: UUID;
  permissions: CronofyElementPermission[];
};

export const getCronofyElementToken = async ({
  lawyerId,
  permissions,
}: GetCronofyElementTokenParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      permissions,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/meetings/ui-element-token`,
    options
  );

  return response;
};

export type GetCronofyElementTokenResponse = {
  token: string;
  subs: string[];
};

export const useGetCronofyElementToken = () =>
  useServiceCall<GetCronofyElementTokenResponse, GetCronofyElementTokenParams>({
    serviceCall: getCronofyElementToken,
  });
