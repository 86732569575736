import { Profile } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

export type ProfileCreate = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  userId?: string;
  lawyerId?: UUID;
};

export const createProfile = async ({
  firstName,
  lastName,
  userId,
  email,
  phoneNumber,
}: ProfileCreate) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      firstName,
      lastName,
      userId,
      email,
      phoneNumber,
    },
  };

  const response = await fetchOutreachService(`/profiles`, options);

  return response;
};

export const useCreateProfile = () =>
  useServiceCall<Profile, ProfileCreate>({
    serviceCall: createProfile,
    successMessage: "Profile created successfully",
  });
