import { useGetCampaignMemberLazyQuery } from "@/graphql/generated";
import {
  CampaignChannel,
  CampaignMember,
  MeetingFeedbackRating,
} from "@/models";
import { TrackedEventName } from "@/third-party/tracking";
import Tracked from "@components/Tracked";
import palette from "@design/palette";
import { Box, Button, Chip, Rating, Stack, TextField } from "@mui/material";
import { useCreateEmailMemberFeedback } from "@services/members/createEmailMemberFeedback";
import { useCreateLinkedinMemberFeedback } from "@services/members/createLinkedinMemberFeedback";
import { usePatchEmailMemberFeedback } from "@services/members/patchEmailMemberFeedback";
import { usePatchLinkedinMemberFeedback } from "@services/members/patchLinkedinMemberFeedback";
import { normalizeGraphqlResult } from "@utils/graphql";
import { UUID } from "@utils/text";
import { useEffect, useMemo, useRef, useState } from "react";
import { RotatingLines } from "react-loader-spinner";

const FEEDBACK_TO_RATING: {
  [key: string]: number;
} = {
  VERY_BAD: 1,
  BAD: 2,
  OK: 3,
  GOOD: 4,
  VERY_GOOD: 5,
};

const RATING_TO_FEEDBACK: {
  [key: number]: MeetingFeedbackRating;
} = {
  1: "VERY_BAD",
  2: "BAD",
  3: "OK",
  4: "GOOD",
  5: "VERY_GOOD",
};

const RATING_TO_LABEL: {
  [key: number]: string;
} = {
  1: "Don't send me leads like this",
  2: "Send me fewer leads like this",
  3: "This lead is ok",
  4: "Send me more leads like this",
  5: "This is my perfect lead",
};

type ConversationFeedbackProps = {
  withNote?: boolean;
  withRatingLabel?: boolean;
  campaignMemberId: UUID;
};
const ConversationFeedback = ({
  withNote = false,
  withRatingLabel = false,
  campaignMemberId,
}: ConversationFeedbackProps) => {
  const [
    getCampaigMember,
    { data: campaignMemberResponse, refetch: refetchCampaignMember },
  ] = useGetCampaignMemberLazyQuery();

  const campaignMember = useMemo(() => {
    if (!campaignMemberResponse) {
      return null;
    }

    return normalizeGraphqlResult(campaignMemberResponse)
      .campaignMember as CampaignMember;
  }, [campaignMemberResponse]);

  useEffect(() => {
    if (!campaignMemberId) {
      return;
    }

    getCampaigMember({ variables: { campaignMemberId } });
  }, [campaignMemberId]);

  // Rating
  const [rating, setRating] = useState<number | null>(null);
  // Note
  const [note, setNote] = useState<string>("");

  const feedback = useMemo(() => {
    if (!campaignMember) {
      return null;
    }

    return campaignMember?.feedbacks?.[0];
  }, [campaignMember, rating, note]);

  // Initialize rating and note
  useEffect(() => {
    if (rating || note) {
      return;
    }

    if (feedback) {
      const { rating, note } = feedback;
      setRating(FEEDBACK_TO_RATING[rating]);
      setNote(note);
    }
  }, [feedback]);

  // Handle Note popover
  const ref = useRef(null);

  // Create and update feedbacks.
  const [createEmailFeedback, createEmailFeedbackContext] =
    useCreateEmailMemberFeedback();
  const [patchEmailFeedback, patchEmailFeedbackContext] =
    usePatchEmailMemberFeedback();
  const [createLinkedinFeedback, createLinkedinFeedbackContext] =
    useCreateLinkedinMemberFeedback();
  const [patchLinkedinFeedback, patchLinkedinFeedbackContext] =
    usePatchLinkedinMemberFeedback();

  const handleOnFeedback = async () => {
    if (!campaignMember) {
      return;
    }
    if (!rating) {
      return;
    }

    // Update feedback
    const feedbackPayload = {
      campaignId: campaignMember.campaignId as string,
      memberId: campaignMember.id as string,
      rating: RATING_TO_FEEDBACK[rating],
      note: note,
    };

    if (feedback) {
      const updatePayload = {
        ...feedbackPayload,
        feedbackId: feedback.id as string,
      };

      if (campaignMember.campaign?.channel === CampaignChannel.EMAIL) {
        await patchEmailFeedback(updatePayload);
      } else if (
        campaignMember.campaign?.channel === CampaignChannel.LINKEDIN
      ) {
        await patchLinkedinFeedback(updatePayload);
      }
    } else {
      // Create feedback
      if (campaignMember.campaign?.channel === CampaignChannel.EMAIL) {
        await createEmailFeedback(feedbackPayload);
      } else if (
        campaignMember.campaign?.channel === CampaignChannel.LINKEDIN
      ) {
        await createLinkedinFeedback(feedbackPayload);
      }
    }
    refetchCampaignMember();
  };

  // Check if we are busy
  const contextes = [
    createEmailFeedbackContext,
    patchEmailFeedbackContext,
    createLinkedinFeedbackContext,
    patchLinkedinFeedbackContext,
  ];

  const isBusy = useMemo(() => {
    return contextes.some((context) => context.loading);
  }, [contextes]);

  const isUpdated = useMemo(() => {
    return createEmailFeedbackContext?.data || patchEmailFeedbackContext?.data;
  }, [createEmailFeedbackContext?.data, patchEmailFeedbackContext?.data]);

  // Refetch conversation if we have updated
  useEffect(() => {
    if (isUpdated) {
      //fetchConversation?.();
    }
  }, [isUpdated]);

  // Close dropdown if we are not busy and has updated
  // useEffect(() => {
  //   if (feedback?.rating !== RATING_TO_FEEDBACK[rating]) {
  //     handleOnFeedback();
  //   }
  // }, [rating]);

  // Handle rating change
  const handleRatingChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: number | null
  ) => {
    e.stopPropagation();

    if (!value) {
      return;
    }

    handleOnFeedback();
  };

  return campaignMember ? (
    <Box>
      {isBusy ? (
        <Box p={1}>
          <RotatingLines
            strokeColor={palette.primary.main}
            strokeWidth="5"
            animationDuration="0.75"
            width="20"
            visible={true}
          />
        </Box>
      ) : (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Tracked
            onClick={{
              name: TrackedEventName.CONVERSATION_RATED,
            }}
          >
            <Rating
              disabled={isBusy}
              ref={ref}
              value={rating || 0}
              onChange={() => handleOnFeedback()}
              onChangeActive={(e, value) => {
                if (!value || value === -1) {
                  return setRating(
                    feedback?.rating ? FEEDBACK_TO_RATING[feedback?.rating] : 0
                  );
                } else {
                  setRating(value);
                }
              }}
            />
          </Tracked>
          {!!(withRatingLabel && rating) && (
            <Box color="primary" pb={0.8}>
              <Chip
                size="small"
                label={RATING_TO_LABEL[rating]}
                color="primary"
                variant="filled"
              />
            </Box>
          )}
        </Stack>
      )}
      {withNote && (
        <Box textAlign={"left"} mt={1}>
          <TextField
            fullWidth
            value={note}
            disabled={isBusy}
            multiline
            rows={2}
            onChange={(e) => setNote(e.target.value)}
          />
          <Tracked onClick={{ name: TrackedEventName.CONVERSATION_NOTE_ADDED }}>
            <Button
              disabled={!note || isBusy}
              variant="contained"
              color="primary"
              onClick={() => {
                handleOnFeedback();
              }}
              sx={{
                mt: 2,
              }}
            >
              Save Feedback
            </Button>
          </Tracked>
        </Box>
      )}
    </Box>
  ) : null;
};

export default ConversationFeedback;
