import {
  useGetCampaignConversionStatsLazyQuery,
  useGetTemplateConversionStatsLazyQuery,
} from "@/graphql/generated";
import { Campaign } from "@/models";
import { useCampaignsContext } from "@context/CampaignsContext";

import { Box, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import ConversionsTable from "../../components/statistics/ConversionsTable";
import Demographics from "../../components/statistics/Demographics";
import OutreachChart from "../../components/statistics/OutreachChart";

const calculateConversionPerformance = (
  value: number,
  average: number,
  stdDeviation: number
): number | null => {
  if (!value || !average || !stdDeviation) {
    return null;
  }

  return ((value - average) / stdDeviation) * 100;
};

const preparePerformancesData = (
  campaigns: Campaign[],
  campaignAggregates: any,
  templateAggregates: any
) => {
  const averageCampaignConversion =
    campaignAggregates?.average?.conversionScore;
  const stdDeviationCampaignConversion =
    campaignAggregates?.stddevPopulation?.conversionScore;
  const averageTemplateConversion =
    templateAggregates?.average?.conversionScore;
  const stdDeviationTemplateConversion =
    templateAggregates?.stddevPopulation?.conversionScore;

  const campaignPerformances = campaigns
    .map((campaign) => {
      return {
        id: campaign.id,
        name: campaign.name,
        conversionScore: campaign.conversionScore,
        status: campaign.status,
        performance: calculateConversionPerformance(
          campaign.conversionScore,
          averageCampaignConversion,
          stdDeviationCampaignConversion
        ),
        templates: campaign.templates?.map((template) => {
          return {
            id: template.id,
            orderIndex: template.orderIndex,
            conversionScore: template.conversionScore,
            content: template.content,
            campaign: campaign,
            name: `Message ${template.orderIndex + 1} - ${campaign.name}`,
            performance: calculateConversionPerformance(
              template.conversionScore,
              averageTemplateConversion,
              stdDeviationTemplateConversion
            ),
          };
        }),
      };
    })
    .filter(
      (campaign) => campaign.templates.length > 0 && !!campaign.performance
    );

  const templatePerformances = campaignPerformances.reduce(
    (acc: Array<any>, campaign: any) => {
      return acc.concat(campaign.templates);
    },
    []
  );

  return {
    campaigns: campaignPerformances,
    templates: templatePerformances,
  };
};

const OverallStatistics = () => {
  // Get campaign and template conversion stats
  const [getCampaignConversionStats, { data: campaignConversionStats }] =
    useGetCampaignConversionStatsLazyQuery();
  const [getTemplateConversionStats, { data: templateConversionStats }] =
    useGetTemplateConversionStatsLazyQuery();

  useEffect(() => {
    getCampaignConversionStats();
    getTemplateConversionStats();
  }, [getCampaignConversionStats, getTemplateConversionStats]);

  useEffect(() => {
    getCampaignConversionStats();
    getTemplateConversionStats();
  }, [getCampaignConversionStats, getTemplateConversionStats]);

  // Get all campaigns
  const { campaigns } = useCampaignsContext();

  if (!campaigns) {
    return null;
  }

  // Order campaigns by conversion score
  campaigns.sort((a: Campaign, b: Campaign) => {
    return b.conversionScore - a.conversionScore;
  });

  const campaignAggregates = campaignConversionStats?.campaigns?.aggregates;
  const templateAggregates = templateConversionStats?.templates?.aggregates;

  // Campaign rows
  const { campaigns: campaignsWithPerformances, templates: templateRows } =
    preparePerformancesData(campaigns, campaignAggregates, templateAggregates);

  return (
    <Stack spacing={2}>
      <Stack
        spacing={2}
        direction={{
          xs: "column",
          lg: "row",
        }}
        justifyContent={"space-between"}
      >
        <Box width={{ xs: "100%", lg: "48%" }}>
          <Box mb={2}>
            <Typography variant="h6">Daily Volume</Typography>
            <Typography variant="body2" color="textSecondary">
              Number of emails sent each day to new contacts
            </Typography>
          </Box>
          <OutreachChart />
        </Box>
        <Box width={{ xs: "100%", lg: "48%" }}>
          <Box mb={4}>
            <Typography variant="h6">Campaign Performance</Typography>
            <Typography variant="body2" color="textSecondary">
              Understand which campaigns are converting best, and which
              campaigns need improving.
            </Typography>
          </Box>
          <ConversionsTable campaigns={campaignsWithPerformances} />
        </Box>
      </Stack>
      <Box>
        <Typography variant="h6">Demographics</Typography>
        <Typography variant="body2" color="textSecondary">
          Demographics of the prospects of your campaigns
        </Typography>
      </Box>
      <Demographics />
    </Stack>
  );
};

export default OverallStatistics;
