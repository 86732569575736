import { BlacklistedOutreach } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

type CreateBlacklistedOutreachParams = {
  lawyerId: string;
  email?: string | null;
  emailDomain?: string | null;
  linkedinUrl?: string | null;
  reason?: string | null;
};

export const createBlacklistedOutreach = async ({
  lawyerId,
  email,
  emailDomain,
  linkedinUrl,
  reason,
}: CreateBlacklistedOutreachParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      email,
      emailDomain,
      linkedinUrl,
      reason,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/blacklist`,
    options
  );

  return response;
};

export const useCreateBlacklistedOutreach = () =>
  useServiceCall<BlacklistedOutreach, CreateBlacklistedOutreachParams>({
    serviceCall: createBlacklistedOutreach,
    successMessage: "Successfully added to blacklist",
  });
