import { useCampaigmMemberMeetingContext } from "@context/CampaigmMemberMeetingContext";
import {
  CronofyElementProvider,
  useCronofyElementContext,
} from "@context/CronofyElementContext";
import { Stack } from "@mui/material";
import { CronofyElementPermission } from "@services/meetings/getCronofyElementToken";
import * as CronofyElements from "cronofy-elements";
import { DateTime } from "luxon";
import { useEffect } from "react";

export interface BookMeetingProps {
  onDateSelect: (start: string, end: string, timezone: string) => void;
}
export default function BookMeeting({ onDateSelect }: BookMeetingProps) {
  const { lawyer } = useCampaigmMemberMeetingContext();

  return !!lawyer?.id ? (
    <CronofyElementProvider
      lawyerId={lawyer?.id}
      permissions={[
        CronofyElementPermission.AVAILABILITY,
        CronofyElementPermission.MANAGED_AVAILABILITY,
        CronofyElementPermission.ACCOUNT_MANAGEMENT,
        CronofyElementPermission.AGENDA,
      ]}
    >
      <SlotPicker
        onPick={(start, end, timezone) => onDateSelect(start, end, timezone)}
      />
    </CronofyElementProvider>
  ) : null;
}

interface SlotPickerProps {
  onPick: (start: string, end: string, timezone: string) => void;
}

function SlotPicker({ onPick }: SlotPickerProps) {
  const { elementToken, subs } = useCronofyElementContext();
  const { contact } = useCampaigmMemberMeetingContext();
  const browserTzId = DateTime.local().zoneName;
  const tzId = contact?.timezone || browserTzId || "America/New_York";

  useEffect(() => {
    if (!elementToken) return;
    if (!subs) return;

    const element = CronofyElements.DateTimePicker({
      element_token: elementToken,
      target_id: "cronofy-date-time-picker",
      data_center: "uk",
      tzid: tzId,
      availability_query: {
        participants: [
          {
            required: "all",
            members: subs?.map((sub) => ({ sub })) || [],
          },
        ],
        required_duration: { minutes: 30 },
        query_periods: [
          { start: "2024-02-28T09:00:00Z", end: "2024-03-28T17:00:00Z" },
        ],
      },
      styles: {
        prefix: "custom-name",
      },
      callback: ({ notification }) => {
        if (notification.type !== "slot_selected") {
          return;
        }

        const slot = notification.slot;

        if (!slot) return;

        const start = slot.start;
        const end = slot.end;
        const timezone = slot.tzid;
        if (start && end) {
          onPick(start, end, timezone);
        }
      },
    });

    return () => {
      if (element?.destroy) {
        element?.destroy();
      }
    };
  }, [elementToken, subs]);

  return (
    <Stack
      alignItems={"center"}
      id="cronofy-date-time-picker"
      width={"100%"}
    ></Stack>
  );
}
