import { CampaignMemberMeetingStatus } from "@/models";
import InfoBox from "@components/InfoBox";
import { CheckIcon, MeetingIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import { useCampaigmMemberMeetingContext } from "@context/CampaigmMemberMeetingContext";
import { useConversationContext } from "@context/ConversationContext";
import { Alert, Chip, Stack, Typography } from "@mui/material";
import { getMeetingDisplayDate } from "@utils/date";

export interface ConversationMeetingProps {}
export default function ConversationMeeting({}: ConversationMeetingProps) {
  const { profile } = useAppContext();
  const { campaignMemberMeeting } = useCampaigmMemberMeetingContext();

  const { contactFullName } = useConversationContext();

  return campaignMemberMeeting?.status ===
    CampaignMemberMeetingStatus.CONFIRMED ? (
    <InfoBox>
      <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Stack direction={"column"} spacing={1} alignItems={"flex-start"}>
            <Chip
              icon={<CheckIcon fontSize="small" />}
              label={"Meeting Booked"}
              color={"success"}
              size={"medium"}
            />
            <Stack direction={"column"} spacing={0} alignItems={"flex-start"}>
              <Typography variant={"subtitle1"} color={"text.secondary"}>
                {contactFullName} has confirmed the meeting
              </Typography>
              <Typography variant={"body2"} color={"text.secondary"}>
                {getMeetingDisplayDate(
                  campaignMemberMeeting?.startTime,
                  campaignMemberMeeting?.endTime,
                  profile?.lawyer?.timezone
                )}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Alert
          severity="warning"
          variant="outlined"
          icon={<MeetingIcon color="error" />}
        >
          Remember to add a video conferencing link to your meeting in your
          calendar!
        </Alert>
      </Stack>
    </InfoBox>
  ) : null;
}
