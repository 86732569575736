import { ROUTE_BILLING, ROUTE_DASHBOARD } from "@/AuthenticatedAppRoutes";
import config from "@/config";
import {
  useGetProfileByUserIdLazyQuery
} from "@/graphql/generated";
import {
  LinkedinLoginStatus,
  PaymentMethod,
  Profile
} from "@/models";
import { TrackedEventName, alias } from "@/third-party/tracking";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useExponentialInterval } from "@hooks/useExponentialInterval";
import { useCanUseService } from "@services/lawyers/canUseService";
import { LawyerCreate, createLawyer } from "@services/lawyers/createLawyer";
import { useUpdateLawyer } from "@services/lawyers/updateLawyer";
import { CalendarProfile, useGetCalendarProfiles } from "@services/meetings/getCalendarProfiles";
import { useGetFreeLeads } from "@services/payments/getFreeLeads";
import { ProfileCreate, useCreateProfile } from "@services/profile/profile";
import {
  GetPaymentSubscriptionPriceResponse,
  useGetPaymentSubscriptionPrice,
} from "@services/subscription/getPaymentSubscriptionPrice";
import { isInternalEmail } from "@utils/email";
import { normalizeGraphqlResult } from "@utils/graphql";
import { UUID } from "@utils/text";
import { LDProvider } from "launchdarkly-react-client-sdk";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

type AppContextProps = {
  profile: Profile | null;
  lawyerId: UUID | null;
  lawyerPublicId: string | null;
  isLoading: boolean;
  isLinkedinAuthenticated: boolean;
  canUseService?: boolean | null;
  isCreatingProfile?: boolean;
  createdProfile: boolean;
  createProfileError: Error | null;
  subscription: GetPaymentSubscriptionPriceResponse | null;
  isCheckingSubscription?: boolean;
  highlightPaymentSetup?: boolean;
  isInternalProfile?: boolean;
  hasPaymentInformation?: boolean | null;
  avgChargedLeadsThisWeek?: number | null;
  freeLeads?: number;
  isPaused?: boolean;
  isPayG?: boolean;
  credits: number | null;
  isCalendarConnected?: boolean;
  hasCalendarProfiles?: boolean;
  calendarProfiles?: CalendarProfile[];
  isManaged?: boolean;
  profileCreate?: ProfileCreate | null;
  logOut?: () => void;
  createLawyer?: (lawyer: LawyerCreate) => void;
  setHighlightPaymentSetup?: (highlightPaymentSetup: boolean) => void;
  refreshCanUseService?: (useCache: boolean) => void;
  fetchProfile?: () => void;
  setIsLoading?: (isLoading: boolean) => void;
};

const initialValues: AppContextProps = {
  profile: null,
  isLoading: false,
  isLinkedinAuthenticated: false,
  canUseService: null,
  isCreatingProfile: false,
  createdProfile: false,
  createProfileError: null,
  subscription: null,
  isCheckingSubscription: false,
  highlightPaymentSetup: false,
  isInternalProfile: false,
  hasPaymentInformation: false,
  avgChargedLeadsThisWeek: 0,
  freeLeads: 0,
  isPaused: false,
  lawyerId: null,
  lawyerPublicId: null,
  isPayG: false,
  credits: null,
  isCalendarConnected: false,
  hasCalendarProfiles: false,
  calendarProfiles: [],
  isManaged: false,
  profileCreate: null,
  logOut: () => { },
  createLawyer: () => { },
  setHighlightPaymentSetup: () => { },
  refreshCanUseService: () => { },
  fetchProfile: () => { },
  setIsLoading: () => { },
};

const AppContext = createContext<AppContextProps>(initialValues);

enum AuthStatus {
  Configuring = "configuring",
  Authenticated = "authenticated",
  Unauthenticated = "unauthenticated",
}
export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<AppContextProps>(initialValues);

  // Profile
  const { user, authStatus, signOut } = useAuthenticator();
  const [triggeredCreateProfile, setTriggeredCreateProfile] = useState(false);
  const [
    getProfileByCognitoId,
    { data: profileData, loading: isLoadingProfile, refetch: fetchProfile },
  ] = useGetProfileByUserIdLazyQuery();

  const getProfile = useCallback(() => {
    if (user?.attributes?.sub) {
      getProfileByCognitoId({
        variables: {
          userId: user.attributes?.sub,
        },
      });
    }
  }, [user?.attributes?.sub, getProfileByCognitoId]);

  // Get Profile
  useEffect(() => {
    getProfile();
  }, [getProfile]);

  // Set Profile
  useEffect(() => {
    if (profileData?.profile) {
      const profile: Profile = normalizeGraphqlResult(profileData?.profile);
      setState((prevState) => ({
        ...prevState,
        profile,
      }));
      setProfileCreate(null);
    }
  }, [profileData?.profile]);

  // If profile is not found, create profile
  const [
    createProfile,
    {
      data: createdProfile,
      loading: isCreatingProfile,
      error: createProfileError,
    },
  ] = useCreateProfile();

  const failedToFindProfile = useMemo(() => {
    return !!(
      authStatus !== AuthStatus.Configuring &&
      !isLoadingProfile &&
      !profileData?.profile &&
      !!user?.attributes?.sub
    );
  }, [
    authStatus,
    isLoadingProfile,
    profileData?.profile,
    user?.attributes?.sub,
  ]);

  // Profile Create
  const [profileCreate, setProfileCreate] = useState<ProfileCreate | null>(
    null
  );

  // Initialise profile create
  const shouldCreateProfile = useMemo(() => {
    const profileAlreadyExists = !!state.profile || !!profileData?.profile;
    const canCreateProfile = !!user?.attributes?.sub;
    const isCognitoConfiguring = authStatus === AuthStatus.Configuring;

    return (
      canCreateProfile &&
      !isLoadingProfile &&
      !profileAlreadyExists &&
      !isCognitoConfiguring &&
      !isCreatingProfile
    );
  }, [
    authStatus,
    isLoadingProfile,
    profileData?.profile,
    state.profile,
    isCreatingProfile,
    user?.attributes?.sub,
  ]);

  useEffect(() => {
    if (state.profile) return;
    if (!shouldCreateProfile) return;
    if (!failedToFindProfile) return;
    if (!!profileCreate) return;
    if (!user?.attributes?.sub) return;

    setProfileCreate({
      userId: user?.attributes?.sub,
      email: user?.attributes?.email,
      firstName: user?.attributes?.given_name,
      lastName: user?.attributes?.family_name,
      phoneNumber: user?.attributes?.phone_number,
    });

    // todo[onboarding]: uncomment this when ready
    // Check if lawyer exists
    // getLawyerByEmail({
    //   variables: {
    //     email: user?.attributes?.email,
    //   },
    // });
  }, [
    failedToFindProfile,
    shouldCreateProfile,
    user?.attributes,
    isLoadingProfile,
    state.profile,
    profileData,
  ]);

  // Create profile
  useEffect(() => {
    if (triggeredCreateProfile) return;
    if (!shouldCreateProfile) return;
    // todo[onboarding]: uncomment this when ready
    // if (!profileCreate?.lawyerId) return;
    if (!profileCreate?.userId) return;
    if (!profileCreate?.email) return;

    setTriggeredCreateProfile(true);
    createProfile(profileCreate!, {
      track: {
        name: TrackedEventName.USER_SIGNED_UP,
      },
    }).then(() => {
      setProfileCreate(null);
      fetchProfile();
    });
  }, [triggeredCreateProfile, shouldCreateProfile, profileCreate]);

  // Set lawyer id for profile create
  // todo[onboarding]: uncomment this when ready
  // useEffect(() => {
  //   if (!shouldCreateProfile) return;
  //   if (!!state.profile) return;
  //   if (!profileCreate) return;
  //   if (!!profileCreate?.lawyerId) return;
  //   if (triggeredCreateProfile) return;

  //   const foundLawyers = lawyerCheckResponse?.lawyers?.nodes;
  //   // We checked but no lawyer exists
  //   if (foundLawyers?.length === 0) {
  //     // Create lawyer
  //     navigate(ROUTE_ONBOARDING);
  //   }

  //   if (foundLawyers?.length === 1) {
  //     setProfileCreate({
  //       ...profileCreate,
  //       lawyerId: foundLawyers[0]?.id,
  //     });
  //   }
  // }, [
  //   triggeredCreateProfile,
  //   shouldCreateProfile,
  //   lawyerCheckResponse,
  //   profileCreate,
  //   state.profile,
  // ]);
  // Alias tracking when profile is created
  useEffect(() => {
    if (state?.profile?.lawyer?.id) {
      alias(state?.profile?.lawyer?.id);
    }
  }, [state?.profile?.lawyer?.id]);

  // Toggle auto activate campaigns
  const [updateLawyer, { loading: isUpdatingLawyer }] = useUpdateLawyer();

  // Is LinkedIn authenticated
  const isLinkedinAuthenticated = useMemo(() => {
    return (
      !!state.profile?.lawyer?.liEmail &&
      state.profile?.lawyer?.liLoginStatus === LinkedinLoginStatus.LOGGED_IN
    );
  }, [state.profile]);

  const [checkCanUseService, { data: canUseServiceResponse }] = useCanUseService();

  const refreshCanUseService = (useCache: boolean = true) => {
    if (!state.profile?.lawyer?.id) return;

    checkCanUseService({
      lawyerId: state.profile?.lawyer?.id,
      useCache,
    });
  };

  // Attempt to retry canUseService checks
  const hasCanUseServiceAnswer = !!canUseServiceResponse;
  useExponentialInterval(
    () => {
      refreshCanUseService(true);
    },
    2_000,
    hasCanUseServiceAnswer ? 0 : 60_000
  );

  // For the first time fetch without cache
  useEffect(() => {
    refreshCanUseService(false);
  }, [state.profile]);

  // Subscription
  const [
    getPaymentSubscriptionPrice,
    { data: subscription, loading: isCheckingSubscription },
  ] = useGetPaymentSubscriptionPrice();

  useEffect(() => {
    if (state.profile?.lawyer?.id) {
      getPaymentSubscriptionPrice({
        lawyerId: state.profile?.lawyer?.id,
      });
    }
  }, [state.profile?.lawyer?.id, getPaymentSubscriptionPrice]);

  // Highlight payment setup
  const navigate = useNavigate();
  const [highlightPaymentSetup, setHighlightPaymentSetup] = useState(false);

  useEffect(() => {
    if (highlightPaymentSetup) {
      navigate(ROUTE_BILLING);
      setHighlightPaymentSetup(false);
    }
  }, [highlightPaymentSetup, navigate]);

  // Is Internal Profile
  const isInternalProfile = useMemo(() => {
    return !!isInternalEmail(state.profile?.email);
  }, [state.profile]);

  // Free leads
  const [getFreeLeads, { data: freeLeadsData }] = useGetFreeLeads();

  useEffect(() => {
    if (state.profile?.lawyer?.id) {
      getFreeLeads({
        lawyerId: state.profile?.lawyer?.id,
      });
    }
  }, [state.profile?.lawyer?.id, getFreeLeads]);

  const lawyerId = state.profile?.lawyer?.id || null;
  const lawyerPublicId = state.profile?.lawyer?.publicId || null;
  const isPayG =
    state.profile?.lawyer?.paymentMethod === PaymentMethod.STRIPE_PAYG;

  // Calendar profiles handling
  const [getCalendarProfiles, { data: calendarProfilesData }] = useGetCalendarProfiles();
  const [calendarProfiles, setCalendarProfiles] = useState<CalendarProfile[]>([]);
  const [hasCalendarProfiles, setHasCalendarProfiles] = useState(false);
  const [isCalendarConnected, setIsCalendarConnected] = useState(false);

  // Fetch calendar profiles when lawyer ID is available
  useEffect(() => {
    if (state.profile?.lawyer?.id) {
      getCalendarProfiles({
        lawyerId: state.profile.lawyer.id
      });
    }
  }, [state.profile?.lawyer?.id]);

  // Update calendar profiles when data changes
  useEffect(() => {
    if (calendarProfilesData?.profiles) {
      setCalendarProfiles(calendarProfilesData.profiles);

      // Check if any profile exists and is connected
      const hasProfile = calendarProfilesData.profiles.some(
        profile => profile.profileConnected
      );
      setHasCalendarProfiles(!!hasProfile);

      // Check if any profile has calendars
      const hasCalendar = calendarProfilesData.profiles.some(
        profile => profile.profileCalendars?.length > 0
      );
      setIsCalendarConnected(!!hasProfile && !!hasCalendar);
    }
  }, [calendarProfilesData]);

  const isManaged = useMemo(() => {
    return !!state.profile?.lawyer?.isManaged;
  }, [state.profile]);

  // LogOut
  const logOut = () => {
    signOut();
    setProfileCreate(null);
    navigate(ROUTE_DASHBOARD);
  };

  const value = useMemo(
    () => ({
      ...state,
      lawyerId,
      lawyerPublicId,
      isPayG,
      isLinkedinAuthenticated,
      isLoading:
        isLoadingProfile || isUpdatingLawyer,
      canUseService: hasCanUseServiceAnswer ? canUseServiceResponse?.canUseKula : null,
      hasPaymentInformation: hasCanUseServiceAnswer
        ? canUseServiceResponse?.hasPaymentInformation
        : null,
      avgChargedLeadsThisWeek: hasCanUseServiceAnswer
        ? Math.round((canUseServiceResponse.avgChargedLeadsThisWeek || 0) * 100) /
        100
        : null,
      isPaused: hasCanUseServiceAnswer && canUseServiceResponse?.isPaused,
      credits: hasCanUseServiceAnswer ? canUseServiceResponse?.credits : null,
      isCreatingProfile,
      freeLeads: freeLeadsData?.freeLeads,
      createdProfile: !!createdProfile,
      createProfileError,
      subscription,
      isCheckingSubscription,
      highlightPaymentSetup,
      isInternalProfile,
      isCalendarConnected,
      hasCalendarProfiles,
      isManaged,
      profileCreate,
      logOut,
      setHighlightPaymentSetup,
      refreshCanUseService,
      fetchProfile,
      createLawyer,
      calendarProfiles,
    }),
    [
      state,
      lawyerId,
      lawyerPublicId,
      isPayG,
      isLoadingProfile,
      isUpdatingLawyer,
      canUseServiceResponse,
      subscription,
      isCheckingSubscription,
      isCreatingProfile,
      isLinkedinAuthenticated,
      createdProfile,
      createProfileError,
      highlightPaymentSetup,
      isInternalProfile,
      freeLeadsData?.freeLeads,
      isCalendarConnected,
      hasCalendarProfiles,
      calendarProfiles,
      isManaged,
      profileCreate,
      logOut,
      setHighlightPaymentSetup,
      refreshCanUseService,
      fetchProfile,
      createLawyer,
    ]
  );
  const context = useMemo(() => {
    if (!lawyerId) return undefined;

    return {
      kind: "user",
      key: lawyerId,
      lawyer_id: lawyerId,
      lawyer_name: state.profile?.lawyer?.name,
      lawyer_email: state.profile?.lawyer?.email,
      profile_id: state.profile?.id,
      profile_email: state.profile?.email,
      profile_internal: isInternalProfile,
    };
  }, [
    lawyerId,
    state.profile?.lawyer?.name,
    state.profile?.lawyer?.email,
    state.profile?.id,
    state.profile?.email,
    isInternalProfile,
  ]);

  return (
    <LDProvider
      deferInitialization={true}
      clientSideID={config.LAUNCHDARKLY_CLIENT_ID}
      context={context}
    >
      <AppContext.Provider value={value}>{children}</AppContext.Provider>
    </LDProvider>
  );
};

export const useAppContext = (): AppContextProps => useContext(AppContext);
