import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

type ValidateCouponCodeParams = {
  lawyerId: string;
  couponCode: string;
};

export const validateCouponCode = async ({
  lawyerId,
  couponCode,
}: ValidateCouponCodeParams) => {
  const options: FetchOptions = {
    method: "GET",
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/payments/coupon?${new URLSearchParams({
      coupon_code: couponCode,
    })}`,
    options
  );

  return response;
};

export const useValidateCouponCode = () =>
  useServiceCall({
    serviceCall: validateCouponCode,
  });
