import MonthNavigation from "@components/MonthNavigation";
import { useAppContext } from "@context/AppContext";
import { useBillingContext } from "@context/BillingContext";
import { DateTime } from "luxon";
import { useEffect } from "react";

const RANGE_IN_DAYS = 30;
const MIN_DATE = DateTime.fromObject({
  day: 1,
  month: 3,
  year: 2024,
}).toJSDate();

export const formatChargeDate = (date: Date) => {
  return DateTime.fromJSDate(date).toFormat("dd LLL yyyy");
};

export default function ChargeFilters() {
  const { lawyerId } = useAppContext();
  const {
    getCharges,
    isLoadingCharges,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
  } = useBillingContext();

  const handleFilter = () => {
    if (isLoadingCharges) return;

    if (startTime < endTime) {
      getCharges(startTime, endTime);
    }
  };

  // Fetch charges when the date range changes
  useEffect(() => {
    handleFilter();
  }, [startTime, endTime, lawyerId]);

  const handleMonthChange = (startDate: Date, endDate: Date) => {
    setStartTime(startDate);
    setEndTime(endDate);
  };

  return (
    <MonthNavigation onChange={handleMonthChange} disabled={isLoadingCharges} />
  );
}
