import { CampaignMember, CampaignMemberStatus, CampaignStatus } from "@/models";
import ContactBox from "@components/ContactBox";
import EmptyMessageBox from "@components/EmptyMessageBox";
import { useCampaignContext } from "@context/CampaignContext";
import { Masonry } from "@mui/lab";
import { Box, Pagination, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

const CAMPAIGN_MEMBER_ORDER = [
  CampaignMemberStatus.SCHEDULED_OUTREACH,
  CampaignMemberStatus.CONTACTED,
  CampaignMemberStatus.COMPLETED,
  CampaignMemberStatus.REPLIED,
  CampaignMemberStatus.WARM_REPLY,
  CampaignMemberStatus.SCHEDULING,
  CampaignMemberStatus.LAWYER_HANDLING,
  CampaignMemberStatus.MEETING_BOOKED,
  CampaignMemberStatus.WORK_BILLED,
];

const ContactedCampaignMembers = () => {
  const {
    campaign,
    getContactedMembersPage,
    totalContactedMembers,
    pagedContactedMembers,
  } = useCampaignContext();
  const [page, setPage] = useState<number>(1);

  const totalPages = useMemo(() => {
    return totalContactedMembers ? Math.ceil(totalContactedMembers / 16) : 0;
  }, [totalContactedMembers]);

  // Get contacted members
  useEffect(() => {
    getContactedMembersPage(page);
  }, [page]);

  return (
    <Box>
      {!pagedContactedMembers?.length && (
        <Box>
          <EmptyMessageBox
            title="No contacted members yet"
            subtitle={
              campaign?.status === CampaignStatus.ACTIVE
                ? "We will be contacting the best matches for you"
                : "Start your campaign to contact your matches"
            }
          />
        </Box>
      )}
      <Stack direction="row" spacing={1} alignItems="center">
        <Pagination
          count={totalPages}
          onChange={(event, value) => {
            setPage(value);
          }}
        />
      </Stack>
      <Box mt={2} />
      {pagedContactedMembers ? (
        <Masonry
          columns={{
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 4,
          }}
          spacing={2}
        >
          {pagedContactedMembers?.map((member: CampaignMember) => (
            <ContactBox
              key={member.id}
              contact={member.contact}
              member={member}
            />
          ))}
        </Masonry>
      ) : null}
    </Box>
  );
};

export default ContactedCampaignMembers;
