import { Company, Contact } from "@/models";
import { PersonSearchIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import { useConversationsContext } from "@context/ConversationsContext";
import { useDebounce } from "@hooks/useDebounce";
import LoopIcon from "@mui/icons-material/Loop";
import { Box } from "@mui/material";

import { TextField, keyframes, styled } from "@mui/material";
import { useSearchConversations } from "@services/search/searchConversations";
import React, { useEffect, useState } from "react";

interface ConversationSearchProps {
  onEntitiesFound: (
    ConversationSearchFilters: ConversationSearchFilters | null
  ) => void;
}

export type ConversationSearchFilters = {
  contacts?: Contact[];
  companies?: Company[];
};

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
`;
const RotatingLoopIcon = styled(LoopIcon)(() => ({
  animation: `${rotate} 2s linear infinite`,
}));

const ConversationSearch: React.FC<ConversationSearchProps> = ({
  onEntitiesFound,
}: ConversationSearchProps) => {
  const { profile } = useAppContext();

  // Get paginatedContactIds and companyIds to only search for those entities
  const { isLoading: isLoadingConversations, contactIds } =
    useConversationsContext();

  // Search conversations
  const [
    searchConversations,
    { data: conversationSearchResponse, loading: isSearchingConversations },
  ] = useSearchConversations();

  const [searchTextValue, setSearchTextValue] = useState<string>("");
  const debouncedTextValue = useDebounce(searchTextValue);

  const handleSearch = () => {
    if (!profile?.lawyer.id) return;
    if (!contactIds) return;

    const searchText = debouncedTextValue?.trim();

    if (!searchText) {
      onEntitiesFound(null);
      return;
    }

    // Only search if we have more than 3 characters
    if (searchText.length < 3) {
      return;
    }

    searchConversations({
      lawyerId: profile?.lawyer.id,
      query: searchText,
      contactIds,
    });
  };

  useEffect(() => {
    handleSearch();
  }, [debouncedTextValue]);

  useEffect(() => {
    if (!conversationSearchResponse) {
      return;
    }

    const entityFilters: ConversationSearchFilters =
      conversationSearchResponse?.results.reduce((acc, result) => {
        const { contactId } = result;

        if (contactId) {
          acc.contacts = [
            ...(acc.contacts || []),
            {
              id: contactId,
            },
          ];
        }

        return acc;
      }, {} as ConversationSearchFilters);

    if (!entityFilters) {
      return;
    }

    onEntitiesFound(entityFilters);
  }, [conversationSearchResponse]);

  return (
    <TextField
      id="search-entities"
      label="Search by first name, second name, or company name"
      variant="outlined"
      fullWidth
      disabled={isSearchingConversations || isLoadingConversations}
      size="small"
      InputProps={{
        startAdornment: (
          <Box mr={1}>
            {isSearchingConversations ? (
              <RotatingLoopIcon />
            ) : (
              <PersonSearchIcon color="action" />
            )}
          </Box>
        ),
      }}
      onChange={(e) => setSearchTextValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSearch();
        }
      }}
    />
  );
};

export default ConversationSearch;
