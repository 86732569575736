import { useCampaignContext } from "@context/CampaignContext";
import { Typography } from "@mui/material";
import ReactTimeAgo from "react-time-ago";

export default function CreatedTimeAgoText() {
  const { campaign } = useCampaignContext();

  return campaign?.createdTime ? (
    <Typography variant="caption" color={"text.secondary"}>
      Created <ReactTimeAgo date={campaign.createdTime} locale="en" />
    </Typography>
  ) : null;
}
