import { TrackOptions, track } from "@/third-party/tracking";
import React, { ReactNode, useEffect, useRef } from "react";

interface TrackedProps {
  children: React.ReactNode;
  onClick?: TrackOptions;
  onHover?: TrackOptions;
  onVisible?: TrackOptions;
  onChange?: TrackOptions;
}

const Tracked: React.FC<TrackedProps> = ({
  children,
  onClick,
  onHover,
  onVisible,
  onChange,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  // Handle visibility
  const [trackedVisibility, setTrackedVisibility] = React.useState(false);

  useEffect(() => {
    if (!onVisible || trackedVisibility) {
      return;
    }

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && onVisible) {
          // Element is visible
          track(onVisible);
        }
      });

      setTrackedVisibility(true);
    };

    const intersectionObserver = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Adjust as needed
    });

    if (elementRef.current) {
      intersectionObserver.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        intersectionObserver.unobserve(elementRef.current);
      }
    };
  }, [onVisible, trackedVisibility]);

  // Handle other events
  // Attach event listeners to the immediate children
  const trackedEvents: {
    [key: string]: TrackOptions | undefined;
  } = { onClick, onHover, onChange };

  const wrapChildWithTracking = (child: ReactNode) => {
    if (React.isValidElement(child)) {
      const childProps: any = child.props;
      const overrides = ["onClick", "onHover", "onChange"]
        .filter((eventName: string) => {
          return childProps[eventName] && !!trackedEvents[eventName];
        })
        .reduce((acc: { [key: string]: any }, eventName: string) => {
          acc[eventName] = (event: any, value?: any) => {
            const _value = value || event?.target?.value;

            if (childProps[eventName]) {
              childProps[eventName](event, _value);
            }

            trackedEvents[eventName]!.props = {
              ...(trackedEvents[eventName]!.props || {}),
              value: _value,
            };

            track(trackedEvents[eventName]!);
          };
          return acc;
        }, {});

      return React.cloneElement(child, {
        ...overrides,
      });
    }

    return child;
  };

  return (
    <div ref={elementRef}>
      {React.Children.map(children, wrapChildWithTracking)}
    </div>
  );
};

export default Tracked;
