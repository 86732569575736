import { Typography } from "@mui/material";
import EmptyMessageBox from "./EmptyMessageBox";

const FewMatchesEmptyState = () => {
  return (
    <EmptyMessageBox
      title="Few matches found in sample"
      subtitle={
        <>
          <Typography variant="body2" color="text.secondary">
            We couldn't find many matches for this campaign.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            You can try rewording your description, or leave it to us and we
            will attempt to hunt down more results.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            We always recommend having an active broad campaign as well.
          </Typography>
        </>
      }
    />
  );
};

export default FewMatchesEmptyState;
