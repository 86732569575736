import config from "@/config";
import { useAppContext } from "@context/AppContext";
import { getFullName } from "@utils/text";
import { useEffect } from "react";

declare global {
  interface Window {
    Intercom: any;
  }
}

const loadIntercom = (profile: any) => {
  if (config.INTERCOM_APP_ID) {
    window.Intercom("boot", {
      app_id: config.INTERCOM_APP_ID,
      api_base: "https://api-iam.intercom.io",
      name: getFullName(profile.firstName, profile.lastName),
      email: profile.email,
      user_id: profile.userId,
    });
  }
};

export const LoadIntercom = () => {
  const { profile } = useAppContext();

  useEffect(() => {
    if (profile?.lawyer) {
      loadIntercom(profile);
    }
  }, [profile]);

  return <></>;
};
