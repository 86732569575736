import { ServiceResponse, useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";

type PatchEmailMessageParams = {
  emailMessageId: string;
  lawyerViewedTime: Date;
};

export const patchEmailMessage = async ({
  emailMessageId,
  lawyerViewedTime,
}: PatchEmailMessageParams) => {
  const options: FetchOptions = {
    method: "PATCH",
    payload: {
      lawyerViewedTime: lawyerViewedTime.toISOString(),
    },
  };

  const response = await fetchOutreachService(
    `/email-messages/${emailMessageId}`,
    options
  );

  return response;
};

export const usePatchEmailMessage = () =>
  useServiceCall<ServiceResponse, PatchEmailMessageParams>({
    serviceCall: patchEmailMessage,
    successMessage: "Email message updated successfully",
    errorMessage: "Failed to update email message",
  });
