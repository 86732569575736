import { CampaignMemberCharge, RefundStatus } from "@/models";
import { AddIcon, DollarIcon, FreeLeadIcon } from "@components/icons";
import { useBillingContext } from "@context/BillingContext";
import palette from "@design/palette";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Fade,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ChargeFilters, { formatChargeDate } from "./ChargeFilters";
import DownloadChargesButton from "./DownloadChargesButton";

const getChargeDate = (charge: CampaignMemberCharge) => {
  const refundedAt = charge.refunds?.[0]?.updatedTime;
  const dates: Array<Date> = [charge.freeAt, charge.paidAt, refundedAt]
    .filter((d) => !!d)
    .map((d) => d!);

  // Get min date
  dates.sort((a: Date, b: Date) => a.getTime() - b.getTime());
  return dates[0];
};

type ChargeStats = {
  free: number;
  paid: number;
  refunded: number;
  paidAmount: number;
  paidTaxAmount: number;
  refundedAmount: number;
};
type ChargeBarData = {
  name: string;
} & ChargeStats;

export default function Charges() {
  const { charges, isLoadingCharges, startTime, endTime } = useBillingContext();

  // const upcomingChargeAmounts = useMemo(() => {
  //   return (
  //     charges
  //       ?.filter((c) => !c.freeAt && !c.paidAt)
  //       .map((c) => c.amount + c.taxAmount) || []
  //   );
  // }, [charges]);

  // Get all dates for the graph between start and end time
  const dates = useMemo(() => {
    const dates: Array<string> = [];
    let currentDate = new Date(startTime);

    while (currentDate <= endTime) {
      dates.push(formatChargeDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }, [startTime, endTime]);

  // Calculate graph data
  const graphData = useMemo(() => {
    return charges?.reduce(
      (
        acc: {
          [key: string]: ChargeBarData;
        },
        charge: CampaignMemberCharge
      ) => {
        const chargeDate = getChargeDate(charge);
        if (!chargeDate) return acc;

        const date: string = formatChargeDate(chargeDate);
        const refund = charge.refunds?.[0];

        if (!date) return acc;

        if (!acc[date]) {
          acc[date] = {
            name: date,
            free: 0,
            paid: 0,
            refunded: 0,
            paidAmount: 0,
            paidTaxAmount: 0,
            refundedAmount: 0,
          };
        }

        if (refund?.status === RefundStatus.APPROVED) {
          const refundDate = formatChargeDate(new Date(refund.updatedTime));
          if (acc[refundDate]) {
            acc[refundDate].refunded += 1;
            acc[refundDate].refundedAmount += refund.amount;
          }
        } else if (charge.freeAt) {
          acc[date].free += 1;
        } else if (charge.paidAt) {
          acc[date].paid += 1;
          acc[date].paidAmount += charge.amount || 0;
          acc[date].paidTaxAmount += charge.taxAmount || 0;
        }

        return acc;
      },
      dates?.reduce(
        (
          acc: {
            [key: string]: ChargeBarData;
          },
          date
        ) => {
          acc[date] = {
            name: date,
            free: 0,
            paid: 0,
            refunded: 0,
            paidAmount: 0,
            paidTaxAmount: 0,
            refundedAmount: 0,
          };
          return acc;
        },
        {}
      )
    );
  }, [charges]);

  // Calculate totals
  const totals = useMemo(() => {
    if (!graphData) return;

    return Object.values(graphData)?.reduce(
      (acc: ChargeStats, data: ChargeStats) => {
        acc.free += data.free;
        acc.paid += data.paid;
        acc.refunded += data.refunded;
        acc.paidAmount += data.paidAmount;
        acc.paidTaxAmount += data.paidTaxAmount;
        acc.refundedAmount += data.refundedAmount;

        return acc;
      },
      {
        free: 0,
        paid: 0,
        refunded: 0,
        paidAmount: 0,
        paidTaxAmount: 0,
        refundedAmount: 0,
      }
    );
  }, [graphData]);

  return (
    <Box width="100%" height="500px" position={"relative"}>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={2}
        justifyContent={"space-between"}
        alignItems={{
          xs: "flex-start",
          sm: "center",
        }}
      >
        <ChargeFilters />
        {!!totals && (
          <Stack
            direction="column"
            spacing={1}
            alignItems={"flex-start"}
            flexGrow={1}
          >
            <Stack direction="row" spacing={1}>
              {!!totals.free && (
                <Chip
                  label={`Free leads: ${totals.free}`}
                  color="success"
                  icon={<FreeLeadIcon fontSize="small" />}
                />
              )}

              {!!(totals.paidAmount + totals.paidTaxAmount) && (
                <Chip
                  icon={<DollarIcon fontSize="small" />}
                  color={"primary"}
                  label={`Paid $${
                    (totals.paidAmount + totals.paidTaxAmount) / 100
                  } for ${totals.paid} leads`}
                />
              )}

              {!!totals.refundedAmount && (
                <Chip
                  icon={<AddIcon fontSize="small" />}
                  label={`Credited $${totals.refundedAmount / 100}`}
                  color="info"
                />
              )}
            </Stack>
          </Stack>
        )}
        <DownloadChargesButton />
      </Stack>
      <Box mt={2} />
      <Divider />
      <Box mt={2} />
      <ResponsiveContainer style={{ opacity: isLoadingCharges ? 0.5 : 1 }}>
        <BarChart
          width={500}
          height={300}
          data={graphData ? Object.values(graphData) : []}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" fontSize={13} />
          <YAxis />
          <Tooltip
            content={({ payload, label }) => {
              if (!payload || !payload.length) return null;
              const data = payload[0].payload as ChargeBarData;
              const paidInDollars =
                ((data.paidAmount || 0) + (data.paidTaxAmount || 0)) / 100;
              const refundedInDollars = (data.refundedAmount || 0) / 100;

              return (
                <Box
                  sx={{
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "5px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography variant={"h6"} color={"text.secondary"}>
                    {label}
                  </Typography>
                  <Divider />
                  <Box mt={1} />
                  {!!data.free && (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="body1" color={"success.main"}>
                        Free leads:
                      </Typography>
                      <Typography variant="body1">
                        <b>{data.free}</b>
                      </Typography>
                    </Stack>
                  )}
                  {!!paidInDollars && (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="body1" color={"primary.main"}>
                        Paid:
                      </Typography>
                      <Typography variant="body1">
                        <b>${paidInDollars}</b> for {data.paid} leads
                      </Typography>
                    </Stack>
                  )}
                  {!!data.refunded && (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="body1" color={"info.main"}>
                        Credited:
                      </Typography>
                      <Typography variant="body1">
                        <b>${refundedInDollars}</b> for {data.refunded} leads
                      </Typography>
                    </Stack>
                  )}
                </Box>
              );
            }}
          />
          <Legend />
          <Bar dataKey="free" stackId="a" fill={palette.success.main} name="Free leads" />
          <Bar dataKey="paid" stackId="a" fill={palette.primary.main} name="Paid leads" />
          <Bar dataKey="refunded" stackId="a" fill={palette.info.main} name="Credited" />
        </BarChart>
      </ResponsiveContainer>
      <Fade in={isLoadingCharges}>
        <Box
          position={"absolute"}
          top={0}
          left={0}
          right={0}
          bottom={0}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress size={100} />
        </Box>
      </Fade>
    </Box>
  );
}
