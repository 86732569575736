import EmptyMessageBox from "@components/EmptyMessageBox";
import RoundedBox from "@components/RoundedBox";
import { useAppContext } from "@context/AppContext";
import { Box, Collapse, Container, Typography } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "./AppBar";
import LoadingSpinner from "./LoadingSpinner";
import TodoList from "./TodoList";

export type PageProps = {
  hideTodoList?: boolean;
  allowGuest?: boolean;
  children: ReactNode;
};

enum ProfileCreateError {
  INVALID_EMAIL = "INVALID_EMAIL",
  PROFILE_ALREADY_EXISTS = "PROFILE_ALREADY_EXISTS",
  LAWYER_NOT_FOUND = "LAWYER_NOT_FOUND",
  OTHER = "OTHER",
}

const getProfileCreateError = (error: string): ProfileCreateError => {
  try {
    return ProfileCreateError[error as keyof typeof ProfileCreateError];
  } catch {
    return ProfileCreateError.OTHER;
  }
};

const ProfileCreateErrorMessage = ({ error, email }: { error: string; email: string }) => {
  const profileCreateError = getProfileCreateError(error);

  return (
    <Typography variant="body1">
      {profileCreateError === ProfileCreateError.INVALID_EMAIL
        ? `Invalid email address: ${email}`
        : null}
      {profileCreateError === ProfileCreateError.PROFILE_ALREADY_EXISTS
        ? `Profile already exists for ${email}. Please contact support@kular.ai if you are no longer able to access your account.`
        : null}
      {profileCreateError === ProfileCreateError.LAWYER_NOT_FOUND ? (
        <>
          We haven't created an account for <b>{email}</b> yet. If you'd like an account, please{" "}
          <Link
            to="https://form.typeform.com/to/vO33C21e"
            style={{"textDecoration": "underline"}}
          >tell us more about you</Link>
        </>
      ) : null}
      {profileCreateError === ProfileCreateError.OTHER
        ? `Please contact support@kular.ai for assistance.`
        : null}
    </Typography>
  );
};

const Page: React.FC<PageProps> = ({
  hideTodoList,
  allowGuest,
  children,
}: PageProps) => {
  const { profile, isCreatingProfile, createProfileError, profileCreate } = useAppContext();

  const [showContent, setShowContent] = useState<boolean>(false);

  useEffect(() => {
    if (allowGuest && !isCreatingProfile) {
      setShowContent(true);
    }

    if (!allowGuest && !!profile) {
      setShowContent(true);
    }
  }, [allowGuest, isCreatingProfile, profile]);

  return (
    <Box
      overflow={"hidden"}
      border={"none"}
      mb={{
        xs: 0,
        sm: 4,
      }}
    >
      <Container
        maxWidth="xl"
        className="page-container"
        sx={{
          p: {
            xs: 0,
            sm: 2,
          },
          m: {
            xs: 0,
            sm: "0 auto",
          },
        }}
      >
        <RoundedBox
          mt={{
            xs: 0,
            sm: 2,
          }}
          borderRadius={{
            xs: 0,
            sm: 4,
          }}
          minHeight={500}
        >
          <AppBar />
          <Box className="page-content">
            <Collapse in={isCreatingProfile && !createProfileError}>
              <LoadingSpinner />
            </Collapse>
            {!hideTodoList && <TodoList />}
            <Collapse in={!!createProfileError}>
              <EmptyMessageBox
                title={"We can not create your profile right now."}
                subtitle={
                  <ProfileCreateErrorMessage
                    email={profileCreate?.email ?? profile?.email ?? ""}
                    error={createProfileError?.message || ""}
                  />
                }
              />
            </Collapse>
            <Collapse in={showContent} mountOnEnter>
              {children}
            </Collapse>
          </Box>
        </RoundedBox>
      </Container>
    </Box>
  );
};

export default Page;
