import { ROUTE_CAMPAIGNS, ROUTE_PROFILE } from "@/AuthenticatedAppRoutes";
import { CampaignStatus } from "@/models";
import { TrackedEventName } from "@/third-party/tracking";
import PaymentMethodNotice from "@components/PaymentMethodNotice";
import Tracked from "@components/Tracked";
import { PaymentIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import { useCampaignsContext } from "@context/CampaignsContext";
import { Alert, Button, Collapse, Stack, Typography } from "@mui/material";
import PauseAccountDialogButton from "@pages/profile/PauseAccountDialogButton";
import { Link, useLocation } from "react-router-dom";

import { useEffect, useState } from "react";

const TodoList = () => {
  const {
    isLinkedinAuthenticated,
    hasPaymentInformation,
    isPaused,
    setHighlightPaymentSetup,
  } = useAppContext();
  const { campaigns } = useCampaignsContext();

  const [hasDismissedReferralBanner, setHasDismissedReferralBanner] = useState(false);

  const pendingCampaigns = campaigns?.filter(
    (campaign) => campaign.status === CampaignStatus.PENDING
  );


  // We set cookies to track if the user has dismissed the referral banner
  // so we don't show it to them on every page load
  useEffect(() => {
    const cookie = document.cookie.includes("hasDismissedReferralBanner=true");
    setHasDismissedReferralBanner(cookie);
  }, []);

  const dismissReferralBanner = () => {
    document.cookie = "hasDismissedReferralBanner=true; max-age=31536000; path=/";
    setHasDismissedReferralBanner(true);
  };

  const hasPendingCampaigns = !!pendingCampaigns?.length;
  const showAlerts = hasPaymentInformation === false || hasPendingCampaigns;

  const location = useLocation();
  const isCampaignRoute = location.pathname === ROUTE_CAMPAIGNS;
  const isProfileRoute = location.pathname === ROUTE_PROFILE;

  return (
    <Collapse in={showAlerts}>
      <Stack
        px={2}
        pt={2}
        pb={1}
        spacing={1}
        direction={"column"}
        bgcolor={"background.info"}
      >
        {hasPaymentInformation === false && (
          <Alert
            severity="warning"
            variant="outlined"
            action={
              !isProfileRoute ? (
                <Tracked
                  onClick={{
                    name: TrackedEventName.SETUP_PAYMENT_ALERT_CLICKED,
                  }}
                >
                  <Button
                    startIcon={<PaymentIcon />}
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => setHighlightPaymentSetup?.(true)}
                  >
                    Enter Your Payment Details
                  </Button>
                </Tracked>
              ) : null
            }
          >
            <PaymentMethodNotice />
          </Alert>
        )}
        {hasPendingCampaigns && (
          <Alert
            severity="warning"
            variant="outlined"
            action={
              !isCampaignRoute && (
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  component={Link}
                  to={ROUTE_CAMPAIGNS}
                >
                  Show Campaigns
                </Button>
              )
            }
          >
            <Typography variant="body2">
              You have <b>{pendingCampaigns.length}</b> pending campaigns.{" "}
              <b>Activate your campaigns</b> to generate conversations.
            </Typography>
          </Alert>
        )}

        {isPaused && (
          <Alert
            severity="warning"
            variant="outlined"
            action={<PauseAccountDialogButton />}
          >
            <Typography variant="body2">
              Your account is currently paused. <b>Resume your account</b> to
              start your campaigns.
            </Typography>
          </Alert>
        )}

        {!hasDismissedReferralBanner && (
          <Alert
            severity="info"
            variant="outlined"
            action={
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={dismissReferralBanner}
              >
                Dismiss
              </Button>
            }
          >
            <Typography variant="body2">
              <Link to="/profile#referrals" style={{ textDecoration: 'none', color: 'inherit' }}>
                Refer a friend and get a free lead when they sign up!
              </Link>
            </Typography>
          </Alert>
        )}
      </Stack>
    </Collapse>
  );
};

export default TodoList;
