import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

interface CreateCampaignParamsRequired {
  lawyerId: string;
  campaignChannel: string;
}

interface CreateCampaignParamsFromSuggested
  extends CreateCampaignParamsRequired {
  suggestedTargetingId: string;
}

type CreateCampaignParams = Partial<CreateCampaignParamsFromSuggested>;

export const createCampaign = async ({
  lawyerId,
  campaignChannel,
  suggestedTargetingId,
}: CreateCampaignParams) => {
  const basePayload = {
    campaignType: campaignChannel,
  };

  let payload = {
    ...basePayload,
    suggestedTargetingId,
  };
  const options: FetchOptions = {
    method: "POST",
    query: payload,
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/campaigns/create`,
    options
  );

  return response;
};

type CreateCampaignResponse = {
  id: UUID;
};

export const useCreateCampaign = () =>
  useServiceCall<CreateCampaignResponse, CreateCampaignParams>({
    serviceCall: createCampaign,
    successMessage: "Your campaign was created!",
    errorMessage: "Error creating your campaign",
  });
