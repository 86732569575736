import { useEffect, useState } from "react";
import { CallbackFunction, useInterval } from "./useInterval";

export const useExponentialInterval = (
  callback: CallbackFunction,
  delay: number,
  maxDelay: number
) => {
  const [currentDelay, setCurrentDelay] = useState<number | null>(delay);

  const callbackRef = useInterval(() => {
    if (currentDelay !== null && currentDelay <= maxDelay) {
      callback();
      setCurrentDelay((prevDelay) =>
        prevDelay !== null ? prevDelay * 2 : null
      );
    }
  }, currentDelay);

  useEffect(() => {
    if (callbackRef.current === null) {
      return;
    }

    if (currentDelay !== null && currentDelay > maxDelay) {
      window.clearInterval(callbackRef.current);
    }
  }, [currentDelay, maxDelay, callbackRef]);

  return callbackRef;
};
