import {
  ChargedPer,
  LawyerAcquisitionType,
  LawyerOnboardingType,
  LawyerPriority,
} from "@/models";
import { LawyerCreate } from "@services/lawyers/createLawyer";
import { ReactNode, createContext, useContext, useState } from "react";

export enum OnboardingStepType {
  FREE_LEADS,
  PERSONAL_INFO,
  TARGETTING,
  BUDGET,
}

// Define an empty context with no specific properties
type OnboardingContextProps = {
  currentStep: OnboardingStepType;
  lawyerCreateForm?: LawyerCreateForm | null;
  setCurrentStep: (step: OnboardingStepType) => void;
  updateFormValue?: (fieldName: LawyerCreateFormField, value: any) => void;
  updateFormValues?: (values: Partial<LawyerCreateForm>) => void;
};

// Create the context with default empty values
const OnboardingContext = createContext<OnboardingContextProps>({
  currentStep: OnboardingStepType.FREE_LEADS,
  setCurrentStep: () => {},
});

type OnboardingProviderProps = {
  children: ReactNode;
};

export const DEFAULT_STRIPE_PRICE_ID = "price_1PLmFUC8mfAi0MaHfMn44rR6";
export const DEFAULT_FREE_LEADS = 3;
export const DEFAULT_PRICE_IN_DOLLARS = 150;

export type LawyerCreateForm = LawyerCreate & {
  focus: string;
  socialProof: string;
  addressIsOk: boolean;
  budgetIsOk: boolean;
  acquisitionChannelOther?: string;
};
export type LawyerCreateFormField = keyof LawyerCreateForm;

export const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const [lawyerCreateForm, setLawyerCreateForm] = useState<LawyerCreateForm>({
    email: "",
    name: "",
    firmName: "",
    timeZone: "",
    country: "",
    countryCode: "",
    websiteUrl: "",
    linkedinUrl: "",
    city: "",
    title: "",
    signatureHtml: "",
    icp: "",
    valueProposition: "",
    focus: "",
    socialProof: "",
    addressIsOk: false,
    budgetIsOk: false,
    acquisitionChannel: undefined,
    priority: LawyerPriority.MEDIUM,
    chargedPer: ChargedPer.LEAD,
    freeLeads: DEFAULT_FREE_LEADS,
    stripePriceId: DEFAULT_STRIPE_PRICE_ID,
    acquisitionType: LawyerAcquisitionType.SELF_SERVE,
    onboardingType: LawyerOnboardingType.SELF_SERVE,
  });

  // Update a single form value
  const updateFormValue = (fieldName: LawyerCreateFormField, value: any) => {
    setLawyerCreateForm((prev: LawyerCreateForm) => {
      return {
        ...prev,
        [fieldName]: value,
      };
    });
  };

  // Update multiple form values at once
  const updateFormValues = (values: Partial<LawyerCreateForm>) => {
    setLawyerCreateForm((prev: LawyerCreateForm) => {
      return {
        ...prev,
        ...values,
      };
    });
  };

  // Set the current step of the onboarding process
  const [currentStep, setCurrentStep] = useState<OnboardingStepType>(
    OnboardingStepType.FREE_LEADS
  );

  return (
    <OnboardingContext.Provider
      value={{
        lawyerCreateForm,
        updateFormValue,
        updateFormValues,
        currentStep,
        setCurrentStep,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => {
  return useContext(OnboardingContext);
};
