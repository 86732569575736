import { useConversationsContext } from "@context/ConversationsContext";
import { Box, Pagination, Stack } from "@mui/material";
import Conversations from "@pages/conversations/conversations/Conversations";
import { useEffect, useState } from "react";

const CONVERSATION_PAGE_SIZE = 10;

const CampaignConversations = () => {
  const { conversations, fetchConversations } = useConversationsContext();

  useEffect(() => {
    fetchConversations();
  }, []);

  const [page, setPage] = useState(1);
  const totalConversations = conversations?.length || 0;
  const totalPages = Math.ceil(totalConversations / CONVERSATION_PAGE_SIZE);

  const paginatedConversations = conversations?.slice(
    (page - 1) * CONVERSATION_PAGE_SIZE,
    page * CONVERSATION_PAGE_SIZE
  );

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems="center" mb={2}>
        <Pagination
          count={totalPages}
          onChange={(event, value) => {
            setPage(value);
          }}
        />
      </Stack>
      <Conversations conversations={paginatedConversations || []} />
    </Box>
  );
};

export default CampaignConversations;
