import { AddIcon } from "@components/icons";
import { Button, List, ListItem, ListItemText, Popover } from "@mui/material";
import { snakeCaseToDisplayText } from "@utils/text";
import React, { useState } from "react";

const TEMPLATE_PLACEHOLDERS = [
  "first_name",
  "last_name",
  "company_name",
  "title",
  // "website_url",
  // "email",
  // "firm_name",
];

type PlaceholderSelectorProps = {
  onSelected: (placeholder: string) => void;
};

const PlaceholderSelector: React.FC<PlaceholderSelectorProps> = ({
  onSelected,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState<null | HTMLElement>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverOpen(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopoverOpen(null);
  };

  const handlePlaceholderSelected = (placeholder: string) => {
    onSelected(placeholder);
    handleClosePopover();
  };

  return (
    <>
      <Button
        size="small"
        onClick={handleOpenPopover}
        variant="text"
        color="primary"
        startIcon={<AddIcon />}
      >
        Insert Placeholder
      </Button>
      <Popover
        anchorEl={isPopoverOpen}
        open={Boolean(isPopoverOpen)}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List dense>
          {TEMPLATE_PLACEHOLDERS.map((placeholder, index) => (
            <ListItem
              dense={true}
              key={index}
              onClick={() => handlePlaceholderSelected(placeholder)}
            >
              <ListItemText>
                <Button
                  fullWidth
                  variant="outlined"
                  size="small"
                  startIcon={<AddIcon />}
                >
                  {snakeCaseToDisplayText(placeholder)}
                </Button>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default PlaceholderSelector;
