import { PaletteOptions } from "@mui/material";

export type AppPaletteOptions = PaletteOptions & {
  warmReply: {
    main: string;
  };
  background: {
    info: string; // Define the type for 'info' property
  };
  text: {
    tertiary: string;
  };
};

const palette = {
  mode: "light",
  primary: {
    main: "#473aff",
    light: "#6f64ff",
    dark: "#08003b",
  },
  secondary: {
    main: "#f04bc4",
    light: "#e373c5",
  },
  error: {
    main: "#ff7189",
  },
  success: {
    main: "#19c37d",
  },
  info: {
    main: "#00a3ff",
  },
  warning: {
    main: "#e7c300",
    dark: "#776713",
  },
  text: {
    primary: "#3d3d3d",
    secondary: "#8a7da4",
    tertiary: "#b2b2b2",
  },
  action: {
    active: "#8f85ff",
    hover: "#e0e0e0",
    selected: "#e0e0e0",
    disabled: "#949394",
    disabledBackground: "#e0e0e0",
  },
  background: {
    default: "#08003b",
    secondary: "#f2f2f2",
    paper: "#fff",
    info: "#fff", // focused paper
  },
  warmReply: {
    main: "#fc5b32",
  },
  divider: "#E9E9E9",
  chart: {
    fill: "#7b75ff",
  }
} as const;

export default palette;
