import { CampaignChannel, Template } from "@/models";
import Tip from "@components/Tip";
import LinkDialog from "@components/editor/LinkDialog";
import PlaceholderSelector from "@components/editor/PlaceholderSelect";
import TemplateEditor, {
  ANCHOR_LINK_REGEX,
  LINK_PLACEHOLDER_REGEX,
  LINK_REGEX,
} from "@components/editor/TemplateEditor";
import { LinkIcon, RevertIcon } from "@components/icons";
import { useCampaignContext } from "@context/CampaignContext";
import { LoadingButton } from "@mui/lab";

import { Alert, Box, Button, Collapse, Stack, Typography } from "@mui/material";
import { useReviseEmailCampaignTemplate } from "@services/campaigns/reviseEmailCampaignTemplate";
import { useReviseLinkedinCampaignTemplate } from "@services/campaigns/reviseLinkedinCampaignTemplate";
import { Editor } from "codemirror";
import { useCallback, useEffect, useMemo, useState } from "react";

type EditTemplateProps = {
  template: Template;
  onClose: () => void;
};

const EditTemplate = ({ template, onClose }: EditTemplateProps) => {
  const [key, setKey] = useState<number>(0);
  const [editor, setEditor] = useState<Editor | null>(null);
  const { editedTemplateId, campaign, fetchCampaign } = useCampaignContext();
  const isEditted = editedTemplateId === template.id;
  const [editedContent, setEdittedContent] = useState<string>(
    template.content || ""
  );

  // Handle Revise
  const [reviseEmailCampaignTemplate, { loading: isRevisingEmailTemplate }] =
    useReviseEmailCampaignTemplate();
  const [
    reviseLinkedinCampaignTemplate,
    { loading: isRevisingLinkedinTemplate },
  ] = useReviseLinkedinCampaignTemplate();

  const reviseTemplate = useCallback(async () => {
    if (!template.id) return;
    if (!campaign?.id) return;

    if (campaign.channel === CampaignChannel.EMAIL) {
      await reviseEmailCampaignTemplate({
        templateId: template.id,
        content: editedContent,
      });
    } else {
      await reviseLinkedinCampaignTemplate({
        templateId: template.id,
        content: editedContent,
      });
    }
    fetchCampaign?.();
    onClose();
  }, [campaign, editedContent, reviseEmailCampaignTemplate]);

  // Revert to saved content
  const handleRevert = () => {
    setEdittedContent(template.content || "");
    setKey((prevKey) => prevKey + 1);
  };

  // Handle insert placeholder
  const handleInsertPlaceholder = useCallback(
    (keyword: string) => {
      if (editor) {
        const keywordText = `{{{${keyword}}}}`;
        editor.replaceSelection(keywordText);

        editor.focus();
      }
    },
    [editor]
  );

  // Handle insert link
  const handleInsertLink = (text: string, url: string) => {
    // If there is a selection replace the selection text with {{{link 'text' 'url'}}}
    // Otherwise insert {{{link 'text' 'url'}}} at the cursor position
    if (!editor) {
      return;
    }

    const doc = editor.getDoc();
    const selection = doc.getSelection();

    if (selection) {
      doc.replaceSelection(`{{{link '${text}' '${url}'}}}`);
    } else {
      const cursor = doc.getCursor();
      doc.replaceRange(`{{{link '${text}' '${url}'}}}`, cursor);
    }

    // Move cursor to the end of the inserted link
    const cursor = doc.getCursor();
    const line = doc.getLine(cursor.line);
    const lineLength = line.length;

    doc.setCursor({ line: cursor.line, ch: lineLength });

    // Focus the editor
    editor.focus();
  };

  // Word count
  const maxWordCount = campaign?.channel === CampaignChannel.EMAIL ? 150 : 100;

  const wordCount = useMemo(() => {
    const content = editedContent?.trim() || "";
    const words = content.split(/\s+/).filter((word) => word.length > 0);
    return words.length;
  }, [editedContent]);

  const readTimeInSec = useMemo(() => {
    return Math.ceil((wordCount / 200) * 60);
  }, [wordCount]);

  // Add link button state
  // Disable when there is no selection or when there is a placeholder in the selection
  const [linkDialogValue, setLinkDialogValue] = useState<{
    text: string;
    url: string;
  } | null>(null);

  const [enableAddLink, setEnableAddLink] = useState(false);
  const checkEnableAddLink = useCallback(() => {
    if (!editor) {
      return;
    }

    const selection = editor.getSelection();

    if (selection) {
      // If selection has more than just text
      // Disable the add link button
      const isTextOnly =
        selection.indexOf("{") === -1 && selection.indexOf("}") === -1;

      setEnableAddLink(isTextOnly);
    } else {
      setEnableAddLink(false);
    }
  }, [editor]);

  // Adjust add link button state on selection change or cursor activity
  useEffect(() => {
    if (editor) {
      editor.on("beforeSelectionChange", checkEnableAddLink);

      editor.on("cursorActivity", checkEnableAddLink);

      return () => {
        editor.off("beforeSelectionChange", checkEnableAddLink);
        editor.off("cursorActivity", checkEnableAddLink);
      };
    }
  }, [editor]);

  // Show links warning
  const [showLinksWarning, setShowLinksWarning] = useState<boolean>(false);
  const hasLinks = useMemo(
    () =>
      !!(
        editedContent &&
        (LINK_PLACEHOLDER_REGEX.test(editedContent.trim()) ||
          ANCHOR_LINK_REGEX.test(editedContent.trim()) ||
          LINK_REGEX.test(editedContent.trim()))
      ),
    [editedContent]
  );
  useEffect(() => {
    const isEmailCampaign = campaign?.channel === CampaignChannel.EMAIL;
    const showWarning = hasLinks && isEmailCampaign;

    setShowLinksWarning(showWarning);
  }, [hasLinks, campaign?.channel, setShowLinksWarning]);

  return (
    <Box>
      <Collapse in={showLinksWarning && isEditted}>
        <Box mb={2}>
          <Alert severity="warning">
            Adding hyperlinks to an email makes it more likely to go to spam.
          </Alert>
        </Box>
      </Collapse>
      <Collapse in={isEditted}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={"flex-start"}
          mb={1}
        >
          <PlaceholderSelector onSelected={handleInsertPlaceholder} />
          <Button
            variant="text"
            size="small"
            startIcon={<RevertIcon />}
            onClick={handleRevert}
          >
            Revert to saved
          </Button>
          <Button
            disabled={!enableAddLink}
            size="small"
            color="primary"
            startIcon={<LinkIcon />}
            onClick={() => {
              if (editor) {
                const selection = editor.getSelection();
                if (selection) {
                  setLinkDialogValue({
                    text: selection,
                    url: "",
                  });
                } else {
                  setLinkDialogValue({
                    text: "",
                    url: "",
                  });
                }
              }
            }}
          >
            <Tip title="Add Link">
              <>Link</>
            </Tip>
          </Button>
        </Stack>
      </Collapse>
      <Box
        bgcolor={isEditted ? "white" : "transparent"}
        borderRadius={1}
        pt={isEditted ? 2 : 0}
      >
        <TemplateEditor
          key={key}
          value={editedContent}
          readonly={!isEditted}
          onChange={(value: string) => {
            setEdittedContent(value);
          }}
          onEditorMount={(editor) => setEditor(editor)}
          onSetLink={(text: string, url: string) => {
            setLinkDialogValue({ text, url });
          }}
        />
      </Box>
      <Stack direction="row" spacing={1} mt={1} alignItems="center">
        <Typography variant="caption" color="textSecondary">
          {readTimeInSec} seconds read
        </Typography>
        <Typography
          variant="caption"
          color={wordCount > maxWordCount ? "error" : "textSecondary"}
        >
          {wordCount}/{maxWordCount} words
        </Typography>
      </Stack>{" "}
      {isEditted && (
        <Stack direction="row" spacing={1} mt={2}>
          <LoadingButton
            variant="outlined"
            onClick={() => {
              handleRevert();
              onClose();
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={reviseTemplate}
            loading={isRevisingEmailTemplate || isRevisingLinkedinTemplate}
          >
            Save
          </LoadingButton>
        </Stack>
      )}
      <LinkDialog
        isOpen={!!linkDialogValue}
        initialValue={linkDialogValue}
        onClose={() => {
          setLinkDialogValue(null);
        }}
        onConfirm={(text: string, url: string) => {
          handleInsertLink(text, url);
        }}
      />
    </Box>
  );
};

export default EditTemplate;
