import { ROUTE_CAMPAIGNS } from "@/AuthenticatedAppRoutes";
import { BackIcon } from "@components/icons";
import palette from "@design/palette";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Info from "./Info";
import SectionTitle from "./SectionTitle";
import NavButton from "./navigation/NavButton";

type SectionProps = {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  titleIcon?: React.ReactNode;
  titleAction?: React.ReactNode;
  titleDescription?: React.ReactNode;
  contentBackgroundColor?: string;
  toViewAll?: string;
  skinny?: boolean;
  withBackButton?: boolean;
  backButtonTitle?: string;
  backButtonUrl?: string;
  children?: React.ReactNode;
};

const SectionCard = ({
  title,
  subTitle,
  titleIcon,
  titleAction,
  children,
  titleDescription,
  toViewAll,
  contentBackgroundColor = palette.background.paper,
  skinny = false,
  withBackButton = false,
  backButtonUrl,
  backButtonTitle = "Back",
}: SectionProps) => {
  return (
    <Card
      elevation={1}
      sx={{
        boxShadow: "none",
        background: "transparent",
        ".MuiCardHeader-root": {
          flexWrap: "wrap",
        },
        ".MuiCardHeader-action": {
          m: 0,
        },
      }}
    >
      {!!title && (
        <CardHeader
          title={
            <Stack direction="row" alignItems="center" spacing={1}>
              {withBackButton && (
                <NavButton
                  title={backButtonTitle}
                  to={backButtonUrl || ROUTE_CAMPAIGNS}
                  icon={<BackIcon />}
                />
              )}
              <SectionTitle
                title={title}
                subTitle={subTitle}
                icon={titleIcon}
              />
            </Stack>
          }
          subheader={!!titleDescription && <Info text={titleDescription} />}
          action={titleAction}
          sx={{
            pb: 0,
            pt: 0,
            pl: 0,
            pr: 0,

            ".MuiCardHeader-action": {
              p: 2,
              alignSelf: "center",
            },
            ":hover": {
              cursor: toViewAll ? "pointer" : "default",
              backgroundColor: toViewAll ? "#F0F0F0" : "transparent",
            },
          }}
        />
      )}
      <CardContent
        sx={{
          backgroundColor: skinny ? "transparent" : contentBackgroundColor,
          border: skinny ? "none" : "1px solid",
          borderColor: skinny ? "transparent" : "divider",
          borderRadius: "8px",
          mt: 2,
          pt: skinny ? 0 : 2,
          pb: skinny ? 0 : 2,
          pl: skinny ? 0 : 2,
          pr: skinny ? 0 : 2,

          "&:last-child": {
            pb: 1,
          },
        }}
      >
        <Box>{children}</Box>

        {toViewAll && (
          <Box textAlign="center" mt={1}>
            <Typography
              variant="body2"
              color="text.secondary"
              {...{
                component: toViewAll ? Link : "h2",
                to: toViewAll,
              }}
              sx={{
                textDecoration: "none",
                textAlign: "right",
              }}
            >
              View All
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default SectionCard;
