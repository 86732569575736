'use client'

import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import { Alert, AlertTitle, AppBar, Backdrop, Box, Toolbar, Typography } from '@mui/material'
import { format, formatDistanceToNow } from 'date-fns'
import { useEffect, useState } from 'react'

function getTimeZone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

interface MaintenanceWindow {
  windowStart: Date;
  windowEnd: Date;
  reason: string;
}

const MAINTENANCE_WINDOWS: MaintenanceWindow[] = [
  {
    // 30th Nov 2024 - 1730 UTC to 2030 UTC
    windowStart: new Date('2024-11-30T17:30:00Z'),
    windowEnd: new Date('2024-11-31T05:00:00Z'),
    reason: "System upgrade"
  },
  {
    // 9th Dec 2024 - DB Upgrade
    windowStart: new Date('2024-12-09T13:30:00Z'),
    windowEnd: new Date('2024-12-09T14:45:00Z'),
    reason: "Database upgrade",
  },
  {
    // 9th Dec 2024 - DB Upgrade
    windowStart: new Date('2024-12-09T18:45:00Z'),
    windowEnd: new Date('2024-12-09T20:45:00Z'),
    reason: "Database re-upgrade",
  }
];

const MAINTENANCE_CHECK_INTERVAL_MS = 1000; // 1 second
const WARNING_THRESHOLD_MINUTES = 30;

export function MaintenanceSplash() {
  const [activeWindow, setActiveWindow] = useState<MaintenanceWindow | null>(null);
  const [upcomingWindow, setUpcomingWindow] = useState<MaintenanceWindow | null>(null);
  const [countdown, setCountdown] = useState<string>('');

  useEffect(() => {
    const checkMaintenanceTime = () => {
      const now = new Date();

      // Check for active maintenance window
      const currentWindow = MAINTENANCE_WINDOWS.find(window =>
        now >= window.windowStart && now <= window.windowEnd
      );

      // Check for upcoming maintenance window
      const nextWindow = MAINTENANCE_WINDOWS.find(window => {
        const minutesUntilStart = (window.windowStart.getTime() - now.getTime()) / 60000;
        return minutesUntilStart > 0 && minutesUntilStart <= WARNING_THRESHOLD_MINUTES;
      });

      setActiveWindow(currentWindow || null);
      setUpcomingWindow(currentWindow ? null : nextWindow || null);

      // Update countdown
      if (currentWindow) {
        setCountdown(formatDistanceToNow(currentWindow.windowEnd));
      } else if (nextWindow) {
        setCountdown(formatDistanceToNow(nextWindow.windowStart, { addSuffix: true }));
      } else {
        setCountdown('');
      }
    };

    // Check immediately
    checkMaintenanceTime();

    // Then check at the specified interval
    const interval = setInterval(checkMaintenanceTime, MAINTENANCE_CHECK_INTERVAL_MS);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {activeWindow && (
        <Backdrop open={true} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Box sx={{ maxWidth: 600, width: '100%', m: 2 }}>
            <Alert
              severity="warning"

              icon={<WarningIcon sx={{ width: "4rem", height: "4rem", mr: 2 }} />}
              sx={{
                p: 4,
              }}
            >
              <AlertTitle sx={{ fontSize: '1.25rem', py: 1 }}>Planned Maintenance</AlertTitle>
              <Typography variant="body1">
                We are currently performing planned maintenance: {activeWindow.reason}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Expected completion: {format(activeWindow.windowEnd, 'h:mm a zzz')} ({getTimeZone()})
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Estimated time remaining: {countdown}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Thank you for your patience.
              </Typography>
            </Alert>
          </Box>
        </Backdrop>
      )}
      {upcomingWindow && (
        <AppBar position="static" color="primary" sx={{ top: 0, bottom: 'auto' }}>
          <Toolbar>
            <InfoIcon sx={{ mr: 2 }} />
            <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>
              Upcoming Maintenance: {upcomingWindow.reason} - Starts {countdown} at {format(upcomingWindow.windowStart, 'h:mm a zzz')} ({getTimeZone()})
            </Typography>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}

