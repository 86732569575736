import { ChargedPer, PaymentMethod } from "@/models";
import { CheckSingleIcon } from "@components/icons";
import { useAppContext } from "@context/AppContext";
import { Chip, Stack, Typography } from "@mui/material";
import { SubscriptionTier } from "@services/subscription/getPaymentSubscriptionPrice";

export default function Subscription() {
  const { profile, freeLeads, subscription, credits } = useAppContext();

  const hadFreeLeads = (profile?.lawyer.freeLeads ?? 0) > 0;
  const shouldShowFreeLeads = freeLeads !== undefined && hadFreeLeads;
  const unit = profile?.lawyer.chargedPer === ChargedPer.LEAD ? "lead" : "meeting";

  return (
    <Stack direction={"column"} spacing={1}>
      {subscription?.tiers?.map((tier: SubscriptionTier, index: number) => (
        <Stack key={index} direction={"row"} spacing={1} alignItems={"center"}>
          <Typography key={index} variant="body1">
            ${Math.floor(tier.unitAmount / 100).toFixed(2)} / {unit}{" "}
          </Typography>
          {tier.upTo && (
            <Typography variant="body2" color={"text.secondary"}>
              up to {tier.upTo} {unit}s
            </Typography>
          )}
          {!tier.upTo && subscription?.tiers?.length !== 1 && (
            <Typography variant="body2" color={"text.secondary"}>
              for the rest
            </Typography>
          )}
        </Stack>
      ))}
      {!subscription?.tiers &&
        subscription?.unitAmount &&
        profile?.lawyer?.paymentMethod === PaymentMethod.STRIPE_PAYG && (
          <Typography variant="body1">
            ${subscription.unitAmount / 100} / {unit}
          </Typography>
        )}

      {!subscription?.tiers &&
        subscription?.unitAmount &&
        profile?.lawyer?.paymentMethod !== PaymentMethod.STRIPE_PAYG && (
          <Typography variant="body1">
            ${subscription.unitAmount / 100} / month
          </Typography>
        )}
      {shouldShowFreeLeads ? (
        <Typography variant="body2" color={"text.secondary"}>
          * {freeLeads} free leads left
        </Typography>
      ) : null}
      {credits && (
        <Chip
          label={`You have $${credits / 100} in credit`}
          color="success"
          variant="outlined"
          icon={<CheckSingleIcon fontSize="small" />}
        />
      )}
    </Stack>
  );
}
