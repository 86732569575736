import { Button } from "@mui/material";

import { useGetLinkedinLoginUrl } from "@services/linkedin/getLinkedinLoginUrl";

interface LinkedinControlProps {
  lawyerId: string;
}

const LinkedinControl = ({ lawyerId }: LinkedinControlProps) => {
  const [getLoginUrl] = useGetLinkedinLoginUrl();

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    const url = await getLoginUrl({ lawyerId: lawyerId });
    const urlString = url && typeof url === 'object' ? Object.values(url).join('') : url;
    if (urlString) {
      window.location.href = urlString;
    }
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleClick}
    >
      Connect LinkedIn
    </Button>
  );
};

export default LinkedinControl;
